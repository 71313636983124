<template>
  <div>
    <div class="field">
      <label class="label">Select Tarps</label>
      <p class="subtitle">Select all tarps that were joined together.</p>
    </div>
    <div v-for="(base_tarp, index) in form.base_tarps" :key="index">
      <div class="field p-fluid">
        <div class="flex justify-content-between align-items-center">
          <label :for="`tj-tarp-${index}`" class="label small mb-0">Tarp {{ index + 1 }}</label>
          <Button severity="danger" class="remove-tarp-btn" text @click="removeTarp(index)" :disabled="deleteIsDisabled">
            <template #icon>
              <span class="material-symbols-outlined sm">delete</span>
            </template>
          </Button>
        </div>
        <SelectBaseTarpInput 
          v-model="form.base_tarps[index].base_tarp" 
          :key="`tj-tarp-${index}`"
          :baseTarps="maintainedTarps"
          :inputId="`tj-tarp-${index}`" />
        
          <Message v-if="joinedBaseTarps.includes(form.base_tarps[index].base_tarp?.serial_no)" severity="warn" :closable="false">
            This tarp is already joined with another tarp ({{ getJoinedTarp(form.base_tarps[index].base_tarp?.serial_no) }}).
          </Message>
      </div>
    </div>
    <div class="flex justify-center p-fluid py-3">
      <Button label="Add Tarp" severity="is-info" @click="addTarp" outlined />
    </div>
  </div>
</template>

<script>
import { useRecordTarpJoinsStore } from '@/stores/RecordTarpJoins';
import { useTarpInventoryStore } from '@/stores/TarpInventory';
import { useBaseTarpsStore } from '@/stores/BaseTarps';

import SelectBaseTarpInput from '../../../components/SelectBaseTarpInput.vue';

import Button from 'primevue/button';
import Message from 'primevue/message';

export default {
  name: "BaseTarps",
  components: {
    SelectBaseTarpInput,
    Button,
    Message,
  },
  created() {
    this.store = useRecordTarpJoinsStore();
    this.tarpInventory = useTarpInventoryStore();
    this.baseTarpsStore = useBaseTarpsStore();
  },
  computed: {
    form() {
      return this.store.tarp_join;
    },
    deleteIsDisabled() {
      return this.form.base_tarps.length <= 2;
    },
    joinedBaseTarps() {
      return this.tarpInventory.tarps.filter(
        t => t.serial_no.split('/').length > 1
      ).map(t => t.serial_no.split('/')).flat();
    },

    maintainedTarps() {
      const maintainedTarps = this.tarpInventory.getMaintainedTarps;
      const maintainedSerialNos = maintainedTarps.map(t => t.serial_no);
      return this.baseTarpsStore.base_tarps.filter(t => maintainedSerialNos.includes(t.serial_no));
    },
  },
  methods: {
    addTarp() {
      this.store.addTarp();
    },
    removeTarp(index) {
      this.store.removeTarp(index);
    },
    getJoinedTarp(serial_no) {
      const joinedTarps = this.tarpInventory.tarps.filter(t => t.serial_no.split('/').includes(serial_no));
      return joinedTarps.map(t => t.serial_no).join(', ');
    }
  }
}

</script>

<style scoped>
.remove-tarp-btn {
  margin-right: -1rem;
  padding: 0.3rem;
}
</style>