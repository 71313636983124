import { useSitesStore } from "@/stores/Sites";


class Storage {

  id = null;
  site = null;
  name = null;
  display_name = null;
  length = null;
  width = null;
  height = null;

  sitesStore = null;

  constructor() {
    this.sitesStore = useSitesStore();
  }

  toJSON() {
    return {
      id: this.id,
      site: this.site.id,
      name: this.name,
      display_name: this.display_name,
      length: this.length,
      width: this.width,
      height: this.height,
    }
  }

  fromJSON(data) {
    this.id = data.id;
    this.site = this.sitesStore.getSiteById(data.site);
    this.name = data.name;
    this.display_name = data.display_name;
    this.length = data.length;
    this.width = data.width;
    this.height = data.height;
    return this
  }

  displayName() {
    // Return the site verbose name or the name with _ replace with spaces and in title case
    return (this.display_name) ? this.display_name : this.name.toUpperCase()
  }

}

export default Storage
