import { defineStore } from 'pinia';
import { getOrCreateItem, getItems, updateStoreLastUpdated, clearStore, addItems, saveItem } from './idb.js';

import NewTarpsInput from './objects/NewTarpsInput.js';
import NewTarpsInputAPI from './api/NewTarpsInput.js';


export const useNewTarpsInputsStore = defineStore('newTarpsInputs', {
  state: () => ({ 
    new_tarps_inputs: [],
    lastUpdated: null,
    idbStoreName: 'newTarpsInputs',
  }),

  actions: {
    async loadNewTarpsInputsFromAPI() {
      // Fetch tarps from API and update indexedDB
      const api = new NewTarpsInputAPI();

      try {
        const response = await api.getNewTarpsInputs();
        const data = response.data;
        this.new_tarps_inputs = data;
        this.updateNewTarpsInputsDB(data);
        return response
      } catch (error) {
        console.error(error);
      }
    },

    async loadNewTarpsInputsFromDB() {
      console.log('Loading new tarps inputs from indexedDB...')
      const newTarpInputs = await getItems(this.idbStoreName);
      this.new_tarps_inputs = newTarpInputs;

      const newTarpInputsMeta = await getOrCreateItem('meta', this.idbStoreName);
      const lastUpdated = newTarpInputsMeta.lastUpdated;
      (lastUpdated) ? this.lastUpdated = lastUpdated : this.lastUpdated = null;
    },

    async loadNewTarpsInputs(awaitAPI) {
      // Load tarps from cache and then update from API in background
      await this.loadNewTarpsInputsFromDB();
      if (navigator.onLine) {
        const response = this.loadNewTarpsInputsFromAPI();
        if (awaitAPI) await response;
      }
    },

    async updateNewTarpsInputsDB(data) {
      // Clears and repopulates tarp works in indexedDB
      clearStore(this.idbStoreName);

      // Add data
      addItems(this.idbStoreName, data);

      // Update last updated timestamp
      const now = Date.now();
      this.lastUpdated = now;
      updateStoreLastUpdated(this.idbStoreName, now);
    },

    async addNewTarpsInput(newTarpInput) {
      saveItem(this.idbStoreName, newTarpInput.id, newTarpInput);
      this.new_tarps_inputs.push(newTarpInput);
    }

  },

  getters: {

    getNewTarpsInputs(state) {
      // Return all tarp joins, ordered by completion time (newest first)
      const sortedNewTarpsInputs = state.new_tarps_inputs.sort((a, b) => {
        return new Date(b.completion_time) - new Date(a.completion_time);
      });
      return sortedNewTarpsInputs.map(nti => new NewTarpsInput().fromJSON(nti));
    },
    
  },

})