import { default as axios } from './http';


class CommoditiesAPI {

    getCommodities = async () => {
        // Fetch stacks from API
        console.log('Fetching commodities from API...')
        const url = '/api/v1/grain-sites/commodities/';
        const response = await axios.get(url)
        return response
    }

}

export default CommoditiesAPI;