<template>
  <div class="field p-fluid">
    <label for="nt-serial-no-1">Serial Number</label>
    <div v-if="serialNoCount === 1">
      <NumberInput 
        inputId="nt-serial-no-1" 
        ref="nt-serial-no-1" 
        v-model="serial_no_1"
        class="w-full" 
        :useGrouping="false"
        :maxFractionDigits="2"
        :min="0"
      />
    </div>
    <div v-else>
      <InputGroup>
        <NumberInput 
          inputId="nt-serial-no-1" 
          ref="nt-serial-no-1" 
          v-model="serial_no_1" 
          class="w-full" 
          :useGrouping="false"
          :maxFractionDigits="2"
          :min="0"
        />
        <InputGroupAddon>
            <span>/</span>
        </InputGroupAddon>
        <NumberInput 
          inputId="nt-serial-no-2" 
          ref="nt-serial-no-2" 
          v-model="serial_no_2" 
          class="w-full" 
          :useGrouping="false"
          :maxFractionDigits="2"
          :min="0"
        />
      </InputGroup>
    </div>
    <div v-if="autoGenerate === true">
      <Button 
        type="button"
        severity="info"
        label="Generate New IAS Serial Number"
        @click="generateSerialNumber" 
        class="mt-2"
        :loading="loading"
      />
    </div>
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue';
import { useRecordNewTarpStore } from '@/stores/RecordNewTarp';

import Button from 'primevue/button';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';

import NumberInput from '@/components/forms/NumberInput.vue';


export default {
  name: "SerialNumberField",
  components: {
    Button,
    InputGroup,
    InputGroupAddon,
    NumberInput,
  },
  
  props: [
    'modelValue',
    'type',
    'coverType',
    'autoGenerate',
  ],
  data() { return {
    loading: false,
  }},
  computed: {
    serial_no_1: {
      get() {
        if (this.type === 'single') {
          return parseInt(this.modelValue) || null;
        } else {
          return parseInt(this.modelValue?.split('/')[0]) || null;
        }
      },
      set(value) {
        if (this.type === 'single') {
          this.$emit('update:modelValue', value);
        } else {
          const serial_no_2 = (value) ? value + 1 : null;
          if (value === null) {
            this.$emit('update:modelValue', null);
          } else if (value !== null) {
            this.$emit('update:modelValue', `${value}/${serial_no_2}`);
          }
        }
      }
    },
    serial_no_2: {
      get() {
        if (this.type === 'single') {
          return null;
        } else {
          const serialNumbers = this.modelValue?.split('/');
          if (serialNumbers?.length === 2) {
            return parseInt(serialNumbers[1]) || null;
          } else {
            return null;
          }
        }
      },
      set(value) {
        if (this.type === 'single') {
          return null;
        } else {
          if (value === null && this.serial_no_1 === null) {
            this.$emit('update:modelValue', null);
          } else if (value !== null && this.serial_no_1 === null) {
            this.$emit('update:modelValue', `/${value}`);
          } else if (value === null && this.serial_no_1 !== null) {
            this.$emit('update:modelValue', `${this.serial_no_1}`);
          } else if (value !== null && this.serial_no_1 !== null) {
            this.$emit('update:modelValue', `${this.serial_no_1}/${value}`);
          }
        }
      }
    },

    serialNoCount() {
      return (this.type === 'single' || this.coverType === 'end') ? 1 : 2;
    }
  },

  watch: {
    coverType(newValue, oldValue) {
      if (newValue === 'end') {
        this.serial_no_2 = null;
      }
      if (newValue === 'straight' && !this.serial_no_2) {
        // Retrigger the setter to generate serial no 2
        this.serial_no_1 = this.serial_no_1;
      }
    }
  },

  methods: {
    async generateSerialNumber() {
      this.loading = true;

      const store = useRecordNewTarpStore();

      const count = this.serialNoCount;

      try {
        const serialNumber = await store.generateSerialNo(count, false);
        this.$emit('update:modelValue', serialNumber);
      } catch (error) {
        Sentry.captureException(error);
        // Check if there is an internet connection
        this.$root.errorNotification(error.message);
      } finally {
        this.loading = false;
      }
      
    },
  }

}

</script>