<template>

    <Calendar
        :inputId="inputId"
        ref="calendar"
        :modelValue="datetime"
        dateFormat="dd/mm/yy"
        :showTime="showTime"
        hourFormat="24"
        :stepMinute="5"
        touchUI
        @dateSelect="dateSelected"
        placeholder="Click to select...">

        <template #footer>
            <div class="field p-fluid" :class="{ 'mt-3': !showTime }">
                <Button 
                    label="Select" 
                    severity="info"
                    @click="select" />
            </div>
        </template>

    </Calendar>

</template>

<script>
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';

import { dateToISOString } from '@/utils/dates';

export default {
    name: "DatetimePicker",
    components: {
        Button,
        Calendar
    },
    props: {
        modelValue: {
            type: [Date, String, null],
            required: true,

        },
        inputId: {
            type: String,
            required: false
        },
        showTime: {
            type: Boolean,
            required: false,
            default: true
        },
    },
    data() { return {
        datetime: null,
        localValue: null,
    }},
    created() {
        (this.modelValue) ? this.datetime = new Date(this.modelValue) : this.datetime = null;
    },
    watch: {
        modelValue(val) {
            if (val) {
                this.datetime = new Date(val);
            } else {
                this.datetime = null;
            }
        }
    },
    methods: {
        select() {
            if (!this.datetime) {
                this.dateSelected(new Date());
            }
            this.$refs.calendar.overlayVisible = false;
        },
        dateSelected(date) {
            this.datetime = date;
            this.$emit('update:modelValue', dateToISOString(date));
        },
    }
}

</script>
