<template>
    <nav class="top-nav-bar fixed top-0 left-0 right-0 w-full bg-primary">
        <div class="nav-items flex align-items-center justify-content-between">
            <div class="nav-left text-left">
                <AppDropdownMenu v-if="$route.meta.showBurgerMenu !== false" />
            </div>
            <div class="nav-center">
                <h2>{{ pageTitle }}</h2>
            </div>
            <div class="nav-right text-right flex align-items-center justify-content-end" style="width: 50px">
                <component
                    v-if="$route.meta.navigationAction"
                    :is="$route.meta.navigationAction"
                />
            </div>
        </div>
    </nav>
</template>

<script>
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import AppDropdownMenu from './components/AppDropdownMenu.vue';
import SectionDropdownMenu from './components/SectionDropdownMenu.vue';

export default {
    name: "TopNavigationBar",
    components: {
        Button,
        Menu,
        AppDropdownMenu,
        SectionDropdownMenu,
    },
    computed: {
        pageTitle() {
            return this.$route.meta.title
        }
    }
}

</script>

<style scoped>
.top-nav-bar {
    height: var(--top-nav-bar-height);
    border-bottom: 1px solid grey;
    margin: 0;
    padding: 5px 15px 10px;
    z-index: 100;
}
.nav-left, .nav-right {
    width: 50px;
}
.left-menu-btn {
    padding: 2px;
    width: 28px;
    height: 28px;
    min-height: 28px;
    border-radius: 5px;
    font-size: 16px;
    color: #333;
    background: white;
    border: none;
}
.left-menu-btn,
.left-menu-btn:hover,
.left-menu-btn:focus {
    box-shadow: none;
    color: #333;
    background: white;
}

.menu-item-content {
    color: #333;
    padding: 10px;
    font-weight: 500;
}

</style>
