<template>
    <div v-if="viewReady" class="view">
        <div class="p-3">
            <QuickLinks class="mb-3" />
            <DownloadSiteDataCard />
            <UnsavedGCMWorks class="mt-4" />
        </div>
    </div>
</template>

<script>
import DownloadSiteDataCard from '@/components/cards/DownloadSiteDataCard.vue';
import QuickLinks from './components/QuickLinks.vue';
import UnsavedGCMWorks from './components/UnsavedGCMWorks/index.vue';


export default {
  name: "GCMHomeView",
  components: {
    DownloadSiteDataCard,
    QuickLinks,
    UnsavedGCMWorks,
  },
  data() { return {
    // Flags
    viewReady: true,
    loading: false,
  }},
}

</script>
