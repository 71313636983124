<template>
    <div class="radio-button-wrapper flex align-items-center"
        :class="classes"
        @click="update(value)">

        <RadioButton v-if="!multiple && !binary" :modelValue="modelValue" :value="value" :name="name" />
        <Checkbox v-else :modelValue="modelValue" :value="value" :name="name" :binary="binary" />

        <label v-if="label" class="ml-2">{{ label }}</label>
        <div v-else-if="labelHTML" v-html="labelHTML" class="ml-2"></div>
    </div>
</template>

<script>
import Checkbox from 'primevue/checkbox';
import RadioButton from 'primevue/radiobutton';


export default {
    name: "RadioSelect",
    props: ['modelValue', 'value', 'label', 'labelHTML', 'name', 'multiple', 'binary'],
    components: {
        Checkbox,
        RadioButton,
    },
    computed: {
        classes() {
            let modelValue;
            (this.multiple) ? modelValue = this.modelValue : modelValue = [this.modelValue];
            return {
                'bg-blue': modelValue.includes(this.value),
                'bg-gray-200': !modelValue.includes(this.value),
                'text-white': modelValue.includes(this.value),
            }
        }
    },
    methods: {
        update(value) {
            let newValue;
            if (this.binary) {
                newValue = !this.modelValue;
            } else if (this.multiple) {
                // If the current value is already in the array, remove it
                if (this.modelValue.includes(value)) {
                    newValue = [...this.modelValue.filter(v => v !== value)];
                // Otherwise, add it
                } else {
                    newValue = [...this.modelValue.concat(value)];
                }
            } else {
                newValue = value;
            }
            this.$emit('update:modelValue', newValue);
        }
    }
}

</script>

<style scoped>
.radio-button-wrapper {
    width: 100%;
    padding: 1rem;
    border-radius: 5px;
}
</style>