import { defineStore } from 'pinia';

import { MAINTENANCE_TYPES } from './enums/OBHM';

import { useRecordOBHWorksStore } from "@/stores/RecordOBHWorks";
import { useRecordSiteWorksStore } from "@/stores/RecordSiteWorks";


export const useRecordOBHMStore = defineStore('recordOBHM', {

  state: () => ({
    activeMaintenanceType: null,
  }),

  actions: {

    setActiveMaintenanceType(maintenanceType) {
      this.activeMaintenanceType = maintenanceType;
    },

    reset() {
      const storesMap = {
        site_works: useRecordSiteWorksStore(),
        obh_works: useRecordOBHWorksStore(),
      }
      const store = storesMap[this.activeMaintenanceType];
      if (store) store.reset(true);
    }

  },

  getters: {

    getActiveMaintenanceType() {
      return MAINTENANCE_TYPES.find(mt => mt.value === this.activeMaintenanceType);
    }

  }
})