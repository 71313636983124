<template>
    <div>
        <h3 class="font-bold mb-3">Unsaved Tarping Works</h3>
        
        <div v-if="unsavedTarpingWorks.length === 0">
            <p class="text-gray-500 mt-1">No unsaved tarping works.</p>
        </div>
        <div v-else>
            <div v-for="tarpingWorks in unsavedTarpingWorks" :key="tarpingWorks.client_id" class="mb-2">
                <UnsavedTarpingWorksCard :tarpingWorks="tarpingWorks" />
            </div>
        </div>
    </div>

</template>

<script>
import Button from 'primevue/button';
import Card from 'primevue/card';

import { useRecordTarpingWorksStore } from '@/stores/RecordTarpingWorks';
import TarpingWorks from '@/stores/objects/TarpingWorks';
import UnsavedTarpingWorksCard from './components/UnsavedTarpingWorksCard.vue';

export default {
    name: "UnsavedTarpingWorks",
    components: {
        Button,
        Card,
        UnsavedTarpingWorksCard,
    },
    data() { return {
        loading: false
    }},
    created() {
        const store = useRecordTarpingWorksStore();
        this.store = store;
    },
    computed: {
        unsavedTarpingWorks() {
            console.log(this.store.unsaved_tarping_works);
            return this.store.unsaved_tarping_works.map(tarpingWorks => new TarpingWorks().fromJSON(tarpingWorks));
        },
    },
    methods: {
        uploadTarpingWorks(tarpingWorks) {
            console.log(tarpingWorks);
        }
    }
}

</script>