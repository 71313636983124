<template>
  <div>
    <ul class="attr-list" :class="{ striped: striped }">
      <li v-for="item in items" :key="item.label">
        <span class="attr">
          {{ item.label }}
        </span>
        <span class="value" :class="item.bgClass">
          <div v-if="Array.isArray(item.value)">
            <ul class="value-list">
              <li v-for="val in item.value" :key="val">
                {{ val }}
              </li>
            </ul>
          </div>
          <span v-else>
            {{ item.value }}
          </span>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: "KeyValueList",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    striped: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getValue(item) {
      // If the items value is an array, 
    }
  }
}

</script>

<style scoped>
.attr-list {
  list-style: none;
  padding: 0;
  margin-left: -6px;
  margin-right: -6px;
}
.attr-list li {
  display: flex;
  justify-content: space-between;
  padding: 6px;
}
.attr-list.striped li:nth-child(odd) {
  background-color: #f2f2f2;
}
.attr-list li .attr {
  font-weight: bold;
  width: 155px;
  flex-shrink: 0;
}
.attr-list li .value {
  flex-grow: 1;
  text-align: left;
}
.attr-list li .value .value-list {
  list-style: none;
  padding: 0;
  padding-inline-start: 0;
}
.attr-list li .value .value-list li {
  padding: 0;
  margin: 0;
}
</style>