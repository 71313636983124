import { v4 as uuidv4 } from 'uuid';

import { ISOStringToDate, dateToISOString } from "../../utils/dates";
import { useBaseTarpsStore } from '@/stores/BaseTarps';
import { useRecordTarpJoinsStore } from '@/stores/RecordTarpJoins';
import { useTarpInventoryStore } from '@/stores/TarpInventory';
import { useTarpJoinUsersStore } from '@/stores/TarpJoinUsers';

import TarpJoinsAPI from '../api/TarpJoins';
import Supervisor from './Supervisor';


class TarpJoinBaseTarp {

  base_tarp = null;
  position = null;

  constructor(position = null) {
    this.position = position;
    this.base_tarp_store = useBaseTarpsStore();
  }

  fromJSON(data) {
    if (data.base_tarp) {
      if (typeof data.base_tarp === 'object') {
        if (data.base_tarp.id) {
          this.base_tarp = this.base_tarp_store.getBaseTarpById(data.base_tarp.id);
        } else {
          this.base_tarp = data.base_tarp
        }
      } else {
        this.base_tarp = this.base_tarp_store.getBaseTarpById(data.base_tarp);
      }
    }
    this.position = data.position;

    return this;
  }

  toJSON() {
    return {
      base_tarp: this.base_tarp?.id,
      new_base_tarp_data: this.base_tarp?.id ? null : this.base_tarp,
      position: this.position,
    }
  }

  isValid() {
    if (!this.base_tarp) return false;
    if (!this.position) return false;
    return true;
  }

}

class TarpJoin {

  id = null;
  client_id = null;

  completion_time = null;
  supervisor = null;
  join_type = null;
  tarp = null;
  tarp_sn = null;
  base_tarps = [new TarpJoinBaseTarp(1), new TarpJoinBaseTarp(2)]
  lead_welder_stitcher = null;
  quality_controller = null;
  comments = null;

  // Soft confirmation for quality control
  confirm_standards_followed = false;

  activity_type = 'tarp_join';

  base_tarp_store = null;

  constructor() {
    this.client_id = uuidv4();
    this.base_tarp_store = useBaseTarpsStore();
    this.tarp_inventory = useTarpInventoryStore();
    this.tarp_join_users = useTarpJoinUsersStore();
  }

  // Adapters

  toJSON() {
    return {
      id: this.id,
      client_id: this.client_id,
      completion_time: dateToISOString(this.completion_time),
      supervisor: this.supervisor?.toJSON(),
      join_type: this.join_type,
      comments: this.comments,
      tarp: this.tarp?.id,
      tarp_sn: this.tarp_sn,
      base_tarps: this.base_tarps.map(bt => bt.toJSON()),
      lead_welder_stitcher: this.lead_welder_stitcher?.id,
      quality_controller: this.quality_controller?.id,
      confirm_standards_followed: this.confirm_standards_followed || false,
    }
  }

  fromJSON(data) {
    this.id = data.id;
    this.client_id = data.client_id;
    this.completion_time = ISOStringToDate(data.completion_time);
    this.supervisor = new Supervisor().fromJSON(data.supervisor);
    this.join_type = data.join_type;
    this.comments = data.comments;

    if (data.tarp) this.tarp = this.tarp_inventory.getTarpById(data.tarp);
    this.tarp_sn = data.tarp_sn;

    if (data.base_tarps) {
      this.base_tarps = data.base_tarps.map(bt => new TarpJoinBaseTarp().fromJSON(bt));
    }

    (data.lead_welder_stitcher) ? this.lead_welder_stitcher = this.tarp_join_users.getUserById(data.lead_welder_stitcher) : this.lead_welder_stitcher = null;
    (data.quality_controller) ? this.quality_controller = this.tarp_join_users.getUserById(data.quality_controller) : this.quality_controller = null;

    this.confirm_standards_followed = data.confirm_standards_followed || false;

    return this
  }

  updateTarpJoinInStores(response) {
    // Removes the tarping works from the locally saved tarping works and 
    // adds the updated tarping works to the tarping works store.
    const recordTarpJoinsStore = useRecordTarpJoinsStore();
    recordTarpJoinsStore.removeTarpJoinFromLocal(response.data.client_id);
    recordTarpJoinsStore.addToTarpJoinsStore(response.data);
  }

  syncToServer = async (data) => {
    if (!data) data = this.toJSON();

    const api = new TarpJoinsAPI();

    let response;

    try {
      
      response = await api.createTarpJoin(data);

      if ([200, 201].includes(response.status)) {
        this.updateTarpJoinInStores(response);
      }

      return response;

    } catch (error) {

      //Sentry.captureException(error);
      console.log(error);

      if (error.response) {
        if (error.response.status === 409) {
          // 409 is returned if the tarping works has already been created on the server
          this.updateTarpJoinInStores(error.response);
        }
        return error.response;
      } else {
        throw error;
      }
      
    }
  }

  // Getters

  isValid() {
    if (!this.completion_time) return false;
    if (!this.join_type) return false;
    if (this.base_tarps.length < 2) return false;
    for (const bt of this.base_tarps) {
      if (!bt.isValid()) return false;
    }
    if (!this.lead_welder_stitcher) return false;
    if (!this.quality_controller) return false;
    if (!this.confirm_standards_followed) return false;
    return true;
  }

  displayName() {
    const tarpSerialNo = this.tarp_sn || this.base_tarps?.map(bt => bt.base_tarp.serial_no).join('/');
    return 'Tarp Join - ' + tarpSerialNo;
  }

  completionTimeDisplay() {
    const completionTime = this.completion_time;
    if (!completionTime) return 'Unknown Completion Time';
    return new Date(completionTime).toLocaleString();
  }

  joinsCount() {
    return this.base_tarps.length - 1;
  }

  get tableGlobalFilterValues() {
    return [
      this.activity_type,
      this.tarp_sn,
    ]
  }
}

export {
  TarpJoinBaseTarp,
  TarpJoin
};
