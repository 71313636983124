import { defineStore } from 'pinia';

import { getOrCreateItem, getItems, deleteItem } from './idb.js';
import { useNewTarpsInputsStore } from './NewTarpsInputs.js';
import { useTarpInventoryStore } from './TarpInventory.js';
import NewTarpsInput from './objects/NewTarpsInput.js';


export const useRecordNewTarpsInputsStore = defineStore('recordNewTarpsInputs', {

  persist: {
    paths: ['new_tarps_input']
  },

  state: () => ({
    new_tarps_input: new NewTarpsInput().toJSON(),
    unsaved_new_tarps_inputs: [],

    // Submission status
    submitted: false,
    submitComplete: false,
    loading: false,
    saveResponse: null,
  }),

  actions: {

    init() {
      const new_tarps_input = new NewTarpsInput();
      if (!this.new_tarps_input) this.new_tarps_input = new_tarps_input.toJSON();
      if (this.submitted) this.reset(true);
    },

    reset(resetSubmissionStatus = false) {
      this.new_tarps_input = new NewTarpsInput().toJSON();
      if (resetSubmissionStatus) {
        this.submitted = false;
        this.submitComplete = false;
        this.loading = false;
        this.saveResponse = null;
      }
    },

    async saveNewTarpsInput() {
      const data = this.new_tarps_input;

      console.log('Saving new_tarps_input', data);

      // Save the new_tarps_inputs to local device
      this.saveNewTarpsInputToLocal(data);

      if (navigator.onLine) {
        try {

          this.loading = true;

          // syncToServer doesn't throw an error if the request fails.
          // Status is returned and handled by the component calling this action.
          const response = await new NewTarpsInput().syncToServer(data);
          this.saveResponse = response;

          this.loading = false;

          return response;

        } catch (error) {
          throw error;
        }
      } else {
        this.saveResponse = { status: 0 };  // Offline
        return this.saveResponse
      }
    },
    
    saveNewTarpsInputToLocal(new_tarps_input) {
      // Check if the new_tarps_input have already been added to the unsaved_tarping_works array
      const existingNewTarpsInput = this.unsaved_new_tarps_inputs.filter(tr => tr.client_id === new_tarps_input.client_id);
      if (existingNewTarpsInput.length) {
        // If the new_tarps_input already exist in the array, replace them with the new new_tarps_input
        this.unsaved_new_tarps_inputs = this.unsaved_new_tarps_inputs.map(tr => {
          if (tr.client_id === new_tarps_input.client_id) {
            return new_tarps_input
          }
          return tj
        });
      } else {
        // Add new_tarps_input to the unsaved_tarp_join array
        this.unsaved_new_tarps_inputs.push(new_tarps_input);
      }
      // Save the unsaved new_tarps_input to indexedDB
      this.saveUnsavedNewTarpsInputToDB(new_tarps_input);
    },

    removeNewTarpsInputFromLocal(clientId) {
      this.unsaved_new_tarps_inputs = [...this.unsaved_new_tarps_inputs.filter(tj => tj.client_id !== clientId)];
      this.removeUnsavedNewTarpsInputFromDB(clientId);
    },

    addToNewTarpsInputStore(new_tarps_input) {
      const tarpInventoryStore = useTarpInventoryStore();

      // Add the new tarps
      const tarps = new_tarps_input.created_tarps;
      for (const tarp of tarps) {
        tarpInventoryStore.addTarp(tarp);
      }

      // Remove archived tarps
      const archivedTarps = new_tarps_input.archived_tarps;
      if (archivedTarps?.length) {
        tarpInventoryStore.removeTarps(archivedTarps);
      }
       
      // Add the new_tarps_input to the new_tarps_input store
      const newTarpsInputStore = useNewTarpsInputsStore();
      newTarpsInputStore.addNewTarpsInput(new_tarps_input);
    },

    async saveUnsavedNewTarpsInputToDB(new_tarps_input) {
      await getOrCreateItem('unsavedNewTarpsInputs', new_tarps_input.client_id, new_tarps_input);
    },

    async removeUnsavedNewTarpsInputFromDB(clientId) {
      await deleteItem('unsavedNewTarpsInputs', clientId);
    },

    async loadUnsavedNewTarpsInputsFromDB() {
      const unsavedNewTarpsInputs = await getItems('unsavedNewTarpsInputs');
      this.unsaved_new_tarps_inputs = unsavedNewTarpsInputs;
    },

  },

  getters: {

    inputsValid() {
      const newTarpsInput = this.getNewTarpsInput;
      return newTarpsInput.isValid();
    },

    getNewTarpsInput() {
      return new NewTarpsInput().fromJSON(this.new_tarps_input);
    },

    getUnsavedNewTarpsInputs() {
      return this.unsaved_new_tarps_inputs.map(sp => new NewTarpsInput().fromJSON(sp));
    },

    getErrors() {
      if (this.saveResponse?.status === 400) {
        return this.saveResponse?.data;
      }
      return [];
    }

  }
})