<template>
    <div>
        <RadioSelect
            class="mb-2"
            :modelValue="modelValue"
            :value="true"
            label="Yes"
            @update:modelValue="update"/>
        <RadioSelect
            class="mb-2"
            :modelValue="modelValue"
            :value="false"
            label="No"
            @update:modelValue="update"/>
    </div>
</template>

<script>
import RadioSelect from '@/components/forms/RadioSelect.vue';

export default {
    name: "StackClosedOutSelect",
    props: ['modelValue'],
    components: {
        RadioSelect,
    },
    methods: {
        update(value) {
            this.$emit('update:modelValue', value)
        }
    }
}

</script>