<template>
  <TopNavigationBar v-show="$route.meta.displayTopNav !== false" />

  <RouterView v-if="initialised" />

  <BottomNavigationBar />

  <DynamicDialog />
  <Toast position="bottom-center">
    <template #icon></template>
    <template #message="slotProps">
      <span v-if="slotProps.message.severity == 'success'" class="material-symbols-outlined">check</span>&nbsp;
      <span v-if="slotProps.message.severity == 'error'" class="material-symbols-outlined">error</span>&nbsp;
      <span>{{ slotProps.message.summary }}</span>
    </template>
  </Toast>

  <FullScreenLoader v-if="!initialised" label="Downloading app data..." />
  
</template>

<script>
import BottomNavigationBar from './navigation/BottomNavigationBar.vue';
import TopNavigationBar from './navigation/TopNavigationBar/index.vue';
import Dialog from 'primevue/dialog';
import DynamicDialog from 'primevue/dynamicdialog';
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';

import { useAuthStore } from '@/stores/Auth';
import { useUIStore } from '@/stores/UI';
import { loadAppData } from '@/utils/data';

import LoginForm from '@/components/auth/LoginForm.vue';
import FullScreenLoader from '@/components/loading/FullScreenLoader.vue'

export default {
  name: "App",
  components: {
    BottomNavigationBar,
    TopNavigationBar,
    Dialog,
    DynamicDialog,
    Toast,
    LoginForm,
    FullScreenLoader,
  },
  data() { return  {
    initialised: false,
    authStore: null,
  }},
  created() {
    this.toast = useToast();
    this.UIStore = useUIStore();
    this.init();
  },
  methods: {

    showNotification(severity = 'info', message = 'Success', life = 2000) {
      this.toast.add({
        severity: severity,
        summary: message,
        closable: false,
        life: life
      });
    },

    successNotification(msg) {
      this.showNotification('success', msg);
    },

    errorNotification(msg) {
      this.showNotification('error', msg);
    },

    noInternetConnectionDetected() {
      this.showNotification('error', 'No internet connection detected');
    },

    async init() {
      // Init all stores and load data
      this.authStore = useAuthStore();

      // Check if user is authenticated
      const accessToken = this.authStore.accessToken;
      if (!accessToken) {
        // Redirect to login
        this.$router.push({ name: 'login' });
      } else {
        await loadAppData(false);

        // Pause for a second
        await new Promise(resolve => setTimeout(resolve, 1500));

        // Redirect user to active app
        const activeView = this.UIStore.activeView;
        if (activeView) {
          try {
            this.$router.push({ name: activeView });
            console.log('Redirecting to:', activeView);
          } catch (error) {
            console.error(error);
            // Redirect to home if active app is not found
            this.$router.push({ name: 'home' });
          }
        }
        
      }

      this.initialised = true;
    },

  }
}

</script>

<style scoped>
header {
  line-height: 1.5;
  max-height: 100vh;
}

.logo {
  display: block;
  margin: 0 auto 2rem;
}

nav {
  width: 100%;
  font-size: 12px;
  text-align: center;
  margin-top: 2rem;
}

nav a.router-link-exact-active {
  color: var(--color-text);
}

nav a.router-link-exact-active:hover {
  background-color: transparent;
}

nav a {
  display: inline-block;
  padding: 0 1rem;
  border-left: 1px solid var(--color-border);
}

nav a:first-of-type {
  border: 0;
}

@media (min-width: 1024px) {
  header {
    display: flex;
    place-items: center;
    padding-right: calc(var(--section-gap) / 2);
  }

  .logo {
    margin: 0 2rem 0 0;
  }

  header .wrapper {
    display: flex;
    place-items: flex-start;
    flex-wrap: wrap;
  }

  nav {
    text-align: left;
    margin-left: -1rem;
    font-size: 1rem;

    padding: 1rem 0;
    margin-top: 1rem;
  }
}
</style>
