<template>
  <UnsavedItemCard 
    :item="OBHMWorks" 
    :label="OBHMWorks.displayName()" 
    :completion_time="OBHMWorks.completionTimeDisplay()"
    :loading="loading"
    @saveItem="uploadOBHMWorks"
    @editItem="editOBHMWorks"
    @deleteItem="deleteOBHMWorks"
  />
</template>

<script>
import Button from 'primevue/button';
import Card from 'primevue/card';
import SpeedDial from 'primevue/speeddial'
import ConfirmDialog from 'primevue/confirmdialog';

import { useRecordSiteWorksStore } from '@/stores/RecordSiteWorks';
import { useRecordOBHWorksStore } from '@/stores/RecordOBHWorks';
import { useRecordOBHMStore } from '@/stores/RecordOBHM';

import UnsavedItemCard from '@/site_components/UnsavedItemCard/index.vue';

export default {
  name: "UnsavedOBHMWorksCard",
  props: ['OBHMWorks'],
  components: {
    Button,
    Card,
    ConfirmDialog,
    SpeedDial,
    UnsavedItemCard,
  },
  data() { return {
    store: null,
    loading: false,
    speedDial: {
      visible: false,
      items: [
        {
          label: 'Save',
          icon: 'upload',
          command: this.uploadOBHMWorks
        },
        {
          label: 'Edit',
          icon: 'edit',
          command: this.editOBHMWorks
        },
        {
          label: 'Delete',
          icon: 'delete',
          command: this.deleteOBHMWorks
        },
      ]
    }
  }},
  created() {
    this.stores = {
      site_works: useRecordSiteWorksStore(),
      obh_works: useRecordOBHWorksStore(),
    }

    this.recordOBHMStore = useRecordOBHMStore();
  },
  methods: {

    async uploadOBHMWorks() {
      this.loading = true;

      if (navigator.onLine) {
        try {
          await this.OBHMWorks.syncToServer();
          this.$root.successNotification('OBHM works uploaded successfully!');
        } catch (e) {
          this.$root.errorNotification(e);
        }
      } else {
        this.$root.noInternetConnectionDetected();
      }

      this.loading = false;
    },

    editOBHMWorks() {
      const store = this.stores[this.OBHMWorks.activity_type];

      store.$patch({ [this.OBHMWorks.activity_type]: this.OBHMWorks.toJSON() } );
      this.recordOBHMStore.setActiveMaintenanceType(this.OBHMWorks.activity_type)

      this.$router.push({
        name: 'obhm_record',
      });
    },

    deleteOBHMWorks() {
      const deleteFunctions = {
        site_works: this.stores.site_works.removeSiteWorksFromLocal,
        obh_works: this.stores.obh_works.removeOBHWorksFromLocal,
      }
      deleteFunctions[this.OBHMWorks.activity_type](this.OBHMWorks.client_id);
      this.$root.successNotification('OBHM works deleted.');
    },

  }
}

</script>