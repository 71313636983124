import axios from 'axios';
import { defineStore } from 'pinia';
import { getOrCreateDB, getOrCreateItem, updateStoreLastUpdated } from './idb.js';

import Storage from './objects/Storage.js';
import StoragesAPI from './api/Storages.js';


export const useStoragesStore = defineStore('storages', {
  state: () => ({ 
    storages: [],
    favourites: [],
    lastUpdated: null,
  }),

  actions: {
    
    async loadStoragesFromAPI() {
      // Fetch storages from API and update indexedDB
      const api = new StoragesAPI();
      const response = await api.getStorages();

      const data = response.data;
      this.storages = data;
      this.updateStoragesDB(data);

      return response
    },

    async loadStoragesFromDB() {
      console.log('Loading storages from indexedDB...')
      const db = await getOrCreateDB();
      const tx = db.transaction(['storages', 'meta'], 'readwrite');
      const storagesStore = tx.objectStore('storages');
      const storages = await storagesStore.getAll();
      console.log(`${storages.length} storages loaded from indexedDB`);
      this.storages = storages;

      const storagesMeta = await getOrCreateItem('meta', 'storages');
      const favourites = storagesMeta.favourites || [];
      this.favourites = favourites;
      const lastUpdated = storagesMeta.lastUpdated;
      (lastUpdated) ? this.lastUpdated = lastUpdated : this.lastUpdated = null;

      await tx.done;
    },

    async loadStorages(awaitAPI) {
      // Load storages from cache and then update from API in background
      await this.loadStoragesFromDB();
      if (navigator.onLine) {
        const response = this.loadStoragesFromAPI();
        if (awaitAPI) await response;
      }
    },

    async updateStoragesDB(data) {
      // Update tarps indexedDB
      const db = await getOrCreateDB();
      const tx = db.transaction(['storages', 'meta'], 'readwrite');
      const storagesStore = tx.objectStore('storages');
      // Clear existing data
      await storagesStore.clear();
      // Add data
      data?.forEach(storage => {
        storagesStore.add(storage)
      });
      // Update last updated timestamp
      const now = Date.now();
      this.lastUpdated = now;
      console.log(`Updating last updated timestamp to ${now}`);
      updateStoreLastUpdated('storages', now, tx);
      await tx.done;
    },

  },

  getters: {

    getStorageById(state) {
      return (storageId) => {
        // Returns storage object from storage id
        const storage = state.storages.find(storage => storage.id === storageId)
        if (storage) return new Storage().fromJSON(storage)
        else         return null
      }
    },

    getStorages(state) {
      return state.storages.map(storage => new Storage().fromJSON(storage))
    },

    getStoragesBySite(state) {
      return (siteId) => {
        // Returns storages objects from site id
        if (siteId === null) return []
        const storages = state.storages.filter(storage => storage.site === siteId)
        return storages.map(storage => new Storage().fromJSON(storage))
      }
    }

  }
})