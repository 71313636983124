import axios from "axios";

import { useAuthStore } from "@/stores/Auth";
import router from '@/router';


const http = axios.create();

// Set config defaults when creating the instance
const ENVIRONMENT = import.meta.env.MODE;
if (ENVIRONMENT === 'development') {
  http.defaults.baseURL = import.meta.env.VITE_API_BASE_URL;
} else {
  // Use the current domain as the base url
  http.defaults.baseURL = window.location.origin;
}


let tokenRefreshFunc = null;

function isUnauthorizedError(error) {
  if (error?.response === undefined) {
    return false;
  }
  const {
      response: { status, statusText },
  } = error;
  return status === 401;
}

function loginRedirect() {
  router.push({ name: 'login' });
}


// Set the AUTH token for any request
http.interceptors.request.use(function (config) {
  const authStore = useAuthStore();
  const token = authStore.accessToken;
  config.headers.Authorization =  token ? `Bearer ${token}` : '';
  return config;
});


http.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  async (error) => {
    console.log('error', error)
    const originalConfig = error.config;

    const authStore = useAuthStore();

    const token = authStore.accessToken;

    // Redirect to login if no token
    if (!token) {
      loginRedirect();
    }

    const whiteListedUrls = [
      // Login url
      '/api/v1/accounts/auth/token/',
      // Refresh token url
      '/api/v1/accounts/auth/token/refresh/',
    ];

    // Throw error if not unauthorized or if the url is whitelisted
    if (!isUnauthorizedError(error) || whiteListedUrls.includes(originalConfig.url)) {
      return Promise.reject(error);
    }

    // If we have gotten to this point, it means the user has a token, but it is expired
    try {
      if (!tokenRefreshFunc) {
        console.log('Refreshing token...');
        tokenRefreshFunc = authStore.refreshAccessToken();
      }

      const newAccessToken = await tokenRefreshFunc;

      originalConfig.headers.Authorization = `Bearer ${newAccessToken}`;

      // retry original request
      try {
        return await axios.request(originalConfig);
      } catch(innerError) {
          // if original req failed with 401 again - it means server returned not valid token for refresh request
          if (isUnauthorizedError(innerError)) {
            throw innerError;
          }
      }
    } catch (err) {

      authStore.setTokens(null, null);
      loginRedirect();

    } finally {
      tokenRefreshFunc = undefined;
    }

  }
);

export default http;
