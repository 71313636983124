import { createRouter, createWebHistory } from 'vue-router';

import { useUIStore } from '@/stores/UI';

import LoginView from '../views/Auth/LoginView/index.vue';
import LogoutView from '../views/Auth/LogoutView/index.vue';

import SettingsView from '@/views/Settings/index.vue';

import TarpingView from '../views/Tarping/index.vue';
import TarpingHomeView from '../views/Tarping/Home/index.vue';
import TarpingHistoricalView from '../views/Tarping/Historical/index.vue';
import TarpingRecordView from '../views/Tarping/Record/index.vue';
import TarpingReportsView from '../views/Tarping/Reports/index.vue';
import ResetFormButton from '@/views/Tarping/Record/components/ResetFormButton.vue';

import GCMView from '../views/GCM/index.vue';
import GCMHomeView from '../views/GCM/Home/index.vue';
import GCMRecordView from '../views/GCM/Record/index.vue';
import GCMReportsView from '../views/GCM/Reports/index.vue';
import GCMHistoricalView from '../views/GCM/Historical/index.vue';
import ResetGCMFormButton from '@/views/GCM/Record/components/ResetGCMFormButton.vue';

import OBHMHomeView from '@/views/OBHM/Home/index.vue';
import OBHMRecordView from '@/views/OBHM/Record/index.vue';
import OBHMHistoricalView from '@/views/OBHM/Historical/index.vue';
import OBHMReportsView from '@/views/OBHM/Reports/index.vue';
import ResetOBHMFormButton from '@/views/OBHM/Record/components/ResetOBHMFormButton.vue';

import SitesView from '../views/SitesView/index.vue';
import TarpInventoryView from '../views/TarpInventoryView/index.vue';

import { setThemeColor } from '@/utils/ui';


const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: { name: 'tarping_home' },
    },
    {
      path: '/tarping',
      name: 'tarping',
      component: TarpingView,
      redirect: { name: 'tarping_home' },
      meta: {
        isParentApp: true,
        title: 'Tarping',
        icon: 'filter_none',
      },
      children: [
        {
          path: '/tarping/home',
          name: 'tarping_home',
          component: TarpingHomeView,
          meta: {
            title: 'Home',
            icon: 'home'
          }
        },
        {
          path: '/tarping/record',
          component: TarpingRecordView,
          name: 'tarping_record',
          meta: {
            title: 'Record',
            icon: 'radio_button_checked',
            quickLink: true,
            navigationAction: ResetFormButton,
          }
        },
        {
          path: '/tarping/historical',
          component: TarpingHistoricalView,
          name: 'tarping_historical',
          meta: {
            title: 'Historical',
            icon: 'reset_image',
            quickLink: true,
          }
        },
        {
          path: '/tarping/reports',
          component: TarpingReportsView,
          name: 'tarping_reports',
          meta: {
            title: 'Reports',
            icon: 'insert_chart',
            quickLink: true,
          }
        },
      ],
    },
    {
      path: '/gcm',
      name: 'gcm',
      component: GCMView,
      redirect: { name: 'gcm_home' },
      meta: {
        isParentApp: true,
        title: 'GCM',
        icon: 'stack',
      },
      children: [
        {
          path: '/gcm/home',
          name: 'gcm_home',
          component: GCMHomeView,
          meta: {
            title: 'Home',
            icon: 'home'
          }
        },
        {
          path: '/gcm/record',
          component: GCMRecordView,
          name: 'gcm_record',
          meta: {
            title: 'Record',
            icon: 'radio_button_checked',
            quickLink: true,
            navigationAction: ResetGCMFormButton,
          }
        },
        {
          path: '/gcm/historical',
          component: GCMHistoricalView,
          name: 'gcm_historical',
          meta: {
            title: 'Historical',
            icon: 'reset_image',
            quickLink: true,
          }
        },
        {
          path: '/gcm/reports',
          component: GCMReportsView,
          name: 'gcm_reports',
          meta: {
            title: 'Reports',
            icon: 'insert_chart',
            quickLink: true,
          }
        },
      ],
    },
    {
      path: '/obhm',
      name: 'obhm',
      component: TarpingView,
      redirect: { name: 'obhm_home' },
      meta: {
        isParentApp: true,
        title: 'OBHM',
        icon: 'view_agenda',
      },
      children: [
        {
          path: '/obhm/home',
          name: 'obhm_home',
          component: OBHMHomeView,
          meta: {
            title: 'Home',
            icon: 'home'
          }
        },
        {
          path: '/obhm/record',
          component: OBHMRecordView,
          name: 'obhm_record',
          meta: {
            title: 'Record',
            icon: 'radio_button_checked',
            quickLink: true,
            navigationAction: ResetOBHMFormButton,
          }
        },
        {
          path: '/obhm/historical',
          component: OBHMHistoricalView,
          name: 'obhm_historical',
          meta: {
            title: 'Historical',
            icon: 'reset_image',
            quickLink: true,
          }
        },
        {
          path: '/obhm/reports',
          component: OBHMReportsView,
          name: 'obhm_reports',
          meta: {
            title: 'Reports',
            icon: 'insert_chart',
            quickLink: true,
          }
        }
      ],
    },
    {
      path: '/tarping/tarp-inventory',
      component: TarpInventoryView,
      name: 'tarp_inventory',
      meta: {
        isAppMenuItem: true,
        displayBottomNav: false,
        title: 'Tarp Inventory',
        icon: 'filter_none',
      }
    },
    {
      path: '/sites',
      name: 'sites',
      component: SitesView,
      meta: {
        isAppMenuItem: true,
        displayBottomNav: false,
        title: 'Sites',
        icon: 'pin_drop'
      }
    },
    {
      path: '/settings',
      name: 'settings',
      component: SettingsView,
      meta: {
        isAppMenuItem: true,
        displayBottomNav: false,
        title: 'Settings',
        icon: 'settings'
      }
    },
    {
      path: '/login',
      name: 'login',
      component: LoginView,
      meta: {
        isAppMenuItem: true,
        title: 'Login',
        icon: 'login',
        displayBottomNav: false,
        displayTopNav: false,
        themeColor: '#ffffff',
      }
    },
    {
      path: '/logout',
      name: 'logout',
      component: LogoutView,
      meta: {
        isAppMenuItem: true,
        title: 'Logout',
        icon: 'logout',
        displayBottomNav: false,
        displayTopNav: false,
        themeColor: '#ffffff',
      }
    },
  ]
})

router.beforeEach((to, from) => {
  // Set html theme color
  const themeColor = to.meta.themeColor || '#041535';
  setThemeColor(themeColor);
  return true
})

export default router
