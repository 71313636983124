class Commodity {

  id = null;
  name = null;
  display_name = null;
  grades = [];

  // Adapters

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      display_name: this.display_name,
      grades: this.grades
    }
  }

  fromJSON(data) {
    this.id = data.id;
    this.name = data.name;
    this.display_name = data.display_name;
    this.grades = data.grades;
    return this
  }

  // Getters

  getGradeById(id) {
    return this.grades.find(grade => grade.id === id)
  }

}

export default Commodity
