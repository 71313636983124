<template>
  <div class="view py-3 px-5">
    <div v-for="setting of settings" :key="setting.value" class="flex justify-content-between">
      <label :for="setting.value">{{ setting.label }}</label>
      <Checkbox 
        v-model="settingsStore[setting.value]" 
        :inputId="setting.value" 
        :name="setting.value"
        binary
      />
    </div>
  </div>
</template>

<script>
import { useSettingsStore } from '@/stores/Settings';

import Checkbox from 'primevue/checkbox';

export default {
  name: "SitesView",
  components: {
    Checkbox,
  },
  data() { return {
    settingsStore: null,
    settings: [
      {
        value: 'disable_numeric_keyboard',
        label: 'Disable Numeric Keyboard',
      }
    ]
  }},
  created() {
    this.settingsStore = useSettingsStore();
  },
}

</script>

<style scoped>
.view {
  height: 100%;
  overflow: hidden;
}

</style>