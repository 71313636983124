<template>
  <div>
    <form novalidate>
      <FormHeader />
      <Divider />
      <Field label="Swing Start Date">
        <DatePicker
          v-model="startDate"
          :showTime="false" 
        />
      </Field>
      <SwingSummaryTable
        :startDate="startDate" 
      />
    </form>
  </div>
</template>

<script>
import Divider from 'primevue/divider';

import { useRecordGCMStore } from '@/stores/RecordGCM';

import DatePicker from '@/components/forms/DatetimePicker.vue';
import Field from '@/components/forms/Field.vue';
import FormHeader from '../../components/FormHeader.vue';
import SwingSummaryTable from './components/SwingSummaryTable.vue';

export default {
  name: "SwingSummary",
  components: {
    DatePicker,
    Field,
    FormHeader,
    SwingSummaryTable,
    Divider,
  },
  created() {
    this.recordGCMStore = useRecordGCMStore();
  },
  computed: {
    startDate: {
      get() {
        return this.recordGCMStore.swing_summary.startDate;
      },
      set(value) {
        this.recordGCMStore.swing_summary.startDate = value;
      }
    }
  },
  methods: {

  }
}

</script>