<template>
  <div class="field p-fluid">
    <label for="sp-completion-time">Completion Time</label>
    <p class="subtitle">
      The time this stockpiling was completed.
    </p>
    <DatetimePicker inputId="sp-completion-time" v-model="form.completion_time" />
  </div>
</template>

<script>
import { useRecordStockpilingStore } from '@/stores/RecordStockpiling';

import DatetimePicker from '@/components/forms/DatetimePicker.vue';
import Message from 'primevue/message';

export default {
  name: "CompletionTime",
  components: {
    DatetimePicker,
    Message
  },
  created() {
      this.store = useRecordStockpilingStore();
      if (!this.store.stockpiling.completion_time) {
        this.store.stockpiling.completion_time = new Date();
      }
  },
  computed: {
    form() {
        return this.store.stockpiling;
    },
  }
}

</script>