<template>
  <div v-if="showCoverEstimated" class="field p-fluid">
    <Divider />
    <label for="cover_estimated">Cover Estimated</label>
    <p class="subtitle">
        Cover estimated is taken from where you will place your flag indicating where the 
        pulled out tarp would reach – i.e. it is an estimation of how much total tarp in on the stack.
    </p>
    <NumberInput
        inputId="cover_estimated"
        v-model="form.cover_estimated" 
        placeholder="The value must be a number" 
        :disabled="form.cover_estimated_max" />
    <div class="flex align-items-center mt-2">
        <Checkbox
            inputId="cover_estimated_max"
            v-model="cover_estimated_max" 
            name="cover_estimated_max"
            binary
            :tabindex="-1" />
        <label for="cover_estimated_max" class="ml-2"> Max </label>
    </div>
    <StorageLengthWarning 
        :value="form.cover_estimated"
        :storageLength="store.selectedStorageLength" />
  </div>
</template>

<script>
import { useRecordTarpingWorksStore } from '@/stores/RecordTarpingWorks';

import StorageLengthWarning from '../../../components/StorageLengthWarning.vue';
import NumberInput from '@/components/forms/NumberInput.vue';
import Divider from 'primevue/divider';
import Checkbox from 'primevue/checkbox';

export default {
  name: 'CoverEstimated',
  components: {
    StorageLengthWarning,
    NumberInput,
    Divider,
    Checkbox
  },
  created() {
      this.store = useRecordTarpingWorksStore();
  },

  computed: {
    form() {
        return this.store.form;
    },
    cover_estimated_max: {
      get() {
        return this.form.cover_estimated_max;
      },
      set(value) {
        const patch = {
          form: {
            cover_estimated_max: value,
          }
        };
        if (value) {
          patch.form.cover_estimated = null;
        }
        this.store.$patch(patch);
      }
    },
    showCoverEstimated() {
        return this.store.isTarpingActivity;
    },
  }
}

</script>