<template>
  <div v-if="viewReady" class="view flex flex-column">
    <div class="bg-white p-3 pb-5 flex-grow-1">
      <MaintenanceTypeSelect v-if="activeMaintenanceType === null" />
      <SiteWorksForm v-else-if="activeMaintenanceType === 'site_works'" />
      <OBHWorksForm v-else-if="activeMaintenanceType === 'obh_works'" />
    </div>
  </div>
</template>

<script>
import { useRecordOBHMStore } from '@/stores/RecordOBHM';

import Button from 'primevue/button';
import Divider from 'primevue/divider';

import MaintenanceTypeSelect from './components/MaintenanceTypeSelect.vue';
import SiteWorksForm from './forms/SiteWorks/index.vue';
import OBHWorksForm from './forms/OBHWorks/index.vue';

import { OBHWorks } from '@/stores/objects/OBHWorks';

export default {
  name: "OBHMRecordView",
  components: {
    Button,
    Divider,
    MaintenanceTypeSelect,
    SiteWorksForm,
    OBHWorksForm,
  },
  data() { return {
    viewReady: true,
    loading: false,
    store: null,
    recordOBHMStore: null,
  }},
  computed: {
    activeMaintenanceType() {
      return this.recordOBHMStore.activeMaintenanceType;
    },
  },
  async created() {
    this.recordOBHMStore = useRecordOBHMStore();
    this.obhWorks = new OBHWorks();
  },
}

</script>