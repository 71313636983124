<template>
  <div>
    <SiteSelect v-model="form.site" />
  </div>
</template>

<script>
import { useRecordStockpilingStore } from '@/stores/RecordStockpiling';

import SiteSelect from '@/site_components/SiteSelect/index.vue';

export default {
  name: "Site",
  components: {
      SiteSelect,
  },
  created() {
    this.store = useRecordStockpilingStore();
  },
  computed: {
    form() {
      return this.store.stockpiling;
    },
  }
}

</script>