<template>
    <div class="field p-fluid">
        <label for="site">Site</label>
        <Dropdown
            class="w-full md:w-14rem"
            placeholder="Select a Site"
            optionLabel="name"
            optionGroupLabel="label"
            optionGroupChildren="items"
            optionDisabled="disabled"
            filterPlaceholder="Search sites..."
            :modelValue="value" 
            :options="groupedSites"
            :loading="loading"
            @update:modelValue="update"
            emptyMessage="No sites"
            scrollHeight="flex"
            filter>
            <template #optiongroup="slotProps">
                <div class="flex align-items-center">
                    <div><p class="font-bold text-color">{{ slotProps.option.label }}</p></div>
                </div>
            </template>
            <template #value="slotProps">
                <div v-if="slotProps.value" class="flex align-items-center">
                    <div>{{ slotProps.value.display_name }}</div>
                </div>
                <span v-else>
                    {{ slotProps.placeholder }}
                </span>
            </template>
            <template #option="slotProps">
                <div class="flex align-items-center">
                    <div>{{ slotProps.option.display_name }}</div>
                </div>
            </template>
        </Dropdown>
    </div>
</template>

<script>
import Dropdown from 'primevue/dropdown';

import { useSitesStore } from '@/stores/Sites';

export default {
    name: "SiteSelect",
    props: ['modelValue'],
    components: {
        Dropdown,
    },
    data() { return {
        store: null,
        loading: false,
    }},
    computed: {
        value() {
            return this.sites.find(site => site.id === this.modelValue)
        },
        sites() {
            return this.store.getSites
        },
        favourites() {
            return this.store.getFavourites
        },
        groupedSites() {
            let favourites;
            if (this.favourites.length === 0) {
                favourites = [{
                    site: null,
                    display_name: 'No favorites saved',
                    disabled: true,
                }]
            } else {
                favourites = this.favourites
            }
            const favouriteIds = favourites.map(site => site.id)
            return [
                {
                    label: 'Favourites',
                    items: favourites,
                },
                {
                    label: 'All Sites',
                    items: this.sites.filter(site => !favouriteIds.includes(site.id)),
                }
            ]
        }
    },
    async created () {
        this.store = useSitesStore();
    },
    methods: {
        update(site) {
            this.$emit('update:modelValue', site.id)
        }
    }
}

</script>