import { default as axios } from './http';

class BaseTarpsAPI {

  getBaseTarps = async () => {
    const url = '/api/v1/tarp-inventory/base-tarps/';
    const response = await axios.get(url)
    return response
  }

  getBaseTarp = async (id) => {
    const url = '/api/v1/tarp-inventory/base-tarps/${id}/';
    const request = await axios.get(url)
    return request
  }

  createBaseTarp = async (data) => {
    const url = '/api/v1/tarp-inventory/base-tarps/create/';
    const request = await axios.post(url, data)
    return request
  }

}

export default BaseTarpsAPI
