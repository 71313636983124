<template>
  <div class="container">
    <div v-if="!initComplete || this.loading">
      <ViewLoadingIndicator />
    </div>
    <div v-else>
      <Success v-if="isSuccess" @recordAnother="recordAnother" />
      <Offline v-else-if="isOffline" @recordAnother="recordAnother" />
      <DataError v-else-if="isDataError" :errors="errors" @returnToForm="returnToForm">
        <slot name="dataErrors"></slot>
      </DataError>
      <UnexpectedError v-else @recordAnother="recordAnother" @tryAgain="reSubmitForm" />
    </div>
  </div>
</template>

<script>
import Button from 'primevue/button';
import Divider from 'primevue/divider';

import ViewLoadingIndicator from '@/components/ViewLoadingIndicator.vue';

import Success from './components/Success.vue';
import Offline from './components/Offline.vue';
import DataError from './components/DataError.vue';
import UnexpectedError from './components/UnexpectedError.vue';

export default {
  name: "FormSubmitLoader",
  components: {
    Button,
    Divider,
    ViewLoadingIndicator,
    Success,
    Offline,
    DataError,
    UnexpectedError,
  },
  props: [
    'status',
    'loading',
    'errors',
    'successMsg',
    'submitComplete'
  ],
  data() { return {
    // Delayed by a second to ensure smooth loading transition
    initComplete: false,
  }},
  created() {
    this.init();
  },
  computed: {
    isOffline() {
      return this.status === 0;
    },
    isSuccess() {
      return [200, 201, 409].includes(this.status);
    },
    isDataError() {
      // Error occurred due to data inputs
      return this.status === 400;
    },
  },
  methods: {
    init() {
      if (this.submitComplete) {
        this.initComplete = true;
        return;
      } else{
        this.initComplete = false;
        setTimeout(() => {
          this.initComplete = true;
        }, 1000);
      }
    },

    reSubmitForm() {
      this.init();
      this.$emit('reSubmitForm');
    },

    returnToForm() {
      this.$emit('returnToForm');
    },

    recordAnother() {
      this.$emit('recordAnother');
    }
  }
}

</script>

<style scoped>

.container {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

</style>