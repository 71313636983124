<template>
    <div>
        <Button
            v-if="activeMenuItems.length > 0"
            :class="['section-menu-toggle-btn', { 'active': secondaryDropdownMenuActive }]" 
            @click="openSecondaryDropdownMenu">
            {{ activeMenuItemTitle }}
            <span v-if="activeMenuItems.length > 1" class="material-symbols-outlined">expand_more</span>
        </Button>
        <SecondaryDropdownMenu v-show="secondaryDropdownMenuActive" :items="activeMenuItems" @close="closeSecondaryDropdownMenu" />
    </div>
</template>

<script>
import Button from 'primevue/button';
import SecondaryDropdownMenu from './SecondaryDropdownMenu.vue';

export default {
    name: "SectionDropdownMenu",
    components: {
        Button,
        SecondaryDropdownMenu,
    },
    data() { return {
        secondaryDropdownMenuActive: false,
    }},
    computed: {
        menus() {
            const routes = this.$router.options.routes.filter(route => route.meta && route.meta.isParentApp && route.children);
            let menu = {};
            routes.forEach(route => {
                let items = route.children.map(item => {
                    return {
                        name: item.name,
                        meta: item.meta,
                        to: item.path,
                        label: item.meta.title,
                        icon: item.meta.icon,
                    }
                })
                menu[route.name] = {
                    items: items
                };
                    
            })
            return menu
        },
        menuItemsFlat() {
            // Return menus as a flat array of menu items
            let menuItems = [];
            for (let menu in this.menus) {
                let items = this.menus[menu].items;
                items = items.map(item => Object.assign(item, { menu: menu }));
                menuItems = menuItems.concat(this.menus[menu].items);
            }
            return menuItems;
        },
        activeMenuItems() {
            const routeName = this.$route.name;

            if (!routeName) return [];

            const items = this.menuItemsFlat;
            const item = items.find(item => item.name === routeName);
            return (item) ? this.menus[item.menu].items : []
        },
        activeMenuItem() {
            const routeName = this.$route.name;
            return this.activeMenuItems.find(item => item.name === routeName);
        },
        activeMenuItemTitle() {
            return (this.activeMenuItem) ? this.activeMenuItem.meta.title : '';
        }
    },
    methods: {
        openSecondaryDropdownMenu() {
            this.secondaryDropdownMenuActive = true;
        },
        closeSecondaryDropdownMenu() {
            this.secondaryDropdownMenuActive = false;
        }
    }
}

</script>

<style>

.section-menu-toggle-btn {
    padding: 3px 10px;
    min-height: 32px;
    border-radius: 5px;
    font-size: 16px;
    color: #333;
    border: none;
    font-weight: 500;
}
.section-menu-toggle-btn,
.section-menu-toggle-btn:hover,
.section-menu-toggle-btn:focus {
    box-shadow: none !important;
    color: #333 !important;
    background: #e5e5e5 !important;
    border: none !important;
}

.section-menu-toggle-btn .material-symbols-outlined {
    transition: 250ms;
}
.section-menu-toggle-btn.active .material-symbols-outlined {
    transform: rotate(180deg);
}

</style>