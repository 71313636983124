import * as Sentry from '@sentry/vue';
import { v4 as uuidv4 } from 'uuid';

import { dateToISOString, ISOStringToDate } from '@/utils/dates';
import { validateRequiredFields } from '@/utils/validation';

import { useBaseTarpsStore } from '@/stores/BaseTarps';
import { useSitesStore } from '@/stores/Sites';
import { useStoragesStore } from '@/stores/Storages';
import { useOBHWorksStore } from '@/stores/OBHWorks';
import { useRecordOBHWorksStore } from '@/stores/RecordOBHWorks';
import OBHWorksAPI from '@/stores/api/OBHWorks';

import { OBH_WORKS_ACTIVITIES } from '@/stores/enums/OBHM';
import Tarp from '@/stores/objects/Tarp';
import Supervisor from '@/stores/objects/Supervisor';


class OBHWorksPartialProgressRecord {

  client_id = null;

  activities = [];

  has_red_straight_progress = false;
  has_red_end_progress = false;
  has_blue_straight_progress = false;
  has_blue_end_progress = false;

  red_straight_is_complete = false;
  red_straight_start_point = null;
  red_straight_end_point = null;
  red_end_progress_percentage = null;

  blue_straight_is_complete = false;
  blue_straight_start_point = null;
  blue_straight_end_point = null;
  blue_end_progress_percentage = null;

  constructor() {
    this.client_id = uuidv4();
  }

  fromJSON(data) {
    this.activities = data.activities;

    this.has_red_straight_progress = data.has_red_straight_progress;
    this.has_red_end_progress = data.has_red_end_progress;
    this.has_blue_straight_progress = data.has_blue_straight_progress;
    this.has_blue_end_progress = data.has_blue_end_progress;

    this.red_straight_is_complete = data.red_straight_is_complete;
    this.red_straight_start_point = data.red_straight_start_point;
    this.red_straight_end_point = data.red_straight_end_point;
    this.red_end_progress_percentage = data.red_end_progress_percentage;

    this.blue_straight_is_complete = data.blue_straight_is_complete;
    this.blue_straight_start_point = data.blue_straight_start_point;
    this.blue_straight_end_point = data.blue_straight_end_point;
    this.blue_end_progress_percentage = data.blue_end_progress_percentage;

    return this;
  }

  toJSON() {
    return {
      activities: this.activities,

      has_red_straight_progress: this.has_red_straight_progress,
      has_red_end_progress: this.has_red_end_progress,
      has_blue_straight_progress: this.has_blue_straight_progress,
      has_blue_end_progress: this.has_blue_end_progress,

      red_straight_is_complete: this.red_straight_is_complete,
      red_straight_start_point: this.red_straight_start_point,
      red_straight_end_point: this.red_straight_end_point,
      red_end_progress_percentage: this.red_end_progress_percentage,

      blue_straight_is_complete: this.blue_straight_is_complete,
      blue_straight_start_point: this.blue_straight_start_point,
      blue_straight_end_point: this.blue_straight_end_point,
      blue_end_progress_percentage: this.blue_end_progress_percentage,
    }
  }

  isValid() {
    if (this.activities.length === 0) {
      return false;
    }
    // At least one of the straight/end progress fields must be selected
    const progressFields = [
      'has_red_straight_progress',
      'has_red_end_progress',
      'has_blue_straight_progress',
      'has_blue_end_progress',
    ]

    if (progressFields.every(field => !this[field])) {
      return false;
    }

    if (this.has_red_straight_progress && !this.red_straight_is_complete) {
      if (
        this.red_straight_start_point === null ||
        this.red_straight_end_point === null
      ) {
        return false;
      }
    }

    if (this.has_red_end_progress && !this.red_end_progress_percentage) {
      return false;
    }

    if (this.has_blue_straight_progress && !this.blue_straight_is_complete) {
      if (
        this.blue_straight_start_point === null ||
        this.blue_straight_end_point === null
      ) {
        return false;
      }
    }

    if (this.has_blue_end_progress && this.blue_end_progress_percentage === null) {
      return false;
    }
    
    return true;
  }

  activitiesDisplay() {
    return this.activities.map(activity => {
      return OBH_WORKS_ACTIVITIES.find(a => a.value === activity).label;
    }).join(', ');
  }

  display() {
    return this.activitiesDisplay();
  }

  detailDisplayItems() {
    const items = [
      {
        label: 'Activities',
        value: this.activities?.map(activity => {
          return OBH_WORKS_ACTIVITIES.find(a => a.value === activity).label;
        }) || [],
      },
    ];
    
    if (this.has_red_straight_progress) {
      let value;
      if (this.red_straight_is_complete) {
        value = '100% Complete';
      } else {
        value = `${this.red_straight_start_point} - ${this.red_straight_end_point}`;
      }
      items.push({
        label: 'Red Straight',
        value: value,
      });
    }

    if (this.has_red_end_progress) {
      items.push({
        label: 'Red End',
        value: `${this.red_end_progress_percentage}% Complete`,
      });
    }

    if (this.has_blue_straight_progress) {
      let value;
      if (this.blue_straight_is_complete) {
        value = '100% Complete';
      } else {
        value = `${this.blue_straight_start_point} - ${this.blue_straight_end_point}`;
      }
      items.push({
        label: 'Blue Straight',
        value: value,
      });
    }

    if (this.has_blue_end_progress) {
      items.push({
        label: 'Blue End',
        value: `${this.blue_end_progress_percentage}% Complete`,
      });
    }

    return items;
  }

}


class OBHWorks {

    id = null;
    client_id = null;

    // OBH
    supervisor = null;
    completion_time = null;
    site = null;
    storage = null;
    storage_system = null;

    // Progress Fields
    obh_maintenance_is_complete = null;
    partial_progress_records = [];

    // Consumables
    nuts = null;
    bolts = null;
    clamps = null;
    toggles = null;
    slider_pins = null;
    poles = null;
    handles = null;

    ridgecaps = null;
    fiberglass_system_ridgecaps = null;
    ias_clamping_system_ridgecaps = null;
    boards = null;
    fiberglass_system_boards = null;
    coarse_threaded_bolts = null;

    struts = null;
    pins = null;

    tin_sheets_fg_patches = null;
    tin_sheets_1m = null;
    tin_sheets_3m = null;
    
    fumigation_t_pieces_replaced = null;
    fumigation_t_pieces_required = null;
    gates_rebuilt = null;
    gates_installed = null;
    gates_removed = null;
    gates_required = null;

    // Grain
    has_grain_in_storage = null;
    grain_peak_actual_start = null;
    grain_peak_actual_end = null;
    has_tarps_on_stack = null;
    base_tarps_on_stack = [];
    new_tarps = [];

    // Struts
    storage_strut_numbering_correct = null;
    storage_straight_length = null;
    storage_numbering_correct = null;
    comments = null;

    // Stores
    sitesStore = null;
    storagesStore = null;
    baseTarpsStore = null;
    OBHWorksStore = null;

    activity_type = 'obh_works';

    // Consumables required to be input for each system and activity
    requiredConsumables = {
      // <obh_system>: {
      //   <activity>: [consumable1, consumable2, ...]
      // }
      timber_boards: {
        nuts_bolts_clamps: ['nuts', 'bolts', 'clamps'],
        ridgecaps_boards: ['ridgecaps', 'boards'],
        struts_pins: ['struts', 'pins'],
        tin_sheeting: ['tin_sheets_fg_patches', 'tin_sheets_1m', 'tin_sheets_3m'],
        fumigation_t_piece: ['fumigation_t_pieces_replaced', 'fumigation_t_pieces_required'],
        gates: ['gates_rebuilt', 'gates_installed', 'gates_removed', 'gates_required'],
      },
      slider_system: {
        nuts_bolts_clamps: ['toggles', 'slider_pins'],
        ridgecaps_boards: ['fiberglass_system_ridgecaps', 'fiberglass_system_boards'],
        struts_pins: ['struts', 'pins'],
        tin_sheeting: ['tin_sheets_fg_patches', 'tin_sheets_1m', 'tin_sheets_3m'],
        fumigation_t_piece: ['fumigation_t_pieces_replaced', 'fumigation_t_pieces_required'],
        gates: ['gates_rebuilt', 'gates_installed', 'gates_removed', 'gates_required'],
      },
      ias_clamping_system: {
        nuts_bolts_clamps: ['poles', 'handles'],
        ridgecaps_boards: ['ias_clamping_system_ridgecaps', 'coarse_threaded_bolts'],
        struts_pins: ['struts', 'pins'],
        tin_sheeting: ['tin_sheets_fg_patches', 'tin_sheets_1m', 'tin_sheets_3m'],
        fumigation_t_piece: ['fumigation_t_pieces_replaced', 'fumigation_t_pieces_required'],
        gates: ['gates_rebuilt', 'gates_installed', 'gates_removed', 'gates_required'],
      },
    }

    constructor() {
      this.client_id = uuidv4();
      this.sitesStore = useSitesStore();
      this.storagesStore = useStoragesStore();
      this.baseTarpsStore = useBaseTarpsStore();
      this.OBHWorksStore = useOBHWorksStore();
    }

    // Adapters

    toJSON() {
      return {
        id: this.id,
        client_id: this.client_id,

        // OBH
        supervisor: this.supervisor?.id,
        site: this.site?.id,
        completion_time: (this.completion_time) ? dateToISOString(this.completion_time): null,
        storage: this.storage?.id,
        storage_system: this.storage_system,

        // Maintenance Progress
        obh_maintenance_is_complete: this.obh_maintenance_is_complete,
        partial_progress_records: this.partial_progress_records.map(
          (partial_progress_record) => partial_progress_record.toJSON()
        ),

        // Consumables
        nuts: this.nuts,
        bolts: this.bolts,
        clamps: this.clamps,
        toggles: this.toggles,
        slider_pins: this.slider_pins,
        poles: this.poles,
        handles: this.handles,
        ridgecaps: this.ridgecaps,
        fiberglass_system_ridgecaps: this.fiberglass_system_ridgecaps,
        ias_clamping_system_ridgecaps: this.ias_clamping_system_ridgecaps,
        boards: this.boards,
        fiberglass_system_boards: this.fiberglass_system_boards,
        coarse_threaded_bolts: this.coarse_threaded_bolts,
        struts: this.struts,
        pins: this.pins,
        tin_sheets_fg_patches: this.tin_sheets_fg_patches,
        tin_sheets_1m: this.tin_sheets_1m,
        tin_sheets_3m: this.tin_sheets_3m,
        fumigation_t_pieces_replaced: this.fumigation_t_pieces_replaced,
        fumigation_t_pieces_required: this.fumigation_t_pieces_required,
        gates_rebuilt: this.gates_rebuilt,
        gates_installed: this.gates_installed,
        gates_removed: this.gates_removed,
        gates_required: this.gates_required,

        // Status
        has_grain_in_storage: this.has_grain_in_storage,
        grain_peak_actual_start: this.grain_peak_actual_start,
        grain_peak_actual_end: this.grain_peak_actual_end,
        has_tarps_on_stack: this.has_tarps_on_stack,
        base_tarps_on_stack: (this.base_tarps_on_stack) ? this.base_tarps_on_stack.map(t => t.id) : [],
        new_tarps: (this.new_tarps) ? this.new_tarps.map(new_tarp => new_tarp.toJSON()) : [],

        // Strut Numbering
        storage_strut_numbering_correct: this.storage_strut_numbering_correct,
        storage_straight_length: this.storage_straight_length,
        storage_numbering_correct: this.storage_numbering_correct,
        comments: this.comments,
      }
    }

    fromJSON(data) {
      this.id = data.id;
      this.client_id = data.client_id || this.id;

      // OBH
      this.supervisor = data.supervisor ? new Supervisor().fromJSON(data.supervisor) : null;
      (data.site) ? this.site = this.sitesStore.getSiteById(data.site) : this.site = null;
      this.completion_time = (data.completion_time) ? ISOStringToDate(data.completion_time) : null;
      this.storage = this.storagesStore.getStorageById(data.storage);
      this.storage_system = data.storage_system;

      // Maintenance Progress
      this.obh_maintenance_is_complete = data.obh_maintenance_is_complete;
      this.partial_progress_records = data.partial_progress_records?.map(
        (record) => new OBHWorksPartialProgressRecord().fromJSON(record)
      ) || [];
      
      // Consumables
      this.nuts = data.nuts;
      this.bolts = data.bolts;
      this.clamps = data.clamps;
      this.toggles = data.toggles;
      this.slider_pins = data.slider_pins;
      this.poles = data.poles;
      this.handles = data.handles;
      this.ridgecaps = data.ridgecaps;
      this.fiberglass_system_ridgecaps = data.fiberglass_system_ridgecaps;
      this.ias_clamping_system_ridgecaps = data.ias_clamping_system_ridgecaps;
      this.boards = data.boards;
      this.fiberglass_system_boards = data.fiberglass_system_boards;
      this.coarse_threaded_bolts = data.coarse_threaded_bolts;
      this.struts = data.struts;
      this.pins = data.pins;
      this.tin_sheets_fg_patches = data.tin_sheets_fg_patches;
      this.tin_sheets_1m = data.tin_sheets_1m;
      this.tin_sheets_3m = data.tin_sheets_3m;
      this.fumigation_t_pieces_replaced = data.fumigation_t_pieces_replaced;
      this.fumigation_t_pieces_required = data.fumigation_t_pieces_required;
      this.gates_rebuilt = data.gates_rebuilt;
      this.gates_installed = data.gates_installed;
      this.gates_removed = data.gates_removed;
      this.gates_required = data.gates_required;

      // Status
      this.has_grain_in_storage = data.has_grain_in_storage;
      this.grain_peak_actual_start = data.grain_peak_actual_start;
      this.grain_peak_actual_end = data.grain_peak_actual_end;
      this.has_tarps_on_stack = data.has_tarps_on_stack;
      this.base_tarps_on_stack = data.base_tarps_on_stack?.map(
        (tarp_id) => this.baseTarpsStore.getBaseTarpById(tarp_id)
      ) || [];
      this.new_tarps = data.new_tarps?.map(
        (new_tarp) => new Tarp().fromJSON(new_tarp)
      ) || [];

      // Strut Numbering
      this.storage_strut_numbering_correct = data.storage_strut_numbering_correct;
      this.storage_straight_length = data.storage_straight_length;
      this.storage_numbering_correct = data.storage_numbering_correct;
      this.comments = data.comments;
      
      return this
    }

    // Validation

    obhIsValid() {
      return validateRequiredFields([
        'completion_time',
        'site',
        'storage',
        'storage_system',
      ], this);
    }

    progressIsValid() {
      if      (this.obh_maintenance_is_complete === null) return false;
      else if (this.obh_maintenance_is_complete === true) return true;
      else if (this.partial_progress_records.length === 0) return false;
      else if (this.partial_progress_records.every(record => record.isValid())) return true;
    }

    consumablesIsValid() {
      const requiredConsumables = this.getRequiredConsumables();
      return validateRequiredFields(requiredConsumables, this);
    }

    statusIsValid() {
      if      (this.has_grain_in_storage === null) return false;
      else if (this.has_grain_in_storage === true) {
        const requiredFields = ['grain_peak_actual_start', 'grain_peak_actual_end', 'has_tarps_on_stack'];
        if (this.has_tarps_on_stack) {
          requiredFields.push('base_tarps_on_stack|new_tarps');
        }
        return validateRequiredFields(requiredFields, this);
      } else {
        return true;
      }
    }

    strutsIsValid() {
      if (!this.hasPreviousWorksForStorage) {
        return validateRequiredFields([
          'storage_strut_numbering_correct',
          'storage_straight_length',
          'storage_numbering_correct',
        ], this);
      }
      return true;
    }

    // Methods

    getActivities() {
      // Return a list of activities from all partial progress records
      const activities = new Set(this.partial_progress_records.flatMap(record => record.activities));
      return Array.from(activities);
    }

    getRequiredConsumables() {
      // Return a list of consumables required for the selected storage system
      if (!this.storage_system) return [];

      const storageSystemConsumables = this.requiredConsumables[this.storage_system];
      if (storageSystemConsumables) {
        if (this.obh_maintenance_is_complete) {
          return Object.values(storageSystemConsumables).flat();
        } else {
          const activities = this.getActivities();
          return Object.entries(storageSystemConsumables).filter(
            ([activity, consumables]) => activities.includes(activity)
          ).map(([activity, consumables]) => consumables).flat();
        }
      }
      return [];
    }

    updateOBHWorksInStores(response) {
      // Removes the tarping works from the locally saved tarping works and 
      // adds the updated tarping works to the tarping works store.
      const recordOBHWorksStore = useRecordOBHWorksStore();
      recordOBHWorksStore.removeOBHWorksFromLocal(response.data.client_id);
      recordOBHWorksStore.addToOBHWorksStore(response.data);
    }

    syncToServer = async (data) => {
      if (!data) data = this.toJSON();
  
      const api = new OBHWorksAPI();
  
      let response;
  
      try {
  
        if (this.id) response = await api.updateOBHWorks(this.id, data);
        else         response = await api.createOBHWorks(data);
  
        if ([200, 201].includes(response.status)) {
          this.updateOBHWorksInStores(response);
        }
  
        return response;
  
      } catch (error) {
  
        Sentry.captureException(error);
        console.log(error);
  
        if (error.response) {
          if (error.response.status === 409) {
            // 409 is returned if the tarping works has already been created on the server
            this.updateOBHWorksInStores(error.response);
          }
          return error.response;
        } else {
          throw error;
        }
        
      }
    }

    // Properties

    displayName() {
      if (this.storage) {
        return `${this.storage.site.displayName()} ${this.storage.displayName()} OBH Works`;
      }
    }

    completionTimeDisplay() {
      const completionTime = this.completion_time;
      if (!completionTime) return 'Unknown Completion Time';
      return new Date(completionTime).toLocaleString();
    }

    hasPreviousWorksForStorage() {
      if (!this.storage?.id) return false;
      const previousStorageWorks = this.OBHWorksStore.getOBHWorksByStorageId(this.storage.id);
      return previousStorageWorks.length > 0;
    }

}

export {
  OBHWorks,
  OBHWorksPartialProgressRecord
}