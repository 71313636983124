<template>
  <div class="flex align-items-center mt-2">
    <span class="material-symbols-outlined mr-2 cursor-pointer" @click="goBack">arrow_back</span>
    <h2 class="font-semibold">
      {{ activeMaintenanceType.label }}
    </h2>
  </div> 
</template>

<script>
import { useRecordGCMStore } from '@/stores/RecordGCM';

import Card from 'primevue/card';

export default {
    name: "FormHeader",

    components: {
        Card,
    },

    created() {
        this.gcmStore = useRecordGCMStore();
    },

    computed: {
        activeMaintenanceType() {
            return this.gcmStore.getActiveMaintenanceType;
        },
    },
    methods: {
      goBack() {
        this.gcmStore.setActiveMaintenanceType(null);
      }
    }
}

</script>