import { default as axios } from './http';


class StacksAPI {

    getStacks = async () => {
        // Fetch stacks from API
        console.log('Fetching stacks from API...')
        const url = '/api/v1/site-data/stacks/';
        const response = await axios.get(url)
        return response
    }

}

export default StacksAPI;
