import { defineStore } from 'pinia';


export const useUIStore = defineStore('UIStore', {
  persist: true,

  state: () => ({
    activeView: 'tarping_home', // tarping, gcm, obhm
  }),

  actions: {

    setActiveView(viewName) {
      this.activeView = viewName;
    },

  },
})
