import { default as axios } from './http';


class TarpRepairTypesAPI {

    getTarpRepairTypes = async () => {
        // Fetch stacks from API
        console.log('Fetching tarp repair types from API...')
        const url = '/api/v1/gcm/tarp-repair-types/';
        const response = await axios.get(url)
        return response
    }

}

export default TarpRepairTypesAPI;
