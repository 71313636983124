<template>

    <form>
        <Divider class="mt-0" />
        <div class="field p-fluid">
            <label for="serial_no">Serial Number</label>
            <InputText id="serial_no" v-model="tarp.serial_no" />

            <div v-if="duplicateSerialNos.length > 0">
              <Message severity="warn" :closable="false">
                Tarp ({{ duplicateSerialNos.join(", ") }}) already exists.
              </Message>
            </div>
            <div v-if="matchingSerialNos.size > 0">
              <Message severity="error" :closable="false">
                Cannot create a new tarp with matching serial numbers ({{ Array.from(matchingSerialNos).join(", ") }}).
              </Message>
            </div>
        </div>

        <div class="field p-fluid">
            <label for="width">Width (m)</label>
            <NumberInput v-model="tarp.width" :maxFractionDigits="2" :min="0" :max="99.99" />
        </div>

        <div class="field p-fluid">
            <label for="length">Length (m)</label>
            <NumberInput v-model="tarp.length" :maxFractionDigits="2" :min="0" :max="99.99" />
        </div>

        <div class="field p-fluid">
            <label for="cover_type">Cover Type</label>
            <Dropdown 
                v-model="tarp.cover_type"
                :options="enums.cover_type"
                optionLabel="label"
                optionValue="value"
                emptyMessage="No cover types"
                scrollHeight="flex">
            </Dropdown>
        </div>

        <div class="field p-fluid">
            <Divider />
            <Button label="Create Tarp" severity="secondary" size="large" :disabled="!formValid" @click="createTarp" raised />
        </div>
    </form>

</template>

<script>
import Button from 'primevue/button';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import Message from 'primevue/message';

import NumberInput from '@/components/forms/NumberInput.vue';

import Tarp from '@/stores/objects/Tarp';
import { useTarpInventoryStore } from '@/stores/TarpInventory';

export default {
  name: "NewTarpForm",
  components: {
    Button,
    Divider,
    Dropdown,
    InputText,
    Message,
    NumberInput,
  },
  inject: ['dialogRef'],
  data() { return {
    tarp: null,
    enums: {
      cover_type: [
        { label: 'Straight', value: 'straight' },
        { label: 'End', value: 'end' },
        { label: 'End Straight', value: 'end_straight' },
      ],
    }
  }},
  created() {
    this.tarp = new Tarp().toJSON();
  },
  computed: {

    baseTarpSerialNos() {
      const tarps = useTarpInventoryStore().getTarps;
      const serialNos = [];
      tarps.forEach(tarp => {
        // Split the serial number into an array of strings at /
        const baseSerialNos = tarp.serial_no.split('/');
        // Add base serial nos to serial nos
        baseSerialNos.forEach(serialNo => {
          serialNos.push(serialNo);
        });
      });
      return serialNos;
    },

    newBaseTarpSerialNos() {
      if (this.tarp.serial_no) return this.tarp.serial_no.split('/');
      else                     return [];
    },

    duplicateSerialNos() {
      return this.newBaseTarpSerialNos.filter(serialNo => this.baseTarpSerialNos.includes(serialNo));
    },
    
    matchingSerialNos() {
      // Return any serial nos that are listed twice
      return new Set(
        this.newBaseTarpSerialNos.filter(serial_no => this.newBaseTarpSerialNos.filter(s => s === serial_no).length > 1)
      );
    },

    formValid() {
      const { serial_no, length, width, cover_type } = this.tarp;
      return serial_no && length && width && cover_type && this.matchingSerialNos.size === 0;
    },
  },
  methods: {
    createTarp() {
      this.$emit('create', this.tarp);
      this.dialogRef.close();
    }
  }
}

</script>