<template>
    <div class="container bg-white">
        <div style="display: block">
            <ProgressSpinner :style="`width: ${width}; height: ${height}`" />
        </div>
        <p v-if="label">{{ label }}</p>
    </div>
</template>

<script>
import ProgressSpinner from 'primevue/progressspinner';

export default {
    name: "FullScreenLoader",
    props: {
        width: {
            type: String,
            default: '50px'
        },
        height: {
            type: String,
            default: '50px',
        },
        label: {
            type: String,
            default: null,
        }
    },
    components: {
        ProgressSpinner,
    },
}

</script>

<style scoped>
.container {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    flex-direction: column;
    z-index: 10000;
}
</style>