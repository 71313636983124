class TarpRepairType {

  id = null;
  name = null;
  verbose_name = null;
  description = null;
  has_holes_repaired_field = false;
  min_holes_repaired_confirmation = null;
  exclude_from_reports = null;
  default_tarp_condition = null;

  // Adapters

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      verbose_name: this.verbose_name,
      description: this.description,
      has_holes_repaired_field: false,
      min_holes_repaired_confirmation: this.min_holes_repaired_confirmation,
      exclude_from_reports: this.exclude_from_reports,
      default_tarp_condition: this.default_tarp_condition,
    }
  }

  fromJSON(data) {
    this.id = data.id;
    this.name = data.name;
    this.verbose_name = data.name;
    this.description = data.description;
    this.has_holes_repaired_field = data.has_holes_repaired_field;
    this.min_holes_repaired_confirmation = data.min_holes_repaired_confirmation;
    this.exclude_from_reports = data.exclude_from_reports;
    this.default_tarp_condition = data.default_tarp_condition;
    return this
  }

  // Getters

}

export default TarpRepairType
