<template>
  <div>
    <Dialog 
      :visible="visible"
      modal 
      header="Select Tarps" 
      :style="style"
      contentClass="flex-grow-1"
      @update:visible="() => closeModal(true)"
    >
      <SelectTarpsMenu
        ref="selectTarpsMenu"
        :multiple="multiple"
        :ordering="ordering"
        :baseTarps="baseTarps"
        @selectTarps="selectTarps"
        @addNew="addNewTarp"
      />
    </Dialog>

    <ConfirmDialog :group="confirmDialogGroup">
      <template #accepticon>
          <span class="material-symbols-outlined mr-1">close</span>
      </template>
      <template #rejecticon>
          <span></span>
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import ConfirmDialog from 'primevue/confirmdialog';
import Dialog from 'primevue/dialog';

import SelectTarpsMenu from '../SelectTarpsMenu/index.vue';

export default {
  name: "NewTarpModal",
  props: [
    'visible',
    'multiple',
    'ordering',
    'selectedTarps',
    'baseTarps',
  ],
  components: {
    ConfirmDialog,
    Dialog,
    SelectTarpsMenu,
  },
  data() { return {
    confirmDialogGroup: "confirmCloseTarpsModal",
    style: {
      width: '100%',
      height: '100vh',
      maxHeight: '100vh',
      borderRadius: '0',
    },
  }},
  methods: {
    closeModal(requireConfirmation = true) {
      // Check if tarps are selected and show a confirmation dialog if so
      if (
        this.$refs.selectTarpsMenu && 
        this.$refs.selectTarpsMenu.selectedTarps.length > 0 && 
        requireConfirmation === true
      ) {
        this.$confirm.require({
          group: this.confirmDialogGroup,
          message: 'Are you sure you want to close this menu? The selected tarps will not be saved.',
          header: 'Close Menu',
          acceptClass: 'p-button-danger',
          acceptLabel: 'Close',
          accept: () => {
            this.$emit('close');
          },
          reject: () => {},
        });
      } else {
        this.$emit('close');
      }
    },

    selectTarps(tarps) {
      this.$emit('onSelect', tarps);
      this.closeModal(false);
    },

    addNewTarp({ selectedTarps, searchSerialNo }) {
      this.$emit('onAddNew', { selectedTarps, searchSerialNo });
    },
  }
};
</script>