import { defineStore } from 'pinia';

import { getOrCreateItem, deleteItem, getItems } from '@/stores/idb';
import { useTarpInventoryStore } from './TarpInventory.js';
import { useOBHWorksStore } from '@/stores/OBHWorks';

import { OBHWorks, OBHWorksPartialProgressRecord } from './objects/OBHWorks';


export const useRecordOBHWorksStore = defineStore('recordOBHWorks', {

  persist: {
    paths: ['obh_works', 'activeStepIndex'],
  },

  state: () => ({
    obh_works: new OBHWorks().toJSON(),

    unsaved_obh_works: [],

    // Submission status
    activeStepIndex: 1,
    submitted: false,
    submit_complete: false,
    loading: false,
    saveResponse: null,
  }),

  actions: {

    init() {
      if (!this.obh_works) this.obh_works = new OBHWorks().toJSON();
      if (this.submitted) this.reset(true);
    },

    removeTarp(index) {
      this.obh_works.tarps_on_stack.splice(index, 1);
    },

    addPartialProgressRecord(record) {
      this.obh_works.partial_progress_records = [
        ...this.obh_works.partial_progress_records,
        record,
      ];
    },
    updatePartialProgressRecord(index, record) {
      this.obh_works.partial_progress_records.splice(index, 1, record);
    },
    deletePartialProgressRecord(index) {
      this.obh_works.partial_progress_records.splice(index, 1);
    },

    reset(resetSubmissionStatus = false) {
      this.obh_works = new OBHWorks().toJSON();
      this.activeStepIndex = 0;
      if (resetSubmissionStatus) {
        this.submitted = false;
        this.loading = false;
        this.saveResponse = null;
      }
    },

    async saveOBHWorks() {

      // Process data
      const siteWorks = new OBHWorks().fromJSON(this.obh_works);
      const data = siteWorks.toJSON();

      console.log('Saving OBH works', data);

      // Save the tarp joins to local device
      this.saveOBHWorksToLocal(data);

      if (navigator.onLine) {
        try {

          this.loading = true;

          // syncToServer doesn't throw an error if the request fails.
          // Status is returned and handled by the component calling this action.
          const response = await new OBHWorks().syncToServer(data);
          this.saveResponse = response;

          this.loading = false;

          return response;

        } catch (error) {
          throw error;
        }
      } else {
        this.saveResponse = { status: 0 };  // Offline
        return this.saveResponse
      }
    },
    
    saveOBHWorksToLocal(siteWorks) {
      // Check if the site works have already been added to the unsaved_obh_works array
      const existingOBHWorks = this.unsaved_obh_works.filter(sw => sw.client_id === siteWorks.client_id);
      if (existingOBHWorks.length) {
        // If the site works already exist in the array, replace them with the new site works
        this.unsaved_obh_works = this.unsaved_obh_works.map(sw => {
          if (sw.client_id === siteWorks.client_id) {
            return siteWorks
          }
          return sw
        });
      } else {
        // Add tarp join to the unsaved_obh_works array
        this.unsaved_obh_works.push(siteWorks);
      }
      // Save the unsaved tarp join to indexedDB
      this.saveUnsavedOBHWorksToDB(siteWorks);
    },

    removeOBHWorksFromLocal(clientId) {
      this.unsaved_obh_works = [...this.unsaved_obh_works.filter(sw => sw.client_id !== clientId)];
      this.removeUnsavedOBHWorksFromDB(clientId);
    },

    addToOBHWorksStore(siteWorks) {
      // Tarp join is returned with the newly created tarp and archived tarps.
      // Need to add the new tarp to the tarp store and remove the archived tarps.

      const tarpInventoryStore = useTarpInventoryStore();

      // Add the new tarp
      const tarps = siteWorks.created_tarps;
      for (const tarp of tarps) {
        tarpInventoryStore.addTarp(tarp);
      }

      // Add the tarp join to the tarp joins store
      const obhWorksStore = useOBHWorksStore();
      obhWorksStore.addOBHWorks(siteWorks);
    },

    async saveUnsavedOBHWorksToDB(siteWorks) {
      await getOrCreateItem('unsavedOBHWorks', siteWorks.client_id, siteWorks);
    },

    async removeUnsavedOBHWorksFromDB(clientId) {
      await deleteItem('unsavedOBHWorks', clientId);
    },

    async loadUnsavedOBHWorksFromDB() {
      const unsavedOBHWorks = await getItems('unsavedOBHWorks');
      this.unsaved_obh_works = unsavedOBHWorks;
    },

  },

  getters: {

    getOBHWorks() {
      return new OBHWorks().fromJSON(this.obh_works);
    },

    getUnsavedOBHWorks() {
      return this.unsaved_obh_works.map(sw => new OBHWorks().fromJSON(sw));
    },

    getErrors() {
      if (this.saveResponse?.status === 400) {
        return this.saveResponse?.data;
      }
      return [];
    },

    formSteps() {
      const siteWorks = this.getOBHWorks
      return [
        {
          name: 'obh_works',
          label: 'Site Works',
          valid: siteWorks.isValid(false),
        },
        {
          name: 'tarps_on_stack',
          label: 'Tarps on Site',
          valid: siteWorks.isValid(),
          disabled: !siteWorks.isValid(false),
        },
      ];
    }

  }
})