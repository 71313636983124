<template>
  <InputText
    :inputId="inputId || 'select-base-tarp'"
    type="text" 
    :modelValue="selectedBaseTarp?.serial_no" 
    placeholder="Click to select..." 
    :disabled="disabled || false"
    @focus="openSelecBaseTarpModal" />
</template>

<script>
import { useBaseTarpsStore } from '@/stores/BaseTarps';
import { useRecordNewTarpStore } from '@/stores/RecordNewTarp';

import Divider from 'primevue/divider';
import Message from 'primevue/message';
import InputText from 'primevue/inputtext';

import SelectBaseTarpMenu from './SelectBaseTarpMenu.vue';
import NewTarpForm from '@/site_components/NewTarpForm/index.vue';

export default {
  name: "BaseTarp",
  components: {
    Divider,
    Message,
    InputText,
    SelectBaseTarpMenu,
    NewTarpForm,
  },
  props: [
    'modelValue', 
    'inputId',
    'newTarpFormOptions',
    'disabled',
    'baseTarps',
  ],
  data() { return {
    baseTarpsStore: null,
    store: null,
    selectBaseTarpModal: null,
  }},
  computed: {
    selectedBaseTarp() {
      if (typeof this.modelValue === 'object') {
        return this.modelValue;
      } else {
        const baseTarpId = this.modelValue;
        if (baseTarpId) {
          return this.baseTarpsStore.getBaseTarpById(baseTarpId)
        }
        return null;
      }
    },
  },
  async created() {
    this.baseTarpsStore = useBaseTarpsStore();
    this.recordNewTarpStore = useRecordNewTarpStore();
  },
  mounted() {
    this.baseTarpsStore.loadBaseTarpsFromDB();
  },
  methods: {
    openSelecBaseTarpModal() {
      this.selectBaseTarpModal = this.$dialog.open(SelectBaseTarpMenu, {
        props: {
          header: "Select tarp",
          style: {
            width: '100vw',
            height: '100vh',
            maxHeight: '100vh',
            borderRadius: '0',
          },
          modal: true,
          dismissableMask: true,
          contentClass: 'flex-grow-1',
        },
        data: {
          newTarpFormOptions: this.newTarpFormOptions || {},
          baseTarps: this.baseTarps || null,
        },
        emits: {
          onSelectBaseTarp: (baseTarp) => {
            this.selectBaseTarp(baseTarp);
            this.selectBaseTarpModal.close();
          },
          onCreateNewTarp: (newBaseTarp) => {
            this.selectBaseTarp(newBaseTarp);
            console.log(newBaseTarp)
            this.selectBaseTarpModal.close();
          },
        }
      });
    },
    selectBaseTarp(baseTarp) {
      this.$emit('update:modelValue', baseTarp);
    },
  }
}

</script>
