import { defineStore } from 'pinia';

import User from '@/stores/objects/User';
import AuthAPI from './api/Auth';


export const useAuthStore = defineStore('auth', {
  persist: {
    paths: ['authenticatedUser', 'accessToken', 'refreshToken']
  },

  state: () => ({ 
    authenticatedUser: null,
    accessToken: null,
    refreshToken: null,
    showLoginModal: false,
    loginForm: {
      email: null,
      password: null,
      incorrectCredentials: false,
      loading: false,
    }
  }),

  actions: {
    
    async login() {
      const user = new User().fromJSON(this.loginForm);
      
      try {
        this.loginForm.loading = true;
        const response = await user.login();
        console.log(response);
        if (response.status === 200) {
          const data = response.data;
          const accessToken = data.access;
          const refreshToken = data.refresh;
          this.setTokens(accessToken, refreshToken);
          this.resetLoginForm();
        }
        return response
      } catch (error) {
        if (error.response && [400, 401].includes(error.response.status)) {
          this.loginForm.incorrectCredentials = true;
          return error.response
        } else {
          throw error
        }
      } finally {
        this.loginForm.loading = false;
      }
    },

    logout() {
      this.setTokens(null, null);
    },

    setAccessToken(token) {
      this.accessToken = token;
    },

    setRefreshToken(token) {
      this.refreshToken = token;
    },

    setTokens(accessToken, refreshToken) {
      this.setAccessToken(accessToken);
      this.setRefreshToken(refreshToken);
    },

    async refreshAccessToken() {
      const refreshToken = this.refreshToken;
      
      if (!refreshToken)
        throw new Error('Refresh token does not exist');
      
      const api = new AuthAPI();
      const response = await api.refreshToken(refreshToken);
      const newAccessToken = response.data.access;

      this.setAccessToken(newAccessToken);

      return newAccessToken;
    },

    resetLoginForm() {
      this.loginForm = {
        email: null,
        password: null,
        incorrectCredentials: false,
        loading: false,
      }
    }
  
  },

  getters: {

    getUser() {
      return new User().fromJSON(this.user)
    },

    isAuthenticated() {
      return this.accessToken !== null;
    }

  }

})
