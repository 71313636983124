import { default as axios } from './http';


class TarpingWorksAPI {

    getTarpingWorks = async () => {
        // Fetch tarps from API
        console.log('Fetching tarping works from API...')
        const url = '/api/v1/site-data/tarping-works/';
        const response = await axios.get(url)
        return response
    }

    createTarpingWorks = async (data) => {
        // Create tarping works in API
        const url = '/api/v1/site-data/tarping-works/create/';
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            }
        }
        const response = await axios.post(url, data, options)
        return response
    }

    updateTarpingWorks = async (data) => {
        // Update tarping works in API
        const url = '/api/v1/site-data/tarping-works/${data.id}/';
        const options = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            }
        }
        const response = await axios.put(url, data, options)
        return response
    }

}

export default TarpingWorksAPI;
