<template>
    <div class="table-container flex-grow-1 pb-0 overflow-x-hidden">
        <DataTable
            v-if="tableReady"
            :value="tarpingWorks"
            class="p-datatable-sm tarping-works-table"
            scrollable 
            scrollHeight="flex"
            v-model:filters="filters"
            filterDisplay="menu"
            paginator 
            :rows="50"
            columnResizeMode="fit"
            showGridlines
            paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            tableStyle="min-width: 700px;"
            @row-click="openTarpingWorksModal">

            <template #header>
                <div class="flex flex-wrap align-items-center justify-content-between gap-2 p-1">
                    <div>
                        <p class="title"><span class="text-xl text-900 font-bold">Tarping Works</span></p>
                        <p class="subtitle"><span><small>Last Updated: {{ tarpingWorksLastUpdated }}</small></span></p>
                    </div>
                    
                    <Button rounded raised severity="secondary" :loading="loading" @click="sync">
                        <template #icon>
                            <span class="material-symbols-outlined">download</span>
                        </template>
                    </Button>
                </div>
            </template>
            <Column field="stack" header="Stack" frozen style="width: 200px" filterField="stack" :showFilterMatchModes="false" @click="console.log('click')">
                <template #filter="{ filterModel }">
                    <div style="max-height: 200px; overflow-y: scroll;">
                        <div v-for="stack in stackOptions" :key="stack.value" class="flex align-items-center" style="margin-top: 10px">
                            <Checkbox v-model="filterModel.value" :inputId="stack.value" name="stack" :value="stack.value" style="margin-right: 5px;" />
                            <label :for="stack.value">{{ stack.display }}</label>
                        </div>
                    </div>
                </template>
                <template #body="slotProps">
                    {{ slotProps.data.stackRef() }}
                </template>
            </Column>
            <Column field="completion_time" header="Completion Time" :showFilterMatchModes="false">
                <template #filter="{ filterModel }">
                    <div>
                        <Calendar v-model="filterModel.value" inline />
                    </div>
                </template>
                <template #body="slotProps">
                    {{ slotProps.data.completionTimeDisplay() }}
                </template>
            </Column>
            <Column field="supervisor" header="Supervisor" filterField="supervisor" :showFilterMatchModes="false">
                <template #filter="{ filterModel }">
                    <div v-for="supervisor in supervisorOptions" :key="supervisor.value" class="flex align-items-center" style="margin-top: 10px">
                        <Checkbox v-model="filterModel.value" :inputId="supervisor.value" name="activity" :value="supervisor.value" style="margin-right: 5px;" />
                        <label :for="supervisor.value">{{ supervisor.display }}</label>
                    </div>
                </template>
                <template #body="slotProps">
                    {{ slotProps.data.supervisor.displayName() }}
                </template>
            </Column>
            <Column field="activities" header="Activities" :showFilterMatchModes="false">
                <template #filter="{ filterModel }">
                    <div v-for="activity in activities" :key="activity.value" class="flex align-items-center" style="margin-top: 10px">
                        <Checkbox v-model="filterModel.value" :inputId="activity.value" name="activity" :value="activity.value" style="margin-right: 5px;" />
                        <label :for="activity.value">{{ activity.display }}</label>
                    </div>
                </template>
                <template #body="slotProps">
                    {{ slotProps.data.activitiesDisplay() }}
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Calendar from 'primevue/calendar';
import Checkbox from 'primevue/checkbox';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import MultiSelect from 'primevue/multiselect';

import TarpingWorkDetails from './TarpingWorkDetails.vue';
import { ACTIVITIES } from '@/stores/enums/TarpingWorks';

import { useTarpingWorksStore } from '@/stores/TarpingWorks';

import { FilterMatchMode, FilterService } from 'primevue/api';


FilterService.register('filterByArrayContains', (tarpingWorkActivities, filterArray) => {
    // Return true if any of the tarping work activities are in the filter array
    if (!filterArray || filterArray.length === 0) return true;
    return tarpingWorkActivities.some(activity => filterArray.includes(activity));
});

FilterService.register('filterByStack', (stack, filterArray) => {
    // Return true if any of the tarping work activities are in the filter array
    if (!filterArray || filterArray.length === 0) return true;
    // Check if stack display name contains search value
    return filterArray.includes(String(stack.id));
});

FilterService.register('filterByCompletionTime', (completionTime, date) => {
    // Return true if any of the tarping work activities are in the filter array
    if (!date) return true;
    // Check if dates match (compare date only, not time)
    const date1 = new Date(completionTime).toDateString();
    const date2 = new Date(date).toDateString();
    return date1 === date2;
});


FilterService.register('filterBySupervisor', (supervisor, filterArray) => {
    // Return true if any of the tarping work activities are in the filter array
    if (!filterArray || filterArray.length === 0) return true;
    return filterArray.includes(supervisor.email);
});


export default {
    name: "TarpingWorksTable",
    components: {
        Button,
        DataTable,
        Calendar,
        Checkbox,
        Column,
        InputText,
        MultiSelect,
        TarpingWorkDetails,
    },
    data() { return {
        tableReady: false,
        loading: false,
        filters: {
          stack: { value: null, matchMode: 'filterByStack' },
          completion_time: { value: null, matchMode: 'filterByCompletionTime' },
          supervisor: { value: null, matchMode: 'filterBySupervisor' },
          activities: { value: null, matchMode: 'filterByArrayContains' },
        },
        activities: ACTIVITIES,
    }},
    computed: {
        tarpingWorks() {
            const tarpingWorks = this.tarpingWorksStore.getTarpingWorks;
            return tarpingWorks
        },
        tarpingWorksLastUpdated() {
            // Format store last updated date for display
            const date = new Date(this.tarpingWorksStore.lastUpdated);
            return date.toLocaleString();
        },
        supervisorOptions() {
            // Get unique supervisors from tarping works
            const supervisors = this.tarpingWorks.map(tarpingWork => tarpingWork.supervisor);
            const supervisorEmails = [...new Set(this.tarpingWorks.map(tarpingWork => tarpingWork.supervisor.email))];
            const supervisorOptions = supervisorEmails.map(email => {
                const supervisor = supervisors.find(supervisor => supervisor.email === email);
                return {
                    display: `${supervisor.displayName()}`,
                    value: `${email}`,
                }
            });
            // Sort supervisor options by display name
            supervisorOptions.sort((a, b) => {
                if (a.display < b.display) return -1;
                if (a.display > b.display) return 1;
                return 0;
            });
            return supervisorOptions;
        },
        stackOptions() {
            // Create {id: display_name ...} name options for stacks
            const stacks = this.tarpingWorks.map(tarpingWork => tarpingWork.stack);
            const stackOptions = {}
            stacks?.forEach(stack => {
                stackOptions[stack.id] = stack.fullName();
            });

            // Convert options to an array of objects
            const stacksArray = Object.entries(stackOptions).map(([key, value]) => {
                return {
                    display: value,
                    value: key,
                }
            });
    
            // Sort stack options by display name
            stacksArray.sort((a, b) => {
                if (a.display < b.display) return -1;
                if (a.display > b.display) return 1;
                return 0;
            });
            return stacksArray;
        }
    },
    async created() {
        this.tarpingWorksStore = useTarpingWorksStore();
        this.tableReady = true;
    },
    methods: {
        async sync() {
            this.loading = true;
            try {
                await this.tarpingWorksStore.loadTarpingWorksFromAPI();
                this.$root.showNotification(
                    'success',
                    'Tarping works synced successfully.',
                );
            } catch (error) {
                console.error(error);
                this.$root.showNotification(
                    'error',
                    'Failed to sync tarping works.',
                );
            } finally {
                this.loading = false;
            }
        },
        createdDisplay(created) {
            const date = new Date(created);
            return date.toLocaleString()
        },
        tarpingWorksActivitiesDisplay(values) {
            const activities = this.tarpingWorksStore.activities;
            const mappedValues = values.map(value => activities.find(a => a.value === value).display);
            return mappedValues.join(', ');
        },
        stackRef(stack) {
            if (!stack) return 'Unknown Stack';
            return `${stack.storage.site.display_name}/${stack.storage.display_name}/${stack.name.toUpperCase()}`;
        },
        openTarpingWorksModal(event) {
            const tarpingWork = event.data;
            const dialogRef = this.$dialog.open(TarpingWorkDetails, {
                props: {
                    header: tarpingWork.stackRef(),
                    style: {
                        width: '100vw',
                        height: '100vh',
                        maxHeight: '100vh',
                        borderRadius: '0',
                    },
                    modal: true,
                    dismissableMask: true,
                    contentClass: 'flex-grow-1',
                },
                data: {
                    tarpingWorks: tarpingWork,
                },
            });
        }
    }
}

</script>

<style>
.table-container {
    overflow: scroll;
}
.tarping-works-table table {
    table-layout: fixed;
}
.tarping-works-table .p-datatable-table .p-datatable-thead > tr > th {
    background: #f1f3f4;
}
.tarping-works-table .p-datatable-tbody {
    font-size: 14px;
}
</style>