<template>
    <div>
        <div class="steps flex flex-row justify-content-between pt-1">
            <RouterLink v-for="(step, index) in steps"
                :key="step.name" 
                :to="`/tarping/record/`"
                :class="{'p-disabled': step.disabled}"
                @click="setActiveStep(index)">
                <div
                    class="step flex flex-column justify-content-center align-items-center"
                    :class="{ active: activeStep == index }">
                    <span class="step-index">{{ index + 1 }}</span>
                    <span class="step-label">{{ step.label }}</span>
                </div>
            </RouterLink>
        </div>
        <Divider />
    </div>
    
</template>

<script>
import { useRecordTarpingWorksStore } from '@/stores/RecordTarpingWorks';

import Divider from 'primevue/divider';
import Steps from 'primevue/steps';

export default {
    name: "FormSteps",
    components: {
        Divider,
        Steps,
    },
    computed: {
        activeStep() {
            return this.store.activeStep;
        },
        steps() {
            return [
                {
                    name: 'stack-profile',
                    label: 'Stack Profile',
                    disabled: false,
                },
                {
                    name: 'site-inputs',
                    label: 'Site Inputs',
                    disabled: !this.store.stackProfileValid
                },
                {
                    name: 'stack-close-out',
                    label: 'Stack Close Out',
                    disabled: !this.store.stackProfileValid || !this.store.siteInputsIsValid
                }
            ]
        },
    },
    created() {
        this.store = useRecordTarpingWorksStore();
    },
    methods: {
        setActiveStep(index) {
            this.store.goToStep(index);
        }
    }
}

</script>

<style scoped>
.steps {
    background: white;
    gap: 10px;
}
.step span {
    text-align: center;
    color: #808080;
}
.step .step-index {
    color: black;
    font-size: 1rem;
    font-weight: bold;
    background: #E2DED0;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    border-radius: 100%;
}
.step.active .step-index {
    background: var(--green-color);
}
.step.active span {
    color: #000000;
}
</style>