<template>
  <div class="view no-bottom-nav no-top-nav">
    <div class="header bg-white p-3  pb-0">
      <div class="logo">
        <img class="logo" src="@/assets/img/GGHS_logo.png">
      </div>
    </div>
    <div class="p-3">
      <h1 class="font-bold text-3xl title">Login</h1>
      <p class="subtitle has-text-grey">Please login to continue</p>
      <Divider />
      <LoginForm @login="onLogin" />
    </div>
    <FullScreenLoader v-if="loadingData" label="Downloading app data..." />
  </div>
</template>

<script>
import Divider from 'primevue/divider';
import FullScreenLoader from '@/components/loading/FullScreenLoader.vue';
import LoginForm from '@/components/auth/LoginForm.vue';

import { loadAppData } from '@/utils/data';

export default {
  name: "LoginView",
  components: {
    Divider,
    FullScreenLoader,
    LoginForm,
  },
  data() { return {
    loadingData: false,
  }},
  methods: {
    async onLogin() {
      this.loadingData = true;
      await loadAppData(true);
      this.loadingData = false;
      this.$router.push({ name: 'tarping_home' });
    }
  }

}
</script>

<style scoped>
.header {
  height: auto;
}
.header .logo {
  width: 100%;
}
</style>
