import { defineStore } from 'pinia';
import { getOrCreateItem, getItems, updateStoreLastUpdated, clearStore, addItems, saveItem } from './idb.js';

import { OBHWorks } from './objects/OBHWorks.js';
import OBHWorksAPI from './api/OBHWorks.js';


export const useOBHWorksStore = defineStore('OBHWorks', {
  state: () => ({ 
    OBHWorks: [],
    lastUpdated: null,
  }),

  actions: {
    async loadOBHWorksFromAPI() {
      // Fetch site works from API and update indexedDB
      const api = new OBHWorksAPI();

      try {
        const response = await api.getOBHWorks();
        const data = response.data;
        this.OBHWorks = data;
        this.updateOBHWorksDB(data);
        return response
      } catch (error) {
        console.error(error);
      }
    },

    async loadOBHWorksFromDB() {
      console.log('Loading tarp works from indexedDB...')
      const OBHWorks = await getItems('OBHWorks');
      this.OBHWorks = OBHWorks;

      const OBHWorksMeta = await getOrCreateItem('meta', 'OBHWorks');
      const lastUpdated = OBHWorksMeta.lastUpdated;
      (lastUpdated) ? this.lastUpdated = lastUpdated : this.lastUpdated = null;
    },

    async loadOBHWorks(awaitAPI) {
      // Load site works from cache and then update from API in background
      await this.loadOBHWorksFromDB();
      if (navigator.onLine) {
        const response = this.loadOBHWorksFromAPI();
        if (awaitAPI) await response;
      }
    },

    async updateOBHWorksDB(data) {
      // Clears and repopulates tarp works in indexedDB
      clearStore('OBHWorks');

      // Add data
      addItems('OBHWorks', data);

      // Update last updated timestamp
      const now = Date.now();
      this.lastUpdated = now;
      updateStoreLastUpdated('OBHWorks', now);
    },

    async addOBHWorks(OBHWorks) {
      saveItem('OBHWorks', OBHWorks.id, OBHWorks);
      this.OBHWorks = [...this.OBHWorks, OBHWorks];
    }

  },

  getters: {

    getOBHWorks(state) {
      // Return all tarp works, ordered by completion time (newest first)
      const sortedOBHWorks = state.OBHWorks.sort((a, b) => {
        return new Date(b.completion_time) - new Date(a.completion_time);
      });
      return sortedOBHWorks.map(ow => new OBHWorks().fromJSON(ow));
    },

    getOBHWorksByStorageId: (state) => (storageId) => {
      // Return all OBH works for a given storage
      if (!storageId) return [];
      return state.OBHWorks.filter(ow => ow.storage === storageId);
    }

  },

})