<template>
    <div v-if="viewReady" class="view flex flex-column no-bottom-nav">
        <SitesTable />
    </div>
</template>

<script>
import Button from 'primevue/button';
import SitesTable from './components/SitesTable.vue';

import { useSitesStore } from '@/stores/Sites';


export default {
    name: "SitesView",
    components: {
        Button,
        SitesTable,
    },
    data() { return {
        // Flags
        viewReady: false,
        loading: false,
        // Stores
        sitesStore: null,
    }},
    computed: {
        sitesLastUpdated() {
            // Format store last updated date for display
            const date = new Date(this.sitesStore.lastUpdated);
            return date.toLocaleString();
        },
    },
    async mounted () {
        // Load stores
        this.sitesStore = useSitesStore();
        this.viewReady = true;
    },
    methods: {

      async sync() {
        this.loading = true;
        try {
          await this.sitesStore.loadSitesFromAPI();
          this.$root.showNotification(
              'success',
              'Sites synced successfully.'
          );
        } catch (error) {
          console.error(error);
          this.$root.showNotification(
              'error',
              'Sites failed to sync.'
          );
        } finally {
          this.loading = false;
        }
      }
      
    }
}

</script>

<style scoped>
.view {
    height: 100%;
    overflow: hidden;
}

</style>