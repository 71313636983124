<template>
  <div class="field p-fluid">
    <label for="completion_time">New Length (m)</label>
    <NumberInput 
        inputId="rw-new-length" 
        ref="newLength" 
        v-model="form.new_length" 
        class="w-full" 
        :useGrouping="false"
        :maxFractionDigits="2"
        :min="0"
    />
  </div>
</template>

<script>
import { useRecordTarpRepairsStore } from '@/stores/RecordTarpRepairs';

import NumberInput from '@/components/forms/NumberInput.vue';

import Message from 'primevue/message';

export default {
  name: "NewLength",
  components: {
    Message,
    NumberInput,
  },
  created() {
      this.store = useRecordTarpRepairsStore();
  },
  computed: {
    form() {
        return this.store.tarp_repair;
    },
  }
}

</script>