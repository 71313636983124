<template>
  <form v-if="formReady" class="tarping-works-form" autocomplete="off">
    <h2 class="title">Stack Close Out</h2>
    <p>
      Here is where we identify when stacks are closed with the final tarp pulled out.
    </p>

    <Divider />

    <div class="field">
      <label for="stack_closed_out">Is the stack closed with the final tarp pulled out and at least temporary boarded?</label>
      <RadioSelect v-model="form.stack_closed_out" :value="true" label="Yes" name="stack_closed_out" class="mb-2" />
      <RadioSelect v-model="form.stack_closed_out" :value="false" label="No" name="stack_closed_out" />
    </div>

    <div class="field p-fluid">
      <Divider />
      <Button
        label="Submit"
        severity="secondary"
        size="large" 
        :disabled="nextDisabled"
        @click="submitForm"
        raised />
    </div>
  </form>
</template>

<script>
import { useRecordTarpingWorksStore } from '@/stores/RecordTarpingWorks';

import Button from 'primevue/button';
import Divider from 'primevue/divider';

import RadioSelect from '@/components/forms/RadioSelect.vue';

export default {
  name: "StackCloseOut",
  components: {
    Button,
    Divider,
    RadioSelect,
  },
  data() { return {
    formReady: false,
    store: null,
  }},
  created() {
    const store = useRecordTarpingWorksStore();
    this.store = store;
    this.formReady = true;
  },
  computed: {
    form() {
      return this.store.form;
    },
    nextDisabled() {
      return !this.store.stackCloseOutValid
    }
  },
  methods: {
    async submitForm() {
      this.store.goToStep(3);
    }
  }
}

</script>
