<template>
  <Field v-slot="slotProps">
    <Dropdown
      :inputId="slotProps.inputId"
      :modelValue="modelValue" 
      :optionLabel="optionLabel"
      :optionValue="optionValue"
      :disabled="disabled || false"
      :placeholder="placeholder"
      :options="options"
      @update:modelValue="update"
    />
  </Field>
</template>

<script>
import Dropdown from 'primevue/dropdown';
import Field from './Field.vue';

export default {
  name: "DropdownField",
  props: [
    'modelValue',
    'options',
    'optionLabel',
    'optionValue',
    'placeholder',
    'disabled',
  ],
  components: {
    Field,
    Dropdown,
  },
  methods: {
    update(value) {
      this.$emit('update:modelValue', value);
    }
  }
}

</script>