<template>
  <div v-if="form.is_new_stack === true">
    <div class="field p-fluid">
      <Divider />
      <label for="has_carryover_grain">
        Does it have grain in it from last year with tarps already on stack, 
        or is it a subsequent stack in the same OBH that starts filling from a 
        small end wall (i.e. not started from the opposite end of the OBH to 
        the previous stack)?
      </label>
      <RadioSelect 
        v-model="has_carryover_grain_index" 
        :value="0"
        label="Normal stack started from an OBH end (no carryover grain and no small end wall)"
        name="has_carryover_grain"
        class="mb-2"
      />
      <RadioSelect
        v-model="has_carryover_grain_index" 
        :value="1"
        label="Filled from small end wall"
        name="has_carryover_grain"
        class="mb-2"
      />
      <RadioSelect 
        v-model="has_carryover_grain_index" 
        :value="2" 
        label="Carryover grain with tarps" 
        name="has_carryover_grain" 
      />
    </div>

    <div v-if="[1, 2].includes(store.form_extra_fields.has_carryover_grain_index)" class="field p-fluid">
      <Divider />
      <label for="starting_peak_actual">
        {{ startingPeakActualLabel }}
      </label>
      <NumberInput
        inputId="starting_peak_actual"
        v-model="form.starting_peak_actual" 
        placeholder="This value must be a number" />
      <StorageLengthWarning 
        :value="form.starting_peak_actual"
        :storageLength="store.selectedStorageLength" />
    </div>

    <div v-if="store.form_extra_fields.has_carryover_grain_index === 2" class="field p-fluid">
      <Divider />
      <label for="pulled_back_folded_tarp_length">
        If the stack already has tarps on it, what is the length of 'FOLDED' tarp on stack at the PEAK ACTUAL?
      </label>
      <NumberInput
        inputId="pulled_back_folded_tarp_length"
        v-model="form.pulled_back_folded_tarp_length" 
        placeholder="This value must be a number" />
    </div>

  </div>
</template>

<script>
import { useRecordTarpingWorksStore } from '@/stores/RecordTarpingWorks';

import Divider from 'primevue/divider';
import RadioSelect from '@/components/forms/RadioSelect.vue';

import NumberInput from '@/components/forms/NumberInput.vue';
import StorageLengthWarning from '../../../components/StorageLengthWarning.vue';

export default {
  name: "IsNewStack",
  components: {
    Divider,
    RadioSelect,
    NumberInput,
    StorageLengthWarning,
  },
  computed: {
    form() {
      return this.store.form;
    },
    has_carryover_grain_index: {
      get() {
        return this.store.form_extra_fields.has_carryover_grain_index;
      },
      set(value) {

        this.store.$patch({
          form_extra_fields: {
            has_carryover_grain_index: value,
          }
        });

        const valueMap = {
          0: false,
          1: true,
          2: true,
        }
        this.store.$patch({
          form: {
            ...this.store.form,
            has_carryover_grain: valueMap[value],
          }
        });

        if (value == 0) {
          this.store.$patch({
            form: {
              ...this.store.form,
              starting_peak_actual: null,
            }
          });
        }

        if (value != 2) {
          this.store.$patch({
            form: {
              ...this.store.form,
              pulled_back_folded_tarp_length: null,
            }
          });
        }
      }
    },
    startingPeakActualLabel() {
      if (this.store.form_extra_fields.has_carryover_grain_index === 1) {
        return `
          What is the grain start point (i.e Small End Wall location, 
          or if no small wall yet installed, the start of the grain, 
          not the peak) of this additional stack?
        `;
      } else if (this.store.form_extra_fields.has_carryover_grain_index === 2) {
        return `
          What is the PEAK ACTUAL from the Carryover Grain?
        `
      }
    },
  },
  created() {
      this.store = useRecordTarpingWorksStore();
  },
  
}
</script>