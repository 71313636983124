<template>
  <Field v-slot="slotProps">
    <NumberInput
      :inputId="slotProps.inputId"
      :modelValue="modelValue"
      :placeholder="placeholder"
      :disabled="disabled || false"
      :maxFractionDigits="maxFractionDigits || 2"
      :min="min"
      :max="max"
      :enterkeyhint="enterkeyhint"
      :useGrouping="false"
      @update:modelValue="update" />
  </Field>
</template>

<script>
import Field from './Field.vue';
import NumberInput from './NumberInput.vue';

export default {
  name: "IntegerField",
  props: [
    'modelValue',
    'placeholder',
    'disabled',
    'maxFractionDigits',
    'min',
    'max',
    'enterkeyhint',
  ],
  components: {
    Field,
    NumberInput,
  },
  methods: {
    update(value) {
      this.$emit('update:modelValue', value);
    }
  }
}

</script>