<template>
    <form v-if="formReady" class="tarping-works-form" @submit.prevent="goToNextStep" novalidate autocomplete="off">

        <h2 class="title">Stack Profile Set Up</h2>
        <p>
            Here is where we can allocate whether the stack has carry-over grain
            tarps still on stack from previous years, etc
        </p>

        <Divider />

        <SiteSelect v-model="form.site" />
        <StorageSelect v-model="form.storage" :siteId="form.site" />
        <StackSelect v-model="form.stack" :storage="form.storage" />
        <IsNewStack />
        <HasCarryoverGrain />

        <div class="field p-fluid">
            <Divider />
            <Button label="Next" severity="secondary" size="large" :disabled="!store.stackProfileValid" type="submit" raised />
        </div>
    </form>
</template>

<script>
import { useRecordTarpingWorksStore } from '@/stores/RecordTarpingWorks';
import { useTarpingWorksStore } from '@/stores/TarpingWorks';

import Button from 'primevue/button';
import Divider from 'primevue/divider';

import HasCarryoverGrain from './components/HasCarryoverGrain.vue';
import IsNewStack from './components/IsNewStack.vue';
import SiteSelect from './components/SiteSelect.vue';
import StorageSelect from './components/StorageSelect.vue';
import StackSelect from './components/StackSelect.vue';


export default {
    name: "StackProfileSetup",
    components: {
      Button,
      Divider,
      HasCarryoverGrain,
      IsNewStack,
      SiteSelect,
      StorageSelect,
      StackSelect,
    },
    data() { return {
      formReady: false,
      store: null,
      tarpingWorksStore: null,
    }},
    async created() {
      const store = useRecordTarpingWorksStore();
      this.store = store;

      const tarpingWorksStore = useTarpingWorksStore();
      this.tarpingWorksStore = tarpingWorksStore;

      store.$subscribe((mutation, state) => {
        if (mutation.events?.key === 'site') {
          store.$patch({
            form: {
              storage: null,
              stack: null,
              is_new_stack: null,
            }
          });
        }
        if (mutation.events?.key === 'storage') {
          store.$patch({
            form: {
              stack: null,
              is_new_stack: null,
            }
          });
        }
        if (mutation.events?.key === 'stack') {
          store.$patch({
            form: {
              is_new_stack: null,
              has_carryover_grain: null,
              starting_peak_actual: null,
              pulled_back_folded_tarp_length: null,
            },
            form_extra_fields: {
              has_carryover_grain_index: null,
            }
          });
        }
      });

      this.formReady = true;
    },
    computed: {
      form() {
        return this.store.form;
      },
    },
    methods: {
      goToNextStep() {
        this.store.goToStep(1);
      },
    }
}

</script>
