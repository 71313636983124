<template>
    <div class="table-container flex-grow-1 pb-0 overflow-x-hidden">
        <DataTable 
            :value="sites" 
            scrollable 
            scrollHeight="flex" 
            class="p-datatable-sm"
            :pt="{ 
                thead: {
                    style: { display: 'none' }
                }
            }">
            <template #header>
                <div class="flex flex-wrap align-items-center justify-content-between gap-2 p-1">
                    <div>
                        <p class="title"><span class="text-xl text-900 font-bold">Sites</span></p>
                        <p class="subtitle"><span><small>Last Updated: {{ sitesLastUpdated }}</small></span></p>
                    </div>
                    
                    <Button severity="secondary" rounded raised :loading="loading" @click="sync">
                        <template #icon>
                            <span class="material-symbols-outlined">download</span>
                        </template>
                    </Button>
                </div>
            </template>
            <Column field="display_name" header="Name"></Column>
            <Column style="width: 68px">
                <template #body="slotProps">
                    <Button rounded severity="success" text raised @click="toggleFavourite(slotProps.data.name)">
                        <template #icon>
                            <span class="icon material-symbols-outlined" :class="{ filled: favourites.includes(slotProps.data.name) }">star</span>
                        </template>
                    </Button>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';

import { useSitesStore } from '@/stores/Sites';


export default {
    name: "SitesTable",
    components: {
        Button,
        Column,
        DataTable
    },
    data() { return {
        tableReady: false,
        loading: false,
        store: null,
    }},
    computed: {
        sites() {
            return this.store.sites;
        },
        favourites() {
            return this.store.favourites;
        },
        sitesLastUpdated() {
            // Format store last updated date for display
            console.log(this.store.lastUpdated);
            const date = new Date(this.store.lastUpdated);
            return date.toLocaleString();
        }
    },
    async created() {
        this.store = useSitesStore();
        this.tableReady = true;
    },
    methods: {
        async sync() {
            this.loading = true;
            try {
                await this.store.loadSitesFromAPI();
            } catch {
                this.$root.showNotification(
                    'error',
                    'Failed to sync sites.',
                );
            } finally {
                this.$root.showNotification(
                    'success',
                    'Sites synced successfully.',
                );
            }
            this.loading = false;
        },
        toggleFavourite(siteName) {
            this.store.toggleSiteFavourite(siteName);
        },
    }
}

</script>

<style scoped>
.table-container {
    overflow: scroll;
}
.p-datatable-thead {
    display: none;
}
</style>