<template>
  <div>
    <div class="text-center">
      <p class="icon">
        <span class="material-symbols-outlined xxlg">error</span>
      </p>
      <p class="text-lg font-bold">
        A repair for this tarp already exists.
      </p>

      <Message severity="error" :closable="false">
        <template v-slot:messageicon><span></span></template>
        <p class="m-0">
          Would you like to add a ‘0' 
          to the serial number and physically paint the '0’ on the tarp?
        </p>
        <div class="flex justify-content-center mt-3">
          <Button 
            label="Disagree & Change"
            severity="danger"
            @click="changeSerialNumber"
            class="mr-2"
            raised />
          <Button 
            label="Agree & Review"
            severity="info"
            @click="addZeroToSerialNumber"
            raised />
        </div>
      </Message>
    </div>
  </div>
</template>

<script>
import Button from 'primevue/button'
import Message from 'primevue/message';

export default {
  name: "TarpRepairAlreadyExistsError",
  components: {
    Button,
    Message,
  },
  methods: {
    changeSerialNumber() {
      this.$emit('changeSerialNumber');
    },
    addZeroToSerialNumber() {
      this.$emit('addZeroToSerialNumber');
    }
  }
}

</script>
