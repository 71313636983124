import { defineStore } from 'pinia';
import { getOrCreateItem, getItems, updateStoreLastUpdated, clearStore, addItems, saveItem } from './idb.js';

import Stockpiling from './objects/Stockpiling.js';
import StockpilingAPI from './api/Stockpiling.js';


export const useStockpilingStore = defineStore('stockpiling', {
  state: () => ({ 
    stockpiling: [],
    lastUpdated: null,
  }),

  actions: {
    async loadStockpilingFromAPI() {
      // Fetch tarps from API and update indexedDB
      const api = new StockpilingAPI();

      try {
        const response = await api.getStockpiling();
        const data = response.data;
        this.stockpiling = data;
        this.updateStockpilingDB(data);
        return response
      } catch (error) {
        console.error(error);
      }
    },

    async loadStockpilingFromDB() {
      console.log('Loading tarp works from indexedDB...')
      const stockpiling = await getItems('stockpiling');
      this.stockpiling = stockpiling;

      const stockpilingMeta = await getOrCreateItem('meta', 'stockpiling');
      const lastUpdated = stockpilingMeta.lastUpdated;
      (lastUpdated) ? this.lastUpdated = lastUpdated : this.lastUpdated = null;
    },

    async loadStockpiling(awaitAPI) {
      // Load tarps from cache and then update from API in background
      await this.loadStockpilingFromDB();
      if (navigator.onLine) {
        const response = this.loadStockpilingFromAPI();
        if (awaitAPI) await response;
      }
    },

    async updateStockpilingDB(data) {
      // Clears and repopulates tarp works in indexedDB
      clearStore('stockpiling');

      // Add data
      addItems('stockpiling', data);

      // Update last updated timestamp
      const now = Date.now();
      this.lastUpdated = now;
      updateStoreLastUpdated('stockpiling', now);
    },

    async addStockpiling(stockpiling) {
      saveItem('stockpiling', stockpiling.id, stockpiling);
      this.stockpiling = [...this.stockpiling, stockpiling];
    }

  },

  getters: {

    getStockpiling(state) {
      // Return all tarp joins, ordered by completion time (newest first)
      const sortedStockpiling = state.stockpiling.sort((a, b) => {
        return new Date(b.completion_time) - new Date(a.completion_time);
      });
      return sortedStockpiling.map(stockpiling => new Stockpiling().fromJSON(stockpiling));
    },
  },

})