import { default as axios } from './http';


class NewTarpsInputAPI {

  getNewTarpsInputs = async () => {
    // Fetch tarps from API
    console.log('Fetching stockpiling works from API...')
    const url = '/api/v1/gcm/new-tarps-input/';
    const response = await axios.get(url)
    return response
  }

  createNewTarpsInput = async (data) => {
    // Create tarping works in API
    const url = '/api/v1/gcm/new-tarps-input/';
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }
    }
    const response = await axios.post(url, data, options)
    return response
  }

}

export default NewTarpsInputAPI;