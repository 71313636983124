import { replaceUnderscoresAndTitleCase } from '@/utils/strings.js'

import { useStoragesStore } from '@/stores/Storages';


class Site {

    id = null;
    name = null;
    display_name = null;

    storagesStore = null;

    constructor() {
      this.storagesStore = useStoragesStore();
    }

    // Adapters

    toJSON() {
      return {
        id: this.id,
        name: this.name,
        display_name: this.display_name,
      }
    }

    fromJSON(data) {
      this.id = data.id;
      this.name = data.name;
      this.display_name = data.display_name;
      return this
    }

    // Properties

    displayName() {
      // Return the site verbose name or the name with _ replace with spaces and in title case
      return (this.display_name) ? this.display_name : replaceUnderscoresAndTitleCase(this.name)
    }

    storages() {
      // Return list of storage objects for this site
      return this.storagesStore.getStoragesBySite(this.id)
    }

}

export default Site
