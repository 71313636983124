import * as Sentry from '@sentry/vue';
import { v4 as uuidv4 } from 'uuid';
import { dateToISOString, ISOStringToDate } from '@/utils/dates';
import { validateRequiredFields } from '@/utils/validation';

import SiteWorksAPI from '@/stores/api/SiteWorks';
import { useSitesStore } from '@/stores/Sites';
import { useTarpInventoryStore } from '@/stores/TarpInventory';
import { useRecordSiteWorksStore } from '@/stores/RecordSiteWorks';
import Tarp from '@/stores/objects/Tarp';
import Supervisor from './Supervisor';


class SiteWorks {

    id = null;
    client_id = null;
    site = null;
    completion_time = null;
    wozzy = null;
    ratchets = null;
    belly_straps = null;
    high_wall_canvecon = null;
    low_wall_canvecon = null;
    small_end_wall_tee_pieces = null;
    small_end_wall_sections = null;
    small_end_walls_still_inside_obhs = null;
    comments = null;
    number_of_outloaded_tarps_on_site = null;
    tarps_on_site_bool = null;
    tarps_on_site = [];
    new_tarps = [];

    activity_type = 'site_works';

    constructor() {
      this.client_id = uuidv4();
      this.sitesStore = useSitesStore();
      this.tarpInventoryStore = useTarpInventoryStore();
    }

    // Adapters

    toJSON() {
      return {
        id: this.id,
        client_id: this.client_id,
        site: this.site?.id,
        completion_time: (this.completion_time) ? dateToISOString(this.completion_time): null,
        supervisor: this.supervisor?.toJSON(),
        wozzy: this.wozzy,
        ratchets: this.ratchets,
        belly_straps: this.belly_straps,
        high_wall_canvecon: this.high_wall_canvecon,
        low_wall_canvecon: this.low_wall_canvecon,
        small_end_wall_tee_pieces: this.small_end_wall_tee_pieces,
        small_end_wall_sections: this.small_end_wall_sections,
        small_end_walls_still_inside_obhs: this.small_end_walls_still_inside_obhs,
        comments: this.comments,
        number_of_outloaded_tarps_on_site: this.number_of_outloaded_tarps_on_site,
        tarps_on_site_bool: this.tarps_on_site_bool,
        tarps_on_site: (this.tarps_on_site) ? this.tarps_on_site.map(t => t.id) : [],
        new_tarps: (this.new_tarps) ? this.new_tarps.map(new_tarp => new_tarp.toJSON()) : [],
      }
    }

    fromJSON(data) {
      this.id = data.id;
      this.client_id = data.client_id || this.id;

      (data.site) ? this.site = this.sitesStore.getSiteById(data.site) : this.site = null;
      this.completion_time = (data.completion_time) ? ISOStringToDate(data.completion_time) : null;
      this.supervisor = new Supervisor().fromJSON(data.supervisor);

      this.wozzy = data.wozzy;
      this.ratchets = data.ratchets;
      this.belly_straps = data.belly_straps;
      this.high_wall_canvecon = data.high_wall_canvecon;
      this.low_wall_canvecon = data.low_wall_canvecon;
      this.small_end_wall_tee_pieces = data.small_end_wall_tee_pieces;
      this.small_end_wall_sections = data.small_end_wall_sections;
      this.small_end_walls_still_inside_obhs = data.small_end_walls_still_inside_obhs;
      this.comments = data.comments;
      this.number_of_outloaded_tarps_on_site = data.number_of_outloaded_tarps_on_site;

      if (data.tarps_on_site_bool !== undefined || data.tarps_on_site_bool !== null) {
        this.tarps_on_site_bool = data.tarps_on_site_bool;
      } else if (data.tarps_on_site?.length > 0) {
        this.tarps_on_site_bool = true;
      }

      this.tarps_on_site = data.tarps_on_site?.map(
        maintained_tarp => this.tarpInventoryStore.getTarpById(maintained_tarp)
      ) || [];

      this.new_tarps = data.new_tarps?.map(
        new_tarp => new Tarp().fromJSON(new_tarp)
      ) || [];
      
      return this
    }

    clean() {
      // Reset fields based on certain conditions to ensure data integrity
      if (!this.tarps_on_site_bool) {
        this.tarps_on_site = [];
        this.new_tarps = [];
      }
    }

    updateSiteWorksInStores(response) {
      // Removes the tarping works from the locally saved tarping works and 
      // adds the updated tarping works to the tarping works store.
      const recordSiteWorksStore = useRecordSiteWorksStore();
      recordSiteWorksStore.removeSiteWorksFromLocal(response.data.client_id);
      recordSiteWorksStore.addToSiteWorksStore(response.data);
    }

    syncToServer = async (data) => {
      if (!data) data = this.toJSON();
  
      const api = new SiteWorksAPI();
  
      let response;
  
      try {
  
        if (this.id) response = await api.updateSiteWorks(this.id, data);
        else         response = await api.createSiteWorks(data);
  
        if ([200, 201].includes(response.status)) {
          this.updateSiteWorksInStores(response);
        }
  
        return response;
  
      } catch (error) {
  
        Sentry.captureException(error);
        console.log(error);
  
        if (error.response) {
          if (error.response.status === 409) {
            // 409 is returned if the tarping works has already been created on the server
            this.updateSiteWorksInStores(error.response);
          }
          return error.response;
        } else {
          throw error;
        }
        
      }
    }

    // Properties

    siteWorksIsValid() {
      const requiredFields = [
        'site',
        'completion_time',
        'wozzy',
        'ratchets',
        'belly_straps',
        'high_wall_canvecon',
        'low_wall_canvecon',
        'small_end_wall_tee_pieces',
        'small_end_wall_sections',
        'small_end_walls_still_inside_obhs',
      ];
      return validateRequiredFields(requiredFields, this.toJSON());
    }

    tarpsIsValid() {
      const requiredFields = [
        'tarps_on_site_bool',
        'number_of_outloaded_tarps_on_site',
      ];

      if (this.tarps_on_site_bool === true) {
        requiredFields.push('tarps_on_site|new_tarps');
      }

      return validateRequiredFields(requiredFields, this.toJSON());
    }

    isValid() {
      return this.siteWorksIsValid() && this.tarpsIsValid();
    }

    displayName() {
      if (this.site) {
        return this.site.displayName() + ' Site Works';
      }
    }

    completionTimeDisplay() {
      const completionTime = this.completion_time;
      if (!completionTime) return 'Unknown Completion Time';
      return new Date(completionTime).toLocaleString();
    }

}

export default SiteWorks
