import { defineStore } from 'pinia';

import { useStoragesStore } from '@/stores/Storages';
import { useTarpingWorksStore } from '@/stores/TarpingWorks';

import { getOrCreateDB } from '@/stores/idb'
import TarpingWorks from './objects/TarpingWorks';


export const useRecordTarpingWorksStore = defineStore('recordTarpingWorks', {
  persist: {
    paths: ['activeStep', 'form', 'form_extra_fields']
  },

  state: () => ({
    activeStep: 0,
    form: new TarpingWorks().toJSON(),
    // Used to store extra fields that are not part of the tarping works object
    // but are required for the form to work.
    form_extra_fields: {
      // Answer index selected by user for has_carryover_grain question
      has_carryover_grain_index: null,
    },
    loading: false,
    // Tarping works that have been completed and submitted 
    // by the user but have not been saved to the server.
    // Need to be stored in IndexedDB as LocalStorage can be cleared by device.
    unsaved_tarping_works: [],
  }),

  actions: {

    goToStep(step) {
        this.activeStep = step;
        // Set scroll height of body to 0
        window.scrollTo(0, 0);
    },

    setNewStack(newStack) {
      this.form.new_stack = newStack;
      this.form.new_stack_commodity = newStack.commodity.id;
    },

    async saveTarpingWorks() {

      const data = this.form;

      console.log('Saving tarping works', data);

      // Save the tarping works to local device
      this.saveTarpingWorksToLocal(data);

      if (navigator.onLine) {
        try {

          this.loading = true;

          // syncToServer doesn't throw an error if the request fails.
          // Status is returned and handled by the component calling this action.
          const response = await new TarpingWorks().syncToServer(data);

          return response;

        } catch (error) {
          throw error;
        }
      } else {
        return { status: 0 }  // Offline
      }
    },
    
    saveTarpingWorksToLocal(tarpingWorks) {
      // Check if the tarping works have already been added to the unsaved_tarping_works array
      const existingTarpingWorks = this.unsaved_tarping_works.filter(tw => tw.client_id === tarpingWorks.client_id);
      if (existingTarpingWorks.length) {
        // If the tarping works already exist in the array, replace them with the new tarping works
        this.unsaved_tarping_works = this.unsaved_tarping_works.map(tw => {
          if (tw.client_id === tarpingWorks.client_id) {
            return tarpingWorks
          }
          return tw
        });
      } else {
        // Add tarping works to the unsaved_tarping_works array
        this.unsaved_tarping_works.push(tarpingWorks);
      }
      // Save the unsaved tarping works to indexedDB
      this.saveUnsavedTarpingWorksToDB(tarpingWorks);
    },

    removeTarpingWorksFromLocal(clientId) {
      this.unsaved_tarping_works = [...this.unsaved_tarping_works.filter(tw => tw.client_id !== clientId)];
      this.removeUnsavedTarpingWorksFromDB(clientId);
    },

    addToTarpingWorksStore(tarpingWorks) {
      // Add the tarping works to the tarping work store
      const tarpingWorksStore = useTarpingWorksStore();
      tarpingWorksStore.addTarpingWorks(tarpingWorks);
    },

    async saveUnsavedTarpingWorksToDB(tarpingWorks) {
      console.log('Saving unsaved tarping works to indexedDB...')
      const db = await getOrCreateDB();
      const tx = db.transaction(['unsavedTarpingWorks'], 'readwrite');
      const unsavedTarpingWorksStore = tx.objectStore('unsavedTarpingWorks');
      // Serialize data
      const data = new TarpingWorks().fromJSON(tarpingWorks).toJSON();
      // Add data
      const alreadyExists = await unsavedTarpingWorksStore.get(data.client_id);
      console.log(alreadyExists);
      if (alreadyExists) {
        unsavedTarpingWorksStore.put(data);
      } else {
        unsavedTarpingWorksStore.add(data);
      }
      await tx.done;
    },

    async removeUnsavedTarpingWorksFromDB(clientId) {
      console.log('Removing unsaved tarping works from indexedDB...')
      const db = await getOrCreateDB();
      const tx = db.transaction(['unsavedTarpingWorks'], 'readwrite');
      const unsavedTarpingWorksStore = tx.objectStore('unsavedTarpingWorks');
      unsavedTarpingWorksStore.delete(clientId);
      await tx.done;
    },

    async loadUnsavedTarpingWorksFromDB() {
      console.log('Loading unsaved tarping works from indexedDB...')
      const db = await getOrCreateDB();
      const tx = db.transaction(['unsavedTarpingWorks'], 'readwrite');
      const unsavedTarpingWorksStore = tx.objectStore('unsavedTarpingWorks');
      const unsavedTarpingWorks = await unsavedTarpingWorksStore.getAll();
      console.log(`${unsavedTarpingWorks.length} unsaved tarping works loaded from indexedDB`);
      this.unsaved_tarping_works = unsavedTarpingWorks;
      await tx.done;
    },

    reset(resetSteps=true) {
      this.form = new TarpingWorks().toJSON();
      this.form_extra_fields = {
        has_carryover_grain_index: null,
      }
      if (resetSteps === true) this.activeStep = 0;
    }

  },

  getters: {

    selectedStorage(state) {
      const storagesStore = useStoragesStore();
      // Return the selected storage object from the form
      const storageId = state.form.storage;
      if (storageId) {
        const storage = storagesStore.getStorageById(storageId);
        return storage;
      }
      return null
    },
    selectedStorageLength() {
      let storage = this.selectedStorage;
      if (storage && storage.length) {
        return storage.length;
      }
      return null
    },

    selectedStackLastTarpingWorks(state) {
      const tarpingWorksStore = useTarpingWorksStore();
      const stackId = state.form.stack;
      if (stackId) {
        // Get tarping works and sort by completion time
        const tarpingWorks = tarpingWorksStore.tarpingWorks.filter(tw => tw.stack === stackId);
        const sortedTarpingWorks = tarpingWorks.sort((a, b) => {
          return new Date(b.completion_time) - new Date(a.completion_time);
        });
        // Return the most recent tarping works
        return sortedTarpingWorks[0];
      }
      return null
    },

    // Field validation
    completionTimeIsValid(state) {
      return state.form.completion_time !== null
    },
    activitiesIsValid(state) {
      return state.form.activities.length > 0
    },
    peakActualIsValid(state) {
      const activities = state.form.activities;

      const peakActualActivites = [
        'tarp_on_pull_out',
        'pulling_tarps_out',
        'cbh_supervisor_input'
      ]

      // If any of the peak actual activities are selected, peak actual is required
      if (activities.some(activity => peakActualActivites.includes(activity))) {
        return state.form.peak_actual !== null
      }
      return true
    },
    coverEstimatedIsValid(state) {
      const activities = state.form.activities;

      const coverEstimatedActivites = [
        'tarp_on_pull_out',
        'pulling_tarps_out',
      ] 
      
      // If any of the cover estimated activities are selected, cover estimated is required
      if (activities.some(activity => coverEstimatedActivites.includes(activity))) {
        return state.form.cover_estimated !== null || state.form.cover_estimated_max === true
      }
      return true
    },
    coverActualIsValid(state) {
      // Check cover actual is not greater than cover estimated
      if (Number.isInteger(state.form.cover_estimated)) {
        if (state.form.cover_actual > state.form.cover_estimated) return false;
      }

      if (state.form.cover_estimated_max === true) {
        if (state.form.cover_actual > state.form.peak_actual) return false;
      }

      const activities = state.form.activities;

      const coverActualActivites = [
        'tarp_on_pull_out',
        'pulling_tarps_out',
      ]

      // If any of the cover actual activities are selected, cover actual is required
      if (activities.some(activity => coverActualActivites.includes(activity))) {
        return state.form.cover_actual !== null || state.form.cover_actual_max === true
      }
      return true
    },
    tarpsIsValid(state) {
      const activities = state.form.activities;
      if (state.form.activities.includes('tarp_on_pull_out')) return state.form.tarps.length > 0 || state.form.new_tarps.length > 0
      return true
    },
    permedIsValid(state) {
      const activities = state.form.activities;
      
      if (activities.includes('perming')) return (
        (state.form.permed !== null || state.form.permed_max === true) &&
        (
          state.form.peak_actual_max === true ||
          state.form.peak_actual === null ||
          state.form.peak_actual >= state.form.permed ||
          state.form.permed_max === true
        )
      )
      return true
    },
    seamsWozziedIsValid(state) {
      const activities = state.form.activities;
      if (activities.includes('wozzying')) return state.form.seams_wozzied !== null || state.form.seams_wozzied_max === true
      return true
    },
    toeLocationIsValid(state) {
      const activities = state.form.activities;
      if (activities.includes('cbh_supervisor_input')) return state.form.toe_location !== null
      return true
    },


    stackProfileValid(state) {

      let form = state.form

      // Always required fields
      let requiredFields = [form.site, form.storage, form.stack]

      if (!this.selectedStackLastTarpingWorks) {
        requiredFields.push(form.is_new_stack)

        if (state.form.is_new_stack === true) {
          requiredFields.push(form.has_carryover_grain)

          if (state.form.has_carryover_grain === true) {
            if ([1,2].includes(state.form_extra_fields.has_carryover_grain_index))
              requiredFields.push(form.starting_peak_actual);

            if (state.form_extra_fields.has_carryover_grain_index === 2) 
              requiredFields.push(form.pulled_back_folded_tarp_length);
          }
        }
      }
      return !requiredFields.includes(null)
    },

    siteInputsIsValid(state) {

      const validators = [
        this.completionTimeIsValid,
        this.activitiesIsValid,
        this.peakActualIsValid,
        this.coverEstimatedIsValid,
        this.coverActualIsValid,
        this.tarpsIsValid,
        this.permedIsValid,
        this.seamsWozziedIsValid,
        this.toeLocationIsValid,
      ]

      // Return true if all validators are true
      return validators.every(value => {
        return value === true
      })

    },

    stackCloseOutValid(state) {
      let form = state.form;
      const requiredFields = [form.stack_closed_out]
      return !requiredFields.includes(null)
    },

    isTarpingActivity(state) {
      return (
        state.form.activities.includes('tarp_on_pull_out') ||
        state.form.activities.includes('pulling_tarps_out')
      )
    },

  }
})
