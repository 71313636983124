<template>
    <div v-if="showStackSelect" class="field p-fluid">
        <Divider />
        <label for="stack">Stack</label>
        <RadioSelect
            v-for="stack in stacks"
            :key="stack.id"
            class="mb-2"
            :modelValue="modelValue"
            :value="stack.id"
            :labelHTML="stackLabel(stack)"
            @update:modelValue="update"
        />
        <AddItemBtn class="mt-3" @onClick="openNewStackModal" />
    </div>
</template>

<script>
import AddItemBtn from '@/components/forms/AddItemBtn.vue';
import RadioSelect from '@/components/forms/RadioSelect.vue';

import { useStacksStore } from '@/stores/Stacks';
import { useRecordTarpingWorksStore } from '@/stores/RecordTarpingWorks';

import NewStackForm from './NewStackForm.vue';
import Stack from '@/stores/objects/Stack';

import Divider from 'primevue/divider';


export default {
    name: "StackSelect",
    props: ['modelValue', 'storage'],
    components: {
        Divider,
        AddItemBtn,
        NewStackForm,
        RadioSelect,
    },
    data() { return {
        store: null,
        formStore: null,
        newStack: null,
    }},
    computed: {
        form() {
            return this.formStore.form;
        },
        currentStacks() {
            // Stacks that are already in the database
            return this.store.getStacksByStorage(this.storage).filter(stack => stack.archived != true)
        },
        stacks() {
            let stacks;
            (this.newStack && this.newStack.storage?.id === this.storage) ? stacks = [...this.currentStacks, this.newStack] : stacks = this.currentStacks;
            return stacks
        },
        value() {
            return this.storages.find(storage => storage.id === this.modelValue)
        },
        showStackSelect() {
            return this.form?.storage
        }
    },
    async created() {
        this.store = useStacksStore();
        this.formStore = useRecordTarpingWorksStore();
        if (this.formStore.form.new_stack) this.newStack = new Stack().fromJSON(this.formStore.form.new_stack);
    },
    methods: {
        stackLabel(stack) {
            const stackIdentifier = stack.name.toUpperCase();
            const commodityName = stack.commodity.display_name;
            let label = `<span class="font-semibold">${stackIdentifier} <i>${commodityName}</i></span>`
            if (stack.grades && stack.grades.length > 0) {
                const grades = stack.grades.map(grade => grade.display_name).join(', ');
                label += `<span><i> - ${grades}<i></span>`
            }
            return label
        },
        update(stackId) {
            this.$emit('update:modelValue', stackId)
        },
        openNewStackModal() {
            const dialogRef = this.$dialog.open(NewStackForm, {
                props: {
                    header: "Create a new stack",
                    style: {
                        width: '100vw',
                        height: 'calc(100 - 2rem)',
                        maxHeight: '100vh',
                        borderRadius: '0',
                    },
                    modal: true,
                    dismissableMask: true,
                    contentClass: 'flex-grow-1',
                },
                emits: {
                    onCreate: (commodityId) => {
                        const newStack = {
                            id: 'new_stack',
                            name: 'New Stack',
                            commodity: commodityId,
                            storage: this.storage,
                        }
                        this.newStack = new Stack().fromJSON(newStack);
                        this.update(newStack.id);
                        this.formStore.setNewStack(newStack);
                    }
                },
            });
        }
    }
}

</script>