import { default as axios } from './http';


class TarpRepairsAPI {

    getTarpRepairs = async () => {
      // Fetch tarps from API
      console.log('Fetching tarping repairs from API...')
      const url = '/api/v1/gcm/tarp-repairs/';
      const response = await axios.get(url)
      return response
    }

    createTarpRepairs = async (data) => {
      // Create tarping works in API
      const url = '/api/v1/gcm/tarp-repairs/';
      const options = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          }
      }
      const response = await axios.post(url, data, options)
      return response
    }

}

export default TarpRepairsAPI;