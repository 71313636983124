import { useSitesStore } from '@/stores/Sites';

class StockpilingRequirements {

  id = null;
  site = null;
  usable_tarp_widths = [];
  tarp_join_types = []
  straight = null;
  gte_20m_tarp_count = null;
  single_tarp_count = null;
  end_tarp_count = null;
  end_straight_tarp_count = null;
  notes = null;

  constructor() {
    this.sitesStore = useSitesStore();
  }

  // Adapters

  toJSON() {
    return {
      id: this.id,
      site: this.site?.id,
      usable_tarp_widths: this.usable_tarp_widths,
      tarp_join_types: this.tarp_join_types,
      straight: this.straight,
      gte_20m_tarp_count: this.gte_20m_tarp_count,
      single_tarp_count: this.single_tarp_count,
      end_tarp_count: this.end_tarp_count,
      end_straight_tarp_count: this.end_straight_tarp_count,
      notes: this.notes,
    }
  }

  fromJSON(data) {
    this.id = data.id;
    if (data.site) this.site = this.sitesStore.getSiteById(data.site);
    this.usable_tarp_widths = data.usable_tarp_widths;
    this.tarp_join_types = data.tarp_join_types;
    this.straight = data.straight;
    this.gte_20m_tarp_count = data.gte_20m_tarp_count;
    this.single_tarp_count = data.single_tarp_count;
    this.end_tarp_count = data.end_tarp_count;
    this.end_straight_tarp_count = data.end_straight_tarp_count;
    this.notes = data.notes;

    return this
  }

}

export default StockpilingRequirements;