import { defineStore } from 'pinia';
import { getOrCreateDB, getOrCreateItem, updateStoreLastUpdated, getItems, clearStore, addItems } from './idb.js';

import BaseTarpsAPI from '@/stores/api/BaseTarps.js';
import BaseTarp from '@/stores/objects/BaseTarp';


const filterBaseTarps = (state, search, from=null, limit=50, qs=null) => {
  let filteredBaseTarps;
  const baseTarpsQS = qs || state.base_tarps;
  if (search) {
    const searchRegex = new RegExp(search, 'i');
    filteredBaseTarps = baseTarpsQS.filter(baseTarp => {
      return baseTarp.serial_no.match(searchRegex);
    });
  } else {
    filteredBaseTarps = baseTarpsQS;
  }

  let fromIndex = 0;
  if (from) {
    fromIndex = filteredBaseTarps.findIndex(baseTarp => baseTarp.id === from);
    if (fromIndex === -1) fromIndex = 0;
  }
  
  let moreResults = false;
  if (filteredBaseTarps.length > fromIndex + limit) {
    moreResults = true;
  }

  const results = filteredBaseTarps.slice(fromIndex, fromIndex + limit);

  console.log(`Returning ${results.length} base tarps from ${fromIndex} to ${fromIndex + limit}`);

  return [[...results.map(baseTarp => new BaseTarp().fromJSON(baseTarp))], moreResults];

}


export const useBaseTarpsStore = defineStore('baseTarps', {
  state: () => ({ 
    base_tarps: [],
    lastUpdated: null,
  }),

  actions: {

    async loadBaseTarpsFromAPI() {
      // Fetch tarps from API and update indexedDB
      const api = new BaseTarpsAPI();

      console.log('Fetching base tarps from API...')

      try {
        const response = await api.getBaseTarps();
        const data = response.data;
        this.base_tarps = data;
        this.updateBaseTarpsDB(data);
        return response
      } catch (error) {
        throw error
      }
    },

    async loadBaseTarpsFromDB() {
      console.log('Loading base tarps from indexedDB...')
      const baseTarps = await getItems('baseTarps');
      console.log(`${baseTarps.length} base tarps loaded from indexedDB`);
      this.base_tarps = baseTarps;

      const baseTarpsMeta = await getOrCreateItem('meta', 'baseTarps');
      const lastUpdated = baseTarpsMeta.lastUpdated;
      (lastUpdated) ? this.lastUpdated = lastUpdated : this.lastUpdated = null;
    },

    async loadTarps() {
      await this.loadBaseTarps(true);
    },
    async loadBaseTarps(awaitAPI) {
      // Load tarps from cache and then update from API in background
      await this.loadBaseTarpsFromDB();
      if (navigator.onLine) {
        const response = this.loadBaseTarpsFromAPI();
        if (awaitAPI) await response;
      }
    },

    async updateBaseTarpsDB(data) {
      clearStore('baseTarps');
      await addItems('baseTarps', data);

      const now = Date.now();
      this.lastUpdated = now;
      updateStoreLastUpdated('baseTarps', now);
    },

    async addBaseTarps(baseTarps) {
      await addItems('baseTarps', baseTarps);
      this.loadBaseTarpsFromDB();
    }
    
  },

  getters: {

    getBaseTarps(state) {
      const sortedBaseTarps = state.base_tarps.sort((a, b) => {
        return b.serial_no - a.serial_no;
      });
      return sortedBaseTarps.map(baseTarp => new BaseTarp().fromJSON(baseTarp));
    },

    getBaseTarpIndex: (state) => (id) => {
      return state.base_tarps.findIndex(baseTarp => baseTarp.id === id);
    },

    filterTarps(state) {
      return (search, from=null, limit=50) => {
        return filterBaseTarps(state, search, from, limit);
      }
    },

    filterBaseTarps(state) {
      return (search, from=null, limit=50, qs=null) => {
        return filterBaseTarps(state, search, from, limit, qs);
      }
    },

    getBaseTarpById: (state) => (id) => {
      const data = state.base_tarps.find(bt => bt.id === id);
      if (data) return new BaseTarp().fromJSON(data);
      else      return null;
    },

    getBaseTarpBySerialNo: (state) => (serialNo) => {
      const data = state.base_tarps.find(bt => bt.serial_no === serialNo);
      if (data) return new BaseTarp().fromJSON(data);
      else      return null;
    },

  }
})