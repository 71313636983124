import { defineStore } from 'pinia';
import { getOrCreateItem, getItems, updateStoreLastUpdated, clearStore, addItems, saveItem } from './idb.js';

import SiteWorks from './objects/SiteWorks.js';
import SiteWorksAPI from './api/SiteWorks.js';


export const useSiteWorksStore = defineStore('siteWorks', {
  state: () => ({ 
    siteWorks: [],
    lastUpdated: null,
  }),

  actions: {
    async loadSiteWorksFromAPI() {
      // Fetch site works from API and update indexedDB
      const api = new SiteWorksAPI();

      try {
        const response = await api.getSiteWorks();
        const data = response.data;
        this.siteWorks = data;
        this.updateSiteWorksDB(data);
        return response
      } catch (error) {
        console.error(error);
      }
    },

    async loadSiteWorksFromDB() {
      console.log('Loading site works from indexedDB...')
      const siteWorks = await getItems('siteWorks');
      this.siteWorks = siteWorks;

      const siteWorksMeta = await getOrCreateItem('meta', 'siteWorks');
      const lastUpdated = siteWorksMeta.lastUpdated;
      (lastUpdated) ? this.lastUpdated = lastUpdated : this.lastUpdated = null;
    },

    async loadSiteWorks(awaitAPI) {
      // Load site works from cache and then update from API in background
      await this.loadSiteWorksFromDB();

      if (navigator.onLine) {
        const response = this.loadSiteWorksFromAPI();
        if (awaitAPI) await response;
      }
    },

    async updateSiteWorksDB(data) {
      // Clears and repopulates tarp works in indexedDB
      clearStore('siteWorks');

      // Add data
      addItems('siteWorks', data);

      // Update last updated timestamp
      const now = Date.now();
      this.lastUpdated = now;
      updateStoreLastUpdated('siteWorks', now);
    },

    async addSiteWorks(siteWorks) {
      saveItem('siteWorks', siteWorks.id, siteWorks);
      this.siteWorks = [...this.siteWorks, siteWorks];
    }

  },

  getters: {

    getSiteWorks(state) {
      // Return all tarp works, ordered by completion time (newest first)
      const sortedSiteWorks = state.siteWorks.sort((a, b) => {
        return new Date(b.completion_time) - new Date(a.completion_time);
      });
      return sortedSiteWorks.map(siteWorks => new SiteWorks().fromJSON(siteWorks));
    },
  },

})