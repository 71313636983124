<template>
  <div class="field p-fluid">
    <label for="completion_time">Condition</label>
    <Dropdown 
      v-model="tarp_repair.condition" 
      :options="conditions" 
      optionLabel="label"
      optionValue="value"
      placeholder="Select condition..." 
      class="w-full md:w-14rem"
      :disabled="hasDefaultCondition"
    />
  </div>
</template>

<script>
import { useRecordTarpRepairsStore } from '@/stores/RecordTarpRepairs';
import { TARP_CONDITIONS } from '@/stores/enums/GCM';

import Dropdown from 'primevue/dropdown';

export default {
  name: "CompletionTime",
  components: {
    Dropdown,
  },
  data() { return {
    conditions: TARP_CONDITIONS,
  }},
  created() {
    this.store = useRecordTarpRepairsStore();

    this.store.$subscribe((mutation, state) => {
      const tarpRepair = this.store.getTarpRepair;
      if (tarpRepair.repair_type?.default_tarp_condition) {
        this.tarp_repair.condition = tarpRepair.repair_type.default_tarp_condition;
      }
    });
  },
  computed: {
    tarp_repair() {
      return this.store.tarp_repair;
    },
    hasDefaultCondition() {
      const tarpRepair = this.store.getTarpRepair
      return Boolean(tarpRepair.repair_type?.default_tarp_condition);
    }
  }
}

</script>