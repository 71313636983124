function validateRequiredFields (fields, data) {
  /* 
    This funcions is used to validate if the fields of a form are not null or empty (in case of Array).
    It receives an array of fields and an object with the data to validate.
    It returns true if all fields are not null or empty, otherwise it returns false.
    
    Example:
    const fields = [
      'field_1',
      'field_2',
      'field_3|field_4', // This means that at least one of the fields field_3 or field_4 must not be null or empty
    ];
    const data = {
      field_1: 'value',
      field_2: 'value',
      field_3: 'value',
      field_4: '',
    };
  */

  return fields.every(field => {
    if ( field.includes('|') ) {
      let fields = field.split('|');
      return fields.some(f => {
        const val = data[f];
        if (val === null || val === undefined || val === '' || (Array.isArray(val) && val.length === 0)) {
          return false;
        }
        return true;
      });
    } else {
      const val = data[field];
      if (val === null || val === undefined || val === '' || (Array.isArray(val) && val.length === 0)) {
        return false;
      }
      return true;
    }
  });
}

export { validateRequiredFields };
