<template>
    <div class="table-container flex-grow-1 pb-0 overflow-x-hidden">
        <DataTable
            v-if="tableReady"
            :value="OBHMWorks"
            class="p-datatable-sm obhm-works-table"
            scrollable 
            scrollHeight="flex"
            v-model:filters="filters"
            filterDisplay="menu"
            paginator 
            :rows="50"
            columnResizeMode="fit"
            showGridlines
            paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            tableStyle="min-width: 700px;"
            @row-click="openOBHMWorksModal">

            <template #header>
                <div class="flex flex-wrap align-items-center justify-content-between gap-2 p-1">
                    <div>
                      <p class="title"><span class="text-xl text-900 font-bold">OBHM Works</span></p>
                      <p class="subtitle"><span><small>Last Updated: {{ OBHMWorksLastUpdated }}</small></span></p>
                    </div>
                    
                    <Button rounded raised severity="secondary" :loading="loading" @click="sync">
                        <template #icon>
                            <span class="material-symbols-outlined">download</span>
                        </template>
                    </Button>
                </div>
            </template>
            <Column field="activity_type" header="Activity" frozen style="width: 200px" filterField="activity_type" :showFilterMatchModes="false">
                <template #filter="{ filterModel }">
                    <div style="max-height: 200px; overflow-y: scroll;">
                        <div v-for="activity in activityOptions" :key="activity.value" class="flex align-items-center" style="margin-top: 10px">
                            <Checkbox v-model="filterModel.value" :inputId="activity.value" name="activity_type" :value="activity.value" style="margin-right: 5px;" />
                            <label :for="activity.value">{{ activity.display }}</label>
                        </div>
                    </div>
                </template>
                <template #body="slotProps">
                    {{ slotProps.data.displayName() }}
                </template>
            </Column>
            <Column field="completion_time" header="Completion Time" :showFilterMatchModes="false">
                <template #filter="{ filterModel }">
                    <div>
                        <Calendar v-model="filterModel.value" inline />
                    </div>
                </template>
                <template #body="slotProps">
                    {{ slotProps.data.completionTimeDisplay() }}
                </template>
            </Column>
            <Column field="supervisor" header="Supervisor" filterField="supervisor" :showFilterMatchModes="false">
                <template #filter="{ filterModel }">
                    <div v-for="supervisor in supervisorOptions" :key="supervisor.value" class="flex align-items-center" style="margin-top: 10px">
                        <Checkbox v-model="filterModel.value" :inputId="supervisor.value" name="activity" :value="supervisor.value" style="margin-right: 5px;" />
                        <label :for="supervisor.value">{{ supervisor.display }}</label>
                    </div>
                </template>
                <template #body="slotProps">
                    {{ slotProps.data.supervisor.displayName() }}
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Calendar from 'primevue/calendar';
import Checkbox from 'primevue/checkbox';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import MultiSelect from 'primevue/multiselect';

import { useSiteWorksStore } from '@/stores/SiteWorks';
import { useOBHWorksStore } from '@/stores/OBHWorks';

import OBHMWorksDetails from './OBHMWorksDetails.vue';

import { FilterMatchMode, FilterService } from 'primevue/api';


FilterService.register('filterByActivityType', (activity_type, filterArray) => {
  return filterArray.includes(activity_type);
});

FilterService.register('filterByCompletionTime', (completionTime, date) => {
    // Return true if any of the OBHM work activities are in the filter array
    if (!date) return true;
    // Check if dates match (compare date only, not time)
    const date1 = new Date(completionTime).toDateString();
    const date2 = new Date(date).toDateString();
    return date1 === date2;
});

FilterService.register('filterBySupervisor', (supervisor, filterArray) => {
    // Return true if any of the OBHM work activities are in the filter array
    if (!filterArray || filterArray.length === 0) return true;
    return filterArray.includes(supervisor.email);
});


export default {
    name: "OBHMWorksTable",
    components: {
        Button,
        DataTable,
        Calendar,
        Checkbox,
        Column,
        InputText,
        MultiSelect,
        OBHMWorksDetails,
    },
    data() { return {
        tableReady: false,
        loading: false,
        filters: {
          completion_time: { value: null, matchMode: 'filterByCompletionTime' },
          supervisor: { value: null, matchMode: 'filterBySupervisor' },
          activity_type: { value: null, matchMode: 'filterByActivityType' },
        },
    }},
    computed: {
        OBHMWorks() {
            const siteWorks = this.siteWorksStore.getSiteWorks;
            const OBHWorks = this.OBHWorksStore.getOBHWorks;

            const OBHMWorks = [
                ...siteWorks,
                ...OBHWorks,
            ].sort((a, b) => {
                return b.completion_time - a.completion_time;
            })
            return OBHMWorks
        },
        OBHMWorksLastUpdated() {
            // Format store last updated date for display
            const date = new Date(this.siteWorksStore.lastUpdated);
            return date.toLocaleString();
        },
        supervisorOptions() {
            // Get unique supervisors from OBHM works
            const supervisors = this.OBHMWorks.map(obhm => obhm.supervisor);
            const supervisorEmails = [...new Set(this.OBHMWorks.map(obhm => obhm.supervisor.email))];
            const supervisorOptions = supervisorEmails.map(email => {
                const supervisor = supervisors.find(supervisor => supervisor.email === email);
                return {
                    display: `${supervisor.displayName()}`,
                    value: `${email}`,
                }
            });
            // Sort supervisor options by display name
            supervisorOptions.sort((a, b) => {
                if (a.display < b.display) return -1;
                if (a.display > b.display) return 1;
                return 0;
            });
            return supervisorOptions;
        },
        activityOptions() {
          return [
            {
              value: 'site_works',
              display: 'Site Works',
            },
            {
              value: 'obh_works',
              display: 'OBH Works',
            },
          ]
        }
    },
    async created() {
        this.siteWorksStore = useSiteWorksStore();
        this.OBHWorksStore = useOBHWorksStore();
        this.tableReady = true;
    },
    methods: {
        async sync() {
            this.loading = true;
            try {
                const promises = [
                    this.siteWorksStore.loadSiteWorks(true),
                ];

                await Promise.all(promises);

                this.$root.showNotification(
                    'success',
                    'OBHM works synced successfully.',
                );
            } catch (error) {
                console.error(error);
                this.$root.showNotification(
                    'error',
                    'Failed to sync OBHM works.',
                );
            } finally {
                this.loading = false;
            }
        },
        openOBHMWorksModal(event) {
            const obhmWorks = event.data;
            const dialogRef = this.$dialog.open(OBHMWorksDetails, {
                props: {
                    header: obhmWorks.displayName(),
                    style: {
                        width: '100vw',
                        height: '100vh',
                        maxHeight: '100vh',
                        borderRadius: '0',
                    },
                    modal: true,
                    dismissableMask: true,
                    contentClass: 'flex-grow-1',
                },
                data: {
                    obhmWorks: obhmWorks,
                },
            });
        }
    }
}

</script>

<style>
.table-container {
    overflow: scroll;
}
.obhm-works-table table {
    table-layout: fixed;
}
.obhm-works-table .p-datatable-table .p-datatable-thead > tr > th {
    background: #f1f3f4;
}
.obhm-works-table .p-datatable-tbody {
    font-size: 14px;
}
</style>