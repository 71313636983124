<template>
  <div class="field p-fluid">
    <label for="tj-join-type">Join Type</label>
    <Dropdown 
      inputId="tj-join-type" 
      ref="tj-join-type" 
      v-model="form.join_type" 
      :options="joinTypes" 
      optionLabel="label"
      optionValue="value"
      placeholder="Select join type..." 
      class="w-full md:w-14rem"
    />
  </div>
</template>

<script>
import { useRecordTarpJoinsStore } from '@/stores/RecordTarpJoins';
import { TARP_JOIN_TYPES } from '@/stores/enums/GCM';

import Dropdown from 'primevue/dropdown';

export default {
  name: "TarpJoin",
  props: ['index'],
  components: {
    Dropdown,
  },
  data() { return {
    joinTypes: TARP_JOIN_TYPES,
  }},
  created() {
    this.store = useRecordTarpJoinsStore();
  },
  computed: {
    form() {
      return this.store.tarp_join;
    },
  }

}

</script>