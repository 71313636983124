<template>
  <div class="field p-fluid">
    <label for="tarps">Tarp Serial Number</label>

    <div class="flex flex-col flex-wrap gap-2">
      <SelectBaseTarpInput v-model="tarp" :disabled="isEdit" />
    </div>

    <Message
      v-if="showNewLengthWarning"
      severity="warn"
      :closable="false">
      {{ newLengthWarningMsg }}
    </Message>

    <Message
      v-if="showNewWidthWarning"
      severity="warn"
      :closable="false">
      {{ newWidthWarningMsg }}
    </Message>

  </div>
</template>

<script>
import { useRecordTarpRepairsStore } from '@/stores/RecordTarpRepairs';

import SelectBaseTarpInput from '../../../components/SelectBaseTarpInput.vue';

import Message from 'primevue/message';

export default {
  name: "BaseTarp",
  components: {
    SelectBaseTarpInput,
    Message,
  },
  data() { return {
    store: null,
  }},
  computed: {
    form() {
      return this.store.tarp_repair;
    },
    tarpRepair() {
        return this.store.getTarpRepair;
    },

    tarp: {
      get() {
        return this.form.base_tarp || this.form.new_base_tarp || null;
      },
      set(baseTarp) {
        if (baseTarp) {
          if (baseTarp.id) {
            this.form.base_tarp = baseTarp.id;
            this.form.new_base_tarp = null;
          } else {
            this.form.new_base_tarp = baseTarp;
            this.form.base_tarp = null;
          }
        } else {
          this.form.base_tarp = null;
          this.form.new_base_tarp = null;
        }
      }
    },

    currentLength() {
        return this.tarpRepair.base_tarp?.length;
    },
    showNewLengthWarning() {
        return this.tarpRepair.new_length > this.currentLength;
    },
    newLengthWarningMsg() {
        if (this.tarpRepair.new_length > this.currentLength) {
            return `New length is greater than the current length (${this.currentLength}m).`;
        }
        return null;
    },

    currentWidth() {
        return this.tarpRepair.base_tarp?.width;
    },
    showNewWidthWarning() {
        return this.tarpRepair.new_width > this.currentWidth;
    },
    newWidthWarningMsg() {
        if (this.tarpRepair.new_width > this.currentWidth) {
            return `New width is greater than the current width (${this.currentWidth}m).`;
        }
        return null;
    },

    isEdit() {
      return this.tarpRepair.id;
    },
  },
  async created() {
    this.store = useRecordTarpRepairsStore();
  },
}

</script>