<template>
  <div class="quick-links">
    <div class="grid">
      <div v-for="link in links" :key="link.name" class="col-6">
        <RouterLink :to="link.to">
          <Card :pt="{ body: { class: 'py-0' } }">
            <template #content>
              <p>
                <span class="icon material-symbols-outlined">{{ link.icon }}</span>
              </p>
              <p class="font-semibold">
                {{ link.label }}
              </p>
            </template>
          </Card>
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script>
import Card from 'primevue/card';

export default {
  name: "QuickLinks",
  props: ['links'],
  components: {
      Card,
  },
}

</script>