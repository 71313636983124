<template>
  <div v-if="viewReady" class="view flex flex-column">
    <div class="bg-white p-3 pb-5 flex-grow-1">
      <MaintenanceTypeSelect v-if="activeMaintenanceType === null" />
      <TarpRepairsForm v-else-if="activeMaintenanceType === 'tarp_repair'" />
      <TarpJoinsForm v-else-if="activeMaintenanceType === 'tarp_join'" />
      <StockpilingForm v-else-if="activeMaintenanceType === 'stockpiling'" />
      <NewTarpsInputForm v-else-if="activeMaintenanceType === 'new_tarps_input'" />
      <TarpPoleInfo v-else-if="activeMaintenanceType === 'tarp_pole_info'" />
      <SwingSummary v-else-if="activeMaintenanceType === 'swing_summary'" />
    </div>
  </div>
</template>

<script>
import { useRecordGCMStore } from '@/stores/RecordGCM';

import Button from 'primevue/button';
import Divider from 'primevue/divider';

import MaintenanceTypeSelect from './components/MaintenanceTypeSelect.vue';
import StockpilingForm from './forms/Stockpiling/index.vue';
import TarpJoinsForm from './forms/TarpJoins/index.vue';
import TarpRepairsForm from './forms/TarpRepairs/index.vue';
import NewTarpsInputForm from './forms/NewTarpsInput/index.vue';
import TarpPoleInfo from './forms/TarpPoleInfo/index.vue';
import SwingSummary from './forms/SwingSummary/index.vue';

export default {
  name: "GCMRecordView",
  components: {
    Button,
    Divider,
    MaintenanceTypeSelect,
    StockpilingForm,
    TarpJoinsForm,
    TarpRepairsForm,
    NewTarpsInputForm,
    TarpPoleInfo,
    SwingSummary,
  },
  data() { return {
    viewReady: true,
    loading: false,
    store: null,
    recordGCMStore: null,
  }},
  computed: {
    activeMaintenanceType() {
      return this.recordGCMStore.activeMaintenanceType;
    },
  },
  async created() {
    this.recordGCMStore = useRecordGCMStore();
    this.recordGCMStore.init();
  },
}

</script>