<script setup>
import { RouterLink, RouterView } from 'vue-router'
</script>

<template>
    <nav v-show="currentRoute.meta.displayBottomNav !== false" class="bottom-nav-bar fixed bottom-0 left-0 right-0 w-full bg-primary text-white">
        <div class="nav-items flex align-items-center">
            <div v-for="item in activeMenuItems" :key="item.label" class="nav-item flex-grow-1 p-1 mt-1" :class="{'p-disabled': item.disabled}">
                <RouterLink :to="item.to">
                    <div>
                        <span class="icon material-symbols-outlined">{{ item.icon }}</span>
                        <span>{{ item.label }}</span>
                    </div>
                </RouterLink>
            </div>
        </div>
    </nav>
</template>

<script>

export default {
    name: "BottomNavigationBar",
    computed: {
        currentRoute() {
            return this.$route
        },
        menus() {
            const routes = this.$router.options.routes.filter(route => route.meta && route.meta.isParentApp && route.children);
            let menu = {};
            routes.forEach(route => {
                let items = route.children.map(item => {
                    return {
                        name: item.name,
                        meta: item.meta,
                        to: item.path,
                        label: item.meta.title,
                        icon: item.meta.icon,
                        disabled: item.meta.disabled
                    }
                })
                menu[route.name] = {
                    items: items
                };
                    
            })
            return menu
        },
        menuItemsFlat() {
            // Return menus as a flat array of menu items
            let menuItems = [];
            for (let menu in this.menus) {
                let items = this.menus[menu].items;
                items = items.map(item => Object.assign(item, { menu: menu }));
                menuItems = menuItems.concat(this.menus[menu].items);
            }
            return menuItems;
        },
        activeMenuItems() {
            const routeName = this.$route.name;

            if (!routeName) return [];

            const items = this.menuItemsFlat;
            const item = items.find(item => item.name === routeName);
            return (item) ? this.menus[item.menu].items : []
        },
        activeMenuItem() {
            const routeName = this.$route.name;
            return this.activeMenuItems.find(item => item.name === routeName);
        },
        activeMenuItemTitle() {
            return (this.activeMenuItem) ? this.activeMenuItem.meta.title : '';
        }
    },
}

</script>

<style scoped>
.bottom-nav-bar {
    height: var(--bottom-nav-bar-height);
    border-top: 1px solid grey;
}
.nav-item.disabled {
    opacity: 0.4;
}
.nav-item a {
    color: whitesmoke;
    font-size: 15px;
}
.router-link-active span {
    color: white;
}
.icon {
    display: block;
}
</style>
