<template>
  <Field v-slot="slotProps">
    <Textarea
      :inputId="slotProps.inputId"
      :modelValue="modelValue" 
      :rows="rows || 5"
      :disabled="disabled || false"
      @update:modelValue="update"
    />
  </Field>
</template>

<script>
import Textarea from 'primevue/textarea';
import Field from './Field.vue';

export default {
  name: "IntegerField",
  props: [
    'modelValue',
    'rows',
    'disabled',
  ],
  components: {
    Field,
    Textarea,
  },
  methods: {
    update(value) {
      this.$emit('update:modelValue', value);
    }
  }
}

</script>