import 'primevue/resources/themes/md-light-indigo/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css'

import './assets/main.css'

import * as Sentry from "@sentry/vue";

import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import DialogService from 'primevue/dialogservice';
import ToastService from 'primevue/toastservice';

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import App from './App.vue'
import router from './router'

const app = createApp(App)

const ENVIRONMENT = import.meta.env.MODE;

// Sentry.init({
//   app,
//   dsn: "https://c75b116a67c0ba38b97759dd4246b31d@o123255.ingest.sentry.io/4506255930556416",
//   environment: ENVIRONMENT,
//   integrations: [
//     new Sentry.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//     }),
//     new Sentry.Replay(),
//   ],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,

//   // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: [
//     "localhost", 
//     /^https:\/\/ias\.app\.gghs\.com\.au/, 
//     /^https:\/\/elpha\.app\.gghs\.com\.au/
//   ],

//   // Capture Replay for 10% of all sessions,
//   // plus for 100% of sessions with an error
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
app.use(pinia)

app.use(router)
app.use(PrimeVue)
app.use(ToastService)
app.use(DialogService)
app.use(ConfirmationService)

app.mount('#app')
