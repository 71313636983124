<template>
  <div>
    <form v-show="!store.submitted" @submit.prevent="submit">
      <FormHeader />
      <Divider />
      <div v-for="field in fields" :key="field.name" class="field p-fluid">
        <div v-show="!field.isHidden">
          <component
            v-if="!field.isHidden"
            :is="field.component" 
            :modelValue="field.value || form[field.name]"
            @update:modelValue="updateField(field, $event)"
            :label="field.label" 
            :subtitle="field.subtitle"
            v-bind="field.props" />
          <Divider />
        </div>
      </div>
      <div class="field p-fluid">
        <Button label="Submit" severity="secondary" size="large" :disabled="!store.inputsValid" type="submit" raised />
      </div>
    </form>

    <FormSubmitLoader 
      v-if="store.submitted"
      :loading="store.loading"
      :submitComplete="store.submitComplete"
      :status="responseStatus"
      :errors="responseErrors"
      @recordAnother="recordAnother"
      @returnToForm="returnToForm"
      @reSubmitForm="reSubmit"
    />
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue';

import { useRecordGCMStore } from '@/stores/RecordGCM';
import { useRecordNewTarpsInputsStore } from '@/stores/RecordNewTarpsInputs';

import FormSubmitLoader from '@/site_components/FormSubmitLoader/index.vue';
import NewTarpForm from '@/site_components/NewTarpForm/index.vue';
import SiteSelect from '@/site_components/SiteSelect/index.vue';
import NewTarps from './components/NewTarps.vue';
import FormHeader from '../../components/FormHeader.vue';

import Button from 'primevue/button';
import Card from 'primevue/card';
import Divider from 'primevue/divider';

export default {
  name: "NewTarpsInput",
  components: {
    FormHeader,
    NewTarpForm,
    FormSubmitLoader,
    SiteSelect,
    NewTarps,
    Button,
    Card,
    Divider,
  },
  created() {
    this.gcmStore = useRecordGCMStore();
    this.store = useRecordNewTarpsInputsStore();
    this.store.init();
  },
  data() { return {
    formOptions: {
      serialNumberType: 'multiple',
      generateSerialNumber: false,
      setDefaults: true,
    },
    localData: null,
  }},
  computed: {
    tarp() {
      return this.store?.tarp;
    },
    form() {
      return this.store.new_tarps_input;
    },
    fields() {
      return [
        {
          name: 'site',
          component: 'SiteSelect',
          label: 'Site',
        },
        {
          name: 'tarps',
          component: 'NewTarps',
          label: 'Tarps',
        },
      ]
    },

    responseStatus() {
      return this.store.saveResponse?.status;
    },
    responseErrors() {
      return this.store.getErrors;
    },
  },
  methods: {
    updateField(field, value) {
      if (field.update) {
        field.update(value);
      } else {
        this.form[field.name] = value;
      }
    },

    async submit() {

      this.store.loading = true;
      this.store.submitComplete = false;
      this.store.submitted = true;

      try {
        await this.store.saveNewTarpsInput();
      } catch (e) {
        Sentry.captureException(e);
        console.error(e);
      } finally {
        // Store a local copy of the form that we can use to restore the form
        // if the user wants to modify the data. Otherwise, we need to reset the
        // store to clear the form.
        this.localData = { ...this.store.new_tarps_input };
        // Reset the store
        this.store.reset(false);
        this.store.loading = false;
        this.store.submitComplete = true;
      }
    },

    recordAnother() {
      this.store.reset(true);
    },
    reSubmit() {
      this.store.new_tarps_input = this.localData;
      this.submit();
    },
    returnToForm() {
      this.store.new_tarps_input = this.localData;
      this.store.loading = false;
      this.store.submitted = false;
    },
    
  }
}

</script>