<template>
  <div class="view no-bottom-nav no-top-nav">
    <div class="p-3 pt-5">
      <p class="text-2xl font-semibold">Are you sure you want to logout?</p>
      <Divider />
      <div class="p-fluid">
        <Button 
          class="btn mb-3" 
          severity="secondary" 
          size="large"
          label="Logout"
          @click="logout" />
      </div>
      <div class="p-fluid">
        <Button 
          class="btn" 
          severity="info" 
          size="large"
          label="Cancel"
          outlined 
          @click="cancelLogout" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from 'primevue/button';
import Divider from 'primevue/divider';

import { useAuthStore } from '@/stores/Auth';

export default {
  name: "LogoutView",
  components: {
    Button,
    Divider,
  },
  methods: {
    logout() {
      const authStore = useAuthStore();
      authStore.logout();
      this.$root.successNotification('You have been logged out.');
      this.$router.push({ name: 'login' });
    },
    cancelLogout() {
      // Return to previous router view
      this.$router.go(-1);
    }
  }

}
</script>

<style scoped>
.btn {
  display: block;
}
</style>