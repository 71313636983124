<template>
    <div class="container">
        <ProgressSpinner :style="`width: ${width}; height: ${height}`" />
    </div>
</template>

<script>
import ProgressSpinner from 'primevue/progressspinner';

export default {
    name: "ViewLoadingIndicator",
    props: {
        width: {
            type: String,
            default: '50px'
        },
        height: {
            type: String,
            default: '50px',
        },
    },
    components: {
        ProgressSpinner,
    },
}

</script>

<style scoped>
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}
</style>