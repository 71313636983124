<template>
    <div class="table-container flex-grow-1 pb-0 overflow-x-hidden">
        <DataTable
            v-if="tableReady"
            :value="tarps"
            v-model:filters="filters"
            filterDisplay="menu"
            class="p-datatable-sm tarps-table"
            scrollable 
            scrollHeight="flex"
            paginator 
            :rows="25"
            paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            @row-click="openTarpModal">

            <template #header>
                <div class="flex flex-wrap align-items-center justify-content-between gap-2 p-1">
                    <div>
                        <p class="title"><span class="text-xl text-900 font-bold">Tarps</span></p>
                        <p class="subtitle"><span><small>Last Updated: {{ tarpsLastUpdated }}</small></span></p>
                    </div>
                    
                    <Button severity="secondary" rounded raised :loading="loading" @click="sync">
                        <template #icon>
                            <span class="material-symbols-outlined">download</span>
                        </template>
                    </Button>
                </div>
            </template>
            <Column field="serial_no" filterField="serial_no" header="Serial No." frozen style="width: 200px">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="Search by serial no." />
                </template>
            </Column>
            <Column field="site" filterField="site" header="Site" style="width: 100px" :showFilterMatchModes="false">
                <template #filter="{ filterModel, filterCallback }">
                    <MultiSelect v-model="filterModel.value" @change="filterCallback()" :options="sites" optionLabel="display_name" placeholder="Any" class="p-column-filter" style="min-width: 14rem" :maxSelectedLabels="2">
                        <template #option="slotProps">
                            <div class="flex align-items-center gap-2">
                                <span>{{ slotProps.option.display_name }}</span>
                            </div>
                        </template>
                    </MultiSelect>
                </template>
                <template #body="slotProps">
                    {{ siteDisplayName(slotProps.data.site) }}
                </template>
            </Column>
            <Column field="stack" header="Stack" filterField="stack" :showFilterMatchModes="false" style="width: 100px">
                <template #filter="{ filterModel, filterCallback }">
                    <MultiSelect v-model="filterModel.value" @change="filterCallback()" :options="stacks" :maxSelectedLabels="0" placeholder="Any" class="p-column-filter" style="min-width: 14rem">
                        <template #option="slotProps">
                            <div class="flex align-items-center gap-2">
                                <span>{{ stackFilterOptionDisplayName(slotProps.option) }}</span>
                            </div>
                        </template>
                    </MultiSelect>
                </template>
                <template #body="slotProps">
                    {{ stackDisplayName(slotProps.data.stack) }}
                </template>
            </Column>
            <Column field="length" header="Length (m)" style="width: 100px"></Column>
            <Column field="width" header="Width (m)" style="width: 100px"></Column>
        </DataTable>
    </div>
</template>

<script>
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { FilterMatchMode } from 'primevue/api';
import InputText from 'primevue/inputtext';
import MultiSelect from 'primevue/multiselect';
import TarpDetails from './TarpsDetails.vue';

import { useSitesStore } from '@/stores/Sites';
import { useStacksStore } from '@/stores/Stacks';
import { useTarpInventoryStore } from '@/stores/TarpInventory';


export default {
    name: "TarpsTable",
    components: {
      Button,
      DataTable,
      Column,
      InputText,
      MultiSelect,
      TarpDetails,
    },
    data() { return {
        tableReady: false,
        loading: false,
        filters: {
          serial_no: { value: null, matchMode: FilterMatchMode.CONTAINS },
          site: { value: null, matchMode: FilterMatchMode.IN },
          stack: { value: null, matchMode: FilterMatchMode.IN },
        },
    }},
    computed: {
      tarps() {
        return this.tarpsStore.getTarps;
      },
      tarpsLastUpdated() {
        // Format store last updated date for display
        const date = new Date(this.tarpsStore.lastUpdated);
        return date.toLocaleString();
      },
      sites() {
        const sitesIds = [...new Set(this.tarps.map(tarp => {
          if (tarp.site) return tarp.site.id
          else           return null
        }))];
        const sites = this.sitesStore.getSites.filter(site => sitesIds.includes(site.id));
        return sites
      },
      stacks() {
        let stacks;
        const filteredSites = this.filters.site.value;
        if (filteredSites && filteredSites.length > 0) {
          console.log(filteredSites);
          const siteIds = filteredSites.map(site => site.id);
          stacks = this.stacksStore.getStacks.filter(stack => siteIds.includes(stack.storage.site.id));
        } else {
          stacks = this.stacksStore.getStacks;
        }

        return stacks
      },
    },
    async created() {
      this.tarpsStore = useTarpInventoryStore();
      this.sitesStore = useSitesStore();
      this.stacksStore = useStacksStore();
      this.tableReady = true;
    },
    methods: {
      async sync() {
        this.loading = true;
        try {
          await this.tarpsStore.loadTarpsFromAPI();
          this.$root.showNotification(
            'success',
            'Tarps synced successfully.',
          );
        } catch {
          this.$root.showNotification(
            'error',
            'Failed to sync tarps.',
          );
        } finally {
          this.loading = false;
        }
      },
      siteDisplayName(site) {
        return (site) ? site.display_name : '---';
      },
      stackDisplayName(stack) {
        if (stack) {
          return `${stack.storage.display_name}/${stack.name.toUpperCase()}`;
        } else {
          return '---';
        }
      },
      stackFilterOptionDisplayName(stack) {
        const siteName = stack.storage.site.display_name;
        const storageName = stack.storage.display_name;
        const stackName = stack.name.toUpperCase();
        return `${siteName}/${storageName}/${stackName}`;
      },
      openTarpModal(event) {
        const tarp = event.data;
        this.$dialog.open(TarpDetails, {
          props: {
            header: tarp.serial_no,
            style: {
              width: '100vw',
              height: '100vh',
              maxHeight: '100vh',
              borderRadius: '0',
            },
            modal: true,
            dismissableMask: true,
            contentClass: 'flex-grow-1',
          },
          data: {
            tarp: tarp,
          },
        });
      }
    }
}

</script>

<style>
.table-container {
    overflow: scroll;
}
.tarps-table table {
    table-layout: fixed;
}
.tarps-table .p-datatable-table .p-datatable-thead > tr > th {
    background: #f1f3f4;
}
.tarps-table .p-datatable-tbody {
    font-size: 12px;
}
</style>