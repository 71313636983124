<template>
  <div class="secondary-drop-down-menu-wrapper" @click="clicked">
    <Menu ref="menu" :model="items" :class="['secondary-dropdown-menu', { 'show-non-exact-active': showNonExactActive }]">
      <template #item="{ item, props }">
        <router-link v-slot="{ href, navigate }" :to="item.to" custom>
          <a :href="href" v-bind="props.action" @click="_navigate(navigate, item, $event)">
            <MaterialIcon :icon="item.icon" />&nbsp;
            <span class="ml-1 font-medium">{{ item.label }}</span>
          </a>
        </router-link>
      </template>
      <template #end>
        <div class="px-5 mb-1">
          <p><small> v{{ appVersion }} ({{ browserVersion }})</small></p>
        </div>
      </template>
    </Menu>
  </div>
</template>

<script>
import { useUIStore } from '@/stores/UI';
import MaterialIcon from '@/components/icons/MaterialIcon.vue';
import Menu from 'primevue/menu';

const APP_VERSION = import.meta.env.VITE_APP_VERSION;

export default {
    name: "SecondaryDropDownMenu",
    props: {
      items: {
        type: Array,
        default: () => []
      },
      showNonExactActive: false,
    },
    components: {
      MaterialIcon,
      Menu,
    },
    computed: {
      appVersion() {
          return APP_VERSION;
      },
      browserVersion() {
        try {
          const userAgent = window.navigator.userAgent;
          let tem; 
          let M = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
          if ( /trident/i.test(M[1]) ) {
            tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
            return 'IE '+ (tem[1] || '');
          }
          if (M[1]=== 'Chrome') {
              tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
              if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
          }
          M = M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
          if ((tem = userAgent.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
          return M.join(' ');
        } catch (e) {
          return 'Unknown';
        }
      }
    },
    created() {
      this.UIStore = useUIStore();
    },
    methods: {
      clicked(event) {
          this.$emit('close');
      },
      _navigate(navigate, item, event) {
        if (item.meta?.isParentApp === true) {
          this.UIStore.setActiveView(item.name);
        }
        navigate(event);
      }
    }
}

</script>

<style>

.secondary-drop-down-menu-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background: linear-gradient(to bottom, transparent 0, transparent var(--top-nav-bar-height), rgba(0,0,0,0.2) var(--top-nav-bar-height));
}

.secondary-dropdown-menu {
    background: white;
    width: 100vw;
    position: absolute;
    left: 0;
    top: var(--top-nav-bar-height);
    border-radius: 0;
    box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.2), 0 4px 5px -3px rgba(0, 0, 0, 0.14), 0 1px 10px -10px rgba(0, 0, 0, 0.12) !important;
}

.secondary-dropdown-menu .p-menuitem-text {
    font-weight: 500;
    font-size: 17px;
}

.secondary-dropdown-menu .p-menuitem-link {
    border-radius: 10px;
    margin: 0 15px;
}

.secondary-dropdown-menu.show-non-exact-active .p-menuitem-link.router-link-active,
.secondary-dropdown-menu .p-menuitem-link.router-link-active-exact {
    background: #EDEDED;
}

</style>