import { v4 as uuidv4 } from 'uuid';


class BaseTarp {
  
  id = null;
  client_id = null;
  serial_no = null;
  width = null;
  length = null;
  cover_type = null;
  condition = null;
  year_of_manufacture = null;
  tarp_material = null;

  constructor() {
    this.client_id = uuidv4();
  }

  toJSON() {
    return {
      id: this.id,
      client_id: this.client_id,
      serial_no: this.serial_no,
      width: this.width,
      length: this.length,
      cover_type: this.cover_type,
      condition: this.condition,
      year_of_manufacture: this.year_of_manufacture,
      tarp_material: this.tarp_material,
    }
  }

  fromJSON(data) {
    this.id = data.id;
    this.client_id = data.client_id || uuidv4();
    this.serial_no = data.serial_no;
    this.width = data.width;
    this.length = data.length;
    this.cover_type = data.cover_type;
    this.condition = data.condition;
    this.year_of_manufacture = data.year_of_manufacture;
    this.tarp_material = data.tarp_material;
    return this;
  }

  coverTypeDisplayShort() {
    // Split cover type into array
    const coverTypes = this.cover_type.split('/').map(ct => ct.toLowerCase());
    if (coverTypes.includes('straight') && coverTypes.includes('end')) {
      return 'E+S'
    } else if (coverTypes.includes('straight')) {
      return 'S'
    } else if (coverTypes.includes('end')) {
      return 'E'
    }
  }

}

export default BaseTarp;