<template>
  <div v-if="showWindSpeed" class="field p-fluid">
    <Divider />
    <label for="wind_speed">Wind Speed</label>
    <p class="subtitle">
      Use the wind speeds meter to record wind speeds
    </p>
    <NumberInput 
      inputId="wind_speed" 
      v-model="form.wind_speed" 
      placeholder="The value must be a number" />
  </div>
</template>

<script>
import { useRecordTarpingWorksStore } from '@/stores/RecordTarpingWorks';

import NumberInput from '@/components/forms/NumberInput.vue';
import Divider from 'primevue/divider';

export default {
  name: 'CoverEstimated',
  components: {
    NumberInput,
    Divider,
  },
  created() {
      this.store = useRecordTarpingWorksStore();
  },
  computed: {
    form() {
        return this.store.form;
    },
    showWindSpeed() {
      return this.store.isTarpingActivity;
    },
  }
}

</script>