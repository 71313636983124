<template>
  <UnsavedItemCard 
    :item="tarpingWorks" 
    :label="tarpingWorks.stackRef()" 
    :completion_time="tarpingWorks.completion_time"
    :loading="loading"
    @saveItem="uploadTarpingWorks"
    @editItem="editTarpingWorks"
    @deleteItem="deleteTarpingWorks"
  />
</template>

<script>
import Button from 'primevue/button';
import Card from 'primevue/card';
import SpeedDial from 'primevue/speeddial'
import ConfirmDialog from 'primevue/confirmdialog';

import { useRecordTarpingWorksStore } from '@/stores/RecordTarpingWorks';

import UnsavedItemCard from '@/site_components/UnsavedItemCard/index.vue';

export default {
  name: "UnsavedTarpingWorksCard",
  props: ['tarpingWorks'],
  components: {
    Button,
    Card,
    ConfirmDialog,
    SpeedDial,
    UnsavedItemCard,
  },
  data() { return {
    store: null,
    loading: false,
    speedDial: {
      visible: false,
      items: [
        {
          label: 'Save',
          icon: 'upload',
          command: this.uploadTarpingWorks
        },
        {
          label: 'Edit',
          icon: 'edit',
          command: this.editTarpingWorks
        },
        {
          label: 'Delete',
          icon: 'delete',
          command: this.deleteTarpingWorks
        },
      ]
    }
  }},
  created() {
    this.store = useRecordTarpingWorksStore();
  },
  computed: {
    siteName() {
      try {
        return this.tarpingWorks.site.displayName()
      } catch (e) {
        return '';
      }
    },
    storageName() {
      try {
        return this.tarpingWorks.storage.displayName()
      } catch (e) {
        return '';
      }
    },
    stackName() {
      try {
        if (this.tarpingWorks.stack === 'new_stack') {
          return 'New Stack';
        } else {
          return this.tarpingWorks.stack.displayName()
        }
      } catch (e) {
        return '';
      }
    },
  },
  methods: {

    async uploadTarpingWorks() {
      this.loading = true;

      if (navigator.onLine) {
        try {
          await this.tarpingWorks.syncToServer();
          this.$root.successNotification('Tarping works uploaded successfully!');
        } catch (e) {
          this.$root.errorNotification(e);
        }
      } else {
        this.$root.noInternetConnectionDetected();
      }
      
      this.loading = false;
    },

    editTarpingWorks() {
      const store = useRecordTarpingWorksStore();
      store.$patch({ form: this.tarpingWorks.toJSON() } );
      this.$router.push({
        name: 'tarping_record',
      });
    },

    deleteTarpingWorks() {
      try {
        this.store.removeTarpingWorksFromLocal(this.tarpingWorks.client_id);
        this.$root.successNotification('Tarping works deleted.');
      } catch {
        this.$root.errorNotification("Error deleting tarping works.");
      }
    },

  }
}

</script>

