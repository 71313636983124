<template>
  <div>
    <div v-if="startDate">
      <div class="flex flex-column">
        <div class="row flex surface-overlay">
          <div class="cell"></div>
          <div class="cell"></div>
          <div class="cell font-bold">Repairs</div>
          <div class="cell font-bold">Joins</div>
        </div>
        <div class="row flex surface-overlay">
          <div class="cell"></div>
          <div class="cell"></div>
          <div class="cell font-bold p-0">
            <div class="flex">
              <div class="cell font-bold flex-grow-1 border-right-only">
                S
              </div>
              <div class="cell font-bold flex-grow-1 border-left-only">
                E
              </div>
            </div>
          </div>
          <div class="cell font-bold p-0">
            <div class="flex">
              <div class="cell font-bold flex-grow-1 border-right-only">
                S
              </div>
              <div class="cell font-bold flex-grow-1 border-left-only">
                W
              </div>
            </div>
          </div>
        </div>
        <div class="row flex bg-blue text-white">
          <div class="cell font-bold">Start Date</div>
          <div class="cell font-bold">{{ startDateDisplay }}</div>
          <div class="cell font-bold p-0">
            <div class="flex">
              <div class="cell font-bold flex-grow-1 border-right-only">
                {{ swingTotals.total.repairs_straights }}
              </div>
              <div class="cell font-bold flex-grow-1 border-left-only">
                {{ swingTotals.total.repairs_ends }}
              </div>
            </div>
          </div>
          <div class="cell font-bold p-0">
            <div class="flex">
              <div class="cell font-bold flex-grow-1 border-right-only">
                {{ swingTotals.total.joins_sews }}
              </div>
              <div class="cell font-bold flex-grow-1 border-left-only">
                {{ swingTotals.total.joins_welds }}
              </div>
            </div>
          </div>
        </div>
        <div v-for="(date, idx) in swingDates" :key="idx" class="row flex">
          <div class="cell">{{ dayOfWeek(date) }}</div>
          <div class="cell">{{ dateDisplay(date) }}</div>
          <div class="cell p-0">
            <div class="flex">
              <div class="cell flex-grow-1 border-right-only">{{ swingTotals[date].repairs_straights }}</div>
              <div class="cell flex-grow-1 border-left-only">{{ swingTotals[date].repairs_ends }}</div>
            </div>
          </div>
          <div class="cell p-0">
            <div class="flex">
              <div class="cell flex-grow-1 border-right-only">{{ swingTotals[date].joins_sews }}</div>
              <div class="cell flex-grow-1 border-left-only">{{ swingTotals[date].joins_welds }}</div>
            </div>
          </div>
        </div>
      
      </div>
    </div>
    
    <div v-else-if="!startDate">
      <div class="text-center mt-5">
        <p class="text-gray-500">Select a start date to generate table summary</p>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressSpinner from 'primevue/progressspinner';

import { convertToLocalDate, isSameDate } from '../../../../../../utils/dates';

import { useTarpJoinsStore } from '@/stores/TarpJoins';
import { useTarpRepairsStore } from '@/stores/TarpRepairs';

export default {
  name: "SwingSummaryTable",
  components: {
    ProgressSpinner,
  },
  props: {
    startDate: {
      type: [String, null],
      required: true
    },
    swingDaysLength: {
      type: Number,
      default: 9
    }
  },
  data() { return {
    loading: true
  }},
  created() {
  },
  computed: {
    _startDate() {
      return new Date(this.startDate);
    },
    _endDate() {
      const d = new Date(this._startDate);
      d.setDate(d.getDate() + this.swingDaysLength);
      return d;
    },
    startDateDisplay() {
      const d = this._startDate;
      return `${d.getDate()} ${d.toLocaleString('default', { month: 'short' })}`;
    },

    swingDates() {
      // Return all the dates in the swing
      const dates = [];
      for (let i = 0; i < this.swingDaysLength; i++) {
        const d = new Date(this._startDate);
        d.setDate(d.getDate() + i);
        dates.push(d);
      }
      return dates
    },

    swingTotals() {
      // Return the total repairs and joins for each day
      const totals = {
        total: {
          repairs_straights: 0,
          repairs_ends: 0,
          joins_sews: 0,
          joins_welds: 0,
        }
      };

      const repairs = useTarpRepairsStore().getTarpRepairsForDateRange(this._startDate, this._endDate);
      const joins = useTarpJoinsStore().getTarpJoinsForDateRange(this._startDate, this._endDate);

      for (let i = 0; i < this.swingDaysLength; i++) {
        const d = new Date(this._startDate);
        d.setDate(d.getDate() + i);

        const localDate = convertToLocalDate(d);

        const repairsForDate = repairs.filter(r => isSameDate(r.completion_time, localDate));
        const repairsStraights = repairsForDate.filter(r => !r.base_tarp || r.base_tarp?.cover_type === 'straight');
        const repairsEnds = repairsForDate.filter(r => r.base_tarp?.cover_type === 'end');

        const joinsForDate = joins.filter(j => isSameDate(j.completion_time, localDate));
        const joinsSews = joinsForDate.filter(j => j.join_type === 'sew');
        const joinsWelds = joinsForDate.filter(j => j.join_type === 'weld');

        const joinsSewsCount = joinsSews.reduce((acc, join) => acc + join.joinsCount(), 0);
        const joinsWeldsCount = joinsWelds.reduce((acc, join) => acc + join.joinsCount(), 0);

        totals[d] = {
          repairs_straights: repairsStraights.length,
          repairs_ends: repairsEnds.length,
          joins_sews: joinsSewsCount,
          joins_welds: joinsWeldsCount,
        };
        totals.total.repairs_straights += repairsStraights.length;
        totals.total.repairs_ends += repairsEnds.length;
        totals.total.joins_sews += joinsSewsCount;
        totals.total.joins_welds += joinsWeldsCount;
      }
      return totals;
    },

  },
  methods: {
    dayOfWeek(date) {
      return date.toLocaleString('default', { weekday: 'short' });
    },
    dateDisplay(date) {
      return date.toLocaleString(undefined, { 
        day: 'numeric',
        month: 'short',
       }
      );
    },
  }
}

</script>

<style scoped>
.cell {
  padding: 10px;
  border: 1px solid black;
  width: 25%;
}
.cell:not(:first-child) {
  margin-left: -1px;
}
.row:not(:first-child) {
  margin-top: -1px;
}

.border-right-only {
  border: none;
  border-right: 1px solid black;

}
.border-left-only {
  border: none;
  border-left: 1px solid black;
}
  
</style>