import { defineStore } from 'pinia';

import { getOrCreateItem, deleteItem, getItems, addItems } from '@/stores/idb';
import { useBaseTarpsStore } from '@/stores/BaseTarps';
import { useTarpInventoryStore } from '@/stores/TarpInventory';
import { useTarpRepairsStore } from '@/stores/TarpRepairs';

import TarpRepair from '@/stores/objects/TarpRepair';


export const useRecordTarpRepairsStore = defineStore('recordTarpRepairs', {

  persist: {
    paths: ['tarp_repair']
  },

  state: () => ({
    tarp_repair: new TarpRepair().toJSON(),
    unsaved_tarp_repairs: [],

    // Submission status
    submitted: false,
    loading: false,
    saveResponse: null,
  }),

  actions: {

    init() {
      const tarpRepair = new TarpRepair();
      if (!this.tarp_repair) this.tarp_repair = tarpRepair.toJSON();
      if (this.submitted) this.reset(true);
    },

    reset(resetSubmissionStatus = false) {
      this.tarp_repair = new TarpRepair().toJSON();
      if (resetSubmissionStatus) {
        this.submitted = false;
        this.loading = false;
        this.saveResponse = null;
      }
    },

    async saveTarpRepair() {
      const data = this.tarp_repair;

      console.log('Saving tarp repair', data);

      // Save the tarp repairs to local device
      this.saveTarpRepairToLocal(data);

      if (navigator.onLine) {
        try {

          this.loading = true;

          // syncToServer doesn't throw an error if the request fails.
          // Status is returned and handled by the component calling this action.
          const response = await new TarpRepair().syncToServer(data);
          this.saveResponse = response;

          this.loading = false;

          return response;

        } catch (error) {
          throw error;
        }
      } else {
        this.saveResponse = { status: 0 };  // Offline
        return this.saveResponse
      }
    },
    
    saveTarpRepairToLocal(tarpRepair) {
      // Check if the tarping works have already been added to the unsaved_tarping_works array
      const existingTarpRepairs = this.unsaved_tarp_repairs.filter(tr => tr.client_id === tarpRepair.client_id);
      if (existingTarpRepairs.length) {
        // If the tarping works already exist in the array, replace them with the new tarping works
        this.unsaved_tarp_repairs = this.unsaved_tarp_repairs.map(tr => {
          if (tr.client_id === tarpRepair.client_id) {
            return tarpRepair
          }
          return tr
        });
      } else {
        // Add tarp repair to the unsaved_tarp_repair array
        this.unsaved_tarp_repairs.push(tarpRepair);
      }
      // Save the unsaved tarp repair to indexedDB
      this.saveUnsavedTarpRepairToDB(tarpRepair);
    },

    removeTarpRepairFromLocal(clientId) {
      this.unsaved_tarp_repairs = [...this.unsaved_tarp_repairs.filter(tr => tr.client_id !== clientId)];
      this.removeUnsavedTarpRepairFromDB(clientId);
    },

    async addToTarpRepairsStore(tarpRepair) {
      // Add the tarping works to the tarping work store
      const tarpRepairsStore = useTarpRepairsStore();
      tarpRepairsStore.addTarpRepair(tarpRepair);

      const tarpInventoryStore = useTarpInventoryStore();

      // Handle tarp state changes
      const stateChange = tarpRepair.tarp_inventory_state_change;
      if (stateChange) {
        await tarpInventoryStore.handleStateChange(stateChange);
      }
      
      // Update base tarp
      const baseTarp = tarpRepair.updated_base_tarp;
      const baseTarpsStore = useBaseTarpsStore();
      await baseTarpsStore.addBaseTarps([baseTarp]);
    },

    async saveUnsavedTarpRepairToDB(tarpRepair) {
      await getOrCreateItem('unsavedTarpRepairs', tarpRepair.client_id, tarpRepair);
    },

    async removeUnsavedTarpRepairFromDB(clientId) {
      await deleteItem('unsavedTarpRepairs', clientId);
    },

    async loadUnsavedTarpRepairsFromDB() {
      const unsavedTarpRepairs = await getItems('unsavedTarpRepairs');
      this.unsaved_tarp_repairs = unsavedTarpRepairs;
    },

  },

  getters: {

    inputsValid() {
      const tarpRepair = this.getTarpRepair;
      return tarpRepair.isValid();
    },

    getTarpRepair() {
      return new TarpRepair().fromJSON(this.tarp_repair);
    },

    getUnsavedTarpRepairs() {
      return this.unsaved_tarp_repairs.map(tr => new TarpRepair().fromJSON(tr));
    },

    getErrors() {
      if (this.saveResponse?.status === 400) {
        return this.saveResponse?.data;
      }
      return [];
    },

    isEditMode() {
      return Boolean(this.tarp_repair.id);
    }

  }
})