import Supervisor from '@/stores/objects/Supervisor';


class TarpJoinUser {

  user = null;
  is_welder_stitcher = false;
  is_quality_controller = false;

  constructor() {
    this.user = new Supervisor();
  }

  // Adapters

  toJSON() {
    return {
      user: this.user.toJSON(),
      is_welder_stitcher: this.is_welder_stitcher,
      is_quality_controller: this.is_quality_controller
    }
  }

  fromJSON(data) {
    this.user.fromJSON(data.user);
    this.is_welder_stitcher = data.is_welder_stitcher;
    this.is_quality_controller = data.is_quality_controller;

    return this
  }

}

export default TarpJoinUser