<template>
  <div class="field p-fluid">
    <label for="storage">OBH</label>
    <Dropdown
      class="w-full md:w-14rem"
      placeholder="Select an OBH"
      :modelValue="value" 
      :options="storages"
      @update:modelValue="update"
      emptyMessage="No OBH found"
      scrollHeight="flex">
      <template #value="slotProps">
        <div v-if="slotProps.value" class="flex align-items-center">
          <div>{{ slotProps.value.display_name }}</div>
        </div>
        <span v-else>
          {{ slotProps.placeholder }}
        </span>
      </template>
      <template #option="slotProps">
        <div class="flex align-items-center">
            <div>{{ slotProps.option.display_name }}</div>
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<script>
import Dropdown from 'primevue/dropdown';
import Divider from 'primevue/divider';

import { useStoragesStore } from '@/stores/Storages';

export default {
  name: "StorageSelect",
  props: ['modelValue', 'siteId'],
  components: {
    Divider,
    Dropdown,
  },
  data() { return {
    store: null,
  }},
  computed: {
    storages() {
      return this.store.getStoragesBySite(this.siteId)
    },
    value() {
      return this.storages.find(storage => storage.id === this.modelValue)
    },
  },
  created() {
    this.store = useStoragesStore();
  },
  methods: {
    update(storage) {
      this.$emit('update:modelValue', storage.id)
    }
  }
}

</script>