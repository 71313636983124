<template>
    <div class="view flex flex-column">
        <TarpingWorksTable />
    </div>
</template>

<script>
import TarpingWorksTable from './components/TarpingWorksTable.vue';

export default {
    name: "Historical",
    components: {
        TarpingWorksTable,
    },
}
</script>

<style scoped>
.view {
    height: calc(100vh - 110px);;
    overflow: hidden;
}
</style>