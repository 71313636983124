<template>
  <div v-if="showCoverActual" class="field p-fluid">
    <Divider />
    <label for="cover_actual">Cover Actual</label>
    <p class="subtitle">
      Cover actual is taken from the strut (closest to i.e. round up or down to the nearest strut) 
      that is in line with the physical end of the folded (or pulled out) tarp. 
      <span class="font-bold">Don't</span> include how much tarp is folded at the peak.
    </p>
    <NumberInput
      inputId="cover_actual"
      v-model="form.cover_actual" 
      placeholder="The value must be a number"
      :disabled="form.cover_actual_max" />
    <div class="flex align-items-center mt-2">
      <Checkbox inputId="cover_actual_max" v-model="cover_actual_max" name="cover_actual_max" binary :tabindex="-1" />
      <label for="cover_actual_max" class="ml-2"> Max </label>
    </div>
    <Message v-if="coverActualGTCoverEstimated" severity="error" :closable="false">
      Cover actual cannot be greater than cover estimated ({{ coverEstimated }}m)
    </Message>
    <StorageLengthWarning 
      :value="form.cover_actual"
      :storageLength="store.selectedStorageLength" />
</div>
</template>

<script>
import { useRecordTarpingWorksStore } from '@/stores/RecordTarpingWorks';

import StorageLengthWarning from '../../../components/StorageLengthWarning.vue';
import NumberInput from '@/components/forms/NumberInput.vue';
import Divider from 'primevue/divider';
import Checkbox from 'primevue/checkbox';
import Message from 'primevue/message';

export default {
  name: 'CoverEstimated',
  components: {
    StorageLengthWarning,
    NumberInput,
    Divider,
    Checkbox,
    Message,
  },
  created() {
    this.store = useRecordTarpingWorksStore();
  },

  computed: {
    form() {
      return this.store.form;
    },
    cover_actual_max: {
      get() {
        return this.form.cover_actual_max;
      },
      set(value) {
        const patch = {
          form: {
            cover_actual_max: value,
          }
        };
        if (value) {
          patch.form.cover_actual = null;
        }
        this.store.$patch(patch);
      }
    },
    showCoverActual() {
      return this.store.isTarpingActivity;
    },
    coverEstimated() {
      if (this.form.cover_estimated_max) {
        return this.form.peak_actual;
      }
      return this.form.cover_estimated;
    },
    coverActualGTCoverEstimated() {
      const coverActual = this.form.cover_actual;
      const coverEstimated = this.coverEstimated;
      return Number.isInteger(coverActual) && Number.isInteger(coverEstimated) && coverActual > coverEstimated;
    }
  },
}

</script>