<template>
  <InputNumber
    :inputId="inputId"
    :modelValue="modelValue"
    :placeholder="placeholder"
    :disabled="disabled"
    :maxFractionDigits="maxFractionDigits"
    :min="min"
    :max="max"
    :inputProps="{
      'inputmode': inputMode,
      'enterkeyhint': enterkeyhint || 'next',
    }"
    :pt="{
      input: {
        autocomplete: 'off',
      }
    }"
    @input="input"
    @update:modelValue="update" />
</template>

<script>
import { useSettingsStore } from '@/stores/Settings';
import InputNumber from 'primevue/inputnumber';

export default {
  name: "NumberInput",
  components: {
    InputNumber
  },
  props: [
    'modelValue',
    'placeholder',
    'disabled',
    'maxFractionDigits', 
    'min', 
    'max', 
    'inputId', 
    'enterkeyhint'
  ],
  computed: {
    settingsStore() {
      return useSettingsStore();
    },
    inputMode() {
      return this.settingsStore.disable_numeric_keyboard ? 'text' : 'decimal';
    }
  },
  methods: {
    input(event) {
      this.update(event.value);
    },
    update(value) {
      this.$emit('update:modelValue', value);
    }
  }
}

</script>