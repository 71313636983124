<template>
  <div class="field p-fluid">
    <label for="completion_time">Repair Type</label>
    <Dropdown 
      v-model="tarpRepair.repair_type" 
      :options="repairTypes" 
      optionLabel="displayName"
      optionValue="id"
      placeholder="Select a repair type" 
      class="w-full md:w-14rem" 
    />
  </div>
</template>

<script>
import { useRecordTarpRepairsStore } from '@/stores/RecordTarpRepairs';
import { useTarpRepairTypesStore } from '@/stores/TarpRepairTypes';

import Dropdown from 'primevue/dropdown';

export default {
  name: "CompletionTime",
  components: {
    Dropdown,
  },
  created() {
      this.store = useRecordTarpRepairsStore();
      this.repairTypesStore = useTarpRepairTypesStore();
  },
  computed: {
    tarpRepair() {
        return this.store.tarp_repair;
    },
    repairTypes() {
        return this.repairTypesStore.tarp_repair_types.map(
            type => ({ ...type, displayName: type.verbose_name || type.name })
        );
    },
  }
}

</script>