<template>
  <div v-if="required">
    <Field :label="label" subtitle="If not 100% complete, please enter the strut start and finish point of the OBHM works.">
      <div class="flex flex-row gap-2">
        <div class="w-6">
          <NumberInput
            :modelValue="modelValue.start" 
            @update:modelValue="update('start', $event)"
            :disabled="modelValue.is_complete" />
          <p><small>Start</small></p>
        </div>
        <div class="w-6">
          <NumberInput 
            :modelValue="modelValue.end"
            @update:modelValue="update('end', $event)"
            :disabled="modelValue.is_complete" />
          <p><small>End</small></p>
        </div>
      </div>
      <div class="mt-3">
        <RadioSelect
          label="100% complete"
          :binary="true"
          :modelValue="modelValue.is_complete"
          @update:modelValue="update('is_complete', $event)" />
      </div>
    </Field>
  </div>
</template>

<script>
import Field from '@/components/forms/Field.vue';
import NumberInput from '@/components/forms/NumberInput.vue';
import RadioSelect from '@/components/forms/RadioSelect.vue';

export default {
  name: "StraightProgress",
  components: {
    Field,
    NumberInput,
    RadioSelect,
  },
  props: {
    label: {
      type: String,
      default: "Straight Progress",
    },
    modelValue: Object,
    required: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    update(key, val) {
      let updatedValue;
      if (key === 'is_complete' && val === true) {
        updatedValue = { ...this.modelValue, [key]: val, start: null, end: null };
      } else {
        updatedValue = { ...this.modelValue, [key]: val };
      }
      this.$emit('update:modelValue', updatedValue);
    }
  }
}

</script>
