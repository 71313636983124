<template>
    <div v-if="viewReady" class="view">
        <div class="p-3">
            <QuickLinks class="mb-3" />
            <SyncData />
            <UnsavedTarpingWorks class="mt-4" />
        </div>
    </div>
</template>

<script>
import QuickLinks from './components/QuickLinks.vue';
import SyncData from './components/SyncData.vue';
import UnsavedTarpingWorks from './components/UnsavedTarpingWorks/index.vue';

export default {
    name: "TarpingHomeView",
    components: {
        QuickLinks,
        SyncData,
        UnsavedTarpingWorks,
    },
    data() { return {
        // Flags
        viewReady: true,
        loading: false,
    }},
}

</script>

<style scoped>

</style>