<template>
    <div>
        <div class="flex align-items-center" style="width: 100px">
            <Button :class="['app-menu-btn', { 'active': isActive}]" @click="openMenu">
                <template #icon>
                    <span class="icon material-symbols-outlined">{{ menuIcon }}</span>
                </template>
            </Button>
            <p class="ml-1">{{ parentRouteName }}</p>
        </div>
        <SecondaryDropdownMenu v-show="isActive" :items="items" :showNonExactActive="true" @close="closeMenu" />
    </div>
</template>

<script>
import Button from 'primevue/button';
import SecondaryDropdownMenu from './SecondaryDropdownMenu.vue';

import { useAuthStore } from '@/stores/Auth';

export default {
    name: "SectionDropdownMenu",
    components: {
        Button,
        SecondaryDropdownMenu,
    },
    data() { return {
        isActive: false,
    }},
    computed: {
        items() {
            const authStore = useAuthStore();

            // Apps
            const appRoutes = this.$router.options.routes.filter(route => route.meta && route.meta.isParentApp);
            let items = appRoutes.map(item => {
                return {
                    name: item.name,
                    meta: item.meta,
                    to: item.path,
                    label: item.meta.title,
                    icon: item.meta.icon,
                    disabled: !item.children
                }
            });
            // Site items
            const siteRoutes = this.$router.options.routes.filter(route => {
                if (route.name == 'login') return authStore.isAuthenticated == false;
                if (route.name == 'logout') return authStore.isAuthenticated == true;
                return route.meta && route.meta.isAppMenuItem == true && route.meta.isVisible !== false
            });
            items = items.concat(
                siteRoutes.map(item => {
                    return {
                        name: item.name,
                        meta: item.meta,
                        to: item.path,
                        label: item.meta.title,
                        icon: item.meta.icon,
                    }
                })
            );
            return items;
        },
        menuIcon() {
            return this.isActive ? 'close' : 'menu';
        },
        parentRouteName() {
            // If the current route is a child route, get the parent route name
            const currentRoute = this.$route;
            if (currentRoute.matched.length > 1) {
                return currentRoute.matched[0].meta.title;
            }
        }
    },
    methods: {
        openMenu() {
            this.isActive = true;
        },
        closeMenu() {
            this.isActive = false;
        }
    }
}

</script>

<style>
.app-menu-btn {
    padding: 0 !important;
    width: 35px !important;
    height: 35px;
    min-height: 28px;
    border-radius: 5px;
    font-size: 16px;
    border: none;
}
.app-menu-btn .icon {
    font-size: 30px;
}
.app-menu-btn,
.app-menu-btn:active,
.app-menu-btn:hover,
.app-menu-btn:focus {
    box-shadow: none !important;
    color: white !important;
    background: transparent !important;
}
</style>