import { defineStore } from 'pinia';

import { getOrCreateItem, getItems, deleteItem } from './idb.js';
import { useStockpilingStore } from './Stockpiling';
import { useTarpInventoryStore } from './TarpInventory';
import Stockpiling from './objects/Stockpiling';


export const useRecordStockpilingStore = defineStore('recordStockpiling', {

  persist: {
    paths: ['stockpiling']
  },

  state: () => ({
    stockpiling: new Stockpiling().toJSON(),
    unsaved_stockpiling: [],

    // Submission status
    submitted: false,
    submitComplete: false,
    loading: false,
    saveResponse: null,
  }),

  actions: {

    init() {
      const stockpiling = new Stockpiling();
      if (!this.stockpiling) this.stockpiling = stockpiling.toJSON();
      if (this.submitted) this.reset(true);
    },

    reset(resetSubmissionStatus = false) {
      this.stockpiling = new Stockpiling().toJSON();
      if (resetSubmissionStatus) {
        this.submitted = false;
        this.submitComplete = false;
        this.loading = false;
        this.saveResponse = null;
      }
    },

    async saveStockpiling() {
      const data = this.stockpiling;

      console.log('Saving stockpiling', data);

      // Save the stockpilings to local device
      this.saveStockpilingToLocal(data);

      if (navigator.onLine) {
        try {

          this.loading = true;

          // syncToServer doesn't throw an error if the request fails.
          // Status is returned and handled by the component calling this action.
          const response = await new Stockpiling().syncToServer(data);
          this.saveResponse = response;

          this.loading = false;

          return response;

        } catch (error) {
          throw error;
        }
      } else {
        this.saveResponse = { status: 0 };  // Offline
        return this.saveResponse
      }
    },
    
    saveStockpilingToLocal(stockpiling) {
      // Check if the stockpiling have already been added to the unsaved_tarping_works array
      const existingStockpiling = this.unsaved_stockpiling.filter(tr => tr.client_id === stockpiling.client_id);
      if (existingStockpiling.length) {
        // If the stockpiling already exist in the array, replace them with the new stockpiling
        this.unsaved_stockpiling = this.unsaved_stockpiling.map(tr => {
          if (tr.client_id === stockpiling.client_id) {
            return stockpiling
          }
          return tj
        });
      } else {
        // Add stockpiling to the unsaved_tarp_join array
        this.unsaved_stockpiling.push(stockpiling);
      }
      // Save the unsaved stockpiling to indexedDB
      this.saveUnsavedStockpilingToDB(stockpiling);
    },

    removeStockpilingFromLocal(clientId) {
      this.unsaved_stockpiling = [...this.unsaved_stockpiling.filter(tj => tj.client_id !== clientId)];
      this.removeUnsavedStockpilingFromDB(clientId);
    },

    addToStockpilingStore(stockpiling) {
      const tarpInventoryStore = useTarpInventoryStore();

      // Add the new tarp
      const tarps = stockpiling.created_tarps;
      for (const tarp of tarps) {
        tarpInventoryStore.addTarp(tarp);
      }

      // Update stockpiled tarp locations
      for (const tarp_id of stockpiling.tarps) {
        const tarp = tarpInventoryStore.getTarpById(tarp_id);
        if (tarp) {
          const tarpData = tarp.toJSON();
          tarpData.site = stockpiling.site;
          tarpInventoryStore.updateTarp(tarpData);
        }
      }

      // Add the stockpiling to the stockpiling store
      const stockpilingStore = useStockpilingStore();
      stockpilingStore.addStockpiling(stockpiling);
    },

    async saveUnsavedStockpilingToDB(stockpiling) {
      await getOrCreateItem('unsavedStockpiling', stockpiling.client_id, stockpiling);
    },

    async removeUnsavedStockpilingFromDB(clientId) {
      await deleteItem('unsavedStockpiling', clientId);
    },

    async loadUnsavedStockpilingFromDB() {
      const unsavedStockpiling = await getItems('unsavedStockpiling');
      this.unsaved_stockpiling = unsavedStockpiling;
    },

  },

  getters: {

    inputsValid() {
      const stockpiling = this.getStockpiling;
      return stockpiling.isValid();
    },

    getStockpiling() {
      return new Stockpiling().fromJSON(this.stockpiling);
    },

    getUnsavedStockpiling() {
      return this.unsaved_stockpiling.map(sp => new Stockpiling().fromJSON(sp));
    },

    getErrors() {
      if (this.saveResponse?.status === 400) {
        return this.saveResponse?.data;
      }
      return [];
    }

  }
})