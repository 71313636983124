<template>
    <div class="quick-links">
        <div class="grid">
            <div v-for="link in links" :key="link.name" class="col-6">
                <RouterLink :to="link.to">
                    <Card :pt="{ body: { class: 'py-0' } }">
                        <template #content>
                            <p>
                                <span class="icon material-symbols-outlined">{{ link.icon }}</span>
                            </p>
                            <p class="font-semibold">
                                {{ link.label }}
                            </p>
                        </template>
                    </Card>
                </RouterLink>
            </div>
        </div>
    </div>
</template>

<script>
import Card from 'primevue/card';

export default {
    name: "QuickLinks",
    components: {
        Card,
    },
    computed: {
        links() {
            const tarpingRoute = this.$router.options.routes
                .find(route => route.name === 'gcm');
            const links = tarpingRoute.children
                .filter(route => route.meta && route.meta.quickLink === true)
                .map(route => {
                    return {
                        name: route.name,
                        meta: route.meta,
                        to: route.path,
                        label: route.meta.title,
                        icon: route.meta.icon,
                    }
                });
            return links;
        }
    }
}

</script>