<template>
  <div>
    <KeyValueList :items="fields" :striped="true" />
    <div v-if="obhmWorks.activity_type === 'obh_works'" class="mt-4">
      <div class="mb-2">
        <h3>Partial Progress Records</h3>
      </div>
      <div v-for="record, idx in obhmWorks.partial_progress_records" :key="record.id">
        <KeyValueList :items="recordFields(record)" :striped="true" />
        <Divider v-if="idx < obhmWorks.partial_progress_records.length" />
      </div>
    </div>
  </div>
</template>

<script>
import { replaceUnderscoresAndTitleCase } from '@/utils/strings';
import { OBH_SYSTEMS } from '@/stores/enums/OBHM';
import KeyValueList from '@/components/data/KeyValueList.vue';

import Divider from 'primevue/divider';

export default {
  name: 'OBHMWorkDetails',
  components: {
    KeyValueList,
    Divider,
  },
  inject: ['dialogRef'],
  created() {
    this.obhmWorks = this.dialogRef.data.obhmWorks
  },
  data () { return {
    obhmWorks: null,
  }},
  computed: {
    fields() {
      const obhm = this.obhmWorks;

      const fields = [
        { label: 'Completion Time', value: obhm.completionTimeDisplay() },
        { label: 'Supervisor', value: obhm.supervisor.displayName() },
      ]

      if (obhm.activity_type === 'site_works') {
        fields.push({ label: 'Site', value: obhm.site.displayName() })

        const consumableFields = [
          'wozzy',
          'ratchets',
          'belly_straps',
          'high_wall_canvecon',
          'low_wall_canvecon',
          'small_end_wall_tee_pieces',
          'small_end_wall_sections',
          'small_end_walls_still_inside_obhs',
        ]
        for (const field of consumableFields) {
          fields.push({ 
            label: replaceUnderscoresAndTitleCase(field),
            value: obhm[field] 
          })
        }

      } else if (obhm.activity_type === 'obh_works') {
        fields.push({ label: 'Site', value: obhm.site.displayName() });
        fields.push({ label: 'Storage', value: obhm.storage.displayName() });
        fields.push({ label: 'OBH System', value: OBH_SYSTEMS.find(os => os.value === obhm.storage_system).label });

        fields.push({ 
          label: 'OBH Maintenance', 
          value: obhm.obh_maintenance_is_complete ? '100% complete' : 'Not 100% complete' 
        });

        const consumableFields = obhm.getRequiredConsumables()
        for (const field of consumableFields) {
          fields.push({ 
            label: replaceUnderscoresAndTitleCase(field),
            value: obhm[field] 
          })
        }

      }

      return fields
    }
  },

  methods: {
    recordFields(record) {
      return record.detailDisplayItems();
    }
  }
}
</script>

<style scoped>
.obhm-attr-list {
  list-style: none;
  padding: 0;
  margin-left: -6px;
  margin-right: -6px;
}
.obhm-attr-list li {
  display: flex;
  justify-content: space-between;
  padding: 6px;
}
.obhm-attr-list li:nth-child(odd) {
  background-color: #f2f2f2;
}
.obhm-attr-list li .attr {
  font-weight: bold;
  width: 155px;
  flex-shrink: 0;
}
.obhm-attr-list li .value {
  flex-grow: 1;
  text-align: left;
}
</style>