<template>
  <div class="text-center">
    <p class="icon">
      <span class="material-symbols-outlined xxlg">error</span>
    </p>
    <p class="text-lg font-bold">
        There was an issue with the data submitted. Please review the form and try again.
    </p>

    <div class="errors-list-container mt-3">
      <ul class="errors-list">
        <li v-for="(errors, field) in response.data" :key="field">
          {{ field.replaceAll('_', ' ') }}
          <ul class="errors-list">
            <li v-for="error in errors" :key="error">
              {{ error }}
            </li>
          </ul>
        </li> 
      </ul>
    </div>
    
    <div class="mt-3">
      <Button 
        label="Review and try again"
        severity="info" 
        size="medium" 
        @click="$emit('returnToForm')"
        raised />
    </div>
    
  </div>
</template>

<script>
import Button from 'primevue/button';

export default {
  name: "DataErrorMsg",
  props: {
    response: {
      type: Object,
      required: true,
    },
  },
  components: {
    Button,
  },
}
</script>

<style scoped>

.icon {
  color: var(--blue-color) !important;
}

.errors-list-container {
  background: var(--red-color);
  color: white;
  padding: 1rem;
  border-radius: 0.5rem;
}
.errors-list {
  text-align: left;
  padding-left: 1rem;
}
.errors-list li {
  padding-inline-start: 0;
}

</style>