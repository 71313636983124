<template>
  <div class="field p-fluid">
    <label for="completion_time">Completion Time</label>
    <p class="subtitle">
      The time these repair works were completed.
    </p>
    <DatetimePicker inputId="rw-completion-time" v-model="tarpRepair.completion_time" />
  </div>
</template>

<script>
import { useRecordTarpRepairsStore } from '@/stores/RecordTarpRepairs';

import DatetimePicker from '@/components/forms/DatetimePicker.vue';
import Message from 'primevue/message';

export default {
  name: "CompletionTime",
  components: {
    DatetimePicker,
    Message
  },
  created() {
      this.store = useRecordTarpRepairsStore();
      if (!this.store.tarp_repair.completion_time) {
        this.store.tarp_repair.completion_time = new Date();
      }
  },
  computed: {
    tarpRepair() {
        return this.store.tarp_repair;
    },
  }
}

</script>