<template>
    <div class="view flex flex-column no-bottom-nav">
        <TarpsTable />
    </div>
</template>

<script>
import TarpsTable from './components/TarpsTable.vue';

export default {
    name: "TarpInventoryView",
    components: {
        TarpsTable
    },
}

</script>

<style scoped>
.view {
    height: 100%;
    overflow: hidden;
}
</style>