<template>
  <div>
    <NavigationAction v-show="showReset" label="Reset" @click="reset" />
    <ConfirmDialog acceptLabel="Reset form" group="resetOBHMFormConfirm">
      <template #accepticon>
        <div></div>
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import { useRecordOBHMStore } from '@/stores/RecordOBHM';

import ConfirmDialog from 'primevue/confirmdialog';
import NavigationAction from '@/navigation/TopNavigationBar/components/NavigationAction.vue'

export default {
  name: "ResetGCMFormButton",
  components: {
    ConfirmDialog,
    NavigationAction,
  },
  data() { return {
    recordOBHMStore: null,
  }},
  created() {
    this.recordOBHMStore = useRecordOBHMStore();
  },
  computed: {
    showReset() {
      return true;
    }
  },
  methods: {
    reset() {
      this.$confirm.require({
        group: 'resetOBHMFormConfirm',
          message: 'Are you sure you want to reset this form? All progress will be lost.',
          header: 'Reset Form',
          acceptClass: 'p-button-danger',
          accept: () => {
            this.recordOBHMStore.reset();
          }
      });
    }
  }
}

</script>