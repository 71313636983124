<template>
  <div class="flex flex-column h-full">
    <div class="flex-grow-0 mb-2">
      <div class="flex gap-2">
        <div class="flex-grow-1">
          <form>
            <NumberInput inputId="tarps" ref="serialNoFilter" v-model="search_serial_no" class="w-full" placeholder="Filter by serial number..." :useGrouping="false" />
          </form>
        </div>
        <div class="flex-grow-0">
          <Button label="+ New" severity="info" class="h-full" @click="createNewTarp" outlined />
        </div>
      </div>
    </div>
    <div class="flex-grow-1 list-wrapper">
      <VirtualScroller :items="filteredTarps" :itemSize="50" class="h-full w-full">
        <template v-slot:item="{ item, options }">
          <div :class="['flex align-items-center p-2']">
            <div class="flex-grow-1">
              <p class="font-semibold mb-0">{{ item.serial_no }}</p>
              <p><small>
                <span v-if="item.site">{{ item.site.displayName() }}</span>
                <span v-else>Unknown site</span> 
                <span v-if="item.stack">&nbsp;&middot;&nbsp;{{ item.stack.storage.displayName() }}/{{ item.stack.displayName() }}</span>
                <span v-if="item.length && item.width">&nbsp;&middot;&nbsp;{{ item.width }}m x {{ item.length }}m</span>
                <span v-if="item.length && item.width">&nbsp;&middot;&nbsp;{{ item.coverTypeDisplayShort() }}</span>
              </small></p>
            </div>
            <div class="flex-grow-0">
              <Button label="Select" size="small" severity="info" @click="select(item)" />
            </div>
          </div>
        </template>
      </VirtualScroller>
    </div>
  </div>
</template>

<script>
import { useTarpInventoryStore } from '@/stores/TarpInventory';

import Button from 'primevue/button';
import VirtualScroller from 'primevue/virtualscroller';

import NumberInput from '@/components/forms/NumberInput.vue';

export default {
  name: "SelectTarpMeny",
  components: {
    Button,
    NumberInput,
    VirtualScroller,
  },
  inject: ['dialogRef'],
  data() { return {
    tarps: [],
    search_serial_no: null,
  }},
  computed: {
    filteredTarps() {
      if (!this.search_serial_no) return this.tarps;
      return this.tarps.filter(tarp => tarp.serial_no.includes(this.search_serial_no));
    }
  },
  created() {
    this.tarps = useTarpInventoryStore().getTarps;
    // Sort tarps by site name, then stack name, then serial number. Show the tarps that are on this site first.
    const site = this.dialogRef.data.site;
    this.tarps.sort((a, b) => {
      if (a.site && b.site) {
        if (a.site.id === site.id && b.site.id !== site.id) return -1;
        if (a.site.id !== site.id && b.site.id === site.id) return 1;
      }
      if (a.site && !b.site) return -1;
      if (!a.site && b.site) return 1;
      if (a.site && b.site) {
        if (a.site.name < b.site.name) return -1;
        if (a.site.name > b.site.name) return 1;
      }
      if (a.stack && b.stack) {
        if (a.stack.name < b.stack.name) return -1;
        if (a.stack.name > b.stack.name) return 1;
      }
      if (a.serial_no < b.serial_no) return -1;
      if (a.serial_no > b.serial_no) return 1;
      return a.serial_no - b.serial_no;
    });
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.serialNoFilter.$el.querySelector('input').focus();
    });
  },
  methods: {
    select(tarp) {
      this.$emit('selectTarp', tarp)
    },
    createNewTarp() {
      this.$emit('createTarp');
    }
  }
}

</script>

<style scoped>
.list-wrapper {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
</style>