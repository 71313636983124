<template>
  <div>
    <form novalidate>
      <FormHeader />
      <Divider />
      <TarpsSelectField v-model="tarps">
        <template v-slot:selectedTarps>
          <div v-for="tarp in tarpsWithBaseTarps" :key="tarp.client_id">
            <div class="mb-3">
              <TarpPoleInfoCard :tarp="tarp" @removeTarp="removeTarp" />
            </div>
          </div>
        </template>
      </TarpsSelectField>
    </form>
  </div>
</template>

<script>
import { useRecordGCMStore } from '@/stores/RecordGCM';

import Tarp from '@/stores/objects/Tarp';
import FormHeader from '../../components/FormHeader.vue';
import TarpsSelectField from '@/site_components/TarpsSelectField/index.vue';
import TarpPoleInfoCard from './components/TarpPoleInfoCard.vue';

import Divider from 'primevue/divider';

export default {
  name: "TarpPoleInfo",
  components: {
    FormHeader,
    TarpsSelectField,
    TarpPoleInfoCard,
    Divider,
  },
  created() {
    this.recordGCMStore = useRecordGCMStore();
  },
  computed: {
    tarps: {
      get() {
        return this.recordGCMStore.tarp_pole_info.tarps;
      },
      set(value) {
        this.recordGCMStore.tarp_pole_info.tarps = value;
      },
    },
    tarpsWithBaseTarps() {
      return this.tarps.map(t => new Tarp(true).fromJSON(t.toJSON()));
    },
  },
  methods: {

    removeTarp(tarp) {
      this.recordGCMStore.tarp_pole_info.tarps = this.tarps.filter(t => t.client_id !== tarp.client_id);
    },

  }
}

</script>