<template>

  <div style="height: 100%">
    <iframe :src="iframeSrc" frameborder="0" width="100%" height="100%"></iframe>
  </div>

</template>

<script>

export default {
  name: "ResetPassword",
  computed: {
    iframeSrc() {
      // Get the current domain
      const domain = window.location.hostname;
      const path = 'admin/password_reset/'
      console.log(domain)
      if (domain === "localhost") {
        // If we're on localhost, use the staging domain
        return `http://localhost:8000/${path}`;
      } else {
        // Otherwise, use the production domain
        return `https://${domain}/${path}`;
      }
    }
  }
}

</script>