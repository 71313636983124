import { openDB } from 'idb';


const getOrCreateDB = async () => {
  const name = 'database';
  const version = import.meta.env.VITE_INDEXEDDB_VERSION;
  const db = await openDB(name, version, {
    upgrade(db, oldVersion, newVersion, transaction, event) {
      // Meta - holds information about the database stores
      if (!db.objectStoreNames.contains('meta')) {
        // Create meta store
        const metaStore = db.createObjectStore(
          "meta",
          { keyPath: "store" }
        );
      }

      // State - holds the state of the app
      if (!db.objectStoreNames.contains('state')) {
        // Create state store
        const draftsStore = db.createObjectStore(
          "state",
          { keyPath: "key" }
        );
      }

      // Tarping Works
      if (!db.objectStoreNames.contains('tarpingWorks')) {
        // Create tarping works store
        const tarpingWorksStore = db.createObjectStore(
          "tarpingWorks",
          { keyPath: "id" }
        );
        tarpingWorksStore.createIndex("season", "season");
        tarpingWorksStore.createIndex("stack", ["stack.site.name", "stack.storage.name", "stack.name"]);
      }

      // Unsaved Tarping Works
      if (!db.objectStoreNames.contains('unsavedTarpingWorks')) {
        // Create unsaved tarping works store
        const unsavedTarpingWorksStore = db.createObjectStore(
          "unsavedTarpingWorks",
          { keyPath: "client_id" }
        );
      }

      // Tarping Reports
      if (!db.objectStoreNames.contains('tarpingReports')) {
        // Create tarping reports store
        // Stores generated pdf reports in base64 format
        const tarpingReportsStore = db.createObjectStore(
          "tarpingReports",
          { keyPath: "id" }
        );
      }

      // Tarps
      if (!db.objectStoreNames.contains('tarps')) {
        // Create tarps store
        const tarpsStore = db.createObjectStore(
          "tarps",
          { keyPath: "serial_no" }
        );
        tarpsStore.createIndex("id", "id");
        tarpsStore.createIndex("site", "site");
        tarpsStore.createIndex("stack", "stack");
      } else {
        // Upgrade tarps store
        const tarpsStore = transaction.objectStore('tarps');
        if (!tarpsStore.indexNames.contains('id')) {
          tarpsStore.createIndex("id", "id");
        }
      }

      // Unsaved Tarps
      if (!db.objectStoreNames.contains('unsavedTarps')) {
        // Create unsaved tarps store
        const unsavedTarpsStore = db.createObjectStore(
          "unsavedTarps",
          { keyPath: "client_id" }
        );
      }

      // Base Tarps
      if (!db.objectStoreNames.contains('baseTarps')) {
        // Create tarps store
        const baseTarpsStore = db.createObjectStore(
          "baseTarps",
          { keyPath: "serial_no" }
        );
      }

      // Commodities
      if (!db.objectStoreNames.contains('commodities')) {
        // Create commodities store
        const commoditiesStore = db.createObjectStore(
          "commodities",
          { keyPath: "name" }
        );
      }

      // Sites
      if (!db.objectStoreNames.contains('sites')) {
        // Create tarps store
        const sitesStore = db.createObjectStore(
          "sites",
          { keyPath: "name" }
        );
      }

      // Storages
      if (!db.objectStoreNames.contains('storages')) {
        // Create storages store
        const storagesStore = db.createObjectStore(
          "storages",
          { keyPath: "id" }
        );
        storagesStore.createIndex("storage", "storage");
      }

      // Stacks
      if (!db.objectStoreNames.contains('stacks')) {
        // Create stacks store
        const stacksStore = db.createObjectStore(
          "stacks",
          { keyPath: "id" }
        );
        // Create a compound index on site, storage, and name
        stacksStore.createIndex("ref", ["site.name", "storage.name", "name"], { unique: true });;
      }


      // Grain Cover Maintenance


      // Tarp Repair Types
      if (!db.objectStoreNames.contains('tarpRepairTypes')) {
        // Create tarp repair types store
        const tarpRepairTypesStore = db.createObjectStore(
          "tarpRepairTypes",
          { keyPath: "id" }
        );
      }

      // Tarp Repairs
      if (!db.objectStoreNames.contains('tarpRepairs')) {
        // Create tarp repairs store
        const tarpRepairsStore = db.createObjectStore(
          "tarpRepairs",
          { keyPath: "id" }
        );
      }

      // Unsaved Tarp Repairs
      if (!db.objectStoreNames.contains('unsavedTarpRepairs')) {
        // Create unsaved tarp repairs store
        const unsavedTarpRepairsStore = db.createObjectStore(
          "unsavedTarpRepairs",
          { keyPath: "client_id" }
        );
      }

      // Tarp Joins
      if (!db.objectStoreNames.contains('tarpJoins')) {
        // Create tarp joins store
        const tarpJoinsStore = db.createObjectStore(
          "tarpJoins",
          { keyPath: "id" }
        );
      }

      // Unsaved Tarp Joins
      if (!db.objectStoreNames.contains('unsavedTarpJoins')) {
        // Create unsaved tarp joins store
        const unsavedTarpJoinsStore = db.createObjectStore(
          "unsavedTarpJoins",
          { keyPath: "client_id" }
        );
      }

      // Tarp Join Users
      if (!db.objectStoreNames.contains('tarpJoinUsers')) {
        // Create tarp join users store
        const tarpJoinUsersStore = db.createObjectStore(
          "tarpJoinUsers",
          { keyPath: "id" }
        );
      }

      // Stockpiling
      if (!db.objectStoreNames.contains('stockpiling')) {
        // Create stockpiling store
        const stockpilingStore = db.createObjectStore(
          "stockpiling",
          { keyPath: "id" }
        );
      }

      // Unsaved Stockpiling
      if (!db.objectStoreNames.contains('unsavedStockpiling')) {
        // Create unsaved stockpiling store
        const unsavedStockpilingStore = db.createObjectStore(
          "unsavedStockpiling",
          { keyPath: "client_id" }
        );
      }

      // Stockpiling Requirements
      if (!db.objectStoreNames.contains('stockpilingRequirements')) {
        // Create stockpiling requirements store
        const stockpilingRequirementsStore = db.createObjectStore(
          "stockpilingRequirements",
          { keyPath: "site" }
        );
      }

      // New Tarps Inputs
      if (!db.objectStoreNames.contains('newTarpsInputs')) {
        // Create new tarp inputs store
        const newTarpInputsStore = db.createObjectStore(
          "newTarpsInputs",
          { keyPath: "id" }
        );
      }

      // Unsaved New Tarp Inputs
      if (!db.objectStoreNames.contains('unsavedNewTarpsInputs')) {
        // Create unsaved new tarp inputs store
        const unsavedNewTarpInputsStore = db.createObjectStore(
          "unsavedNewTarpsInputs",
          { keyPath: "client_id" }
        );
      }

      // Site Works
      if (!db.objectStoreNames.contains('siteWorks')) {
        // Create site works store
        const siteWorksStore = db.createObjectStore(
          "siteWorks",
          { keyPath: "id" }
        );
      }

      // Unsaved Site Works
      if (!db.objectStoreNames.contains('unsavedSiteWorks')) {
        // Create unsaved site works store
        const unsavedSiteWorksStore = db.createObjectStore(
          "unsavedSiteWorks",
          { keyPath: "client_id" }
        );
      }

      // OBH Works
      if (!db.objectStoreNames.contains('OBHWorks')) {
        // Create OBH works store
        const OBHWorksStore = db.createObjectStore(
          "OBHWorks",
          { keyPath: "id" }
        );
      }

      // Unsaved OBH Works
      if (!db.objectStoreNames.contains('unsavedOBHWorks')) {
        // Create unsaved OBH works store
        const unsavedOBHWorksStore = db.createObjectStore(
          "unsavedOBHWorks",
          { keyPath: "client_id" }
        );
      }

    }
  });
  return db;
}

async function getStore(storeName) {
  const db = await getOrCreateDB();
  const tx = db.transaction([storeName], 'readwrite');
  const store = tx.objectStore(storeName);
  return store;
}

async function getOrCreateItem(storeName, key, data = {}) {
  const db = await getOrCreateDB();
  const tx = db.transaction([storeName], 'readwrite');
  const store = tx.objectStore(storeName);
  try {
    const existingItem = await store.get(key);
    if (existingItem) {
      return existingItem;
    } else {
      // Item doesn't exist, create a new item and store it
      const keyPath = store.keyPath;
      const newItemData = Object.assign({}, data, { [keyPath]: key });
      const formattedData = formatData(newItemData);
      const newItem = await store.put(formattedData);
      return newItem;
    }
  } catch (error) {
    throw new Error(`Error: ${error}`);
  }
}

async function saveItem(storeName, key, data) {
  const db = await getOrCreateDB();
  const tx = db.transaction([storeName], 'readwrite');
  const store = tx.objectStore(storeName);
  const item = await store.get(key);
  if (item) {
    const updatedItem = Object.assign({}, item, data);
    await store.put(updatedItem);
    await tx.done;
    return updatedItem;
  } else {
    // Item doesn't exist, create a new item and store it
    const keyPath = store.keyPath;
    const newItemData = Object.assign({}, data, { [keyPath]: key });
    const formattedData = formatData(newItemData);
    const newItem = await store.put(formattedData);
    await tx.done;
    return newItem;
  }
}

async function addItems(storeName, items) {
  const db = await getOrCreateDB();
  const tx = db.transaction([storeName], 'readwrite');
  const store = tx.objectStore(storeName);

  items?.forEach(item => {
    const formattedItem = formatData(item);
    store.put(formattedItem);
  });

  await tx.done;
}

async function deleteItem(storeName, key) {
  const db = await getOrCreateDB();
  const tx = db.transaction([storeName], 'readwrite');
  const store = tx.objectStore(storeName);
  await store.delete(key);
  await tx.done;
}

async function deleteItems(storeName, keys) {
  const db = await getOrCreateDB();
  const tx = db.transaction([storeName], 'readwrite');
  const store = tx.objectStore(storeName);
  keys?.forEach(key => {
    store.delete(key);
  });
  await tx.done;
}

async function deleteItemsByIndex(storeName, indexName, indexValue) {
  const db = await getOrCreateDB();
  const tx = db.transaction([storeName], 'readwrite');
  const store = tx.objectStore(storeName);
  const index = store.index(indexName);
  const keys = await index.getAllKeys(indexValue);
  keys?.forEach(key => {
    store.delete(key);
  });
  await tx.done;
}

async function clearStore(storeName) {
  const db = await getOrCreateDB();
  const tx = db.transaction([storeName], 'readwrite');
  const store = tx.objectStore(storeName);
  await store.clear();
  await tx.done;
}

async function getItems(storeName) {
  const db = await getOrCreateDB();
  const tx = db.transaction([storeName], 'readwrite');
  const store = tx.objectStore(storeName);
  const items = await store.getAll();
  await tx.done;
  return items;
}

async function updateStoreLastUpdated(store, lastUpdated, tx = null) {
  let meta = await getOrCreateItem('meta', store);
  meta.lastUpdated = lastUpdated;
  await saveItem('meta', store, meta);
}

function formatData(data) {
  // Format data to be stored in indexedDB by removing proxy objects.
  return JSON.parse(JSON.stringify(data));
}

export { 
  getOrCreateDB,
  getOrCreateItem,
  getStore,
  updateStoreLastUpdated,
  saveItem,
  deleteItem,
  deleteItems,
  deleteItemsByIndex,
  getItems,
  clearStore,
  addItems,
};
