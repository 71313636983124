// import axios from 'axios';
import { default as axios } from './http';

class AuthAPI {

  async login(data) {
    const url = '/api/v1/accounts/auth/token/';
    const response = await axios.post(url, data)
    return response
  }

  async refreshToken(refreshToken) {

    const refreshPayload = {
        refresh: refreshToken
    };

    const url = '/api/v1/accounts/auth/token/refresh/';

    const response = await axios.post(url, refreshPayload);
    return response;
  }

  logout() {

  }

}

export default AuthAPI
