import axios from 'axios';
import { defineStore } from 'pinia';
import { getOrCreateDB, getOrCreateItem, updateStoreLastUpdated } from './idb.js';

import TarpingWorks from './objects/TarpingWorks.js';
import TarpingWorksAPI from './api/TarpingWorks.js';


export const useTarpingWorksStore = defineStore('tarpingWorks', {
  state: () => ({ 
    tarpingWorks: [],
    lastUpdated: null,
  }),

  actions: {
    async loadTarpingWorksFromAPI() {
      // Fetch tarps from API and update indexedDB
      const api = new TarpingWorksAPI();

      try {
        const response = await api.getTarpingWorks();
        const data = response.data;
        this.tarpingWorks = data;
        this.updateTarpingWorksDB(data);
        return response
      } catch (error) {
        console.error(error);
      }
    },

    async loadTarpingWorksFromDB() {
      console.log('Loading tarping works from indexedDB...')
      const db = await getOrCreateDB();
      const tx = db.transaction(['tarpingWorks', 'meta'], 'readwrite');
      const tarpingWorksStore = tx.objectStore('tarpingWorks');
      const tarpingWorks = await tarpingWorksStore.getAll();
      console.log(`${tarpingWorks.length} tarping works loaded from indexedDB`);
      this.tarpingWorks = tarpingWorks;

      const tarpingWorksMeta = await getOrCreateItem('meta', 'tarpingWorks');
      const lastUpdated = tarpingWorksMeta.lastUpdated;
      (lastUpdated) ? this.lastUpdated = lastUpdated : this.lastUpdated = null;
      
      await tx.done;
    },

    async loadTarpingWorks(awaitAPI) {
      // Load tarps from cache and then update from API in background
      await this.loadTarpingWorksFromDB();
      if (navigator.onLine) {
        const response = this.loadTarpingWorksFromAPI();
        if (awaitAPI) await response;
      }
    },

    async updateTarpingWorksDB(data) {
      // Clears and repopulates tarping works in indexedDB
      const db = await getOrCreateDB();
      const tx = db.transaction(['tarpingWorks', 'meta'], 'readwrite');
      const tarpingWorksStore = tx.objectStore('tarpingWorks');
      // Clear existing data
      await tarpingWorksStore.clear();
      // Add data
      data?.forEach(tarpingWorks => {
        tarpingWorksStore.add(tarpingWorks)
      });
      // Update last updated timestamp
      const now = Date.now();
      this.lastUpdated = now;
      console.log(`Updating last updated timestamp to ${now}`);
      updateStoreLastUpdated('tarpingWorks', now, tx);
      await tx.done;
    },

    async addTarpingWorks(tarpingWork) {
      // Add tarping work to store and indexedDB
      const db = await getOrCreateDB();
      const tx = db.transaction(['tarpingWorks'], 'readwrite');
      const tarpingWorksStore = tx.objectStore('tarpingWorks');

      if (tarpingWorksStore.get(tarpingWork.id)) {
        tarpingWorksStore.put(tarpingWork);
      } else {
        tarpingWorksStore.add(tarpingWork);
      }
      this.tarpingWorks.push(tarpingWork);
      await tx.done;
    }

  },

  getters: {

    getTarpingWorks(state) {
      // Return all tarping works, ordered by completion time (newest first)
      const sortedTarpingWorks = state.tarpingWorks.sort((a, b) => {
        return new Date(b.completion_time) - new Date(a.completion_time);
      });
      return sortedTarpingWorks.map(tarpingWork => new TarpingWorks().fromJSON(tarpingWork));
    },
  },

})
