<template>
  <div class="container">
    <div v-if="!initComplete || store.loading">
      <ViewLoadingIndicator />
    </div>
    <div v-else>
      <CreatedMsg v-if="isSuccess" @recordAnother="recordAnother" />
      <OfflineMsg v-else-if="isOffline" @recordAnother="recordAnother" />
      <DataErrorMsg v-else-if="isDataError" :response="response" @returnToForm="returnToForm" />
      <UnexpectedErrorMsg v-else @recordAnother="recordAnother" @tryAgain="reSubmitForm" />
    </div>
  </div>
</template>

<script>
import { useRecordTarpingWorksStore } from '@/stores/RecordTarpingWorks';

import * as Sentry from "@sentry/vue";

import Button from 'primevue/button';
import Divider from 'primevue/divider';

import ViewLoadingIndicator from '@/components/ViewLoadingIndicator.vue';

import CreatedMsg from './components/CreatedMsg.vue';
import OfflineMsg from './components/OfflineMsg.vue';
import DataErrorMsg from './components/DataErrorMsg.vue';
import UnexpectedErrorMsg from './components/UnexpectedErrorMsg.vue';

export default {
  name: "SubmitRecord",
  components: {
    Button,
    Divider,
    ViewLoadingIndicator,
    CreatedMsg,
    OfflineMsg,
    DataErrorMsg,
    UnexpectedErrorMsg,
  },
  data() { return {
    store: null,
    localForm: null,
    // Delayed by a second to ensure smooth loading transition
    initComplete: false,
    response: null,
  }},
  created() {
    const store = useRecordTarpingWorksStore();
    this.store = store;

    setTimeout(() => {
        this.initComplete = true;
    }, 1000);

    this.submitForm();
  },
  computed: {
    isOffline() {
      return this.response.status === 0;
    },
    isSuccess() {
      return [200, 201, 409].includes(this.response.status);
    },
    isDataError() {
      // Error occurred due to data inputs
      return this.response.status === 400;
    },
  },
  methods: {
    reSubmitForm() {
      this.response = null;

      // Restore the form
      this.store.form = this.localForm;

      this.initComplete = false;
      setTimeout(() => {
        this.initComplete = true;
      }, 1000);

      this.submitForm();
    },

    async submitForm() {
      this.store.loading = true;
      try {
        const response = await this.store.saveTarpingWorks();
        this.response = response;
      } catch (e) {
        Sentry.captureException(e);
        
        console.error(e);
        this.response = 'error'
      } finally {
        // Store a local copy of the form that we can use to restore the form
        // if the user wants to modify the data. Otherwise, we need to reset the
        // store to clear the form.
        this.localForm = this.store.form;
        // Reset the store
        this.store.reset(false);
        this.store.loading = false;
      }
    },

    returnToForm() {
      // Restore the form
      this.store.form = this.localForm;
      this.store.goToStep(0);
    },

    recordAnother() {
      this.store.reset();
    }
  }
}

</script>

<style scoped>

.container {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

</style>