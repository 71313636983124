<template>
  <div>
    <KeyValueList :items="fields" :striped="true" />
  </div>
</template>

<script>
import KeyValueList from '@/components/data/KeyValueList.vue';

import Divider from 'primevue/divider';

export default {
  name: 'TarpDetails',
  components: {
    KeyValueList,
    Divider,
  },
  inject: ['dialogRef'],
  created() {
    this.tarp = this.dialogRef.data.tarp;
  },
  data () { return {
    tarp: null,
  }},
  computed: {
    fields() {
      const tarp = this.tarp;

      const fields = [
        { label: 'Serial Number', value: tarp.serial_no },
        { label: 'Join Type', value: tarp.join_type || '-' },
        { label: 'YoM', value: tarp.year_of_manufacture },
        { label: 'Cover Type', value: tarp.cover_type },
        { label: 'Condition', value: tarp.condition },
        { label: 'Width', value: tarp.width },
        { label: 'Length', value: tarp.length },
        { label: 'Tape Color', value: this.tapeColor[0], bgClass: this.tapeColor[1] }
      ]

      return fields
    },

    tapeColor() {
      const tarp = this.tarp;
      const length = tarp.length;
      const coverTypes = tarp.cover_type.toLowerCase().split('/');

      let color;
      if (coverTypes.length === 1 && coverTypes.includes('end')) color = 'white';
      else if (coverTypes.includes('end') && coverTypes.includes('straight')) color = 'silver';
      else if (length > 20) color = 'yellow';
      else if (length > 16) color = 'green';
      else if (length > 12) color = 'blue';
      else color = 'red';

      const colorClassMap = {
        white: 'bg-white',
        silver: 'surface-300',
        yellow: 'bg-yellow-400',
        green: 'bg-green-500',
        blue: 'bg-blue-500',
        red: 'bg-red-500',
      }

      return [color, colorClassMap[color] + ' px-1 capitalize'];
    }
  },
}
</script>