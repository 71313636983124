<template>
  <div class="field p-fluid">
    <label for="sp-completion-time">Completion Time</label>
    <p class="subtitle">
      The time this site works was completed.
    </p>
    <DatetimePicker inputId="sp-completion-time" v-model="form.completion_time" />
  </div>
</template>

<script>
import SiteWorksMixin from './mixins';

import DatetimePicker from '@/components/forms/DatetimePicker.vue';
import Message from 'primevue/message';

export default {
  name: "CompletionTime",
  components: {
    DatetimePicker,
    Message
  },
  mixins: [SiteWorksMixin],
  created() {
    if (!this.form.completion_time) {
      this.form.completion_time = new Date();
    }
  },
}

</script>