import { defineStore } from 'pinia';
import { getOrCreateItem, getItems, updateStoreLastUpdated, clearStore, addItems, saveItem } from './idb.js';

import StockpilingRequirements from './objects/StockpilingRequirements.js';
import StockpilingRequirementsAPI from './api/StockpilingRequirements.js';


export const useStockpilingRequirementsStore = defineStore('stockpilingRequirements', {
  state: () => ({ 
    stockpilingRequirements: [],
    lastUpdated: null,
  }),

  actions: {
    async loadStockpilingRequirementsFromAPI() {
      const api = new StockpilingRequirementsAPI();

      try {
        const response = await api.getStockpilingRequirements();
        const data = response.data;
        this.stockpilingRequirements = data;
        this.updateStockpilingRequirementsDB(data);
        return response
      } catch (error) {
        console.error(error);
      }
    },

    async loadStockpilingRequirementsFromDB() {
      console.log('Loading stockpiling requirements from indexedDB...')
      const stockpilingRequirements = await getItems('stockpilingRequirements');
      this.stockpilingRequirements = stockpilingRequirements;

      const stockpilingRequirementsMeta = await getOrCreateItem('meta', 'stockpilingRequirements');
      const lastUpdated = stockpilingRequirementsMeta.lastUpdated;
      (lastUpdated) ? this.lastUpdated = lastUpdated : this.lastUpdated = null;
    },

    async loadStockpilingRequirements(awaitAPI) {
      // Load tarps from cache and then update from API in background
      await this.loadStockpilingRequirementsFromDB();
      if (navigator.onLine) {
        const response = this.loadStockpilingRequirementsFromAPI();
        if (awaitAPI) await response;
      }
    },

    async updateStockpilingRequirementsDB(data) {
      // Clears and repopulates tarp works in indexedDB
      clearStore('stockpilingRequirements');

      // Add data
      addItems('stockpilingRequirements', data);

      // Update last updated timestamp
      const now = Date.now();
      this.lastUpdated = now;
      updateStoreLastUpdated('stockpilingRequirements', now);
    },

  },

  getters: {

    getStockpilingRequirements(state) {
      return state.stockpilingRequirements.map(
        sr => new StockpilingRequirements().fromJSON(sr)
      );
    },

    getStockpilingRequirementsBySiteID: (state) => (siteID) => {
      return state.stockpilingRequirements.find(sr => sr.site === siteID);
    }
  },

})