import { useRecordTarpJoinsStore } from "../RecordTarpJoins";

const MAINTENANCE_TYPES = [
  { 
    value: 'tarp_repair', 
    label: 'Tarp Repairs',
    icon: 'construction',
  },
  {
    value: 'tarp_join',
    label: 'Tarp Joins',
    icon: 'join',
    storeName: 'recordTarpJoins',
  },
  {
    value: 'stockpiling',
    label: 'Stockpiling',
    icon: 'warehouse',
  },
  {
    value: 'new_tarps_input',
    label: 'New Tarps',
    icon: 'add_circle',
  },
  {
    value: 'tarp_pole_info',
    label: 'Tarp Pole Info',
    icon: 'flag',
  },
  {
    value: 'swing_summary',
    label: 'Swing Summary',
    icon: 'table',
  },
]

const TARP_JOIN_TYPES = [
  { value: 'sew', label: 'Sew' },
  { value: 'weld', label: 'Weld' },
]

const TARP_CONDITIONS = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: 'unserviceable', label: 'Unserviceable' },
  { value: 'reus', label: 'reUS' },
  { value: 'deleted_sn', 'label': 'Deleted Serial No.' },
]


const UNSERVICEABLE_DAMAGE_TYPES = [
  { value: 'sun_damage', label: 'Sun Damage' },
  { value: 'personnel_damage', label: 'Personnel Damage' },
  { value: 'loader_damage', label: 'Loader Damage' },
  { value: 'mouse_damage', label: 'Mouse Damage' },
  { value: 'bird_damage', label: 'Bird Damage' },
  { value: 'unknown', label: 'Unknown' },
]


const TARP_MATERIALS = [
  { value: 'pvc', label: 'PVC' },
]

const COVER_TYPES = [
  { value: 'end', label: 'End' },
  { value: 'straight', label: 'Straight' },
]


export {
  MAINTENANCE_TYPES,
  TARP_CONDITIONS,
  TARP_JOIN_TYPES,
  UNSERVICEABLE_DAMAGE_TYPES,
  TARP_MATERIALS,
  COVER_TYPES,
}
