import { default as axios } from './http';


class StockpilingRequirementsAPI {

  getStockpilingRequirements = async () => {
    // Fetch tarps from API
    console.log('Fetching stockpiling works from API...')
    const url = '/api/v1/gcm/stockpiling/requirements/';
    const response = await axios.get(url)
    return response
  }

}

export default StockpilingRequirementsAPI;
