<template>
  <div>
    <form v-show="!store.submitted" @submit.prevent="submit" novalidate>
      <FormHeader />
      <Divider />
      <NewWidth />
      <Divider />
      <NewLength />
      <Divider />
      <RepairType />
      <Divider />
      <div v-if="isUnserviceable">
        <Divider />
        <DropdownField
          v-model="tarp_repair.unserviceable_damage_type"
          label="Unserviceable Damage Type"
          :options="unserviceableDamageTypes"
          optionLabel="label"
          optionValue="value"
          placeholder="Select an unserviceable damage type" 
        />
      </div>
      <div v-if="hasHolesRepairedField">
        <Divider />
        <IntegerField
          v-model="tarp_repair.holes_repaired"
          label="Holes Repaired"
        />
        <div v-if="confirmHolesRepaired">
          <div class="mt-3">
            <RadioSelect
              v-model="tarp_repair.holes_repaired_confirmed"
              :value="true"
              label="Confirm this value is correct"
              :binary="true" />
          </div>
        </div>
      </div>
      <Divider />
      <Condition />
      <Divider />
      <BaseTarpField />
      <Divider />
      <TextareField
        label="Comments"
        v-model="tarp_repair.comments" />
      <Divider />
      <div class="field p-fluid">
        <Button 
          label="Submit"
          severity="secondary"
          size="large"
          :disabled="!store.inputsValid"
          type="submit"
          raised />
      </div>
    </form>
    <FormSubmitLoader
      v-if="store.submitted"
      :loading="store.loading"
      :status="store.saveResponse?.status"
      :errors="store.getErrors"
      @recordAnother="recordAnother"
      @returnToForm="returnToForm"
      @reSubmitForm="reSubmit"
    >
      <template v-slot:dataErrors v-if="repairAlreadyExistsError">
        <TarpAlreadyExistsError
          @changeSerialNumber="changeSerialNumber" 
          @addZeroToSerialNumber="addZeroToSerialNumber"
        />
      </template>
    </FormSubmitLoader>
  </div>
</template>

<script>
import * as Sentry from "@sentry/vue";

import { onBeforeRouteLeave } from 'vue-router';

import { useRecordGCMStore } from '@/stores/RecordGCM';
import { useRecordTarpRepairsStore } from '@/stores/RecordTarpRepairs';
import { useBaseTarpsStore } from '@/stores/BaseTarps';
import BaseTarp from '@/stores/objects/BaseTarp';

import { UNSERVICEABLE_DAMAGE_TYPES } from '@/stores/enums/GCM';

import BaseTarpField from './fields/BaseTarp.vue';
import CompletionTime from './fields/CompletionTime.vue';
import Condition from './fields/Condition.vue';
import NewLength from './fields/NewLength.vue';
import NewWidth from './fields/NewWidth.vue';
import RepairType from './fields/RepairType.vue';

import DropdownField from '@/components/forms/DropdownField.vue';
import FormHeader from '../../components/FormHeader.vue';

import IntegerField from '@/components/forms/IntegerField.vue';
import RadioSelect from '@/components/forms/RadioSelect.vue';
import TextareField from '@/components/forms/TextareaField.vue';
import FormSubmitLoader from '@/site_components/FormSubmitLoader/index.vue';

import TarpAlreadyExistsError from './components/TarpRepairAlreadyExistsError.vue';

import Button from 'primevue/button';
import Card from 'primevue/card';
import Divider from 'primevue/divider';

export default {
  name: "TarpRepairWorksForm",
  components: {
    BaseTarpField,
    CompletionTime,
    Condition,
    NewLength,
    NewWidth,
    RepairType,
    FormHeader,
    TextareField,
    DropdownField,
    FormSubmitLoader,
    RadioSelect,
    IntegerField,
    TarpAlreadyExistsError,
    Button,
    Card,
    Divider,
  },
  created() {
    this.gcmStore = useRecordGCMStore();
    this.store = useRecordTarpRepairsStore();
    this.store.init();

    onBeforeRouteLeave((to, from, next) => {
      this.beforeRouteLeave(to, from, next);
    });
  },
  data() { return {
    loading: false,
    submitted: false,
    localData: null,
  }},
  computed: {
    tarp_repair() {
      return this.store.tarp_repair;
    },
    isUnserviceable() {
      return this.store.getTarpRepair.repair_type?.name.toLowerCase() === 'unserviceable'
    },
    hasHolesRepairedField() {
      return this.store.getTarpRepair.repair_type?.has_holes_repaired_field === true;
    },
    confirmHolesRepaired() {
      return (
        typeof this.tarp_repair.holes_repaired === 'number' &&
        (this.store.getTarpRepair.repair_type?.min_holes_repaired_confirmation || 0) > (this.tarp_repair.holes_repaired || 0)
      );
    },
    activeMaintenanceType() {
      return this.gcmStore.getActiveMaintenanceType;
    },

    unserviceableDamageTypes() {
      return UNSERVICEABLE_DAMAGE_TYPES;
    },
    
    repairAlreadyExistsError() {
      const is400Error = this.store.saveResponse?.status === 400;
      const tarpRepairAlreadyExists = this.store.saveResponse?.data?.base_tarp?.repair_already_exists;
      const newTarpRepairAlreadyExists = this.store.saveResponse?.data?.new_tarp?.repair_already_exists;
      return is400Error && (tarpRepairAlreadyExists || newTarpRepairAlreadyExists);
    },
  },
  methods: {
    async submit() {
      this.store.loading = true;
      this.store.submitted = true;
      try {
        const response = await this.store.saveTarpRepair();
        this.response = response;
      } catch (e) {
        Sentry.captureException(e);
        console.error(e);
      } finally {
        // Store a local copy of the form that we can use to restore the form
        // if the user wants to modify the data. Otherwise, we need to reset the
        // store to clear the form.
        this.localData = this.store.tarp_repair;
        // Reset the store
        this.store.reset(false);
        this.store.loading = false;
      }
    },

    recordAnother() {
      this.store.reset(true);
    },
    reSubmit() {
      this.store.tarp_repair = this.localData;
      this.submit();
    },
    returnToForm() {
      this.store.tarp_repair = this.localData;
      this.store.submitted = false;
    },

    changeSerialNumber() {
      this.localData.base_tarp = null;
      this.localData.new_tarp = null;
      this.returnToForm();
    },

    addZeroToSerialNumber() {
      // First we need to add a '0' to the serial number and
      // see if any tarps exist with that serial number.
      // If they do, update the tarp_repair object with the new tarp id.
      // If they don't, create a new tarp with the new serial number.
      const baseTarpsStore = useBaseTarpsStore();

      let currentTarpData;
      if (this.localData.base_tarp) {
        const baseTarp = baseTarpsStore.getBaseTarpById(this.localData.base_tarp);
        currentTarpData = baseTarp.toJSON();
      } else {
        currentTarpData = this.localData.new_tarp;
      }

      const newSerialNumber = `${currentTarpData.serial_no}0`;
      const existingBaseTarp = baseTarpsStore.getBaseTarpBySerialNo(newSerialNumber);
      if (existingBaseTarp) {
        this.localData.base_tarp = existingBaseTarp.id;
      } else {
        // Create as an object so a client_id is generated.
        const newBaseTarp = new BaseTarp().fromJSON({
          ...currentTarpData,
          serial_no: newSerialNumber,
          id: null,
        });
        this.localData.new_base_tarp = newBaseTarp.toJSON();
        this.localData.base_tarp = null;
        console.log('localData', this.localData);
      }

      this.returnToForm();
    },

    beforeRouteLeave(to, from, next) {
      if (this.tarp_repair.id || this.store.submitted === true) {
        this.store.reset(true);
      }
      next();
    },

  }
}

</script>
