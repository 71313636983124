import { v4 as uuidv4 } from 'uuid';

import { ISOStringToDate, dateToISOString } from "../../utils/dates";
import { useTarpInventoryStore } from '@/stores/TarpInventory';
import { useSitesStore } from '@/stores/Sites';

import StockpilingAPI from '../api/Stockpiling';
import { useRecordStockpilingStore } from '@/stores/RecordStockpiling';
import Tarp from './Tarp';
import Supervisor from './Supervisor';

class Stockpiling {

  id = null;
  client_id = null;
  completion_time = null;
  site = null;
  tarps = []
  tarp_sns = []
  new_tarps = []

  activity_type = 'stockpiling';

  constructor() {
    this.client_id = uuidv4();
    this.tarpInventory = useTarpInventoryStore();
    this.sitesStore = useSitesStore();
  }

  // Adapters

  toJSON() {
    return {
      id: this.id,
      client_id: this.client_id,
      completion_time: dateToISOString(this.completion_time),
      supervisor: this.supervisor?.toJSON(),
      site: this.site?.id,
      tarps: this.tarps.map(tarp => tarp.id),
      tarp_sns: this.tarp_sns,
      new_tarps: this.new_tarps?.map(tarp => tarp.toJSON()),
    }
  }

  fromJSON(data) {
    this.id = data.id;
    this.client_id = data.client_id;
    this.completion_time = ISOStringToDate(data.completion_time);
    this.supervisor = new Supervisor().fromJSON(data.supervisor);
    
    if (data.site) {
      this.site = this.sitesStore.getSiteById(data.site);
    }

    this.tarps = data.tarps.map(tarpId => this.tarpInventory.getTarpById(tarpId));
    // Order tarps by serial number
    this.tarps.sort((a, b) => {
      return a.serial_no - b.serial_no;
    });
    this.tarp_sns = data.tarp_sns;

    this.new_tarps = data.new_tarps?.map(tarpData => {
      const tarp = new Tarp();
      tarp.fromJSON(tarpData);
      return tarp;
    });

    return this
  }

  updateStockpilingInStores(response) {
    // Removes the tarping works from the locally saved tarping works and 
    // adds the updated tarping works to the tarping works store.
    const recordStockpilingStore = useRecordStockpilingStore();
    recordStockpilingStore.removeStockpilingFromLocal(response.data.client_id);
    recordStockpilingStore.addToStockpilingStore(response.data);
  }

  syncToServer = async (data) => {
    if (!data) data = this.toJSON();

    const api = new StockpilingAPI();

    let response;

    try {

      if (this.id) response = await api.updateStockpiling(this.id, data);
      else         response = await api.createStockpiling(data);

      if ([200, 201].includes(response.status)) {
        this.updateStockpilingInStores(response);
      }

      return response;

    } catch (error) {

      //Sentry.captureException(error);
      console.log(error);

      if (error.response) {
        if (error.response.status === 409) {
          // 409 is returned if the tarping works has already been created on the server
          this.updateStockpilingInStores(error.response);
        }
        return error.response;
      } else {
        throw error;
      }
      
    }
  }

  // Getters

  isValid() {
    if (!this.completion_time) return false;
    if (!this.site) return false;
    if (this.tarps.length < 1 && this.new_tarps.length < 1) return false;
    return true;
  }

  displayName() {
    return 'Stockpiling - ' + this.site?.displayName()
  }

  completionTimeDisplay() {
    const completionTime = this.completion_time;
    if (!completionTime) return 'Unknown Completion Time';
    return new Date(completionTime).toLocaleString();
  }

  get tableGlobalFilterValues() {
    return [
      // activity_type
      this.activity_type,
      ...this.tarp_sns,
    ]
  }

}

export default Stockpiling;
