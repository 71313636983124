import { useCommoditiesStore } from '@/stores/Commodities';
import { useStoragesStore } from '@/stores/Storages';


class Stack {

  id = null;
  storage = null;
  season = null;
  name = null;
  commodity = null;
  grades = [];
  data = null;
  archived = false;
  archived_at = null;

  commoditiesStore = null;
  sitesStore = null;

  constructor() {
    this.commoditiesStore = useCommoditiesStore();
    this.storagesStore = useStoragesStore();
  }

  // Adapters

  toJSON() {
    return {
      id: this.id,
      storage: this.storage.id,
      season: this.season,
      name: this.name,
      commodity: this.commodity.id,
      grades: this.grades.map(grade => grade.id),
      archived: this.archived,
      archived_at: this.archived_at,
      data: this.data,
    }
  }

  fromJSON(data) {
    this.id = data.id;
    this.storage = this.storagesStore.getStorageById(data.storage);
    this.season = data.season;
    this.name = data.name;
    this.commodity = this.commoditiesStore.getCommodityById(data.commodity);
    if (data.grades) this.grades = data.grades.map(grade => this.commodity.getGradeById(grade));
    this.archived = data.archived;
    this.archived_at = (data.archived_at) ? new Date(data.archived_at) : null;
    this.data = data.data;
    return this
  }

  displayName() {
    return this.name.toUpperCase()
  }

  fullName() {
    return `${this.storage.site.displayName()}/${this.storage.displayName()}/${this.name.toUpperCase()}`
  }

}

export default Stack
