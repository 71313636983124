const MAINTENANCE_TYPES = [
  { 
    value: 'site_works', 
    label: 'Site by Site Works',
    icon: 'construction',
  },
  {
    value: 'obh_works',
    label: 'OBH by OBH Works',
    icon: 'join',
  },
]

const OBH_WORKS_ACTIVITIES = [
  {
    value: 'nuts_bolts_clamps',
    label: 'Nuts, Bolts & Clamps',
  },
  {
    value: 'ridgecaps_boards',
    label: 'Ridgecaps & Boards',
  },
  {
    value: 'struts_pins',
    label: 'Struts & Pins',
  },
  {
    value: 'tin_sheeting',
    label: 'Tin Sheeting',
  },
  {
    value: 'fumigation_t_piece',
    label: 'Fumigation T-piece',
  },
  {
    value: 'gates',
    label: 'Gates',
  },
]


const OBH_SYSTEMS = [
  {
    value: 'timber_boards',
    label: 'Timber Boards',
  },
  {
    value: 'slider_system',
    label: 'Slider System (Fiberglass Boards)',
  },
  {
    value: 'ias_clamping_system',
    label: 'IAS Clamping System',
  }
]


export {
  MAINTENANCE_TYPES,
  OBH_WORKS_ACTIVITIES,
  OBH_SYSTEMS,
}
