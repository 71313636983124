import { defineStore } from 'pinia';

import { MAINTENANCE_TYPES } from './enums/GCM';

import { useRecordTarpJoinsStore } from "./RecordTarpJoins";
import { useRecordTarpRepairsStore } from "./RecordTarpRepairs";
import { useRecordStockpilingStore } from "./RecordStockpiling";
import { useRecordNewTarpsInputsStore } from "./RecordNewTarpsInputs";


export const useRecordGCMStore = defineStore('recordGCM', {

  state: () => ({
    activeMaintenanceType: null,

    tarp_pole_info: {
      tarps: [],
    },
    swing_summary: {
      startDate: null,
    }
  }),

  actions: {

    init() {
      if (!MAINTENANCE_TYPES.find(maintenanceType => maintenanceType.value === this.activeMaintenanceType)) {
        this.activeMaintenanceType = null;
      }
    },

    setActiveMaintenanceType(maintenanceType) {
      if (!maintenanceType) {
        const store = this.getStore(this.activeMaintenanceType);
        if (store && store.isEditMode) store.reset(true);
      }
      this.activeMaintenanceType = maintenanceType;
    },

    reset() {
      this.tarp_pole_info.tarps = [];
      const store = this.getStore(this.activeMaintenanceType);
      if (store) store.reset(true);
    }

  },

  getters: {

    getActiveMaintenanceType() {
      return MAINTENANCE_TYPES.find(maintenanceType => maintenanceType.value === this.activeMaintenanceType);
    },

    getStore: (state) => (maintenanceType) => {
      const storesMap = {
        tarp_repair: useRecordTarpRepairsStore(),
        tarp_join: useRecordTarpJoinsStore(),
        stockpiling: useRecordStockpilingStore(),
        new_tarps_input: useRecordNewTarpsInputsStore(),
      }
      return storesMap[maintenanceType];
    }

  }
})
