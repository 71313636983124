<template>
  <div class="text-center">
    <p class="icon">
      <span class="material-symbols-outlined xxlg">error</span>
    </p>
    <p class="text-lg font-bold">
        An unexpected error occurred. Please check your internet connection and try again.
        If the problem persists, please contact support.
    </p>

    <div class="mt-3">
      <Button 
        label="Try again" 
        severity="info" 
        size="medium" 
        @click="$emit('tryAgain')"
        raised />
    </div>
    
  </div>
</template>

<script>
import Button from 'primevue/button';

export default {
  name: "UnexpectedError",
  components: {
    Button,
  },
}
</script>

<style scoped>

.icon {
  color: var(--blue-color) !important;
}

</style>