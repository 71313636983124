import { defineStore } from 'pinia';
import { getOrCreateDB, getOrCreateItem, updateStoreLastUpdated } from './idb.js';
import axios from 'axios';

import Stack from '@/stores/objects/Stack';
import StacksAPI from './api/Stacks.js';


export const useStacksStore = defineStore('stacks', {
  state: () => ({ 
    stacks: [],
    lastUpdated: null,
  }),

  actions: {
    async loadStacksFromAPI() {
      // Fetch Stacks from API and update indexedDB
      const api = new StacksAPI();

      const response = await api.getStacks();
      this.stacks = response.data;
      this.updateStacksDB(response.data);

      return response
    },

    async loadStacksFromDB() {
      console.log('Loading stacks from indexedDB...')

      const db = await getOrCreateDB();
      const tx = db.transaction(['stacks', 'meta'], 'readwrite');
      const stacksStore = tx.objectStore('stacks');
      const stacks = await stacksStore.getAll();

      console.log(`${stacks.length} stacks loaded from indexedDB`);

      this.stacks = stacks;
      const stacksMeta = await getOrCreateItem('meta', 'stacks');
      const lastUpdated = stacksMeta.lastUpdated;
      (lastUpdated) ? this.lastUpdated = lastUpdated : this.lastUpdated = null;
      
      await tx.done;
    },

    async loadStacks(awaitAPI) {
      // Load stacks from cache and then update from API in background
      await this.loadStacksFromDB();
      if (navigator.onLine) {
        const res = this.loadStacksFromAPI();
        if (awaitAPI === true) await res;
      }
    },

    async updateStacksDB(data) {
      // Update tarps indexedDB
      const db = await getOrCreateDB();
      const tx = db.transaction(['stacks', 'meta'], 'readwrite');
      const stacksStore = tx.objectStore('stacks');
      // Clear existing data
      await stacksStore.clear();
      // Add data
      data?.forEach(stack => {
        stacksStore.add(stack)
      });
      // Update last updated timestamp
      const now = Date.now();
      this.lastUpdated = now;
      console.log(`Updating last updated timestamp to ${now}`);
      updateStoreLastUpdated('stacks', now, tx);
      await tx.done;
    }
  },

  getters: {

    getStacks(state) {
        return state.stacks.map(stack => new Stack().fromJSON(stack))
    },

    getStackById(state) {
      return (stackId) => {
        const stack = state.stacks.find(stack => stack.id === stackId);
        if (stack) return new Stack().fromJSON(stack);
        else       return null
      }
    },

    getStacksByStorage: (state) => (storageId) => {
      const stacks = state.stacks.filter(stack => stack.storage === storageId);
      return stacks.map(stack => new Stack().fromJSON(stack))
    }

  }
})
