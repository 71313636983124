<template>
  <div>
    <Card class="action-card bg-secondary" :pt="{ body: { class: 'py-0' } }" @click="syncData">
      <template #content>
        <div class="flex justify-content-between align-content-center align-items-center">
          <div>
            <div class="flex align-content-center align-items-center">
                <div>
                  <p class="title text-lg font-medium">Download Site Data</p>
                </div>
            </div>
          </div>
          <div class="sync-btn-wrapper">
            <Button class="sync-btn bg-white text-secondary" severity="secondary" :loading="loading" text raised>
                <template #icon>
                  <span class="material-symbols-outlined">download</span>
                </template>
            </Button>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import Button from 'primevue/button';
import Card from 'primevue/card';

import { loadAppData } from '@/utils/data';

export default {
    name: "DownloadSiteDataCard",
    components: {
        Button,
        Card,
    },
    data() { return {
        loading: false,
    }},
    methods: {
        async syncData() {
            if (!navigator.onLine) {
                this.$root.noInternetConnectionDetected();
            }
            // Prevent multiple syncs
            if (this.loading) return;

            this.loading = true;

            // Sync sites, tarping works, tarp inventory and stacks
            try {
                await loadAppData(true);
            } catch (error) {
                this.$root.showNotification(
                    'error',
                    'Faild to sync data.',
                );
                return;
            } finally {
                this.loading = false;
            }
            
            this.$root.showNotification(
                'success',
                'Data synced successfully.',
            );
        }
    }
}

</script>

<style scoped>

.icon {
    border-radius: 100%;
    height: 60px;
    width: 60px;
    background: #ededed;
}

.sync-btn-wrapper {
  background: white;
  border-radius: 100%;
}
.sync-btn {
    border-radius: 100%;
    width: 60px;
    height: 60px;
    background-color: rgba(255, 255, 255, 1) !important;
}

</style>