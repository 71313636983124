<template>
  <div class="text-center">
    <p class="icon"><span class="material-symbols-outlined xxlg">cloud_done</span></p>
    <p class="text-lg font-bold">
        Tarping works successfully recorded.
    </p>

    <div class="mt-3">
      <Button 
        label="Record another" 
        severity="info" 
        size="medium" 
        @click="$emit('recordAnother')"
        raised />
    </div>
    
  </div>
</template>

<script>
import Button from 'primevue/button';

export default {
  name: "CreatedMsg",
  components: {
    Button,
  },

}
</script>

<style scoped>

.icon {
  color: var(--green-color) !important;
}

</style>