<template>
  <div v-if="showWarning">
    <Message 
        severity="warn"
        :closable="false">
        This value is greater than the OBH length ({{ storageLength }}m).
    </Message>
  </div>
</template>

<script>
import Message from 'primevue/message';

export default {
  name: "StorageLengthWarning",
  props: {
    storageLength: {
      type: Number,
      required: true,
    },
    value: {
      type: Number,
    },
  },
  components: {
    Message,
  },
  computed: {
    showWarning() {
      return this.value > this.storageLength;
    },
  },
}

</script>