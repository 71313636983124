import TarpingReportsAPI from "../api/TarpingReports";
import { getOrCreateDB } from '../idb.js';

class GeneratedReport {

    id = null;
    object_key = null;
    created = null;

    constructor() {
      this.api = new TarpingReportsAPI()
    } 
  
    // Adapters
  
    toJSON() {
      return {
        id: this.id,
        object_key: this.object_key,
        created: this.created.toISOString(),
      }
    }
  
    fromJSON(data) {
      this.id = data.id;
      this.object_key = data.object_key;
      this.created = new Date(data.created);
      return this
    }
  
    // Actions

    addToDatabase = async (blob) => {
      const db = await getOrCreateDB();
      const tx = db.transaction(['tarpingReports'], 'readwrite');
      const store = tx.objectStore('tarpingReports');
      store.add({id: this.id, blob: blob});
      await tx.done;
      console.log("Report saved to device...")
    }

    openReportInPreview(blob) {
      const pdfBlob = new Blob([blob], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(pdfBlob);
      
      // create <a> tag dynamically
      const fileLink = document.createElement('a');
      fileLink.href = url;

      // it forces the name of the downloaded file
      fileLink.download = this.object_key.split('/').pop();

      // triggers the click event
      fileLink.click();
    }

    openReport = async () => {
      // Retrieve the report from the idb storage and open it in a new window as an object url
      const db = await getOrCreateDB();
      const tx = db.transaction(['tarpingReports'], 'readwrite');
      const store = tx.objectStore('tarpingReports');

      let report = await store.get(this.id);
      
      let blob;
      if (!report) {
        blob = await this.downloadReport();
      } else {
        blob = report.blob;
      }
      this.openReportInPreview(blob);
    }
  
    downloadReport = async () => {
      const signedUrlResponse = await this.api.getSignedUrl(this.id);
      const signedUrl = signedUrlResponse.data.url;
      const response = await this.api.downloadTarpingReport(signedUrl);
      this.addToDatabase(response.data);
      return response.data
    }
  
  }
  
  export default GeneratedReport
  