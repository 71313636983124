<template>
  <div>
    <Field 
      label="Progress Records"
      subtitle="Create progress records with activities that are complete to the same point on the OBH">
      <div v-for="(record, index) in records" :key="record.id" class="pt-1 pb-1">
        <PartialProgressRecordDetails 
          :record="record" 
          :index="index"
          @editRecord="editRecord"
          @deleteRecord="deleteRecord" 
        />
      </div>

      <div class="flex justify-center p-fluid py-2">
        <Button label="Add Progress Record" severity="info" @click="addRecord" />
      </div>
    </Field>

    <PartialProgressRecordModal 
      :visible="progressRecordModalActive"
      v-model="activeRecord"
      @saveRecord="saveRecord"
      @close="closeProgressRecordModal"
    />
  </div>
</template>

<script>
import { useRecordOBHWorksStore } from '@/stores/RecordOBHWorks';
import { OBHWorksPartialProgressRecord } from '@/stores/objects/OBHWorks';

import Field from '@/components/forms/Field.vue';
import PartialProgressRecordDetails from './components/PartialProgressRecordDetails.vue';
import PartialProgressRecordModal from './components/PartialProgressRecordModal/index.vue';

import Button from 'primevue/button';

export default {
  name: "PartialProgressRecords",
  props: [],
  components: {
    Field,
    PartialProgressRecordDetails,
    PartialProgressRecordModal,
    Button,
  },
  created() {
    this.store = useRecordOBHWorksStore();
  },
  data() { return {
    activeRecord: new OBHWorksPartialProgressRecord().toJSON(),
    activeRecordIndex: null,
    progressRecordModalActive: false,
  }},
  computed: {
    form() {
      return this.store.obh_works;
    },
    records () {
      return this.form.partial_progress_records.map(record => new OBHWorksPartialProgressRecord().fromJSON(record));
    },
  },
  
  methods: {
    resetActiveRecord() {
      this.activeRecord = new OBHWorksPartialProgressRecord().toJSON();
      this.activeRecordIndex = null;
    },

    saveRecord(data) {
      if (this.activeRecordIndex === null) {
        this.store.addPartialProgressRecord(data);
      } else {
        this.store.updatePartialProgressRecord(this.activeRecordIndex, data);
      }
      this.resetActiveRecord();
    },
    addRecord() {
      this.activeRecord = new OBHWorksPartialProgressRecord().toJSON();
      this.activeRecordIndex = null;
      this.openProgressRecordModal();
    },
    editRecord(index) {
      this.activeRecord = this.records[index].toJSON();
      this.activeRecordIndex = index;
      this.openProgressRecordModal();
    },
    deleteRecord(index) {
      this.store.deletePartialProgressRecord(index);
    },
    openProgressRecordModal() {
      this.progressRecordModalActive = true;
    },
    closeProgressRecordModal() {
      this.activeRecord = null;
      this.activeRecordIndex = null;
      this.progressRecordModalActive = false;
    },
  },
}

</script>
