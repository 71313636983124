<template>
  <div class="field p-fluid">
    <label for="sp-completion-time">{{ label }}</label>
    <p class="subtitle">
      {{ subtitle }}
    </p>
    <DatetimePicker inputId="sp-completion-time" :modelValue="modelValue" @update:modelValue="update" />
  </div>
</template>

<script>
import DatetimePicker from '@/components/forms/DatetimePicker.vue';
import Message from 'primevue/message';

export default {
  name: "CompletionTime",
  components: {
    DatetimePicker,
    Message
  },
  props: ['label', 'subtitle', 'modelValue'],
  created() {
    if (!this.modelValue) {
      this.update(new Date());
    }
  },
  methods: {
    update(val) {
      this.$emit('update:modelValue', val);
    }
  }
}

</script>