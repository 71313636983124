<template>
  <div>
    <Card class="border-1 surface-border">
      <template #title>
        <div class="flex justify-content-between align-items-center">
          <label class="label mb-0">{{ tarp.serial_no }}</label>
          <Button severity="danger" size="small" text @click="removeTarp">
            <template #icon>
              <span class="material-symbols-outlined">delete</span>
            </template>
          </Button>
        </div>
      </template>
      <template #content>
        <KeyValueList :items="tarpPoleInfo" />
      </template>
    </Card>
  </div>
</template>

<script>
import { useBaseTarpsStore } from '@/stores/BaseTarps';

import KeyValueList from '@/components/data/KeyValueList.vue';

import Button from 'primevue/button';
import Card from 'primevue/card';

export default {
  name: "TarpPoleInfoCard",
  components: {
    KeyValueList,
    Button,
    Card,
  },
  props: {
    tarp: {
      type: Object,
      required: true,
    },
  },

  computed: {

    tapeColor() {
      const tarp = this.tarp;
      const length = tarp.length;
      const coverTypes = tarp.cover_type?.toLowerCase().split('/');

      let color;
      if (coverTypes.length === 1 && coverTypes.includes('end')) color = 'white';
      else if (coverTypes.includes('end') && coverTypes.includes('straight')) color = 'silver';
      else if (length > 20) color = 'yellow';
      else if (length > 16) color = 'green';
      else if (length > 12) color = 'blue';
      else color = 'red';

      const colorClassMap = {
        white: 'bg-white',
        silver: 'surface-300',
        yellow: 'bg-yellow-400',
        green: 'bg-green-500',
        blue: 'bg-blue-500',
        red: 'bg-red-500',
      }

      return [color, colorClassMap[color] + ' px-1 capitalize'];
    },
    condition() {
      const conditions = this.tarp.condition?.split('/') || [];
      const intConditions = conditions.filter(c => Number.isInteger(parseInt(c))).map(c => parseInt(c));
      
      if (intConditions.length === 0) return '(Unknown)';

      const minCondition = Math.min(...intConditions);
      const joinType = this.tarp.join_type.split('/')[0].toLowerCase();
      
      const joinTypeMap = {
        sew: 'S',
        weld: 'W',
      }

      if (!joinType) return minCondition;

      return `${joinTypeMap[joinType]||'?'}${minCondition}`;
    },
    dimensions() {
      const coverTypes = this.tarp.cover_type?.toLowerCase().split('/');

      const _length = parseFloat(this.tarp.length.toString());
      const _width = parseFloat(this.tarp.width.toString());

      const length = Number.isInteger(_length) ? _length : _length.toFixed(1);
      const width = Number.isInteger(_width) ? _width : _width.toFixed(1);

      if (coverTypes[0] === 'end' && coverTypes.includes('straight')) {
        const baseTarpsStore = useBaseTarpsStore();
        const endBaseTarp = baseTarpsStore.getBaseTarpBySerialNo(this.tarp.serial_no.split('/')[0]);
        
        const _endLength = parseFloat(endBaseTarp.length.toString());
        const _straightLength = length - _endLength;
        const endLength = Number.isInteger(_endLength) ? _endLength : _endLength.toFixed(1);
        const straightLength = Number.isInteger(_straightLength) ? _straightLength : _straightLength.toFixed(1);

        return `${width} x E${endLength}+${straightLength}`;
      } else if (coverTypes[0] === 'end') {
        return `${width} x ${length}E`;
      } else {
        return `${width} x ${length}`;
      }
    },

    tarpPoleInfo() {
      const info = [
        {
          label: 'Tape Color',
          value: this.tapeColor[0],
          bgClass: this.tapeColor[1],
        },
        {
          label: 'Tarps',
          value: this.tarp.serial_no.split('/'),
        },
        {
          label: 'Condition',
          value: this.condition,
        },
        {
          label: 'YoM',
          value: new Date().getFullYear(),
        },
        {
          label: 'Dimensions',
          value: this.dimensions,
        }
      ]
      return info;
    }

  },

  methods: {
    removeTarp() {
      this.$emit('removeTarp', this.tarp);
    }
  }
}

</script>