<template>
  <div>
    <KeyValueList :items="fields" :striped="true" />
    <div v-if="isEditable" class="mt-4">
      <Button class="w-full btn"
        :label="editBtnLabel" 
        severity="info" 
        size="medium" 
        @click="edit" />
    </div>
  </div>
</template>

<script>
import Button from 'primevue/button';

import KeyValueList from '@/components/data/KeyValueList.vue';
import { toTitleCase } from '@/utils/strings';
import { useRecordGCMStore } from '@/stores/RecordGCM';
import { useRecordTarpRepairsStore } from '@/stores/RecordTarpRepairs';
import { useRecordTarpJoinsStore } from '@/stores/RecordTarpJoins';

export default {
  name: 'TarpingWorkDetails',
  components: {
    Button,
    KeyValueList,
  },
  inject: ['dialogRef'],
  created() {
    this.gcmWorks = this.dialogRef.data.gcmWorks
  },
  data () { return {
    gcmWorks: null,
  }},
  computed: {
    fields() {
      const gcm = this.gcmWorks;

      const fields = [
          { label: 'Completion Time', value: gcm.completionTimeDisplay() },
          { label: 'Supervisor', value: gcm.supervisor.displayName() },
      ]

      if (gcm.activity_type === 'tarp_repair') {
        fields.push({ label: 'Repair Type', value: gcm.repair_type.name });
        fields.push({ label: 'New Length (m)', value: gcm.new_length });
        fields.push({ label: 'New Width (m)', value: gcm.new_width });
        fields.push({ label: 'Condition', value: gcm.condition });
        fields.push({ label: 'Holes Repaired', value: gcm.holes_repaired || '-' });
      }

      if (gcm.activity_type === 'tarp_join') {
        fields.push({ label: 'Join Type', value: toTitleCase(gcm.join_type) });
      }

      if (gcm.activity_type === 'stockpiling') {
        const tarps = gcm.tarp_sns.join(', ');
        fields.push({ label: 'Tarps', value: tarps });
      }

      if (gcm.activity_type === 'new_tarps_input') {
        fields.push({ label: 'Tarps', value: gcm.tarps.map(t => t.serial_no) });
      }

      return fields
    },
    isEditable() {
      return ['tarp_repair', 'tarp_join'].includes(this.gcmWorks.activity_type);
    },
    editBtnLabel() {
      return this.gcmWorks.activity_type === 'tarp_repair' ? 'Edit Repair' : 'Edit Join';
    }
  },
  methods: {
    edit() {
      const recordGCMStore = useRecordGCMStore();
      const stores = {
        tarp_repair: useRecordTarpRepairsStore(),
        tarp_join: useRecordTarpJoinsStore(),
      }
      
      const store = stores[this.gcmWorks.activity_type];
      store.$patch({ [this.gcmWorks.activity_type]: this.gcmWorks.toJSON() } );
      recordGCMStore.setActiveMaintenanceType(this.gcmWorks.activity_type);

      this.$router.push({
        name: 'gcm_record',
      });
      this.dialogRef.close();
    }
  
  }
}
</script>

<style scoped>
.gcm-attr-list {
  list-style: none;
  padding: 0;
  margin-left: -6px;
  margin-right: -6px;
}
.gcm-attr-list li {
  display: flex;
  justify-content: space-between;
  padding: 6px;
}
.gcm-attr-list li:nth-child(odd) {
  background-color: #f2f2f2;
}
.gcm-attr-list li .attr {
  font-weight: bold;
  width: 155px;
  flex-shrink: 0;
}
.gcm-attr-list li .value {
  flex-grow: 1;
  text-align: left;
}
</style>
