<template>
  <div v-if="viewReady" class="view flex flex-column">
    <div class="bg-white p-3 pb-5 flex-grow-1">
      <FormSteps v-if="activeStep !== 3" />
      <StackProfileSetup v-show="activeStep === 0" />
      <SiteInputs v-show="activeStep === 1" />
      <StackCloseOut v-show="activeStep === 2" />
      <SubmitRecord v-if="activeStep === 3" />
    </div>
  </div>
</template>

<script>
import { useRecordTarpingWorksStore } from '@/stores/RecordTarpingWorks';

import Button from 'primevue/button';
import Divider from 'primevue/divider';

import SiteInputs from './steps/SiteInputs/index.vue';
import StackCloseOut from './steps/StackCloseOut/index.vue';
import StackProfileSetup from './steps/StackProfileSetup/index.vue';
import SubmitRecord from './steps/SubmitRecord/index.vue';
import FormSteps from './components/FormSteps.vue';

export default {
  name: "TarpingRecordView",
  components: {
    Button,
    Divider,
    FormSteps,
    SiteInputs,
    StackCloseOut,
    StackProfileSetup,
    SubmitRecord,
  },
  data() { return {
    viewReady: true,
    loading: false,
    store: null,
  }},
  computed: {
    activeStep() {
      return this.store.activeStep;
    }
  },
  async created() {
    this.store = useRecordTarpingWorksStore();

    if (this.store.activeStep === 3) {
      this.store.goToStep(0);
    }
  },
}

</script>