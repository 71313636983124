<template>
  <div class="view flex flex-column">
    <OBHMWorksTable />
  </div>
</template>

<script>
import OBHMWorksTable from './components/OBHMWorksTable.vue';

export default {
  name: "OBHMHistoricalView",
  components: {
    OBHMWorksTable,
  },
}
</script>

<style scoped>
.view {
  height: calc(100vh - 110px);;
  overflow: hidden;
}
</style>