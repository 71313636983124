import { default as axios } from './http';


class TarpJoinUsersAPI {

    getTarpJoinUsers = async () => {
        // Fetch stacks from API
        console.log('Fetching tarp join users from API...')
        const url = '/api/v1/gcm/tarp-joins/users/';
        const response = await axios.get(url)
        return response
    }

}

export default TarpJoinUsersAPI;