<template>
  <div>
    <h3 class="font-bold mb-3">Unsaved GCM Works</h3>
    
    <div v-if="unsavedGCMWorks.length === 0">
      <p class="text-gray-500 mt-1">No unsaved GCM works.</p>
    </div>
    <div v-else>
      <div v-for="GCMWorks in unsavedGCMWorks" :key="GCMWorks.client_id" class="mb-2">
        <UnsavedGCMWorksCard :GCMWorks="GCMWorks" />
      </div>
    </div>
  </div>

</template>

<script>
import Button from 'primevue/button';
import Card from 'primevue/card';

import { useRecordTarpRepairsStore } from '@/stores/RecordTarpRepairs';
import { useRecordTarpJoinsStore } from '@/stores/RecordTarpJoins';
import { useRecordStockpilingStore } from '@/stores/RecordStockpiling';
import { useRecordNewTarpsInputsStore } from '@/stores/RecordNewTarpsInputs';

import UnsavedGCMWorksCard from './components/UnsavedGCMWorksCard.vue';

export default {
  name: "UnsavedGCMWorks",
  components: {
    Button,
    Card,
    UnsavedGCMWorksCard,
  },
  data() { return {
    loading: false
  }},
  created() {
    this.recordTarpRepairsStore = useRecordTarpRepairsStore();
    this.recordTarpJoinsStore = useRecordTarpJoinsStore();
    this.recordStockpilingStore = useRecordStockpilingStore();
    this.recordNewTarpsInputsStore = useRecordNewTarpsInputsStore();
  },
  computed: {
    unsavedGCMWorks() {
      
      const unsavedTarpRepairs = this.recordTarpRepairsStore.getUnsavedTarpRepairs;
      const unsavedTarpJoins = this.recordTarpJoinsStore.getUnsavedTarpJoins;
      const unsavedStockpiling = this.recordStockpilingStore.getUnsavedStockpiling;
      const unsavedNewTarpsInputs = this.recordNewTarpsInputsStore.getUnsavedNewTarpsInputs

      const GCMWorks = [
        ...unsavedTarpRepairs,
        ...unsavedTarpJoins,
        ...unsavedStockpiling,
        ...unsavedNewTarpsInputs,
      ];
      
      return GCMWorks.sort((a, b) => {
        return b.completion_time - a.completion_time;
      });
    },
  },
}
</script>