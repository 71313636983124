function replaceUnderscoresAndTitleCase(str) {
    // Replace all underscores with spaces
    var replacedStr = str?.replace(/_/g, ' ') || '';

    return toTitleCase(replacedStr)
  }

  function toTitleCase(str) {
    // Convert to title case
    var titleCaseStr = str.toLowerCase().split(' ').map(function(word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ');
  
    return titleCaseStr;
  }

  export { replaceUnderscoresAndTitleCase, toTitleCase }
