<template>
    <form v-if="componentReady">
        <div class="field">
            <label for="commodity">Select commodity of new stack</label>
            <Dropdown
                class="w-full md:w-14rem"
                placeholder="Select a commodity"
                optionLabel="display_name"
                v-model="commodity"
                :options="commodities"
                emptyMessage="No commodities"
                scrollHeight="flex">
            </Dropdown>
        </div>

        <div class="field p-fluid">
            <Divider />
            <Button 
                label="Create Stack" 
                severity="secondary" 
                size="large" 
                :disabled="!commodity" 
                @click="createStack"
                raised />
        </div>

    </form>
</template>

<script>
import { useCommoditiesStore } from '@/stores/Commodities';

import Button from 'primevue/button';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';

export default {
    name: "NewStackForm",
    components: {
        Button,
        Divider,
        Dropdown,
    },
    inject: ['dialogRef'],
    data() { return {
        componentReady: false,
        store: null,
        commodity: null,
    }},
    computed: {
        commodities() {
            return this.store.commodities;
        },
    },
    async created() {
        const commoditiesStore = useCommoditiesStore();
        await commoditiesStore.loadCommoditiesFromDB();

        this.store = commoditiesStore;
        if (this.commodities.length === 0 && navigator.onLine) {
            await commoditiesStore.loadCommoditiesFromAPI();
        }
        this.componentReady = true;
    },
    methods: {
        createStack() {
            this.$emit('create', this.commodity.id);
            this.dialogRef.close();
        }
    }
}

</script>