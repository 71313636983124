<template>
  <div>
    <div class="field p-fluid">
      <Field label="Are there any maintained tarps still on site?">
        <RadioSelect v-model="form.tarps_on_site_bool" :value="true" label="Yes" />
        <RadioSelect v-model="form.tarps_on_site_bool" :value="false" label="No" class="mt-2" />
      </Field>
    </div>
    <div v-if="form.tarps_on_site_bool === true">
      <Divider />
      <TarpsSelectField
        :modelValue="tarps"
        @update:modelValue="updateTarps"
        :multiple="true"
        :generateSerialNumber="false"
        label="Maintained Tarps On Site"
      />
    </div>

  </div>
</template>

<script>
import { useRecordSiteWorksStore } from '@/stores/RecordSiteWorks';
import { useRecordNewTarpStore } from '@/stores/RecordNewTarp';

import SelectTarpsMenu from '@/site_components/SelectTarpsMenu/index.vue';
import TarpListItem from '@/site_components/SelectTarpsMenu/TarpListItem.vue';
import NewTarpForm from '@/site_components/NewTarpForm/index.vue';

import SelectTarpsModal from '@/site_components/SelectTarpsModal/index.vue';
import NewTarpModal from '@/site_components/NewTarpModal/index.vue';
import TarpsSelectField from '@/site_components/TarpsSelectField/index.vue';

import RadioSelect from '@/components/forms/RadioSelect.vue';
import Field from '@/components/forms/Field.vue';
import Divider from 'primevue/divider';

import Button from 'primevue/button';

export default {
  name: "Tarps",
  components: {
    SelectTarpsMenu,
    TarpListItem,
    NewTarpForm,
    SelectTarpsModal,
    TarpsSelectField,
    NewTarpModal,
    RadioSelect,
    Field,
    Divider,
    Button,
  },
  data() { return {
    newTarpModalVisible: false,
    selectTarpsModalVisible: false,

    // Store selected tarps when a user elects to create new tarp
    selectedTarps: null,
  }},
  computed: {
    form() {
      return this.store.site_works;
    },
    tarps() {
      const tarps = this.store.getSiteWorks?.tarps_on_site;
      const new_tarps = this.store.getSiteWorks?.new_tarps;
      return tarps.concat(new_tarps).sort((a, b) => a.serial_no - b.serial_no);
    },
  },
  created() {
    this.store = useRecordSiteWorksStore();
    this.recordNewTarpStore = useRecordNewTarpStore();
  },
  methods: {
    updateTarps(tarps) {
      // Seperate tarps into existing tarps and newly created tarps
      const existingTarps = tarps.filter(tarp => tarp.id);
      const newTarps = tarps.filter(tarp => !tarp.id);
      this.form.tarps_on_site = existingTarps.map(tarp => tarp.id);
      this.form.new_tarps = newTarps;
    }
  }
}

</script>

<style scoped>
.selected-tarps-list {
  margin: 0.5rem -0.5rem;
}
</style>