import { defineStore } from 'pinia';
import { getOrCreateDB, getOrCreateItem, updateStoreLastUpdated } from './idb.js';

import TarpRepairType from '@/stores/objects/TarpRepairType';
import TarpRepairTypesAPI from './api/TarpRepairTypes.js';


export const useTarpRepairTypesStore = defineStore('tarpRepairTypes', {
  state: () => ({ 
    tarp_repair_types: [],
    lastUpdated: null,
  }),

  actions: {
    
    async loadTarpRepairTypesFromAPI() {
      // Fetch commodities from API and update indexedDB
      const api = new TarpRepairTypesAPI();
      const response = await api.getTarpRepairTypes();

      const data = response.data;
      this.tarp_repair_types = data;
      this.updateTarpRepairTypesDB(data);

      return response
    },

    async loadTarpRepairTypesFromDB() {
      console.log('Loading tarp repair types from indexedDB...')
      const db = await getOrCreateDB();
      const tx = db.transaction(['tarpRepairTypes', 'meta'], 'readwrite');
      const tarpRepairTypesStore = tx.objectStore('tarpRepairTypes');
      const tarpRepairTypes = await tarpRepairTypesStore.getAll();
      console.log(`${tarpRepairTypes.length} tarp repair types loaded from indexedDB`);
      this.tarp_repair_types = tarpRepairTypes;

      const tarpRepairTypesMeta = await getOrCreateItem('meta', 'tarpRepairTypes');
      const lastUpdated = tarpRepairTypesMeta.lastUpdated;
      (lastUpdated) ? this.lastUpdated = lastUpdated : this.lastUpdated = null;
      
      await tx.done;
    },

    async loadTarpRepairTypes(awaitAPI) {
      // Load tarp repair types from cache and then update from API in background
      await this.loadTarpRepairTypesFromDB();
      if (navigator.onLine) {
        const req = this.loadTarpRepairTypesFromAPI();
        if (awaitAPI === true) await req;
      }
    },

    async updateTarpRepairTypesDB(data) {
      // Update tarps indexedDB
      const db = await getOrCreateDB();
      const tx = db.transaction(['tarpRepairTypes', 'meta'], 'readwrite');
      const tarpRepairTypesStore = tx.objectStore('tarpRepairTypes');
      // Clear existing data
      await tarpRepairTypesStore.clear();
      // Add data
      data?.forEach(tarpRepairType => {
        tarpRepairTypesStore.add(tarpRepairType)
      });
      // Update last updated timestamp
      const now = Date.now();
      this.lastUpdated = now;
      console.log(`Updating last updated timestamp to ${now}`);
      updateStoreLastUpdated('tarpRepairTypes', now, tx);
      await tx.done;
    },
  },

  getters: {

    getTarpRepairTypes(state) {
      return state.tarp_repair_types.map(tarpRepairType => new TarpRepairType().fromJSON(tarpRepairType));
    },

    getTarpRepairTypeById: (state) => (id) => {
      return new TarpRepairType().fromJSON(state.tarp_repair_types.find(tarpRepairType => tarpRepairType.id === id));
    }

  }

})