<template>
  <div>
    <form v-show="!store.submitted" @submit.prevent="submit" novalidate>
      <FormHeader />
      <Divider />
      <JoinType />
      <Divider />
      <BaseTarps />
      <Divider />
      <DropdownField
        v-model="tarp_join.lead_welder_stitcher"
        label="Lead Welder/Stitcher"
        :options="weldersStichers"
        optionLabel="displayName"
        optionValue="id"
        placeholder="Select a lead welder/stitcher..." />
      <Divider />
      <DropdownField
        v-model="tarp_join.quality_controller"
        label="Quality Controller"
        :options="qualityControllers"
        optionLabel="displayName"
        optionValue="id"
        placeholder="Select a quality controller..." />
      <Divider />
      <TextareaField
        label="Comments"
        v-model="tarp_join.comments" />
      <Divider />
      <div class="mt-3">
        <label class="label">Confirmation required</label>
        <p class="subtitle">This join must adhere to the following standards:</p>
        <ul class="mt-1">
          <li>There are no pleats in the weld or stitch</li>
          <li>The stitch is locked every 10m</li>
          <li>The weld/stitch is physically checked and ticked every 10m</li>
          <li>“Cut here” is written on the weld 3m from both ends of the weld</li>
        </ul>
        <div class="mt-3">
          <RadioSelect
            v-model="tarp_join.confirm_standards_followed"
            :value="true"
            label="Yes, I confirm that this join adheres to these standards"
            :binary="true" />
        </div>
      </div>
      <Divider />
      <div class="field p-fluid">
        <Button label="Submit" severity="secondary" size="large" :disabled="!store.inputsValid" type="submit" raised />
      </div>
    </form>
    <FormSubmitLoader 
      v-if="store.submitted"
      :loading="store.loading"
      :submitComplete="store.submitComplete"
      :status="responseStatus"
      :errors="responseErrors"
      @recordAnother="recordAnother"
      @returnToForm="returnToForm"
      @reSubmitForm="reSubmit"
    />
  </div>
</template>

<script>
import * as Sentry from "@sentry/vue";

import { onBeforeRouteLeave } from 'vue-router';

import { useRecordGCMStore } from '@/stores/RecordGCM';
import { useRecordTarpJoinsStore } from '@/stores/RecordTarpJoins';
import { useTarpJoinUsersStore } from '@/stores/TarpJoinUsers';

import FormHeader from '../../components/FormHeader.vue';

import BaseTarps from './fields/BaseTarps.vue';
import JoinType from './fields/JoinType.vue';
import TarpJoinWidget from './fields/TarpJoinsWidget/index.vue';

import CompletionTime from '@/components/forms/CompletionTime.vue';
import DropdownField from '@/components/forms/DropdownField.vue';
import RadioSelect from '@/components/forms/RadioSelect.vue';
import TextareaField from '@/components/forms/TextareaField.vue';

import FormSubmitLoader from '@/site_components/FormSubmitLoader/index.vue';

import Button from 'primevue/button';
import Card from 'primevue/card';
import Divider from 'primevue/divider';

export default {
  name: "TarpJoinsForm",
  components: {
    FormHeader,
    FormSubmitLoader,
    BaseTarps,
    CompletionTime,
    JoinType,
    TarpJoinWidget,
    DropdownField,
    RadioSelect,
    TextareaField,
    Button,
    Card,
    Divider,
  },
  created() {
    this.gcmStore = useRecordGCMStore();
    this.tarpJoinUsersStore = useTarpJoinUsersStore();
    this.store = useRecordTarpJoinsStore();
    this.store.init();

    onBeforeRouteLeave((to, from, next) => {
      this.beforeRouteLeave(to, from, next);
    });
  },
  computed: {
    tarp_join() {
      return this.store.tarp_join;
    },
    responseStatus() {
      return this.store.saveResponse?.status;
    },
    responseErrors() {
      return this.store.getErrors;
    },

    weldersStichers() {
      return this.tarpJoinUsersStore.getWeldersStitchers.map(
        user => ({ id: user.id, displayName: user.displayName() })
      );
    },
    qualityControllers() {
      return this.tarpJoinUsersStore.getQualityControllers.map(
        user => ({ id: user.id, displayName: user.displayName() })
      );
    },
  },
  methods: {
    async submit() {
      this.store.loading = true;
      this.store.submitComplete = false;
      this.store.submitted = true;

      try {
        await this.store.saveTarpJoin();
      } catch (e) {
        Sentry.captureException(e);
        console.error(e);
      } finally {
        // Store a local copy of the form that we can use to restore the form
        // if the user wants to modify the data. Otherwise, we need to reset the
        // store to clear the form.
        this.localData = this.store.tarp_join;
        // Reset the store
        this.store.reset(false);
        this.store.loading = false;
        this.store.submitComplete = true;
      }
    },

    recordAnother() {
      this.store.reset(true);
    },
    reSubmit() {
      this.store.tarp_join = this.localData;
      this.submit();
    },
    returnToForm() {
      this.store.tarp_join = this.localData;
      this.store.submitted = false;
    },

    beforeRouteLeave(to, from, next) {
      if (this.tarp_join.id || this.store.submitted === true) {
        this.store.reset(true);
      }
      next();
    },
  }
}

</script>