import { defineStore } from 'pinia';
import { getOrCreateItem, getItems, updateStoreLastUpdated, clearStore, addItems, saveItem } from './idb.js';
import { isSameOrAfterDate, isSameOrBeforeDate } from '../utils/dates.js';

import TarpRepair from './objects/TarpRepair.js';
import TarpRepairsAPI from './api/TarpRepairs.js';


export const useTarpRepairsStore = defineStore('tarpRepairs', {
  state: () => ({ 
    tarpRepairs: [],
    lastUpdated: null,
  }),

  actions: {
    async loadTarpRepairsFromAPI() {
      // Fetch tarps from API and update indexedDB
      const api = new TarpRepairsAPI();

      try {
        const response = await api.getTarpRepairs();
        const data = response.data;
        this.tarpRepairs = data;
        this.updateTarpRepairsDB(data);
        return response
      } catch (error) {
        console.error(error);
      }
    },

    async loadTarpRepairsFromDB() {
      console.log('Loading tarp works from indexedDB...')
      const tarpRepairs = await getItems('tarpRepairs');
      this.tarpRepairs = tarpRepairs;

      const tarpRepairsMeta = await getOrCreateItem('meta', 'tarpRepairs');
      const lastUpdated = tarpRepairsMeta.lastUpdated;
      (lastUpdated) ? this.lastUpdated = lastUpdated : this.lastUpdated = null;
    },

    async loadTarpRepairs(awaitAPI) {
      // Load tarps from cache and then update from API in background
      await this.loadTarpRepairsFromDB();
      if (navigator.onLine) {
        const response = this.loadTarpRepairsFromAPI();
        if (awaitAPI) await response;
      }
    },

    async updateTarpRepairsDB(data) {
      // Clears and repopulates tarp works in indexedDB
      clearStore('tarpRepairs');

      // Add data
      addItems('tarpRepairs', data);

      // Update last updated timestamp
      const now = Date.now();
      this.lastUpdated = now;
      updateStoreLastUpdated('tarpRepairs', now);
    },

    async addTarpRepair(tarpRepair) {
      saveItem('tarpRepairs', tarpRepair.id, tarpRepair);
      this.tarpRepairs = [
        ...this.tarpRepairs.filter(tr => tr.client_id !== tarpRepair.client_id),
        tarpRepair
      ];
    }

  },

  getters: {

    getTarpRepairs(state) {
      // Return all tarp works, ordered by completion time (newest first)
      const sortedTarpRepairs = state.tarpRepairs.sort((a, b) => {
        return new Date(b.completion_time) - new Date(a.completion_time);
      });
      return sortedTarpRepairs.map(tarpRepair => new TarpRepair().fromJSON(tarpRepair));
    },

    getTarpRepairsForDateRange(state) {
      return (dateStart, dateEnd) => {
        return state.tarpRepairs.filter(tarpRepair => {

          const completionTime = new Date(tarpRepair.completion_time);
          return isSameOrAfterDate(completionTime, dateStart) && isSameOrBeforeDate(completionTime, dateEnd);

        }).map(tarpRepair => new TarpRepair().fromJSON(tarpRepair));
      }
    }
    
  },

})
