import { default as axios } from './http';


class StoragesAPI {

    getStorages = async () => {
        // Fetch stacks from API
        console.log('Fetching storages from API...')
        const url = '/api/v1/grain-sites/storages/';
        const response = await axios.get(url)
        return response
    }

}

export default StoragesAPI;