<template>
  <div>
    <h3 class="font-bold mb-3">Unsaved OBHM Works</h3>
    
    <div v-if="unsavedOBHMWorks.length === 0">
      <p class="text-gray-500 mt-1">No unsaved OBHM works.</p>
    </div>
    <div v-else>
      <div v-for="OBHMWorks in unsavedOBHMWorks" :key="OBHMWorks.client_id" class="mb-2">
        <UnsavedOBHMWorksCard :OBHMWorks="OBHMWorks" />
      </div>
    </div>
  </div>

</template>

<script>
import Button from 'primevue/button';
import Card from 'primevue/card';

import { useRecordSiteWorksStore } from '@/stores/RecordSiteWorks';
import { useRecordOBHWorksStore } from '@/stores/RecordOBHWorks';

import UnsavedOBHMWorksCard from './components/UnsavedOBHMWorksCard.vue';

export default {
  name: "UnsavedOBHMWorks",
  components: {
    Button,
    Card,
    UnsavedOBHMWorksCard,
  },
  data() { return {
    loading: false
  }},
  created() {
    this.recordSiteWorksStore = useRecordSiteWorksStore();
    this.recordOBHWorksStore = useRecordOBHWorksStore();
  },
  computed: {
    unsavedOBHMWorks() {

      const unsavedSiteWorks = this.recordSiteWorksStore.getUnsavedSiteWorks;
      const unsavedOBHWorks = this.recordOBHWorksStore.getUnsavedOBHWorks;

      const OBHMWorks = [
        ...unsavedSiteWorks,
        ...unsavedOBHWorks
      ];
      
      return OBHMWorks.sort((a, b) => {
        return b.completion_time - a.completion_time;
      });
    },
  },
}
</script>