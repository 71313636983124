<template>
    <form v-if="formReady" class="tarping-works-form" @submit.prevent="goToNextStep" novalidate autocomplete="off">
        <h2 class="title">Daily Site Inputs</h2>
        <p>For productivity, tarp management and work flow applications.</p>

        <Divider />

        <div v-if="form.stack">

            <CompletionTime />
            <ActivitySelect id="activities" v-model="form.activities"  />
            <CoverEstimated />
            <PeakActual />
            <ToeLocation />
            <CoverActual />
            <WindSpeed />
            <TarpsSelect inputId="tarps" v-if="form.site" v-model="form.tarps" :siteId="form.site" />
            <Permed />
            <SeamsWozzied />
            <Comments />

        </div>

        <div class="field p-fluid">
            <Divider />
            <Button label="Next" severity="secondary" size="large" :disabled="!store.siteInputsIsValid" type="submit" raised />
        </div>
    </form>
</template>

<script>
import { useRecordTarpingWorksStore } from '@/stores/RecordTarpingWorks';
import { useTarpInventoryStore } from '@/stores/TarpInventory';

import CompletionTime from './components/CompletionTime.vue';
import CoverEstimated from './components/CoverEstimated.vue';
import PeakActual from './components/PeakActual.vue';
import CoverActual from './components/CoverActual.vue';
import ToeLocation from './components/ToeLocation.vue';
import WindSpeed from './components/WindSpeed.vue';
import Permed from './components/Permed.vue';
import SeamsWozzied from './components/SeamsWozzied.vue';
import Comments from './components/Comments.vue';
import ActivitySelect from './components/ActivitySelect.vue';
import StackClosedOutSelect from './components/StackClosedOutSelect.vue';
import TarpsSelect from './components/TarpsSelect.vue';

import Button from 'primevue/button';
import Divider from 'primevue/divider';


export default {
    name: "SiteInputs",
    components: {
        CompletionTime,
        CoverEstimated,
        PeakActual,
        CoverActual,
        ToeLocation,
        WindSpeed,
        Permed,
        SeamsWozzied,
        Comments,
        ActivitySelect,
        StackClosedOutSelect,
        TarpsSelect,
        Button,
        Divider,
    },
    data() { return {
        formReady: false,
        store: null,
        tarpInventory: null,
    }},
    created() {
      this.tarpInventory = useTarpInventoryStore();

      const store = useRecordTarpingWorksStore();
      this.store = store;
      this.formReady = true;
    },
    computed: {
      form() {
          return this.store.form;
      },
      nextDisabled() {
          let values = [
              this.form.site,
              this.form.storage,
              this.form.stack,
              this.form.peak_actual,
              this.form.activity,
          ];
          if (this.isTarpingActivity) {
              if (this.form.activities.includs('tarp_on_pull_out')) values.push(this.form.tarps);
              values.push(this.form.wind_speed);
              (this.form.cover_actual_max === true) ? 
                  values.push(this.form.cover_actual_max) : 
                  values.push(this.form.cover_actual);
              (this.form.cover_estimated_max === true) ? 
                  values.push(this.form.cover_estimated_max) : 
                  values.push(this.form.cover_estimated);
          }
          if (this.form.activities.includes('perming')) {
              (this.form.permed_max === true) ? 
                  values.push(this.form.permed_max) :
                  values.push(this.form.permed);
          }
          if (this.form.activities.includes('wozzying')) {
              values.push(this.form.seams_wozzied);
          }
          return values.includes(null);
      },
    },
    methods: {
      goToNextStep() {
        this.store.goToStep(2);
      },
    }
}

</script>

