<template>
    <div v-if="viewReady" class="view flex flex-column">
        <ReportsMenu app_section="tarping" />
    </div>
</template>

<script>
import ReportsMenu from '@/site_components/ReportsMenu/index.vue';

import Button from 'primevue/button';
import Divider from 'primevue/divider';

export default {
    name: "TarpingRecordView",
    components: {
        Button,
        Divider,
        ReportsMenu,
    },
    data() { return {
        viewReady: true,
        loading: false,
        store: null,
    }},
}

</script>
