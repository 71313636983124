<template>
  <div>
    <div style="max-height: 200px; overflow-y: scroll;">
      <input 
        type="text" 
        v-model="serialNo"
        class="p-inputtext p-component p-column-filter" 
        placeholder="Serial No." 
      />
      <div v-for="activity in activityOptions" :key="activity.value" class="flex align-items-center" style="margin-top: 10px">
        <Checkbox 
          v-model="activityTypes"
          :inputId="activity.value" 
          name="activity_type" 
          :value="activity.value" 
          style="margin-right: 5px;" 
        />
        <label :for="activity.value">{{ activity.display }}</label>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from 'primevue/checkbox';

export default {
  name: 'SerialNumberFilter',
  props: [
    'modelValue',
    'activityOptions',
  ],
  components: {
    Checkbox,
  },
  data() { return {
    serialNo: '',
    activityTypes: [],
  }},
  created() {
    if (this.modelValue) {
      this.serialNo = this.modelValue.serialNo || '';
      this.activityTypes = this.modelValue.activityTypes || [];
    }
  },
  watch: {
    modelValue: {
      handler(value) {
        console.log(value)
        if (value === null) {
          this.serialNo = ''
          this.activityTypes = []
        }
      },
      immediate: true,
    },
    serialNo(value) {
      this.$emit('update:modelValue', {
        serialNo: value,
        activityTypes: this.activityTypes,
      })
    },
    activityTypes(value) {
      this.$emit('update:modelValue', {
        serialNo: this.serialNo,
        activityTypes: value,
      })
    },
  }
}
</script>
