import { defineStore } from 'pinia';

import { getOrCreateItem, deleteItem, getItems } from '@/stores/idb';
import { useTarpInventoryStore } from './TarpInventory';
import { useTarpJoinsStore } from '@/stores/TarpJoins';

import { TarpJoin, TarpJoinBaseTarp } from './objects/TarpJoin';


export const useRecordTarpJoinsStore = defineStore('recordTarpJoins', {

  persist: {
    paths: ['tarp_join']
  },

  state: () => ({
    tarp_join: new TarpJoin().toJSON(),

    unsaved_tarp_joins: [],

    // Submission status
    submitted: false,
    submit_complete: false,
    loading: false,
    saveResponse: null,
  }),

  actions: {

    init() {
      if (!this.tarp_join) this.tarp_join = new TarpJoin().toJSON();
      if (this.submitted) this.reset(true);
    },

    addTarp() {
      this.tarp_join.base_tarps.push(new TarpJoinBaseTarp().toJSON());
      for (const [idx, bt] of this.tarp_join.base_tarps.entries()) {
        bt.position = idx + 1;
      }
    },

    removeTarp(index) {
      if (this.tarp_join.base_tarps.length > 2) this.tarp_join.base_tarps.splice(index, 1);
      for (const [idx, bt] of this.tarp_join.base_tarps.entries()) {
        bt.position = idx + 1;
      }
    },

    reset(resetSubmissionStatus = false) {
      this.tarp_join = new TarpJoin().toJSON();
      if (resetSubmissionStatus) {
        this.submitted = false;
        this.loading = false;
        this.saveResponse = null;
      }
    },

    async saveTarpJoin() {

      // Process data
      const tarpJoin = new TarpJoin().fromJSON(this.tarp_join);
      const data = tarpJoin.toJSON();

      console.log('Saving tarp join', data);

      // Save the tarp joins to local device
      this.saveTarpJoinToLocal(data);

      if (navigator.onLine) {
        try {

          this.loading = true;

          // syncToServer doesn't throw an error if the request fails.
          // Status is returned and handled by the component calling this action.
          const response = await new TarpJoin().syncToServer(data);
          this.saveResponse = response;

          this.loading = false;

          return response;

        } catch (error) {
          throw error;
        }
      } else {
        this.saveResponse = { status: 0 };  // Offline
        return this.saveResponse
      }
    },
    
    saveTarpJoinToLocal(tarpJoin) {
      // Check if the tarping works have already been added to the unsaved_tarping_works array
      const existingTarpJoins = this.unsaved_tarp_joins.filter(tj => tj.client_id === tarpJoin.client_id);
      if (existingTarpJoins.length) {
        // If the tarping works already exist in the array, replace them with the new tarping works
        this.unsaved_tarp_joins = this.unsaved_tarp_joins.map(tj => {
          if (tj.client_id === tarpJoin.client_id) {
            return tarpJoin
          }
          return tj
        });
      } else {
        // Add tarp join to the unsaved_tarp_join array
        this.unsaved_tarp_joins.push(tarpJoin);
      }
      // Save the unsaved tarp join to indexedDB
      this.saveUnsavedTarpJoinToDB(tarpJoin);
    },

    removeTarpJoinFromLocal(clientId) {
      this.unsaved_tarp_joins = [...this.unsaved_tarp_joins.filter(tj => tj.client_id !== clientId)];
      this.removeUnsavedTarpJoinFromDB(clientId);
    },

    addToTarpJoinsStore(tarpJoin) {
      // Handle tarp inventory state change and add the tarp join to the tarp joins store

      // Handle tarp inventory state change
      const tarpInventoryStore = useTarpInventoryStore();
      tarpInventoryStore.handleStateChange(tarpJoin.tarp_inventory_state_change);
      
      // Add the tarp join to the tarp joins store
      const tarpJoinsStore = useTarpJoinsStore();
      tarpJoinsStore.addTarpJoin(tarpJoin);
    },

    async saveUnsavedTarpJoinToDB(tarpJoin) {
      await getOrCreateItem('unsavedTarpJoins', tarpJoin.client_id, tarpJoin);
    },

    async removeUnsavedTarpJoinFromDB(clientId) {
      await deleteItem('unsavedTarpJoins', clientId);
    },

    async loadUnsavedTarpJoinsFromDB() {
      const unsavedTarpJoins = await getItems('unsavedTarpJoins');
      this.unsaved_tarp_joins = unsavedTarpJoins;
    },

  },

  getters: {

    inputsValid() {
      const tarpJoin = this.getTarpJoin;
      return tarpJoin.isValid();
    },

    getTarpJoin() {
      return new TarpJoin().fromJSON(this.tarp_join);
    },

    getUnsavedTarpJoins() {
      return this.unsaved_tarp_joins.map(tj => new TarpJoin().fromJSON(tj));
    },

    getErrors() {
      if (this.saveResponse?.status === 400) {
        return this.saveResponse?.data;
      }
      return [];
    },

    isEditMode() {
      return Boolean(this.tarp_join.id);
    }

  }
})