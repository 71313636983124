<template>
    <div>
        <Card class="bg-blue unsaved-tarping-works-card" :pt="{ body: { class: 'py-0' } }">
            <template #content>
                <div class="flex justify-content-between align-content-center align-items-center">
                    <div>
                        <div class="flex align-content-center align-items-center">
                        <div>
                            <p class="title text-lg font-medium text-white">
                            {{ report.report_name }}
                            </p>
                            <p class="text-white subtitle mb-0">{{ lastGeneratedTime }}</p>
                        </div>
                        </div>
                    </div>
                    <div>
                      <Button 
                        class="download-btn bg-white text-secondary" 
                        severity="secondary" 
                        :loading="loading"
                        :disabled="lastGeneratedReport === null" text raised
                        @click="openReport">
                        <template #icon>
                          <span class="material-symbols-outlined">download</span>
                        </template>
                      </Button>
                    </div>
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import * as Sentry from "@sentry/vue";

import Button from 'primevue/button';
import Card from 'primevue/card';
import Divider from 'primevue/divider';

import GeneratedReport from '@/stores/objects/GeneratedReport';

export default {
  name: "Report",
  props: {
    report: {
      type: Object,
      required: true,
    }
  },
  components: {
    Button,
    Card,
    Divider,
  },
  data() { return {
    loading: false,
  }},
  computed: {
    lastGeneratedReport() {
      const generatedReports = this.report.generated_reports;
      if (generatedReports.length > 0) {
        return new GeneratedReport().fromJSON(generatedReports[0]);
      }
      return null
    },
    lastGeneratedTime() {
      if (this.lastGeneratedReport) {
        const localeDate = new Date(this.lastGeneratedReport.created).toLocaleDateString();
        const localeTime = new Date(this.lastGeneratedReport.created).toLocaleTimeString();
        return `${localeDate} ${localeTime}`;
      }
      return 'No reports have been generated yet.'
    },
    downloadReportURL() {
      if (this.lastGeneratedReport) {
        return this.lastGeneratedReport.download_url;
      }
      return null;
    }
  },
  methods: {
    downloadReport() {
      this.lastGeneratedReport.downloadReport()
    },
    async openReport() {
      this.loading = true;
      try {
        await this.lastGeneratedReport.openReport();
      } catch (error) {
        Sentry.captureException(error);
        this.$root.errorNotification("An error occurred.")
      } finally {
        this.loading = false;
      }
    }
  }
}

</script>

<style scoped>
.download-link.disabled {
  pointer-events: none;
  cursor: default;
}
.download-btn {
  border-radius: 100%;
  width: 60px;
  height: 60px;
  background: white !important;
}
.download-btn:disabled {
  background-color: white !important;
}
</style>
