import { defineStore } from 'pinia';
import { getOrCreateItem, getItems, updateStoreLastUpdated, clearStore, addItems, saveItem } from './idb.js';
import { isSameOrAfterDate, isSameOrBeforeDate } from '../utils/dates.js';

import { TarpJoin } from './objects/TarpJoin.js';
import TarpJoinsAPI from './api/TarpJoins.js';


export const useTarpJoinsStore = defineStore('tarpJoins', {
  state: () => ({ 
    tarpJoins: [],
    lastUpdated: null,
  }),

  actions: {
    async loadTarpJoinsFromAPI() {
      // Fetch tarps from API and update indexedDB
      const api = new TarpJoinsAPI();

      try {
        const response = await api.getTarpJoins();
        const data = response.data;
        this.tarpJoins = data;
        this.updateTarpJoinsDB(data);
        return response
      } catch (error) {
        console.error(error);
      }
    },

    async loadTarpJoinsFromDB() {
      console.log('Loading tarp works from indexedDB...')
      const tarpJoins = await getItems('tarpJoins');
      this.tarpJoins = tarpJoins;

      const tarpJoinsMeta = await getOrCreateItem('meta', 'tarpJoins');
      const lastUpdated = tarpJoinsMeta.lastUpdated;
      (lastUpdated) ? this.lastUpdated = lastUpdated : this.lastUpdated = null;
    },

    async loadTarpJoins(awaitAPI) {
      // Load tarps from cache and then update from API in background
      await this.loadTarpJoinsFromDB();
      if (navigator.onLine) {
        const response = this.loadTarpJoinsFromAPI();
        if (awaitAPI) await response;
      }
    },

    async updateTarpJoinsDB(data) {
      // Clears and repopulates tarp works in indexedDB
      clearStore('tarpJoins');

      // Add data
      addItems('tarpJoins', data);

      // Update last updated timestamp
      const now = Date.now();
      this.lastUpdated = now;
      updateStoreLastUpdated('tarpJoins', now);
    },

    async addTarpJoin(tarpJoin) {
      saveItem('tarpJoins', tarpJoin.id, tarpJoin);
      this.tarpJoins = [
        ...this.tarpJoins.filter(tj => tj.id !== tarpJoin.id),
        tarpJoin
      ];
    }

  },

  getters: {

    getTarpJoins(state) {
      // Return all tarp joins, ordered by completion time (newest first)
      const sortedTarpJoins = state.tarpJoins.sort((a, b) => {
        return new Date(b.completion_time) - new Date(a.completion_time);
      });
      return sortedTarpJoins.map(tarpJoin => new TarpJoin().fromJSON(tarpJoin));
    },

    getTarpJoinsForDateRange(state) {
      return (dateStart, dateEnd) => {
        // Return all tarp joins on a given date
        const tarpJoins = state.tarpJoins.filter(tarpJoin => {
          const completionTime = new Date(tarpJoin.completion_time);
          return isSameOrAfterDate(completionTime, dateStart) && isSameOrBeforeDate(completionTime, dateEnd);
        });
        return tarpJoins.map(tarpJoin => new TarpJoin().fromJSON(tarpJoin));
      }
    },

  },

})