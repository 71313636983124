<template>
  <div v-if="form.stack && !store.selectedStackLastTarpingWorks" class="field p-fluid">
    <Divider />
    <label for="is_new_stack">Is this a new stack (i.e. is this the first time your business has performed tarping activities on the stack for the season)?</label>
    <RadioSelect v-model="form.is_new_stack" :value="true" label="Yes" name="is_new_stack" class="mb-2" />
    <RadioSelect v-model="form.is_new_stack" :value="false" label="No" name="is_new_stack" />
  </div>
</template>

<script>
import { useRecordTarpingWorksStore } from '@/stores/RecordTarpingWorks';

import Divider from 'primevue/divider';
import RadioSelect from '@/components/forms/RadioSelect.vue';

export default {
  name: "IsNewStack",
  components: {
    Divider,
    RadioSelect,
  },
  created() {
      this.store = useRecordTarpingWorksStore();
  },
  computed: {
    form() {
        return this.store.form;
    },
  }
}
</script>