import { useRecordSiteWorksStore } from '@/stores/RecordSiteWorks';

const SiteWorksMixin = {
  created() {
    this.store = useRecordSiteWorksStore();
  },
  computed: {
    form() {
      return this.store.site_works;
    },
  }
}

export default SiteWorksMixin;
