<template>
    <div>
        <p class="title mb-3"><span class="text-xl text-900 font-bold">{{ report_type.report_type }}</span></p>
        <div v-if="report_type.reports.length === 0" class="text-center">
            <p class="text-lg text-900">No reports available.</p>
        </div>
        <div v-else>
            <div v-for="report in report_type.reports" :key="report.report_name" class="mb-2">
                <Report :report="report" />
            </div>
        </div>
    </div>
</template>

<script>
import Button from 'primevue/button';
import Divider from 'primevue/divider';

import Report from './Report.vue';

export default {
    name: "ReportTypeSection",
    props: {
        report_type: {
            type: Object,
            required: true,
        }
    },
    components: {
        Button,
        Divider,
        Report,
    },
    data() { return {
        viewReady: true,
        loading: false,
        store: null,
    }}
}

</script>