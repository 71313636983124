import { v4 as uuidv4 } from 'uuid';
import * as Sentry from '@sentry/vue';

import NewTarpsInputAPI from '@/stores/api/NewTarpsInput';
import { useRecordNewTarpsInputsStore } from '@/stores/RecordNewTarpsInputs';
import { useSitesStore } from '@/stores/Sites';
import Supervisor from '@/stores/objects/Supervisor';
import Tarp from '@/stores/objects/Tarp.js';
import { validateRequiredFields } from '@/utils/validation';


class NewTarpsInput {

    id = null;
    client_id = null;
    supervisor = null;
    site = null;
    tarps = [];
    completion_time = null;

    activity_type = 'new_tarps_input';

    constructor() {
      this.client_id = uuidv4();
      this.sitesStore = useSitesStore();
    }

    // Adapters

    toJSON() {
      return {
        id: this.id,
        client_id: this.client_id,
        supervisor: this.supervisor?.id,
        site: this.site?.id || null,
        tarps: this.tarps?.map(tarp => tarp.toJSON()),
        completion_time: this.completion_time,
      }
    }

    fromJSON(data) {
      this.id = data.id
      this.client_id = data.client_id
      this.supervisor = data.supervisor ? new Supervisor().fromJSON(data.supervisor) : null;
      this.site = data.site ? this.sitesStore.getSiteById(data.site) : null;
      this.tarps = data.tarps ? data.tarps.map(tarp => new Tarp().fromJSON(tarp)) : [];
      this.completion_time = data.completion_time ? new Date(data.completion_time) : null;
      return this
    }

    // Actions

    updateNewTarpsInputInStores(response) {
      // Removes the tarping works from the locally saved tarping works and 
      // adds the updated tarping works to the tarping works store.
      const recordNewTarpsInputsStore = useRecordNewTarpsInputsStore();
      recordNewTarpsInputsStore.removeNewTarpsInputFromLocal(response.data.client_id);
      recordNewTarpsInputsStore.addToNewTarpsInputStore(response.data);
    }

    syncToServer = async (data) => {
      if (!data) data = this.toJSON();
  
      const api = new NewTarpsInputAPI();
  
      let response;
  
      try {
  
        if (this.id) response = await api.updateNewTarpsInput(this.id, data);
        else         response = await api.createNewTarpsInput(data);
  
        if ([200, 201].includes(response.status)) {
          this.updateNewTarpsInputInStores(response);
        }
  
        return response;
  
      } catch (error) {
  
        Sentry.captureException(error);
        console.log(error);
  
        if (error.response) {
          if (error.response.status === 409) {
            // 409 is returned if the tarping works has already been created on the server
            this.updateNewTarpsInputInStores(error.response);
          }
          return error.response;
        } else {
          throw error;
        }
        
      }
    }

    // Properties

    isValid() {
      const requiredFields = ['site', 'tarps'];
      const requiredFieldsValid = validateRequiredFields(requiredFields, this);

      const tarpsValid = this.tarps.every(t => t.isValid());
      return requiredFieldsValid && tarpsValid;
    }

    displayName() {
      return `New Tarps Input - ${this.site?.displayName() || 'Unknown Site'}`
    }

    completionTimeDisplay() {
      const completionTime = this.completion_time;
      if (!completionTime) return '';
      return new Date(completionTime).toLocaleString();
    }

    get tableGlobalFilterValues() {
      return [
        // activity_type
        this.activity_type,
        ...this.tarps.map(t => t.serial_no),
      ]
    }

}

export default NewTarpsInput;
