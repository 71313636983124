import AuthAPI from '@/stores/api/Auth';

class User {

  first_name = null;
  last_name = null;
  email = null;
  password = null;

  auth_api = null;

  constructor() {
    this.auth_api = new AuthAPI();
  }

  // Adapters

  toJSON() {
    return {
      first_name: this.first_name,
      last_name: this.last_name,
      email: this.email,
      password: this.password,
    }
  }

  fromJSON(data) {
    this.first_name = data.first_name;
    this.last_name = data.last_name;
    this.email = data.email;
    this.password = data.password;
    return this
  }

  // Actions

  async login(data) {
    if (!data) data = this.toJSON();
    const response = await this.auth_api.login(data);
    return response
  }

  async logout() {
    return await this.auth_api.logout()
  }

}

export default User
