<template>
  <div v-if="form.activities.includes('wozzying')" class="field p-fluid">
    <Divider />
    <label for="wozzied">Wozzied</label>
    <p class="subtitle">
      Seams wozzied is the number of whole seams (OBH wall to OBH wall) wozzied during this shift.
    </p>
    <NumberInput
      inputId="wozzied"
      v-model="form.seams_wozzied"
      placeholder="The value must be a number" 
      :disabled="form.seams_wozzied_max" />
    <div class="flex align-items-center mt-2">
      <Checkbox
        inputId="wozzied_max"
        v-model="seams_wozzied_max" 
        name="seams_wozzied_max" 
        binary
        :tabindex="-1" />
      <label for="wozzied_max" class="ml-2"> Max </label>
    </div>
  </div>
</template>

<script>
import { useRecordTarpingWorksStore } from '@/stores/RecordTarpingWorks';

import NumberInput from '@/components/forms/NumberInput.vue';
import Divider from 'primevue/divider';
import Checkbox from 'primevue/checkbox';

export default {
  name: 'SeamsWozzied',
  components: {
    NumberInput,
    Divider,
    Checkbox,
  },
  created() {
    this.store = useRecordTarpingWorksStore();
  },
  computed: {
    form() {
      return this.store.form;
    },
    seams_wozzied_max: {
      get() {
        return this.form.seams_wozzied_max;
      },
      set(value) {
        const patch = {
          form: {
            seams_wozzied_max: value,
          }
        };
        if (value) {
          patch.form.seams_wozzied = null;
        }
        this.store.$patch(patch);
      }
    }
  },
}
</script>