<template>
  <div v-if="viewReady" class="view">
    <div class="p-3">
      <QuickLinks class="mb-3" :links="quickLinks" />
      <DownloadSiteDataCard />
      <UnsavedOBHMWorks class="mt-4" />
    </div>
  </div>
</template>

<script>
import DownloadSiteDataCard from '@/components/cards/DownloadSiteDataCard.vue';
import QuickLinks from '@/site_components/QuickLinks/index.vue';
import UnsavedOBHMWorks from './components/UnsavedOBHMWorks/index.vue';


export default {
  name: "GCMHomeView",
  components: {
    DownloadSiteDataCard,
    QuickLinks,
    UnsavedOBHMWorks,
  },
  data() { return {
    // Flags
    viewReady: true,
    loading: false,
  }},
  computed: {
    quickLinks() {
      const OBHMRoute = this.$router.options.routes
        .find(route => route.name === 'obhm');

      const links = OBHMRoute.children
        .filter(route => route.meta && route.meta.quickLink === true)
        .map(route => {
          return {
            name: route.name,
            meta: route.meta,
            to: route.path,
            label: route.meta.title,
            icon: route.meta.icon,
          }
        });

      return links;
    }
  },
}

</script>