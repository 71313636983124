<template>
  <div>
    <h2 class="font-semibold mt-2 mb-4">Select Maintenance Type</h2>
    <div v-for="type in maintenance_types" :key="type.value">
      <Card class="bg-blue mb-3 cursor-pointer" @click="selectMaintenanceType(type.value)">
        <template #content>
          <div class="flex gap-2">
            <span class="icon material-symbols-outlined text-white">{{ type.icon }}</span>
            <p class="font-semibold text-white">
              {{ type.label }}
            </p>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import { useRecordOBHMStore } from '@/stores/RecordOBHM';
import { MAINTENANCE_TYPES } from '@/stores/enums/OBHM';

import Card from 'primevue/card';

export default {
  name: "OBHMTypeSelect",
  components: {
    Card,
  },
  data() { return {
    maintenance_types: MAINTENANCE_TYPES,
    recordOBHMStore: null,
  }},
  created() {
    this.recordOBHMStore = useRecordOBHMStore();
  },
  methods: {
    selectMaintenanceType(type) {
      this.recordOBHMStore.setActiveMaintenanceType(type);
    }
  }

}
</script>