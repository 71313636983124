<template>
  <div>
    <TarpsSelectField
      :modelValue="tarps"
      @update:modelValue="updateTarps"
      :multiple="true"
      :generateSerialNumber="false"
      label="Tarps"
    />

    <SelectTarpsModal 
      :visible="selectTarpsModalVisible"
      :multiple="true"
      ordering="serial_no"
      @onSelect="confirmSelectTarps"
      @onAddNew="openNewTarpModal"
      @close="selectTarpsModalVisible = false"
    />

    <NewTarpModal
      ref="newTarpModal"
      :visible="newTarpModalVisible"
      :formOptions="{
        serialNumberType: 'double',
        generateSerialNumber: false,
      }"
      @onCreate="confirmCreateNewTarp"
      @close="closeNewTarpModal"
    />

  </div>
</template>

<script>
import { useRecordStockpilingStore } from '@/stores/RecordStockpiling';
import { useRecordNewTarpStore } from '@/stores/RecordNewTarp';

import NewTarpForm from '@/site_components/NewTarpForm/index.vue';
import NewTarpModal from '@/site_components/NewTarpModal/index.vue';
import SelectTarpsMenu from '@/site_components/SelectTarpsMenu/index.vue';
import SelectTarpsModal from '@/site_components/SelectTarpsModal/index.vue';
import TarpListItem from '@/site_components/SelectTarpsMenu/TarpListItem.vue';
import TarpsSelectField from '@/site_components/TarpsSelectField/index.vue';


import Button from 'primevue/button';

export default {
  name: "Tarps",
  components: {
    SelectTarpsMenu,
    TarpListItem,
    NewTarpForm,
    SelectTarpsModal,
    NewTarpModal,
    TarpsSelectField,
    Button,
  },
  data() { return {
    createTarpLoading: false,
    newTarpModalVisible: false,
    selectTarpsModalVisible: false,

    // Store selected tarps when a user elects to create new tarp
    selectedTarps: null,
  }},
  computed: {
    form() {
      return this.store.stockpiling;
    },
    tarps() {
      const tarps = this.store.getStockpiling?.tarps;
      const new_tarps = this.store.getStockpiling?.new_tarps;
      return tarps.concat(new_tarps).sort((a, b) => a.serial_no - b.serial_no);
    },
    tarpsSelectedText() {
      return this.form.tarps.length === 1 ? '1 tarp selected.' : `${this.form.tarps.length} tarps selected.`;
    },
  },
  created() {
    this.store = useRecordStockpilingStore();
    this.recordNewTarpStore = useRecordNewTarpStore();
  },
  methods: {
    updateTarps(tarps) {
      // Seperate tarps into existing tarps and newly created tarps
      const existingTarps = tarps.filter(tarp => tarp.id);
      const newTarps = tarps.filter(tarp => !tarp.id);
      this.form.tarps = existingTarps.map(tarp => tarp.id);
      this.form.new_tarps = newTarps;
    },

    addTarps() {
      this.selectTarpsModalVisible = true;
    },

    confirmSelectTarps(tarps) {
      if (!tarps || tarps.length === 0) return;

      const tarpsToAdd = tarps.filter(tarp => !this.form.tarps.includes(tarp));
      const tarpIds = tarpsToAdd.map(tarp => tarp.id);
      this.form.tarps = this.form.tarps.concat(tarpIds);
      this.selectTarpsModalVisible = false;
    },

    removeTarp(tarp) {
      if (tarp.id) {
        this.form.tarps = this.form.tarps.filter(t => t !== tarp.id);
      } else {
        this.form.new_tarps = this.form.new_tarps.filter(t => t.client_id !== tarp.client_id);
      }
    },

    openNewTarpModal({ selectedTarps, searchSerialNo }) {
      console.log(selectedTarps, searchSerialNo);
      this.selectedTarps = selectedTarps;
      this.newTarpModalVisible = true;

      // Set serial number if provided, make sure to wait for the modal to open.
      this.$nextTick(() => {
        if (searchSerialNo) this.$refs.newTarpModal.set({ serial_no: String(searchSerialNo) });
      });
    },

    closeNewTarpModal() {
      this.selectedTarps = null;
      this.newTarpModalVisible = false;
    },

    addNewTarp(selectedTarps) {
      // Open create tarp modal
      this.openNewTarpModal(selectedTarps);
    },

    confirmCreateNewTarp(tarp) {
      if (this.selectedTarps) this.confirmSelectTarps(this.selectedTarps);
      this.form.new_tarps.push(tarp);

      // Close modals
      this.selectTarpsModalVisible = false;
      this.newTarpModalVisible = false;
    },

  }
}

</script>

<style scoped>
.selected-tarps-list {
  margin: 0.5rem -0.5rem;
}
</style>