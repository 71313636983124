<template>
  <div>
    <SiteSelect v-model="form.site" />
  </div>
</template>

<script>
import SiteWorksMixin from './mixins';

import SiteSelect from '@/site_components/SiteSelect/index.vue';

export default {
  name: "Site",
  components: {
      SiteSelect,
  },
  mixins: [SiteWorksMixin],
}

</script>