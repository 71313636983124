import { default as axios } from './http';

class TarpInventoryAPI {

  getTarps = async () => {
    const url = '/api/v1/tarp-inventory/tarps/';
    const response = await axios.get(url)
    return response
  }

  getTarp = async (id) => {
    const url = '/api/v1/tarp-inventory/tarps/${id}/';
    const request = await axios.get(url)
    return request
  }

  createTarp = async (data) => {
    const url = '/api/v1/tarp-inventory/tarps/create/';
    const request = await axios.post(url, data)
    return request
  }

  getGeneratedTarpSerialNumbers = async (count) => {
    const url = '/api/v1/tarp-inventory/generate-tarp-serial-numbers/';
    const data = { count: count }
    const request = await axios.post(url, data)
    return request
  }

}

export default TarpInventoryAPI
