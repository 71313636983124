import { v4 as uuidv4 } from 'uuid';

import { ISOStringToDate, dateToISOString } from "@/utils/dates";
import { validateRequiredFields } from "@/utils/validation";
import { useBaseTarpsStore } from '@/stores/BaseTarps';
import { useTarpRepairTypesStore } from '@/stores/TarpRepairTypes';
import { useRecordTarpRepairsStore } from '@/stores/RecordTarpRepairs';

import TarpRepairsAPI from '../api/TarpRepairs';
import BaseTarp from './BaseTarp';
import Supervisor from './Supervisor';

class TarpRepair {

  id = null;
  client_id = null;
  completion_time = null;
  supervisor = null;
  base_tarp = null;
  base_tarp_sn = null;
  new_base_tarp = null;
  repair_type = null;
  new_width = null;
  new_length = null;
  condition = null;
  unserviceable_damage_type = null;
  holes_repaired = null;
  holes_repaired_confirmed = false; // This is a field that is not sent to the server, it is used to confirm the holes repaired by the user.
  comments = null;

  tarp = null;
  archived_tarps = [];

  base_tarp_store = null;
  repair_type_store = null;

  activity_type = 'tarp_repair';

  constructor() {
    this.client_id = uuidv4();
    this.base_tarp_store = useBaseTarpsStore();
    this.repair_type_store = useTarpRepairTypesStore();
  }

  // Adapters

  toJSON() {
    return {
      id: this.id,
      client_id: this.client_id,
      completion_time: dateToISOString(this.completion_time),
      supervisor: this.supervisor?.toJSON(),
      base_tarp: this.base_tarp?.id,
      base_tarp_sn: this.base_tarp_sn,
      new_base_tarp: this.new_base_tarp?.toJSON(),
      repair_type: this.repair_type?.id,
      new_width: this.new_width,
      new_length: this.new_length,
      condition: this.condition,
      unserviceable_damage_type: this.unserviceable_damage_type,
      holes_repaired: this.holes_repaired,
      holes_repaired_confirmed: this.holes_repaired_confirmed,
      comments: this.comments,
      activity_type: 'tarp_repair'
    }
  }

  fromJSON(data) {
    this.id = data.id;
    this.client_id = data.client_id;
    this.completion_time = ISOStringToDate(data.completion_time);
    
    this.supervisor = new Supervisor().fromJSON(data.supervisor);

    if (data.base_tarp) {
      this.base_tarp = this.base_tarp_store.getBaseTarpById(data.base_tarp);
    }
    this.base_tarp_sn = data.base_tarp_sn;
    if (data.new_base_tarp) {
      this.new_base_tarp = new BaseTarp().fromJSON(data.new_base_tarp);
    }

    if (data.repair_type) {
      this.repair_type = this.repair_type_store.getTarpRepairTypeById(data.repair_type);
    }
    
    this.new_width = data.new_width;
    this.new_length = data.new_length;
    this.condition = data.condition;
    this.unserviceable_damage_type = data.unserviceable_damage_type;
    this.holes_repaired = data.holes_repaired;
    this.holes_repaired_confirmed = data.holes_repaired_confirmed;
    this.comments = data.comments;

    this.activity_type = 'tarp_repair'

    return this
  }

  // Actions

  updateTarpRepairInStores(response) {
    // Removes the tarping works from the locally saved tarping works and 
    // adds the updated tarping works to the tarping works store.
    const recordTarpRepairsStore = useRecordTarpRepairsStore();
    recordTarpRepairsStore.removeTarpRepairFromLocal(response.data.client_id);
    recordTarpRepairsStore.addToTarpRepairsStore(response.data);
  }

  syncToServer = async (data) => {
    if (!data) data = this.toJSON();

    // If a new tarp has been created, set the width and length of the new tarp
    // to that input for new_width and new_length.
    if (data.new_base_tarp) {
      data.new_base_tarp.width = data.new_width;
      data.new_base_tarp.length = data.new_length;
    }

    const api = new TarpRepairsAPI();

    let response;

    try {

      response = await api.createTarpRepairs(data);

      if ([200, 201].includes(response.status)) {
        this.updateTarpRepairInStores(response);
      }

      return response;

    } catch (error) {

      //Sentry.captureException(error);
      console.log(error);

      if (error.response) {
        if (error.response.status === 409) {
          // 409 is returned if the tarping works has already been created on the server
          this.updateTarpRepairInStores(error.response);
        }
        return error.response;
      } else {
        throw error;
      }
      
    }
  }

  // Getters

  isValid() {
    const requiredFields = [
      'completion_time',
      'base_tarp|new_base_tarp',
      'repair_type',
      'new_width',
      'new_length',
      'condition',
    ];
    if (this.repair_type?.name.toLowerCase() === 'unserviceable') {
      requiredFields.push('unserviceable_damage_type');
    }
    if (this.repair_type?.has_holes_repaired_field) {
      requiredFields.push('holes_repaired');
      if (this.repair_type?.min_holes_repaired_confirmation > this.holes_repaired) {
        return this.holes_repaired_confirmed;
      }
    }
    return validateRequiredFields(requiredFields, this);
  }

  displayName() {
    const serialNo = this.base_tarp?.serial_no || this.base_tarp_sn || 'Unknown Tarp';
    return 'Repair - ' + serialNo;
  }

  supervisorDisplay() {
    const supervisor = this.supervisor;
    if (!supervisor) return 'Unknown Supervisor';

    const firstName = supervisor.first_name;
    const lastName = supervisor.last_name;
    if (firstName && lastName) return `${firstName} ${lastName}`;
    else if (firstName) return firstName;
    else if (lastName) return lastName;
    else return supervisor.email;
  }

  completionTimeDisplay() {
    const completionTime = this.completion_time;
    if (!completionTime) return 'Unknown Completion Time';
    return new Date(completionTime).toLocaleString();
  }

  get tableGlobalFilterValues() {
    return [
      // activity_type
      this.activity_type,
      this.base_tarp_sn,
    ]
  }

}

export default TarpRepair;
