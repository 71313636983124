<template>
  <div v-if="showPeakActual" class="field p-fluid">
    <Divider />
    <label for="peak_actual">Peak Actual</label>
    <p class="subtitle">
      Peak actual is taken from the strut (closest to i.e. round up or down to the nearest
      strut) that is inline with the peak of the grain before it starts to fall away.
    </p>
    <NumberInput
      inputId="peak_actual"
      enterkeyhint="next"
      v-model="form.peak_actual"
      placeholder="The value must be a number"
      @blur="confirmPeakActual" />
    <StorageLengthWarning 
      :value="form.peak_actual"
      :storageLength="store.selectedStorageLength" />
  </div>
</template>

<script>
import { useRecordTarpingWorksStore } from '@/stores/RecordTarpingWorks';

import StorageLengthWarning from '../../../components/StorageLengthWarning.vue';
import NumberInput from '@/components/forms/NumberInput.vue';
import Divider from 'primevue/divider';
import Checkbox from 'primevue/checkbox';

export default {
  name: 'PeakActual',
  components: {
    StorageLengthWarning,
    NumberInput,
    Divider,
    Checkbox
  },
  created() {
    this.store = useRecordTarpingWorksStore();
  },

  computed: {
    form() {
      return this.store.form;
    },
    showPeakActual() {
      const peakActualActivities = new Set([
          'tarp_on_pull_out',
          'pulling_tarps_out',
          'perming',
          'wozzying',
          'cbh_supervisor_input',
      ]);
      return this.form.activities.some(activity => peakActualActivities.has(activity));
    },
  },
  methods: {
    confirmPeakActual() {
      // If the peak actual is less than the previous peak actual, confirm with the user
      const peakActual = this.form.peak_actual;
      const lastPeakActual = this.store.selectedStackLastTarpingWorks?.peak_actual;
      if (peakActual && lastPeakActual) {
        if (peakActual < lastPeakActual) {
          this.$confirm.require({
            message: `
              The peak actual (${peakActual}m) is less than the last recorded peak actual for this stack (${lastPeakActual}m). 
              Please confirm this is correct?`,
            header: 'Confirm Peak Actual',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              // Do nothing
            },
            reject: () => {
              this.form.peak_actual = null;
            }
          });
        }
      }
    },
  }
}
</script>