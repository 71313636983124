<template>
  <div>
    <form @submit.prevent="submit" novalidate>
      <div v-for="field in fields" :key="field.name">
        <component
          :is="field.component"
          :modelValue="modelValue[field.name]"
          @update:modelValue="update($event, field.name)"
          v-bind="field.props"
        />
        <Divider />
      </div>
      <div class="field p-fluid">
        <Button
          label="Submit"
          severity="secondary"
          size="large"
          :disabled="!isValid"
          type="submit"
          :loading="loading"
          raised
        />
      </div>
    </form>
  </div>
</template>

<script>
import { COVER_TYPES, TARP_CONDITIONS, TARP_MATERIALS } from '@/stores/enums/GCM';

import SerialNumberField from './components/SerialNumberField.vue';
import DropdownField from '@/components/forms/DropdownField.vue';
import IntegerField from '@/components/forms/IntegerField.vue';

import Button from 'primevue/button';
import Card from 'primevue/card';
import Divider from 'primevue/divider';

export default {
  name: "NewTarpForm",
  components: {
    SerialNumberField,
    DropdownField,
    IntegerField,
    Button,
    Card,
    Divider,
  },
  props: {
    loading: { type: Boolean, default: false },
    serialNumberType: { type: String, default: 'single' },
    generateSerialNumber: { type: Boolean, default: false },
    excludeFields: { type: Array, default: () => [] },
    defaults: { 
      type: Object,
      default: () => ({
        cover_type: null,
        serial_no: null,
        width: 41,
        length_ends: 22,
        length_straights: 24,
        tarp_material: 'pvc',
        year_of_manufacture: new Date().getFullYear(),
        condition: '5',
      }),
    },
    setDefaults: { type: Boolean, default: false },
    modelValue: { type: Object,   required: true },
  },
  computed: {
    fields() {
      const fields = [
        { 
          name: 'serial_no', 
          component: 'SerialNumberField',
          props: {
            type: this.serialNumberType,
            coverType: this.modelValue.cover_type,
            autoGenerate: this.generateSerialNumber,
          }
        },
        { 
          name: 'cover_type',
          component: 'DropdownField',
          props: {
            label: 'Cover Type',
            options: COVER_TYPES,
            optionLabel: 'label',
            optionValue: 'value',
            placeholder: 'Select cover type...',
          }
        },
        { 
          name: 'width', 
          component: 'IntegerField',
          props: {
            label: 'Width (m)',
            maxFractionDigits: 2,
          }
        },
        { 
          name: 'length', 
          component: 'IntegerField',
          props: {
            label: 'Length (m)',
            maxFractionDigits: 2,
          }
        },
        { 
          name: 'year_of_manufacture',  
          component: 'IntegerField',
          props: {
            label: 'Year of Manufacture',
            maxFractionDigits: 0,
            max: new Date().getFullYear(),
          }
        },
        {
          name: 'condition',
          component: 'DropdownField',
          props: {
            label: 'Condition',
            options: TARP_CONDITIONS,
            optionLabel: 'label',
            optionValue: 'value',
            placeholder: 'Select condition...',
          }
        },
        { 
          name: 'tarp_material', 
          component: 'DropdownField',
          props: {
            label: 'Tarp Material',
            options: TARP_MATERIALS,
            optionLabel: 'label',
            optionValue: 'value',
            placeholder: 'Select tarp material...',
          }
        },
      ]
      return fields.filter(field => !this.excludeFields.includes(field.name));
    },

    isValid() {
      return this.fields.every(field => this.modelValue[field.name]);
    }
  },
  watch: {
    'modelValue.cover_type': function(val) {
      if (this.setDefaults === true) {
        if (val === 'end') {
          this.modelValue.length = this.defaults.length_ends;
        } else {
          this.modelValue.length = this.defaults.length_straights;
        }
      }
    }
  },
  created() {
    if (this.setDefaults) {
      this.$emit('update:modelValue', { ...this.modelValue, ...this.defaults });
    }
  },
  methods: {
    submit() {
      this.$emit('onSubmit', this.modelValue);
    },
    update(val, fieldName) {
      this.$emit('update:modelValue', { ...this.modelValue, [fieldName]: val });
    }
  }
}

</script>