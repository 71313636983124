import axios from 'axios';
import { default as api } from './http';




class TarpingReportsAPI {

    getTarpingReports = async () => {
      // Fetch tarps from API
      console.log('Fetching tarping reports from API...')
      const url = '/api/v1/reports/generated-reports/';
      const response = await api.get(url)
      return response
    }

    getSignedUrl = async (generatedReportId) => {
      console.log("Fetching signed url for report...")
      const url = `/api/v1/reports/generated-reports/${generatedReportId}/download/`;
      const response = await api.get(url)
      return response
    }

    downloadTarpingReport = async (download_url) => {
        
      const response = await axios.get(download_url, {
        responseType: 'blob',
      });
      return response
    }

}

export default TarpingReportsAPI;
