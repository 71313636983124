<template>
  <UnsavedItemCard
    ref="unsavedItemCard"
    :item="GCMWorks" 
    :label="GCMWorks.displayName()" 
    :completion_time="GCMWorks.completion_time"
    :loading="loading"
    @saveItem="uploadGCMWorks"
    @editItem="editGCMWorks"
    @deleteItem="deleteGCMWorks"
  />
</template>

<script>
import Button from 'primevue/button';
import Card from 'primevue/card';
import SpeedDial from 'primevue/speeddial'
import ConfirmDialog from 'primevue/confirmdialog';

import { useRecordTarpRepairsStore } from '@/stores/RecordTarpRepairs';
import { useRecordTarpJoinsStore } from '@/stores/RecordTarpJoins';
import { useRecordStockpilingStore } from '@/stores/RecordStockpiling';
import { useRecordNewTarpsInputsStore } from '@/stores/RecordNewTarpsInputs';
import { useRecordGCMStore } from '@/stores/RecordGCM';

import UnsavedItemCard from '@/site_components/UnsavedItemCard/index.vue';

export default {
  name: "UnsavedGCMWorksCard",
  props: ['GCMWorks'],
  components: {
    Button,
    Card,
    ConfirmDialog,
    SpeedDial,
    UnsavedItemCard,
  },
  data() { return {
    store: null,
    loading: false,
    speedDial: {
      visible: false,
      items: [
        {
          label: 'Save',
          icon: 'upload',
          command: this.uploadGCMWorks
        },
        {
          label: 'Edit',
          icon: 'edit',
          command: this.editGCMWorks
        },
        {
          label: 'Delete',
          icon: 'delete',
          command: this.deleteGCMWorks
        },
      ]
    }
  }},
  created() {
    const recordTarpRepairsStore = useRecordTarpRepairsStore();
    const recordTarpJoinsStore = useRecordTarpJoinsStore();
    const recordStockpilingStore = useRecordStockpilingStore();
    const recordNewTarpsInputsStore = useRecordNewTarpsInputsStore();

    this.stores = {
      tarp_repair: recordTarpRepairsStore,
      tarp_join: recordTarpJoinsStore,
      stockpiling: recordStockpilingStore,
      new_tarps_input: recordNewTarpsInputsStore,
    }

    this.recordGCMStore = useRecordGCMStore();
  },
  methods: {

    async uploadGCMWorks() {
      this.loading = true;

      if (navigator.onLine) {
        try {
          const response = await this.GCMWorks.syncToServer();
          if (response.status === 201) {
            this.$root.successNotification('GCM works uploaded successfully!');
          } else {
            console.log(response)
            this.$root.errorNotification(`Error uploading GCM works - ${JSON.stringify(response.data)}`);
          }
        } catch (e) {
          this.$root.errorNotification(e);
        }
      } else {
        this.$root.noInternetConnectionDetected();
      }

      this.loading = false;
    },

    editGCMWorks() {
      const store = this.stores[this.GCMWorks.activity_type];

      store.$patch({ [this.GCMWorks.activity_type]: this.GCMWorks.toJSON() } );
      this.recordGCMStore.setActiveMaintenanceType(this.GCMWorks.activity_type);

      this.$router.push({
        name: 'gcm_record',
      });
    },

    deleteGCMWorks() {
      const deleteFunctions = {
        tarp_repair: this.stores.tarp_repair.removeTarpRepairFromLocal,
        tarp_join: this.stores.tarp_join.removeTarpJoinFromLocal,
        stockpiling: this.stores.stockpiling.removeStockpilingFromLocal,
        new_tarps_input: this.stores.new_tarps_input.removeNewTarpsInputFromLocal,
      }

      try {
        deleteFunctions[this.GCMWorks.activity_type](this.GCMWorks.client_id);
        this.$root.successNotification('GCM works deleted.');
      } catch (e) {
        this.$root.errorNotification("Error deleting GCM works.");
      }
    },

  }
}

</script>

