<template>
  <div class="flex flex-column h-full">
    <div class="flex-grow-0 mb-2">
      <div class="flex gap-2">
        <div class="flex-grow-1">
          <form>
            <NumberInput 
              inputId="serialNoFilter" 
              ref="serialNoFilter" 
              v-model="search_serial_no" 
              class="w-full" 
              placeholder="Filter by serial number..." 
              :useGrouping="false"
              @input="filterTarps"
              @focus="resetSearch"
            />
          </form>
        </div>
        <div class="flex-grow-0">
          <Button severity="info" class="h-full" text outlined @click="refreshTarps" :loading="refreshingTarps">
            <template #icon>
              <span class="material-symbols-outlined">refresh</span>
            </template>
          </Button>
        </div>
        <div class="flex-grow-0">
          <Button label="+ New" severity="info" class="h-full" @click="addNew" outlined />
        </div>
      </div>
    </div>
    <div class="flex-grow-1 list-wrapper overflow-scroll">
      <TarpListItem v-for="tarp in tarps"
        :key="tarp.id" :tarp="tarp"
        :isSelected="selectedTarpIds.includes(tarp.id)"
        :multiple="multiple"
        @selectTarp="selectTarp(tarp)"
      />
      <div v-if="moreResults" class="flex justify-center p-fluid py-3 px-2">
        <Button severity="info" label="Load more..." @click="loadMore" :loading="loadingMore" text />
      </div>
    </div>
    <div class="flex-grow-0 mb-2">
      <div class="field p-fluid">
        <Button severity="info" :label="selectBtnLabel" :disabled="this.selectedTarps.length === 0" @click="selectTarps"></Button>
      </div>
    </div>
  </div>
</template>

<script>
import { useBaseTarpsStore } from '@/stores/BaseTarps';
import { useTarpInventoryStore } from '@/stores/TarpInventory';

import Button from 'primevue/button';
import NumberInput from '@/components/forms/NumberInput.vue';

import TarpListItem from './TarpListItem.vue';

export default {
  name: "SelectTarpsMenu",
  components: {
    Button,
    NumberInput,
    TarpListItem,
  },
  props: ['multiple', 'ordering', 'site', 'baseTarps'],
  inject: ['dialogRef'],
  data() { return {
    tarps: [],
    selectedTarps: [],
    search_serial_no: null,
    store: null,
    refreshingTarps: false,
    loadingMore: false,
    moreResults: false,
  }},
  created() {
    if (this.baseTarps === true) {
      this.store = useBaseTarpsStore();
    } else {
      this.store = useTarpInventoryStore();
    }
    
    if (this.ordering === 'serial_no') {
      this.tarps.sort((a, b) => {
        if (a.serial_no < b.serial_no) return -1;
        if (a.serial_no > b.serial_no) return 1;
        return 0;
      });
      return;
    } else {
      // Sort tarps by site name, then stack name, then serial number. 
      // Show the tarps that are on this site first.
      const site = this.site;
      this.tarps.sort((a, b) => {
        if (a.site && b.site) {
          if (a.site.id === site.id && b.site.id !== site.id) return -1;
          if (a.site.id !== site.id && b.site.id === site.id) return 1;
        }
        if (a.site && !b.site) return -1;
        if (!a.site && b.site) return 1;
        if (a.site && b.site) {
          if (a.site.name < b.site.name) return -1;
          if (a.site.name > b.site.name) return 1;
        }
        if (a.stack && b.stack) {
          if (a.stack.name < b.stack.name) return -1;
          if (a.stack.name > b.stack.name) return 1;
        }
        if (a.serial_no < b.serial_no) return -1;
        if (a.serial_no > b.serial_no) return 1;
        return a.serial_no - b.serial_no;
      });
    }
  },
  mounted() {
    this.filterTarps();
    this.$nextTick(() => {
      this.$refs.serialNoFilter.$el.querySelector('input').focus();
    });
  },
  computed: {
    selectedTarpIds() {
      return this.selectedTarps.map(t => t.id);
    },

    canAddNew() {
      return this.dialogRef.data?.canAddNew || true;
    },
    selectBtnLabel() {
      if (this.selectedTarps.length === 0) return 'Select';
      if (this.selectedTarps.length === 1) return 'Select 1 tarp';
      return `Select ${this.selectedTarps.length} tarps`;
    },
  },
  methods: {
    selectTarp(tarp) {
      if (this.multiple) {
        if (this.selectedTarpIds.includes(tarp.id)) {
          this.selectedTarps = this.selectedTarps.filter(t => t.id !== tarp.id);
        } else {
          this.selectedTarps.push(tarp);
        }
      } else {
        this.$emit('selectTarp', tarp)
      }
    },
    selectTarps() {
      this.$emit('selectTarps', this.selectedTarps);
    },

    createNewTarp() {
      this.$emit('createTarp');
    },

    filterChanged() {
      this.filterTarps();
    },
    filterTarps(from=null, append=false) {
      const [tarps, moreResults] = this.store.filterTarps(this.search_serial_no, from, 20);
      if (append === true) {
        this.tarps = [...this.tarps, ...tarps];
      } else {
        this.tarps = tarps;
      }
      this.moreResults = moreResults;
    },
    loadMore() {
      const from = this.tarps[this.tarps.length - 1].id
      this.loadingMore = true;
      this.filterTarps(from, true)
      this.loadingMore = false;
    },

    addNew() {
      this.$emit('addNew', {
        selectedTarps: this.selectedTarps,
        searchSerialNo: this.search_serial_no,
      });
    },

    async refreshTarps() {
      this.refreshingTarps = true;
      await this.store.loadTarps(true);
      this.refreshingTarps = false;
    },

    resetSearch() {
      this.search_serial_no = null;
      this.filterTarps();
    },
  }
}

</script>

<style scoped>
.list-wrapper {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
</style>