<template>
  <div class="field p-fluid">
    <label :for="inputId">{{ label }}</label>
    <p class="subtitle">
      {{ subtitle }}
    </p>
    <slot :inputId="inputId"></slot>
    <slot name="message">
      <Message v-if="error" :severity="errorSeverity" :text="error" />
    </slot>
  </div>
</template>

<script>
import Message from 'primevue/message';

export default {
  name: "Field",
  components: {
    Message
  },
  props: {
    label: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: false
    },
    error: {
      type: String,
      required: false
    },
    errorSeverity: {
      type: String,
      default: "error"
    },
  },
  created() {
    // Generate a random string for the inputId
    this.inputId = Math.random().toString(36).substring(7);
  }
}

</script>