<template>
  <div class="p-3">
    <div v-for="report_type in reports" :key="report_type.report_type">
      <ReportTypeSection :report_type="report_type" />
      <Divider />
    </div>
  </div>
</template>

<script>
import { useTarpingReportsStore } from '@/stores/TarpingReports';

import ReportTypeSection from './ReportTypeSection.vue';

import Button from 'primevue/button';
import Divider from 'primevue/divider';

export default {
  name: "ReportsMenu",
  props: ['app_section'],
  components: {
    Button,
    Divider,
    ReportTypeSection,
  },
  data() { return {
    viewReady: true,
    loading: false,
    store: null,
  }},
  computed: {
    reports() {
      return this.store.getReports.filter(report => report.app_section === this.app_section);
    }
  },
  async created() {
    this.store = useTarpingReportsStore();
  },
}

</script>