<template>
  <div>
    <div class="steps flex flex-row justify-content-between">
      <RouterLink v-for="(step, index) in steps"
        :key="step.name"
        :to="currentRoute"
        class="flex-grow-1"
        :class="{'p-disabled': index > invalidStepIndex && disableAfterInvalidSteps}"
        @click="setActiveStepIndex(index)">
        <div
          class="step flex flex-column justify-content-center align-items-center"
          :class="{ active: activeStepIndex == index }">
          <span class="step-index">{{ index + 1 }}</span>
          <span class="step-label">{{ step.label }}</span>
        </div>
      </RouterLink>
    </div>
  </div>
</template>

<script>
import Divider from 'primevue/divider';
import Steps from 'primevue/steps';

export default {
  name: "FormSteps",
  components: {
    Divider,
    Steps,
  },
  props: {
    steps: {
      type: Array,
      required: true
    },
    activeStepIndex: {
      type: Number,
      required: true
    },
    disableAfterInvalidSteps: {
      // Disables steps after the current step if the current step is invalid.
      // Steps are considered invalid if the `isValid` property is set to `false`.
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentRoute() {
      return this.$route;
    },

    invalidStepIndex() {
      // Get the index of the first invalid step
      const invalidStep = this.steps.findIndex(step => step.isValid === false);
      if (invalidStep > -1) {
        return invalidStep;
      }
      return this.steps.length;
    }
  },
  methods: {
    setActiveStepIndex(index) {
      this.$emit('setActiveStepIndex', index);
    }
  }
}

</script>

<style scoped>
.steps {
    background: white;
    gap: 10px;
}
.step span {
    text-align: center;
    color: #808080;
}
.step .step-index {
    color: black;
    font-size: 1rem;
    font-weight: bold;
    background: #E2DED0;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    border-radius: 100%;
}
.step.active .step-index {
    background: var(--green-color);
}
.step.active span {
    color: #000000;
}
</style>