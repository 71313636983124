<template>
  <div>
  <Card class="bg-blue unsaved-item-card" :pt="{ body: { class: 'py-0' } }">
    <template #content>
      <div class="flex justify-content-between align-content-center align-items-center">
        <div>
          <div class="flex align-content-center align-items-center">
            <div>
              <p class="title text-lg font-medium text-white">
                {{ label }}
              </p>
              <p v-if="completion_time" class="text-white subtitle mb-0">{{ new Date(completion_time).toLocaleString() }}</p>
            </div>
          </div>
        </div>
        <div class="speeddial-container">
          <SpeedDial
            v-if="!loading"
            :model="speedDial.items"
            direction="left"
            showIcon="pi pi-bars"
            hideIcon="pi pi-times" 
            buttonClass="p-button-outlined speeddial-btn bg-white"
            @show="speedDial.visible = true"
            @hide="speedDial.visible = false" >

              <template #icon="vars">
                <span v-if="!vars.visible" class="material-symbols-outlined">more_horiz</span>
                <span v-else class="material-symbols-outlined">close</span>
              </template>

              <template #item="slotProps">
                <Button class="speeddial-btn bg-white text-primary p-button-icon-only mr-2" raised rounded @click="slotProps.item.command">
                  <span class="material-symbols-outlined">{{ slotProps.item.icon }}</span>
                </Button>
              </template>
          </SpeedDial>
          <Button v-else class="speeddial-btn loading bg-white p-button-icon-only" :loading="loading" />
        </div>
        <div class="overlay" v-show="speedDial.visible"></div>
        <ConfirmDialog :group="item.client_id">
          <template #accepticon>
              <span class="material-symbols-outlined mr-1">delete</span>
          </template>
          <template #rejecticon>
              <span></span>
          </template>
        </ConfirmDialog>
      </div>
    </template>
  </Card>
  </div>
</template>

<script>
import Button from 'primevue/button';
import Card from 'primevue/card';
import SpeedDial from 'primevue/speeddial';
import ConfirmDialog from 'primevue/confirmdialog';

export default {
  name: "UnsavedItemCard",
  props: {
    item: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    completion_time: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Button,
    Card,
    SpeedDial,
    ConfirmDialog,
  },
  data() { return {
    speedDial: {
      visible: false,
      items: [
        { label: 'Save', icon: 'upload', command: this.save },
        { label: 'Edit', icon: 'edit', command: this.edit },
        { label: 'Delete', icon: 'delete', command: this.delete },
      ],
    },
  }},

  methods: {
    save() {
      this.speedDial.visible = false;
      this.$emit('saveItem', this.item);
    },
    edit() {
      this.speedDial.visible = false;
      this.$emit('editItem', this.item);
    },
    delete() {
      this.$confirm.require({
        group: this.item.client_id,
        message: 'Are you sure you want to delete this item?',
        header: 'Delete Confirmation',
        acceptClass: 'p-button-danger',
        acceptLabel: 'Delete',
        accept: this.confirmDelete,
        reject: () => {},
      });
    },

    confirmDelete() {
      this.$emit('deleteItem', this.item);
    },
  },
}

</script>

<style>

.unsaved-item-card {
  position: relative;
}
.unsaved-item-card .speeddial-container {
  position: relative;
  width: 60px;
  height: 50px;
  text-align: right;
}
.unsaved-item-card .speeddial-btn {
  width: 50px !important;
  height: 50px !important;
  margin: 0;
  min-width: 0 !important;
  border-radius: 100% !important;
}
.unsaved-item-card .speeddial-btn.loading {
  background-color: white !important;
}
.unsaved-item-card .p-speeddial-direction-left {
  right: 0;
}

.unsaved-item-card .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0.5;
  border-radius: 4px;
}

</style>