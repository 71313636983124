<template>
  <span class="material-symbols-outlined">
    {{ icon }}
  </span>
</template>

<script>

export default {
  name: "MaterialIcon",
  props: {
    icon: {
      type: String,
      required: true
    }
  }
}

</script>