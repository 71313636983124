<template>
  <Field v-slot="slotProps">
    <div v-for="option in options" :key="option.value" class="mt-1 mb-1">
      <RadioSelect
        :inputId="slotProps.inputId"
        :modelValue="modelValue"
        :value="option.value"
        :label="option.label"
        :multiple="multiple"
        :binary="binary"
        @update:modelValue="update"
      />
    </div>
  </Field>
</template>

<script>
import RadioSelect from './RadioSelect.vue';
import Field from './Field.vue';

export default {
  name: "RadioSelectField",
  props: {
    modelValue: {
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    binary: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Field,
    RadioSelect,
  },
  methods: {
    update(value) {
      this.$emit('update:modelValue', value);
    }
  }
}

</script>