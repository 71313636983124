<template>
  <div class="flex flex-column h-full">
    <div class="flex-grow-0 mb-2">
      <div class="flex gap-2">
        <div class="flex-shrink-1 flex-grow-1">
          <form>
            <NumberInput 
              inputId="tarps" 
              ref="serialNoFilter" 
              v-model="search_serial_no" 
              class="w-full" 
              placeholder="Filter by serial number..." 
              :useGrouping="false"
              @input="filterChanged"
            />
          </form>
        </div>
        <div class="card flex justify-content-center">
          <Button 
            type="button" 
            icon="pi pi-ellipsis-v" 
            @click="toggleNewTarpMenu" 
            aria-haspopup="true" 
            aria-controls="overlay_menu"
            outlined>
            <template #icon>
              <span class="material-symbols-outlined">add</span>
            </template>
          </Button>
          <Menu ref="menu" id="overlay_menu" :model="newTarpMenuItems" :popup="true" />
        </div>
      </div>
    </div>
    <div class="flex-grow-1 list-wrapper">
      <div v-for="item in baseTarps" :key="item.id" :class="['flex align-items-center p-2']">
        <div class="flex-grow-1">
          <p class="font-semibold mb-0">{{ item.serial_no }}</p>
          <p><small>
            <span v-if="item.length && item.width">{{ item.width }}m x {{ item.length }}m</span>
            <span>&nbsp;&middot;&nbsp;{{ item.coverTypeDisplayShort() }}</span>
          </small></p>
        </div>
        <div class="flex-grow-0">
          <Button label="Select" size="small" severity="info" @click="select(item)" />
        </div>
      </div>
      <div v-if="moreResults" class="flex justify-center p-fluid py-3 px-2">
        <Button label="Load more..." @click="loadMore" :loading="loadingMore" outlined />
      </div>
    </div>

    <!-- New IAS SN Modal -->
    <NewTarpModal
      ref="newIASTarpModal"
      :visible="newIASTarpModalVisible"
      :formOptions="newIASTarpFormOptions"
      @onCreate="createNewTarp"
      @close="closeNewTarpModal"
    />

    <!-- New CBH SN Modal -->
    <NewTarpModal
      ref="newCBHTarpModal"
      :visible="newCBHTarpModalVisible"
      :formOptions="newCBHTarpFormOptions"
      @onCreate="createNewTarp"
      @close="closeNewTarpModal"
    />

  </div>
</template>

<script>
import { useBaseTarpsStore } from '@/stores/BaseTarps';
import { useRecordGCMStore } from '@/stores/RecordGCM';

import Button from 'primevue/button';
import Menu from 'primevue/menu';

import NumberInput from '@/components/forms/NumberInput.vue';

import NewTarpModal from '@/site_components/NewTarpModal/index.vue';

export default {
  name: "SelectBaseTarpMenu",
  components: {
    Button,
    Menu,
    NumberInput,
    NewTarpModal
  },
  inject: ['dialogRef'],
  data() { return {
    baseTarps: [],
    moreResults: false,
    loadingMore: false,
    search_serial_no: null,
    baseTarpsStore: null,
    newIASTarpModalVisible: false,
    newCBHTarpModalVisible: false,
    newTarpMenuItems: [
      { 
        label: 'New IAS SN',
        command: () => this.openNewTarpModal('ias'),
      },
      { 
        label: 'New CBH SN',
        command: () => this.openNewTarpModal('cbh') 
      },
    ]
  }},
  computed: {
    newIASTarpFormOptions () {
      const newTarpFormOptions = this.newTarpFormBaseOptions;
      return {
        ...newTarpFormOptions,
        serialNumberType: 'single',
        generateSerialNumber: true,
        excludeFields: this.newTarpFormExcludeFields + ['year_of_manufacture']
      }
    },
    newCBHTarpFormOptions () {
      const newTarpFormOptions = this.newTarpFormBaseOptions;
      return {
        ...newTarpFormOptions,
        serialNumberType: 'single',
        generateSerialNumber: false,
        excludeFields: this.newTarpFormExcludeFields
      }
    },
    newTarpFormBaseOptions() {
      return this.dialogRef.data?.newTarpFormOptions || {};
    },
    newTarpFormExcludeFields() {
      if (this.recordGCMStore.activeMaintenanceType === 'tarp_repair') {
        return ['width', 'length', 'condition'];
      }
      return [];
    }
  },
  created() {
    this.recordGCMStore = useRecordGCMStore();
    this.baseTarpsStore = useBaseTarpsStore();
    // Get first 50 base tarps in store
    this.filterBaseTarps();
  },
  mounted() {
    // Focus on search input when the modal is opened
    this.$nextTick(() => {
      this.$refs.serialNoFilter.$el.querySelector('input').focus();
    });
  },
  methods: {
    select(baseTarp) {
      this.$emit('selectBaseTarp', baseTarp)
    },
    
    filterChanged() {
      this.filterBaseTarps();
    },
    filterBaseTarps(from=null, append=false) {
      const [baseTarps, moreResults] = this.baseTarpsStore.filterBaseTarps(this.search_serial_no, from, 20, this.dialogRef.data?.baseTarps);
      if (append === true) {
        this.baseTarps = [...this.baseTarps, ...baseTarps];
      } else {
        this.baseTarps = baseTarps;
      }
      this.moreResults = moreResults;
    },
    loadMore() {
      const from = this.baseTarps[this.baseTarps.length - 1].id
      this.loadingMore = true;
      this.filterBaseTarps(from, true)
      this.loadingMore = false;
    },

    toggleNewTarpMenu(event) {
      this.$refs.menu.toggle(event);
    },
    openNewTarpModal(type) {
      // TODO: Set new tarp serial no value as the current search value
      if (type === 'ias') {
        this.$refs.newIASTarpModal.set({ serial_no: String(this.search_serial_no) });
        this.newIASTarpModalVisible = true;
      } else if (type === 'cbh') {
        // Set the search value as the serial no
        this.$refs.newCBHTarpModal.set({ serial_no: String(this.search_serial_no) });
        this.newCBHTarpModalVisible = true;
      }
    },
    closeNewTarpModal() {
      this.newIASTarpModalVisible = false;
      this.newCBHTarpModalVisible = false;
    },

    createNewTarp(tarp) {
      this.$emit('createNewTarp', tarp);
    }

  }
}

</script>

<style scoped>
.list-wrapper {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  overflow: scroll;
}
</style>