<template>
  <div v-if="form.activities.includes('cbh_supervisor_input')" class="field p-fluid">
    <Divider />
    <label for="toe_location">Toe Location</label>
    <NumberInput
      id="toe_location"
      v-model="form.toe_location" 
      placeholder="The value must be a number"
    />
    <StorageLengthWarning 
      :value="form.toe_location"
      :storageLength="store.selectedStorageLength" />
  </div>
</template>

<script>
import { useRecordTarpingWorksStore } from '@/stores/RecordTarpingWorks';

import StorageLengthWarning from '../../../components/StorageLengthWarning.vue';
import NumberInput from '@/components/forms/NumberInput.vue';
import Divider from 'primevue/divider';

export default {
  name: 'PeakActual',
  components: {
    StorageLengthWarning,
    NumberInput,
    Divider,
  },
  created() {
    this.store = useRecordTarpingWorksStore();
  },

  computed: {
    form() {
      return this.store.form;
    },
    showToeLocation() {
      return this.form.activities.includes('cbh_supervisor_input');
    },
  }
}
</script>