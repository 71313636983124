import { defineStore } from 'pinia';
import { getOrCreateDB, getOrCreateItem, updateStoreLastUpdated } from './idb.js';

import TarpingReportsAPI from './api/TarpingReports.js';


export const useTarpingReportsStore = defineStore('tarpingReports', {
  persist: true,

  state: () => ({ 
    reports: [],
    lastUpdated: null,
  }),

  actions: {

    async loadTarpingReportsFromAPI() {
      // Fetch sites from API and update indexedDB
      const api = new TarpingReportsAPI();
      const response = await api.getTarpingReports();

      const data = response.data;
      this.reports = data;

      this.cleanUpReportsDB();

      return response
    },

    async loadTarpingReportsFromDB() {
      console.log('Loading tarping reports from indexedDB...')
      const db = await getOrCreateDB();
      const tx = db.transaction(['tarpingReports', 'meta'], 'readwrite');
      const tarpingReportsStore = tx.objectStore('tarpingReports');
      const tarpingReports = await tarpingReportsStore.getAll();
      console.log(`${tarpingReports.length} reports loaded from indexedDB`);
      this.reports = tarpingReports;

      const tarpingReportsMeta = await getOrCreateItem('meta', 'tarpingReports');
      const favourites = tarpingReportsMeta.favourites || [];
      this.favourites = favourites;
      const lastUpdated = tarpingReportsMeta.lastUpdated;
      (lastUpdated) ? this.lastUpdated = lastUpdated : this.lastUpdated = null;
      
      await tx.done;
    },

    async loadTarpingReports(awaitAPI) {
      if (navigator.onLine) {
        const response = this.loadTarpingReportsFromAPI();
        if (awaitAPI) await response;
      }
    },

    async updateTarpingReportsDB(data) {
      // Update tarps indexedDB
      const db = await getOrCreateDB();
      const tx = db.transaction(['tarpingReports', 'meta'], 'readwrite');
      const tarpingReportsStore = tx.objectStore('tarpingReports');
      // Clear existing data
      await tarpingReportsStore.clear();
      // Add data
      data?.forEach(site => {
        tarpingReportsStore.add(site)
      });
      // Update last updated timestamp
      const now = Date.now();
      this.lastUpdated = now;
      console.log(`Updating last updated timestamp to ${now}`);
      updateStoreLastUpdated('tarpingReports', now, tx);
      await tx.done;
    },

    async getGeneratedReportSignedURL(reportId) {
      const api = new TarpingReportsAPI();
      const response = await api.getGeneratedReportSignedURL(reportId);
      return response.data.url;
    },

    async cleanUpReportsDB() {
      // Get a list of the generated report ids from the reports and 
      // delete any downloaded generated reports that are not in the list
      const generatedReports = [];
      this.reports.forEach(report_type => {
        const reports = report_type.reports;
        generatedReports.push(...reports.map(report => report.generated_reports));
      });
      const generatedReportIds = generatedReports.flat().map(report => report.id);
      
      const db = await getOrCreateDB();
      const tx = db.transaction(['tarpingReports'], 'readwrite');
      const store = tx.objectStore('tarpingReports');

      const downloadedIds = await store.getAllKeys();

      const idsToDelete = downloadedIds.filter(id => !generatedReportIds.includes(id));
      console.log('Deleting downloaded reports: ', idsToDelete);

      idsToDelete.forEach(id => {
        store.delete(id);
      });
    }
  },

  getters: {

    getReports: (state) => {
      return state.reports;
    }

  }
})