<template>

    <div class="p-fluid text-center relative">
        <div class="horizontal-line"></div>
        <Button severity="info" size="large" rounded raised @click="onClick">
            <template #icon>
                <span class="material-symbols-outlined">add</span>
            </template>
        </Button>
    </div>

</template>


<script>
import Button from 'primevue/button';

export default {
    name: "CreateNewItemBtn",
    props: ['label'],
    components: {
        Button,
    },
    methods: {
        onClick() {
            this.$emit('onClick');
        }
    }
}

</script>

<style scoped>

.horizontal-line {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--blue-color); /* Adjust as needed */
  opacity: 0.5;
  transform: translateY(-50%);
}

</style>