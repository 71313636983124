class Supervisor {

  id = null;
  email = null;
  first_name = null;
  last_name = null;

  // Adapters

  toJSON() {
    return {
      id: this.id,
      email: this.email,
      first_name: this.first_name,
      last_name: this.last_name,
    }
  }

  fromJSON(data) {
    if (!data) return this

    this.id = data.id;
    this.email = data.email;
    this.first_name = data.first_name;
    this.last_name = data.last_name;

    return this
  }

  displayName() {
    const firstName = this.first_name;
    const lastName = this.last_name;
    const email = this.email;
    if (firstName && lastName) return `${firstName} ${lastName}`;
    else if (firstName) return firstName;
    else if (lastName) return lastName;
    else if (email) return this.email;
    else return 'Unknown Supervisor'
  }

}

export default Supervisor
