<template>
  <Dialog 
    :visible="visible"
    modal 
    header="Create Progress Record" 
    :style="style"
    contentClass="flex-grow-1"
    @update:visible="closeModal"
  >
    <form>
      <div v-for="field in fields" :key="field.name" :class="field.class">
        <div v-if="!field.hide">
          <component 
            :is="field.component" 
            :modelValue="field.value"
            @update:modelValue="updateField(field.name, $event)"
            :label="field.label"
            :subtitle="field.subtitle"
            v-bind="field.props" />
          <Divider v-if="field.hasDivider !== false" />
        </div>
      </div>
      <div class="field p-fluid">
        <Button
          label="Save"
          severity="secondary"
          size="large"
          :disabled="!recordIsValid"
          type="button"
          @click="saveRecord"
          raised
        />
      </div>
    </form>
  </Dialog>
</template>

<script>
import { OBH_WORKS_ACTIVITIES } from '@/stores/enums/OBHM';
import { OBHWorksPartialProgressRecord } from '@/stores/objects/OBHWorks';

import Field from '@/components/forms/Field.vue';
import IntegerField from '@/components/forms/IntegerField.vue';
import RadioSelect from '@/components/forms/RadioSelect.vue';
import RadioSelectField from '@/components/forms/RadioSelectField.vue';

import StraightProgress from './components/StraightProgress.vue';

import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';

export default {
  name: "NewTarpModal",
  props: [
    'modelValue',
    'visible',
  ],
  components: {
    Field,
    RadioSelect,
    RadioSelectField,
    StraightProgress,
    Button,
    Dialog,
    Divider,
  },
  data() { return {
    style: {
      width: '100%',
      height: '100vh',
      maxHeight: '100vh',
      borderRadius: '0',
    },
    recordCopy: new OBHWorksPartialProgressRecord().toJSON(),
  }},
  computed: {
    fields() {
      const $this = this;

      const fields = [
        {
          name: 'activities',
          label: 'Activities',
          subtitle: 'Select the activities that are complete to the same point on the OBH',
          component: 'RadioSelectField',
          props: {
            options: OBH_WORKS_ACTIVITIES,
            multiple: true,
          },
          value: this.recordCopy.activities || [],
          update(val) {
            $this.recordCopy.activities = val;
          }
        },
        {
          name: '',
          label: 'Straight/Ends Progress',
          subtitle: 'Select all straights and ends that have had maintenance performed',
          component: 'Field',
          hasDivider: false,
        },
        {
          name: 'has_red_straight_progress',
          component: 'RadioSelect',
          props: {
            binary: true,
            label: 'Red Straight',
            value: true,
          },
          class: 'pb-1',
          hasDivider: false,
          value: $this.recordCopy.has_red_straight_progress,
        },
        {
          name: 'has_red_end_progress',
          component: 'RadioSelect',
          props: {
            binary: true,
            label: 'Red End',
            value: true,
          },
          class: 'pb-1',
          hasDivider: false,
          value: $this.recordCopy.has_red_end_progress,
        },
        {
          name: 'has_blue_straight_progress',
          component: 'RadioSelect',
          props: {
            binary: true,
            label: 'Blue Straight',
            value: true,
          },
          class: 'pb-1',
          hasDivider: false,
          value: this.recordCopy.has_blue_straight_progress,
        },
        {
          name: 'has_blue_end_progress',
          component: 'RadioSelect',
          props: {
            binary: true,
            label: 'Blue End',
            value: true,
          },
          class: 'pb-1',
          value: this.recordCopy.has_blue_end_progress,
        },
        {
          name: 'red_straight_progress',
          label: 'Red Straight Progress',
          component: 'StraightProgress',
          hide: !this.recordCopy.has_red_straight_progress,
          value: {
            start: this.recordCopy.red_straight_start_point,
            end: this.recordCopy.red_straight_end_point,
            is_complete: this.recordCopy.red_straight_is_complete,
          },
          update(val) {
            $this.updateField('red_straight_start_point', val.start);
            $this.updateField('red_straight_end_point', val.end);
            $this.updateField('red_straight_is_complete', val.is_complete);
          }
        },
        {
          name: 'red_end_progress_percentage',
          label: 'Red End Progress',
          subtitle: 'Select the approximate percentage of the red end that has been maintained',
          component: 'RadioSelectField',
          hide: !this.recordCopy.has_red_end_progress,
          value: this.recordCopy.red_end_progress_percentage,
          props: {
            options: [
              { label: '25%', value: 25 },
              { label: '50%', value: 50 },
              { label: '75%', value: 75 },
              { label: '100%', value: 100 },
            ],
            multiple: false,
          },
        },
        {
          name: 'blue_straight_progress',
          label: 'Blue Straight Progress',
          component: 'StraightProgress',
          hide: !this.recordCopy.has_blue_straight_progress,
          value: {
            start: this.recordCopy.blue_straight_start_point,
            end: this.recordCopy.blue_straight_end_point,
            is_complete: this.recordCopy.blue_straight_is_complete,
          },
          update(val) {
            $this.updateField('blue_straight_start_point', val.start);
            $this.updateField('blue_straight_end_point', val.end);
            $this.updateField('blue_straight_is_complete', val.is_complete);
          }
        },
        {
          name: 'blue_end_progress_percentage',
          label: 'Blue End Progress',
          subtitle: 'Select the approximate percentage of the blue end that has been maintained',
          component: 'RadioSelectField',
          hide: !this.recordCopy.has_blue_end_progress,
          value: this.recordCopy.blue_end_progress_percentage,
          props: {
            options: [
              { label: '25%', value: 25 },
              { label: '50%', value: 50 },
              { label: '75%', value: 75 },
              { label: '100%', value: 100 },
            ],
            multiple: false,
          },
        },
      ]
      return fields;
    },

    getProgressRecord() {
      return new OBHWorksPartialProgressRecord().fromJSON(this.recordCopy);
    },

    recordIsValid() {
      return this.getProgressRecord.isValid();
    }
  },
  created() {
    this.resetRecordCopy();
  },
  watch: {
    modelValue(val) {
      this.resetRecordCopy();
    }
  },
  methods: {
    resetRecordCopy() {
      this.recordCopy = { ...this.modelValue }
    },

    updateField(fieldName, value) {
      const field = this.fields.find(f => f.name === fieldName);
      if (field?.update) {
        field.update(value);
      } else {
        this.recordCopy = { ...this.recordCopy, [fieldName]: value };
      }
    },

    saveRecord() {
      this.$emit('saveRecord', this.recordCopy);
      this.closeModal();
    },
    closeModal() {
      this.$emit('close');
    },
  }
};
</script>