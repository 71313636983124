<template>
  <div class="flex align-items-center mt-2">
    <span class="material-symbols-outlined mr-2 cursor-pointer" @click="goBack">arrow_back</span>
    <h2 class="font-semibold">
      {{ activeOBHMType.label }}
    </h2>
  </div> 
</template>

<script>
import { useRecordOBHMStore } from '@/stores/RecordOBHM';

import Card from 'primevue/card';

export default {
  name: "FormHeader",

  components: {
    Card,
  },

  created() {
    this.obhmStore = useRecordOBHMStore();
  },

  computed: {
    activeOBHMType() {
      return this.obhmStore.getActiveMaintenanceType;
    },
  },
  methods: {
    goBack() {
      this.obhmStore.setActiveMaintenanceType(null);
    }
  }
}

</script>