import { defineStore } from 'pinia';

import Tarp from '@/stores/objects/Tarp';

import { getOrCreateItem, deleteItem, getItems, addItems } from '@/stores/idb';
import { useBaseTarpsStore } from '@/stores/BaseTarps';
import { useTarpInventoryStore } from '@/stores/TarpInventory';


export const useRecordNewTarpStore = defineStore('recordNewTarp', {

  persist: {
    paths: ['tarp', 'extra_fields']
  },

  state: () => ({
    tarp: new Tarp().toJSON(),
    unsaved_tarps: [],
    
    // Submission status
    submitted: false,
    submitComplete: false,
    loading: false,
    saveResponse: null,
  }),

  actions: {

    init() {
      const resetSubmissionStatus = true;
      const resetForm = false;
      this.reset(resetSubmissionStatus, resetForm);
    },

    reset(resetSubmissionStatus = false, resetForm = true) {
      if (resetForm === true) {
        this.tarp = new Tarp().toJSON();
        const defaults = {
          width: 41,
          tarp_material: 'pvc',
          year_of_manufacture: new Date().getFullYear(),
          condition: '5'
        };
        this.tarp = { ...this.tarp, ...defaults }
      };
      if (resetSubmissionStatus) {
        this.submitted = false;
        this.submitComplete = false;
        this.loading = false;
        this.saveResponse = null;
      }
    },

    async saveTarp() {

      const data = this.tarp;
      console.log('Saving tarp', data);

      // Save the stockpilings to local device
      this.saveTarpToLocal(data);

      if (navigator.onLine) {
        try {

          this.loading = true;

          // syncToServer doesn't throw an error if the request fails.
          // Status is returned and handled by the component calling this action.
          const response = await new Tarp().syncToServer(data);
          this.saveResponse = response;

          this.loading = false;

          return response;

        } catch (error) {
          throw error;
        }
      } else {
        this.saveResponse = { status: 0 };  // Offline
        return this.saveResponse
      }
    },

    async saveTarpToLocal(tarp) {
      // Check if the tarp have already been added to the unsaved_tarps array
      const existingTarps = this.unsaved_tarps.filter(t => t.client_id === t.client_id);
      if (existingTarps.length) {
        // If the tarping works already exist in the array, replace them with the new tarping works
        this.unsaved_tarps = this.unsaved_tarps.map(t => {
          if (t.client_id === t.client_id) {
            return t
          }
          return t
        });
      } else {
        // Add tarp to the unsaved_tarps array
        this.unsaved_tarps.push(tarp);
      }
      // Save the unsaved tarp join to indexedDB
      await this.saveUnsavedTarpToDB(tarp);
      // Refresh the unsaved tarps from indexedDB
      this.loadUnsavedTarpsFromDB();
    },

    removeUnsavedTarpFromLocal(clientId) {
      this.unsaved_tarps = [...this.unsaved_tarps.filter(t => t.client_id !== clientId)];
      this.removeUnsavedTarpFromDB(clientId);
    },

    addToTarpInventoryStore(tarp) {
      const tarpInventoryStore = useTarpInventoryStore();
      tarpInventoryStore.addTarp(tarp);
    },

    addToBaseTarpsStore(baseTarps) {
      const baseTarpsStore = useBaseTarpsStore();
      baseTarpsStore.addBaseTarps(baseTarps);
    },

    async saveUnsavedTarpToDB(tarp) {
      await getOrCreateItem('unsavedTarps', tarp.client_id, tarp);
    },

    async removeUnsavedTarpFromDB(clientId) {
      await deleteItem('unsavedTarps', clientId);
    },

    async loadUnsavedTarpsFromDB() {
      const unsavedTarps = await getItems('unsavedTarps');
      this.unsaved_tarps = unsavedTarps;
    },

    async generateSerialNo(count = 1, set = false) {
      const tarp = new Tarp();
      const response = await tarp.generateSerialNo(count);
      const serialNumbers = response?.data?.serial_numbers;
      if (serialNumbers) {
        const serialNumber = serialNumbers.join('/');
        if (set) {
          this.tarp.serial_no = serialNumber
        }
        return serialNumber;
      }
      return null;
    }

  },

  getters: {
    getErrors() {
      if (this.saveResponse?.status === 400) {
        return this.saveResponse?.data;
      }
      return [];
    },

    getUnsavedTarps() {
      return this.unsaved_tarps.map(t => new Tarp().fromJSON(t));
    }
  }

})