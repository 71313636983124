<template>
  <form>
    <div v-if="authStore.loginForm.incorrectCredentials === true" class="field p-fluid">
      <Message severity="error" :closable="false">
          Incorrect login credentials provided.
      </Message>
    </div>
    <div class="field p-fluid">
      <label for="username">Email</label>
      <InputText type="email" v-model="authStore.loginForm.email" />
    </div>
    
    <div class="field p-fluid">
      <label for="password">Password</label>
      <Password inputClass="w-full" v-model="authStore.loginForm.password" :feedback="false" />
    </div>
    <Divider />
    <div class="mt-3">
      <Button class="w-full btn"
        label="Submit" 
        severity="secondary" 
        size="large" 
        :loading="authStore.loginForm.loading" 
        @click="login" />
    </div>
    <p class="text-center block mt-3" @click="forgotPassword">Forgot password?</p>
  </form>
</template>

<script>
import Button from 'primevue/button';
import Divider from 'primevue/divider';
import InputText from 'primevue/inputtext';
import Message from 'primevue/message';
import Password from 'primevue/password';

import { useAuthStore } from '@/stores/Auth';

import ResetPassword from './ResetPassword.vue';

export default {
  name: 'LoginForm',
  components: {
    Button,
    Divider,
    InputText,
    Message,
    Password,

  },
  data() { return {
    email: null,
    password: null,
    loading: false,
  }},
  created() {
    this.authStore = useAuthStore();
  },
  methods: {
    async login() {
      try {
        const response = await this.authStore.login(this.email, this.password);
        if (response.status === 200) {
          this.$emit('login');
        }
      } catch(err) {
        console.error(err);
        this.$root.errorNotification(err.message);
      }
    },

    forgotPassword() {
      this.restPasswordModal = this.$dialog.open(ResetPassword, {
        props: {
          header: "Reset Password",
          style: {
            width: '100vw',
            height: '100vh',
            maxHeight: '100vh',
            borderRadius: '0',
          },
          modal: true,
          dismissableMask: true,
          contentClass: 'flex-grow-1',
        },
        emits: {},
      });
    }
  }
}

</script>