<template>
  <div>
    <div v-if="!previousTarpJoin" class="field p-fluid">
      <label :for="`tj-left-base-tarp-${index}`">Serial Number 1</label>
      <NumberInput
        :inputId="`tj-left-base-tarp-${index}`"
        :ref="`tj-left-base-tarp-${index}`"
        v-model="tarpJoin.left_base_tarp" 
        class="w-full" 
        :useGrouping="false"
        :maxFractionDigits="0"
        :min="0"
      />
    </div>
    <div class="field p-fluid">
      <label :for="`tj-join-${index}`">Join Type</label>
      <Dropdown 
        :inputId="`tj-join-${index}`" 
        :ref="`tj-join-${index}`" 
        v-model="tarpJoin.join_type" 
        :options="joinTypes" 
        optionLabel="label"
        optionValue="value"
        placeholder="Select join type..." 
        class="w-full md:w-14rem"
      />
    </div>
    <div class="field p-fluid">
      <label :for="`tj-right-base-tarp-${index}`">Serial Number 2</label>
      <NumberInput
        :inputId="`tj-right-base-tarp-${index}`" 
        :ref="`tj-right-base-tarp-${index}`" 
        v-model="tarpJoin.right_base_tarp" 
        class="w-full" 
        :useGrouping="false"
        :maxFractionDigits="0"
        :min="0"
      />
    </div>
  </div>
</template>

<script>
import { useRecordTarpJoinsStore } from '@/stores/RecordTarpJoins';
import { TARP_JOIN_TYPES } from '@/stores/enums/GCM';

import NumberInput from '@/components/forms/NumberInput.vue';

import Dropdown from 'primevue/dropdown';

export default {
  name: "TarpJoin",
  props: ['index'],
  components: {
    Dropdown,
    NumberInput
  },
  data() { return {
    joinTypes: TARP_JOIN_TYPES,
  }},
  created() {
    this.store = useRecordTarpJoinsStore();
  },
  computed: {
    tarpJoin() {
      return this.store.form.tarp_joins[this.index];
    },
    previousTarpJoin() {
      const previousIndex = this.index - 1;
      if (previousIndex < 0) {
        return null;
      }
      return this.store.form.tarp_joins[previousIndex];
    },
  }

}

</script>