<template>
  <Dialog 
    :visible="visible"
    modal 
    header="Create Tarp" 
    :style="style" 
    @update:visible="closeModal"
  >
    <NewTarpForm
      v-model="tarp"
      v-bind="formOptions"
      @onSubmit="createNewTarp"
    />
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';

import Tarp from '@/stores/objects/Tarp';
import NewTarpForm from '@/site_components/NewTarpForm/index.vue';

export default {
  name: "NewTarpModal",
  props: [
    'visible',
    'formOptions',
  ],
  components: {
    Dialog,
    NewTarpForm,
  },
  data() { return {
    style: {
      width: '100%',
      height: '100vh',
      maxHeight: '100vh',
      borderRadius: '0',
      backgroundColor: 'white',
    },
    tarp: new Tarp().toJSON(),
  }},
  methods: {
    closeModal() {
      this.$emit('close');
    },

    set(data) {
      this.tarp = { ...this.tarp, ...data };
    },
    reset() {
      this.tarp = new Tarp().toJSON();
    },

    createNewTarp() {
      this.$emit('onCreate', this.tarp);
      this.reset();
      this.closeModal();
    }
  }
};
</script>

