<template>
  <div class="field p-fluid">
    <Divider />
    <label for="comments">Comments</label>
    <Textarea id="comments" v-model="form.comments" rows="5" cols="30" autoResize />
  </div>
</template>

<script>
import { useRecordTarpingWorksStore } from '@/stores/RecordTarpingWorks';

import Divider from 'primevue/divider';
import Textarea from 'primevue/textarea';

export default {
  name: 'Comments',
  components: {
    Divider,
    Textarea,
  },
  created() {
    this.store = useRecordTarpingWorksStore();
  },
  computed: {
    form() {
      return this.store.form;
    },
  },
}
</script>