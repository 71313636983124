import { default as axios } from './http';


class SitesAPI {

    getSites = async () => {
        // Fetch stacks from API
        console.log('Fetching sites from API...')
        const url = '/api/v1/grain-sites/sites/';
        const response = await axios.get(url)
        return response
    }

}

export default SitesAPI;