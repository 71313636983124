<template>
  <div>
    <TarpJoin v-for="(tarp_join, index) in tarpJoins" :key="index" :index="index" />
  </div>
</template>

<script>
import { useRecordTarpJoinsStore } from '@/stores/RecordTarpJoins';

import TarpJoin from './components/TarpJoin.vue';

export default {
  name: "TarpJoinsWidget",
  components: {
    TarpJoin
  },
  created() {
    this.store = useRecordTarpJoinsStore();
  },
  computed: {
    tarpJoins() {
      return this.store.getTarpJoins;
    }
  }
}

</script>