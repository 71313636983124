<template>
  <div class="text-center">
    <p class="icon">
      <span class="material-symbols-outlined xxlg">signal_disconnected</span>
      <span class="material-symbols-outlined xxlg">security_update_good</span>
    </p>
    <p class="text-lg font-bold">
      No internet connection detected.
    </p>
    <p>
      Your data has been saved on this device and can be synced 
      to the server once internet connection is restored.
    </p>

    <div class="mt-3">
      <Button 
        label="Record another"
        severity="info"
        size="medium"
        @click="$emit('recordAnother')"
        raised />
    </div>
  </div>
</template>

<script>
import Button from 'primevue/button';

export default {
  name: "OfflineMsg",
  components: {
    Button,
  },
}
</script>

<style scoped>

.icon {
  color: var(--blue-color) !important;
}

</style>