<template>
  <Card class="border-1 surface-border">
    <template #title>
      <div class="flex justify-content-end align-items-center">
        <div>
          <Button severity="info" size="small" text @click="editRecord">
            <template #icon>
              <span class="material-symbols-outlined">edit</span>
            </template>
          </Button>
          <Button severity="danger" size="small" text @click="deleteRecord">
            <template #icon>
              <span class="material-symbols-outlined">delete</span>
            </template>
          </Button>
        </div>
      </div>
    </template>
    <template #content>
      <KeyValueList :items="displayItems" />
    </template>
  </Card>
</template>

<script>
import KeyValueList from '@/components/data/KeyValueList.vue';
import RadioSelectField from '@/components/forms/RadioSelectField.vue';

import Button from 'primevue/button';
import Card from 'primevue/card';
import Divider from 'primevue/divider';

export default {
  name: "PartialProgressRecord",
  components: {
    KeyValueList,
    RadioSelectField,
    Button,
    Card,
    Divider,
  },
  props: ['record', 'index'],
  computed: {
    displayItems() {
      return this.record.detailDisplayItems();
    }
  },
  methods: {
    deleteRecord() {
      this.$emit('deleteRecord', this.index);
    },
    editRecord() {
      this.$emit('editRecord', this.index);
    }
  }
}

</script>
