const ACTIVITIES = [
  { value: 'tarp_on_pull_out', display: 'Putting tarp on stack and pulling out' },
  { value: 'pulling_tarps_out', display: 'Pulling tarps (Out/Down/Up the Face)' },
  { value: 'perming', display: 'Perming' },
  { value: 'wozzying', display: 'Wozzying' },
  { value: 'small_end_wall', display: 'Small end wall' },
  { value: 'itp', display: 'ITP' },
  { value: 'cbh_supervisor_input', display: 'CBH Supervisor Input' },
]

export {
  ACTIVITIES,
}