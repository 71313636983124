<template>
    <div class="view flex flex-column">
        <GCMWorksTable />
    </div>
</template>

<script>
import GCMWorksTable from './components/GCMWorksTable.vue';

export default {
    name: "GCMHistoricalView",
    components: {
        GCMWorksTable,
    },
}
</script>

<style scoped>
.view {
    height: calc(100vh - 110px);;
    overflow: hidden;
}
</style>