import { defineStore } from 'pinia';

import { getOrCreateItem, deleteItem, getItems } from '@/stores/idb';
import { useTarpInventoryStore } from './TarpInventory';
import { useSiteWorksStore } from '@/stores/SiteWorks';

import SiteWorks from './objects/SiteWorks';


export const useRecordSiteWorksStore = defineStore('recordSiteWorks', {

  persist: {
    paths: ['site_works', 'activeStepIndex'],
  },

  state: () => ({
    site_works: new SiteWorks().toJSON(),

    unsaved_site_works: [],

    // Submission status
    activeStepIndex: 0,
    submitted: false,
    submit_complete: false,
    loading: false,
    saveResponse: null,
  }),

  actions: {

    init() {
      if (!this.site_works) this.site_works = new SiteWorks().toJSON();
      if (this.submitted) this.reset(true);
    },      

    removeTarp(index) {
      this.site_works.tarps_on_site.splice(index, 1);
    },

    reset(resetSubmissionStatus = false) {
      this.site_works = new SiteWorks().toJSON();
      this.activeStepIndex = 0;
      if (resetSubmissionStatus) {
        this.submitted = false;
        this.loading = false;
        this.saveResponse = null;
      }
    },

    async saveSiteWorks() {

      // Process data
      const siteWorks = new SiteWorks().fromJSON(this.site_works);
      siteWorks.clean();

      const data = siteWorks.toJSON();

      console.log('Saving tarp join', data);

      // Save the tarp joins to local device
      this.saveSiteWorksToLocal(data);

      if (navigator.onLine) {
        try {

          this.loading = true;

          // syncToServer doesn't throw an error if the request fails.
          // Status is returned and handled by the component calling this action.
          const response = await new SiteWorks().syncToServer(data);
          this.saveResponse = response;

          this.loading = false;

          return response;

        } catch (error) {
          throw error;
        }
      } else {
        this.saveResponse = { status: 0 };  // Offline
        return this.saveResponse
      }
    },
    
    saveSiteWorksToLocal(siteWorks) {
      // Check if the site works have already been added to the unsaved_site_works array
      const existingSiteWorks = this.unsaved_site_works.filter(sw => sw.client_id === siteWorks.client_id);
      if (existingSiteWorks.length) {
        // If the site works already exist in the array, replace them with the new site works
        this.unsaved_site_works = this.unsaved_site_works.map(sw => {
          if (sw.client_id === siteWorks.client_id) {
            return siteWorks
          }
          return sw
        });
      } else {
        // Add tarp join to the unsaved_site_works array
        this.unsaved_site_works.push(siteWorks);
      }
      // Save the unsaved tarp join to indexedDB
      this.saveUnsavedSiteWorksToDB(siteWorks);
    },

    removeSiteWorksFromLocal(clientId) {
      this.unsaved_site_works = [...this.unsaved_site_works.filter(sw => sw.client_id !== clientId)];
      this.removeUnsavedSiteWorksFromDB(clientId);
    },

    addToSiteWorksStore(siteWorks) {
      // Tarp join is returned with the newly created tarp and archived tarps.
      // Need to add the new tarp to the tarp store and remove the archived tarps.

      const tarpInventoryStore = useTarpInventoryStore();

      // Add the new tarp
      const tarps = siteWorks.created_tarps;
      for (const tarp of tarps) {
        tarpInventoryStore.addTarp(tarp);
      }

      // Add the tarp join to the tarp joins store
      const siteWorksStore = useSiteWorksStore();
      siteWorksStore.addSiteWorks(siteWorks);
    },

    async saveUnsavedSiteWorksToDB(siteWorks) {
      await getOrCreateItem('unsavedSiteWorks', siteWorks.client_id, siteWorks);
    },

    async removeUnsavedSiteWorksFromDB(clientId) {
      await deleteItem('unsavedSiteWorks', clientId);
    },

    async loadUnsavedSiteWorksFromDB() {
      const unsavedSiteWorks = await getItems('unsavedSiteWorks');
      this.unsaved_site_works = unsavedSiteWorks;
    },

  },

  getters: {

    getSiteWorks() {
      return new SiteWorks().fromJSON(this.site_works);
    },

    getUnsavedSiteWorks() {
      return this.unsaved_site_works.map(sw => new SiteWorks().fromJSON(sw));
    },

    getErrors() {
      if (this.saveResponse?.status === 400) {
        return this.saveResponse?.data;
      }
      return [];
    },

    formSteps() {
      const siteWorks = this.getSiteWorks
      return [
        {
          name: 'site_works',
          label: 'Site Works',
          valid: siteWorks.isValid(false),
        },
        {
          name: 'tarps_on_site',
          label: 'Tarps on Site',
          valid: siteWorks.isValid(true),
          disabled: !siteWorks.isValid(false),
        },
      ];
    }

  }
})