import axios from 'axios';
import { defineStore } from 'pinia';
import { getOrCreateDB, getOrCreateItem, updateStoreLastUpdated, getItems } from './idb.js';

import Site from './objects/Site.js';
import SitesAPI from './api/Sites.js';


export const useSitesStore = defineStore('sites', {
  persist: true,

  state: () => ({ 
    sites: [],
    favourites: [],
    lastUpdated: null,
  }),

  actions: {

    async loadSitesFromAPI() {
      // Fetch sites from API and update indexedDB
      const api = new SitesAPI();
      const response = await api.getSites();

      const data = response.data;
      this.sites = data;
      this.updateSitesDB(data);

      return response
    },

    async loadSitesFromDB() {
      console.log('Loading sites from indexedDB...')
      const sites = await getItems('sites')
      console.log(`${sites.length} sites loaded from indexedDB`);
      this.sites = sites;

      const sitesMeta = await getOrCreateItem('meta', 'sites');
      const favourites = sitesMeta.favourites || [];
      this.favourites = favourites;
      const lastUpdated = sitesMeta.lastUpdated;
      (lastUpdated) ? this.lastUpdated = lastUpdated : this.lastUpdated = null;

    },

    async loadSites(awaitAPI) {
      // Load sites from cache and then update from API in background
      await this.loadSitesFromDB();
      if (navigator.onLine) {
        const response = this.loadSitesFromAPI();
        if (awaitAPI) await response;
      }
    },

    async updateSitesDB(data) {
      // Update tarps indexedDB
      const db = await getOrCreateDB();
      const tx = db.transaction(['sites', 'meta'], 'readwrite');
      const sitesStore = tx.objectStore('sites');
      // Clear existing data
      await sitesStore.clear();
      // Add data
      data?.forEach(site => {
        sitesStore.add(site)
      });
      // Update last updated timestamp
      const now = Date.now();
      this.lastUpdated = now;
      console.log(`Updating last updated timestamp to ${now}`);
      updateStoreLastUpdated('sites', now, tx);
      await tx.done;
    },

    async toggleSiteFavourite(siteName) {
      // Toggle site favourite
      const site = await getOrCreateItem('sites', siteName);
      if (site) {
        // Toggle favourite
        const index = this.favourites.indexOf(siteName);
        if (index > -1) {
          // Remove from favourites
          this.favourites.splice(index, 1);
        } else {
          // Add to favourites
          this.favourites.push(siteName);
        }
        // Update favourites in meta
        const sitesMeta = await getOrCreateItem('meta', 'sites');
        sitesMeta.favourites = [...this.favourites];
        metaStore.put(sitesMeta);
      }
    }
  },

  getters: {

    getSites() {
      return this.sites.map(site => new Site().fromJSON(site))
    },

    getSiteById() {
      return (siteId) => {
        // Returns site object from site id
        const site = this.sites.find(site => site.id === siteId)
        if (site) return new Site().fromJSON(site)
        else      return null
      }
    },

    getSiteByName: (state) => {
      return (siteName) => {
        const site = state.sites.find(site => site.name === siteName)
        console.log('fwefgwefwef', site, siteName, state.sites)
        const siteObj = new Site();
        const value = siteObj.fromJSON(site)
        console.log(siteObj)
        return siteObj
      }
    },

    getSiteDisplayName: (state) => {
      return (siteName) => {
        const site = state.sites.find(site => site.name === siteName)
        if (site) {
          return new Site().fromJSON(site).displayName()
        }
      }
    },

    getSiteStorages: (state) => {
      return (siteName) => {
        const site = state.sites.find(site => site.name === siteName)
        if (site) {
          return site.storages
        }
        return []
      }
    },

    getStorage(state) {
      return (storageId) => {
        // Returns storage object from storage id
        const storage = state.sites.flatMap(site => site.storages).find(storage => storage.id === storageId)
        return storage
      }
    },

    getFavourites(state) {
      // Returns list of site objects from favourites
      const favourites = state.sites.filter(site => state.favourites.includes(site.name))
      return favourites.map(site => new Site().fromJSON(site))
    }
  }
})
