<template>
  <div v-if="form.activities.includes('perming')" class="field p-fluid">
    <Divider />
    <label for="permed">Permed</label>
    <p class="subtitle">
      The perming input is taken from the strut (closest to i.e. round up or down to the nearest strut) 
      that is in line with the last section that is permed.
      If a stack is closed out please write CLOSED and the strut number inline with the peak of the stack.
    </p>
    <NumberInput
      inputId="permed"
      v-model="form.permed" 
      placeholder="The value must be a number"
      :disabled="form.permed_max" />
    <div class="flex align-items-center mt-2">
      <Checkbox
        inputId="permed_max"
        v-model="permed_max" 
        name="permed_max" 
        binary
        :tabindex="-1" />
      <label for="permed_max" class="ml-2"> Max </label>
    </div>
    <Message v-if="!form.permed_max && form.peak_actual && form.permed > form.peak_actual" severity="error" :closable="false">
      Permed cannot be greater than peak actual.
    </Message>
  </div>
</template>

<script>
import { useRecordTarpingWorksStore } from '@/stores/RecordTarpingWorks';

import NumberInput from '@/components/forms/NumberInput.vue';
import Divider from 'primevue/divider';
import Checkbox from 'primevue/checkbox';
import Message from 'primevue/message';

export default {
  name: 'Permed',
  components: {
    NumberInput,
    Divider,
    Checkbox,
    Message,
  },
  created() {
    this.store = useRecordTarpingWorksStore();
  },
  computed: {
    form() {
      return this.store.form;
    },
    permed_max: {
      get() {
        return this.form.permed_max;
      },
      set(value) {
        const patch = {
          form: {
            permed_max: value,
          }
        };
        if (value) {
          patch.form.permed = null;
        }
        this.store.$patch(patch);
      }
    },
  },
}
</script>