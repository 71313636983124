import { defineStore } from 'pinia';
import { getOrCreateDB, getOrCreateItem, updateStoreLastUpdated, getItems } from './idb.js';

import Commodity from '@/stores/objects/Commodity';
import CommoditiesAPI from './api/Commodities.js';


export const useCommoditiesStore = defineStore('commodities', {
  state: () => ({ 
    commodities: [],
    lastUpdated: null,
  }),

  actions: {
    
    async loadCommoditiesFromAPI() {
      // Fetch commodities from API and update indexedDB
      const api = new CommoditiesAPI();
      const response = await api.getCommodities();

      const data = response.data;
      this.commodities = data;
      this.updateCommoditiesDB(data);

      return response
    },

    async loadCommoditiesFromDB() {
      console.log('Loading commodities from indexedDB...')
      const commodities = await getItems('commodities')
      console.log(`${commodities.length} commodities loaded from indexedDB`);
      this.commodities = commodities;

      const commoditiesMeta = await getOrCreateItem('meta', 'commodities');
      const favourites = commoditiesMeta.favourites || [];
      this.favourites = favourites;
      const lastUpdated = commoditiesMeta.lastUpdated;
      (lastUpdated) ? this.lastUpdated = lastUpdated : this.lastUpdated = null;
    },

    async loadCommodities(awaitAPI) {
      // Load commodities from cache and then update from API in background
      await this.loadCommoditiesFromDB();
      if (navigator.onLine) {
        const req = this.loadCommoditiesFromAPI();
        if (awaitAPI === true) await req;
      }
    },

    async updateCommoditiesDB(data) {
      // Update tarps indexedDB
      const db = await getOrCreateDB();
      const tx = db.transaction(['commodities', 'meta'], 'readwrite');
      const commoditiesStore = tx.objectStore('commodities');
      // Clear existing data
      await commoditiesStore.clear();
      // Add data
      data?.forEach(commodity => {
        commoditiesStore.add(commodity)
      });
      // Update last updated timestamp
      const now = Date.now();
      this.lastUpdated = now;
      console.log(`Updating last updated timestamp to ${now}`);
      updateStoreLastUpdated('commodities', now, tx);
      await tx.done;
    },
  },

  getters: {

    getCommodities(state) {
      return state.commodities.map(commodity => new Commodity().fromJSON(commodity));
    },

    getCommodityById: (state) => (id) => {
      return new Commodity().fromJSON(state.commodities.find(commodity => commodity.id === id));
    }

  }

})
