<template>
    <div @click="onClick">
        <slot name="label">
            <p class="label mb-0">{{ label }}</p>
        </slot>
    </div>
</template>

<script>

export default {
    name: "NavigationAction",
    props: {
        label: {
            type: String,
            default: '',
        },
    },
    methods: {
        onClick() {
            this.$emit('click');
        }
    }
}

</script>

<style scoped>
.label {
    font-size: 1rem;
    font-weight: 500;
}
</style>