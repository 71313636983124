import { defineStore } from 'pinia';
import { getOrCreateItem, updateStoreLastUpdated, clearStore, addItems, getItems  } from './idb.js';

import TarpJoinUser from '@/stores/objects/TarpJoinUser';
import TarpJoinUsersAPI from './api/TarpJoinUsers.js';
import Supervisor from './objects/Supervisor.js';


export const useTarpJoinUsersStore = defineStore('tarpJoinUsers', {
  state: () => ({ 
    tarp_join_users: [],
    lastUpdated: null,
  }),

  actions: {
    
    async loadTarpJoinUsersFromAPI() {
      // Fetch commodities from API and update indexedDB
      const api = new TarpJoinUsersAPI();
      const response = await api.getTarpJoinUsers();

      const data = response.data;
      this.tarp_join_users = data;
      this.updateTarpJoinUsersDB(data);

      return response
    },

    async loadTarpJoinUsersFromDB() {
      console.log('Loading tarp join users from indexedDB...')
      const tarpJoinUsers = await getItems('tarpJoinUsers');
      this.tarp_join_users = tarpJoinUsers;

      console.log(`${tarpJoinUsers.length} tarp join users loaded from indexedDB`);

      const tarpJoinUsersMeta = await getOrCreateItem('meta', 'tarpJoinUsers');
      const lastUpdated = tarpJoinUsersMeta.lastUpdated;
      (lastUpdated) ? this.lastUpdated = lastUpdated : this.lastUpdated = null;
    },

    async loadTarpJoinUsers(awaitAPI) {
      // Load tarp join users from cache and then update from API in background
      await this.loadTarpJoinUsersFromDB();
      if (navigator.onLine) {
        const req = this.loadTarpJoinUsersFromAPI();
        if (awaitAPI === true) await req;
      }
    },

    async updateTarpJoinUsersDB(data) {
      // Clears and repopulates tarp works in indexedDB
      clearStore('tarpJoinUsers');

      // Add data
      addItems('tarpJoinUsers', data);

      // Update last updated timestamp
      const now = Date.now();
      this.lastUpdated = now;
      updateStoreLastUpdated('tarpJoinUsers', now);
    },
  },

  getters: {

    getTarpJoinUsers(state) {
      return state.tarp_join_users.map(tju => new TarpJoinUser().fromJSON(tju));
    },

    getTarpJoinUserById: (state) => (id) => {
      return new TarpJoinUser().fromJSON(state.tarp_join_users.find(tarpJoinUser => tarpJoinUser.id === id));
    },

    getUserById: (state) => (id) => {
      const tju  = state.getTarpJoinUsers.find(tju => tju.user.id === id);
      return tju?.user || new Supervisor();
    },

    getWeldersStitchers(state) {
      return state.getTarpJoinUsers.filter(
        tju => tju.is_welder_stitcher).map(tju => tju.user);
    },

    getQualityControllers(state) {
      return state.getTarpJoinUsers.filter(
        tju => tju.is_quality_controller
      ).map(tju => tju.user);
    },

  }

})